import React from "react";
import "antd/dist/antd.css";
import { Row, Col, Input, Button, Icon, Tooltip } from "antd";
import style from "./style"

class DynamicBasicEntity extends React.Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() { }

    callBasicEntities = (entity) => {
        console.log('entity=====>', entity)
        return (
            <Row 
                style={style.basicForm.row}
            >
                <Col span={2} />
                <Col span={21} style={style.basicForm.col}>

                    {!entity.matchSearch && <div style={{ display: 'flex', alignItems:'center' }}> {!entity.newLabel &&
                        <div style = {{ width: '500px', padding: '5px', fontWeight : entity.showBold ? 'bold' : 'normal'}} 
                        className={entity.setActive ? "dynamicLabeActive" : "dynamicLabel"} onClick={() => this.props.handleDynamicClick(entity.name, entity.id)}>
                            {entity.name}
                        </div>}
                        {entity.showDelete && this.props.showAdvanceIcons && 
                        <Icon type="close" style={{fontSize: '12px', marginLeft:'5px', color: 'red'}} onClick={e => this.props.updateInput(e, entity.id, 'delete')} />
                    }
                    </div>}
                    {entity.newLabel && this.props.showAdvanceIcons &&
                        <Input placeholder="Label Name" defaultValue={entity.name} onPressEnter={e => this.props.updateInput(e, entity.id, 'save')} onChange={e => this.props.updateInput(e, entity.id, 'update')}
                            suffix={<Tooltip>
                                <Icon type="check" onClick={e => this.props.updateInput(e, entity.id, 'save')} style={{ color: 'green', cursor: 'pointer' }} />
                                <Icon type="close" onClick={e => this.props.updateInput(e, entity.id, 'delete')} style={{ color: '#f04134', cursor: 'pointer' }} />
                            </Tooltip>} />}
                </Col>
            </Row>
        )
    }

    renderList(item) {
        console.log('ites=====>', item)
        return (
            <div>
                <Row className="b_title"
                    key={item.id}
                    style={style.basicForm.row}
                >
                    <Col span={22} style={style.basicForm.col}>
                     {!item.matchSearch &&  <div  className={item.setActive ? "dynamicLabeActive" : "dynamicLabel"} style={{padding: '5px', display: 'flex', color: 'rgb(92, 193, 187)'}}>
                        <span id={"span-" + item.id} onClick={() => item = this.expand(item)
                        } className={(item.expand == true && !item.matchSearch) || item.showexpand
                                    ? "expand_sec" : "collapse_sec" } >
                            <Icon type="caret-right" style={{fontSize: '8px', marginRight:'5px'}} />
                            <Icon type="caret-down" style={{fontSize: '8px', marginRight:'5px'}} />
                        </span> 
                        <span style={{width: '100%', fontWeight : item.showBold ? 'bold' : 'normal'}} 
                                    onClick={() => this.props.handleDynamicClick(item.text || item.label || item.name || item.labelName, item.id)}>
                                    {item.text || item.label || item.name || item.labelName}
                        </span>
                        </div> }
                    </Col>
                    <Col span={1} />
                    <Col span={1}>
                    {!item.matchSearch && this.props.showAdvanceIcons && <Button
                            onClick={() => this.addclass(item)}  title="Add Child"  
                            type="link"
                            icon="plus"
                            style={{ color: '#0e77ca', border: 'thin', paddingBottom: '10px' }}
                        />}

                    </Col>
                </Row>

                <div className="column-reverse" id={item.id} 
                style={(item.expand == true && !item.matchSearch) || item.showexpand ? {display:'flex'} :  {display:'none'}}>
                    {item.entities.map((it) => {
                        return this.callBasicEntities(it)
                    })}
                </div>

            </div>
        );
    }

    addclass(item) {
        this.props.addEntityChild(item.id)
        var parent=document.getElementById(item.id);
        var master=document.getElementById('span-'+ item.id);
       
        
            master.className="expand_sec";
        

        if(parent !==null && parent !== undefined){
           
                parent.style.display = "flex";
            
        }
    }
    
    expand (item){
        console.log("div Id",item);
        if('expand' in item){
            item.expand = !item.expand;
        }else {
            item.expand = true;
        }
        var parent=document.getElementById(item.id);
        var master=document.getElementById('span-'+ item.id);
        if(master.className === "expand_sec")
            master.className="collapse_sec";
        else {
            master.className="expand_sec";
        }

        if(parent !==null && parent !== undefined){
            console.log('parent=====>',parent)
            if(parent.style.display === "flex"){
                console.log('flex')
                parent.style.display = "none";
            }else {
                parent.style.display = "flex";
            }
        }
        console.log("d",item);
        return item;
    }

    render() {
        const { list } = this.props;
        console.log('list====>', list)
        const buttonStyle = style.basicForm.close_fill
        const container = style.basicForm.constainer
        container.display = list.length > 0 ? 'block' : 'none'
        buttonStyle.display = list.length > 0 ? 'block' : 'none'
        return (
            <div>
                {list.map(it => {
                    return this.renderList(it);
                })}
            </div>
        )

    }
}

export default DynamicBasicEntity
