import { getUidToken } from "./LoginApis";
import { middleCallback } from "./commonApis";
import { BASE_URL } from "../helpers/Constants";
const superagent = require("superagent");

export const approveUserByAdmin = (userid, orgId, callback) => {
  console.log("Approve user By Admin", BASE_URL, window.location);
  const { uid, token } = getUidToken();

  superagent
    .post(BASE_URL + "approveUser")
    .send({ orgId: orgId, userId: userid })
    .set("uid", uid)
    .set("token", token)
    .end((err, res) => {
      middleCallback(err, res, callback);
    });
};

export const recoverUserByAdmin = (userId, callback) => {
  const { uid, token } = getUidToken();
  let url = BASE_URL + "recoverUserById/" + userId;
  superagent
    .post(url)
    .set("uid", uid)
    .set("token", token)
    .end((err, res) => {
      middleCallback(err, res, callback);
    });
};

export const deleteUserByAdmin = (userId, callback) => {
  const { uid, token } = getUidToken();
  let url = BASE_URL + "deleteUserById/" + userId;
  superagent
    .post(url)
    .set("uid", uid)
    .set("token", token)
    .end((err, res) => {
      middleCallback(err, res, callback);
    });
};

export const getAdminUsers = (searchObject, callback) => {
    console.log("In the getAdminUsers",searchObject)
  if (searchObject.page === null || searchObject.page === undefined) {
    searchObject.page = 0;
  }
  if (searchObject.size === null || searchObject.size === undefined) {
    searchObject.size = 10;
  }
  let url =
    BASE_URL +
    searchObject.orgId +
    "/getAdminUsers?page=" +
    searchObject.page +
    "&size=" +
    searchObject.size +
    "&orderBy=" +
    searchObject.orderBy +
    "&sortOrder=" +
    searchObject.sortOrder;
  const { uid, token } = getUidToken();
  superagent
    .get(url)
    .set("uid", uid)
    .set("token", token)
    .end((err, res) => {
      middleCallback(err, res, callback);
    });
};

export const getAdminsAndUsersByOrgId = (orgId, callback) => {
  let url = BASE_URL + "getAdminsAndUsersByOrgId/" + orgId;
  const { uid, token } = getUidToken();
  superagent
    .post(url)
    .set("uid", uid)
    .set("token", token)
    .end((err, res) => {
      middleCallback(err, res, callback);
    });
};

export const getOrganizationUsers = (orgId, callback) => {
  const { uid, token, roleId } = getUidToken();
  console.log("invoked: getOrganizationUsers");
  let url = BASE_URL + "getAdminsAndUsersByOrgId/" + orgId + "?page=0&size=10";
  if (parseInt(roleId) === 2) {
    url = BASE_URL + orgId + "/getAdminUsers?page=0&size=10";
    superagent
      .get(url)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback, "body");
        // callback(err, res.body);
      });
  } else {
    superagent
      .post(url)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback);
      });
  }
};

export const getOrgDetailsForAdmin = (userId, callback) => {
  let url = BASE_URL + "getOrgDetailsForAdmin/" + userId;
  const { uid, token } = getUidToken();
  superagent
    .post(url)
    .set("uid", uid)
    .set("token", token)
    .end((err, res) => {
      middleCallback(err, res, callback);
    });
};

export const getOrganizationUsersWithPagination = (
  orgId,
  page,
  size,
  callback,
  roleIdCheck,
  searchType,
  searchKeyword
) => {
  const { uid, token, roleId } = getUidToken();
  console.log("invoked: getOrganizationUsers");
  let url =
    BASE_URL +
    "getAdminsAndUsersByOrgId/" +
    orgId +
    "?page=" +
    page +
    "&size=" +
    size +
    "&Role=" +
    roleIdCheck;
  if (parseInt(roleId) === 2) {
    url =
      BASE_URL +
      orgId +
      "/getAdminUsers?page=" +
      page +
      "&size=" +
      size +
      "&searchType=" +
      searchType +
      "&searchKeyword=" +
      searchKeyword;
    superagent
      .get(url)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback, "body");
        // callback(err, res.body);
      });
  } else {
    superagent
      .post(url)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback);
      });
  }
};



export const createNewUserByAdmin = (fname, lname, email, orgName, orgId, role, managerId,status, callback)=>{
    console.log("Create New user By Admin",BASE_URL, window.location);
    const {uid, token} = getUidToken();
  
    superagent
    .post(BASE_URL +"createAdminORUserByAdmin/"+ uid)
    .send({ firstName: fname, secondName: lname, email:email, roleId:role,status:status})
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  }


  export const createNewUserBySuperAdmin = (fname, lname, email, orgName, orgId, role, managerId, status, callback)=>{
    console.log("Create New user By Admin",BASE_URL, window.location);
    const {uid, token} = getUidToken();
  
    superagent
    .post(BASE_URL +"createAdminORUserBySuperAdmin/"+ orgId)
    .send({ firstName: fname, secondName: lname, email:email, roleId:role, managerId:managerId, status:status})
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  }

  export const getUsersById = (userId,callback) => {
    console.log("getuserbyid",userId)
    let url = BASE_URL + "getUserInfo/"+userId;
    const { uid, token } = getUidToken();
     superagent
      .get(url)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
    };

    export const updateUserByAdmin = (fname, lname, email, role,userId, profileLink, profilePic,status,notificationToken,notes,mAuthorized,passwordChanged,managerId,deleted,approved,oauthId, oauthType,orgRefId, callback)=>{
        console.log("Create New user By Admin",BASE_URL, window.location);
        const {uid, token} = getUidToken();
      
        superagent
        .post(BASE_URL +"updateUserDetails")
        .send({ firstName: fname, secondName: lname, email:email, roleId:role,id:userId,profileLink:profileLink,profilePic:profilePic,status:status, notificationToken:notificationToken,notes:notes,mAuthorized:mAuthorized,managerId:managerId,passwordChanged:passwordChanged, deleted:deleted,approved:approved,oauthId:oauthId,oauthType:oauthType, orgIdRef:orgRefId })
          .set("uid", uid)
          .set("token", token)
          .end((err, res) => {
            middleCallback(err, res, callback)
          });
      }


      export const getAllOrganizationsWithOutPagination = (callback) => {
        let url = BASE_URL + "getAllOrgs";
        const { uid, token } = getUidToken();
         superagent
          .post(url)
          .set("uid", uid)
          .set("token", token)
          .end((err, res) => {
            middleCallback(err, res, callback)
          });
        };


        export const getAdminsByOrgId = (orgId,callback) => {
            const { uid, token } = getUidToken();
            let url = BASE_URL + "getAdminsAndUsersByOrgId/"+orgId;
            superagent
            .post(url)
            .set("uid", uid)
            .set("token", token)
            .end((err, res) => {
              middleCallback(err, res, callback)
            });
         }


         export const uploadProfileImage = (file, userId, callbackFn, progressCallback) => {
            // console.log('uploading file ', file, uid, pida, token);
            const { uid, token } = getUidToken();
            superagent
                .put(BASE_URL + "profile/image/upload/"+userId)
                .set("uid", uid)
                .set("token", token)
                .attach("file", file)
                .on("progress", function(event) {
                  console.log("Percentage done: ", event.percent);
                  if ( progressCallback ) {
                    progressCallback(event);
                  }
                })
                .end((err, res) => {
                  middleCallback(err, res, callbackFn)
                });
          };

          export const getAllOrganizations = (callback,page,size,orderBy,sortOrder,query) => {
            //let url = BASE_URL + "getAllOrgs";
            const { uid, token } = getUidToken();
            let url = BASE_URL + "getAllOrgsWithPagination";
           let searchrequest = {};
          
              if(query && query.length>0){
                searchrequest = {page:page,size:size,orderBy:orderBy,sortOrder:sortOrder, searchByCompanyName:query}  
              }else{
                searchrequest = {page:page,size:size,orderBy:orderBy,sortOrder:sortOrder}  
              }
              
             superagent
              .post(url).send(searchrequest)
              .set("uid", uid)
              .set("token", token)
              .end((err, res) => {
                middleCallback(err, res, callback)
              });
            };

            export const deleteOrganizationById = (orgId, callback) => {
              const { uid, token } = getUidToken();
              let url = BASE_URL + "deleteOrganisationById/" + orgId;
               superagent
                .post(url)
                .set("uid", uid)
                .set("token", token)
                .end((err, res) => {
                  middleCallback(err, res, callback)
                });
              };

              export const updateSubscription = (orgId, planId, type, callback) => {
                const { uid, token } = getUidToken();
                let url = BASE_URL + "updateSubscription/" + orgId + "/" + planId + "/" + type;
                 superagent
                  .post(url)
                  .set("uid", uid)
                  .set("token", token)
                  .end((err, res) => {
                    middleCallback(err, res, callback)
                  });
            };
        
            export const getSubscriptionPlans = (callback) => {
                  const { uid, token } = getUidToken();
                  let url = BASE_URL + "getPlans";
                   superagent
                    .get(url)
                    .set("uid", uid)
                    .set("token", token)
                    .end((err, res) => {
                      middleCallback(err, res, callback)
                    });
              };

              export const createOrganization = (orgName, webSiteUrl, contactName, contactEmail, contactPhone, city, country, logoUrl, notes, callback) => {
                const { uid, token } = getUidToken();
                superagent
                  .post(BASE_URL + "createOrg")
                  .send({ name: orgName, website: webSiteUrl, contactName: contactName, contactEmail: contactEmail, contactPhone: contactPhone, city: city, country: country, logoPic: logoUrl, notes: notes})
                  .set("uid", uid)
                  .set("token", token)
                  .end((err, res) => {
                    middleCallback(err, res, callback)
                  });
              };

              export const getOrganizationById = (orgId, callback) => {
                let url = BASE_URL + "getOrgDetailsbyOrgId/" + orgId;
                const { uid, token } = getUidToken();
                 superagent
                  .post(url)
                  .set("uid", uid)
                  .set("token", token)
                  .end((err, res) => {
                    middleCallback(err, res, callback)
                  });
                };
                
                export const updateOrganization = (id, orgName, webSiteUrl, contactName, contactEmail, contactPhone, city, country, logoUrl, notes, callback) => {
                  const { uid, token } = getUidToken();
                  superagent
                    .post(BASE_URL + "updateOrg")
                    .send({ id: id, name: orgName, website: webSiteUrl, contactName: contactName, contactEmail: contactEmail, contactPhone: contactPhone, city: city, country: country, logoPic: logoUrl, notes: notes})
                    .set("uid", uid)
                    .set("token", token)
                    .end((err, res) => {
                      middleCallback(err, res, callback)
                    });
                };