
const svgPath = {
    M6: 'M6.49544 3.62691L9.81868 4.35774C9.96974 4.39428 10.1208 4.32119 10.1586 4.17503C10.1963 4.02886 10.1208 3.8827 9.96974 3.8827L6.91085 3.18841L7.85495 0.301643C7.89271 0.155477 7.81719 0.00931198 7.66613 0.00931198C7.51507 -0.0272294 7.36402 0.0458534 7.32625 0.192019L6.30662 3.33458C6.26886 3.4442 6.34438 3.59037 6.49544 3.62691Z',
    M9: 'M9.10117 19.559C9.66764 18.5724 10.2341 17.5492 10.8006 16.5626C9.96975 17.3665 9.10118 18.1704 8.2326 18.9743C7.40179 18.3166 6.68427 17.6223 6.00452 16.8549C6.04228 16.8184 6.08005 16.8184 6.11781 16.7818C6.64651 16.4164 7.17521 16.0145 7.66614 15.649C8.34589 15.1375 8.98788 14.5893 9.55434 13.9681C10.0075 13.4566 10.4229 12.9084 10.8006 12.3238C10.8383 12.2872 10.8383 12.2507 10.8761 12.1776C13.1419 13.42 15.37 14.699 17.5981 15.9414C17.5603 15.9779 17.5603 16.0145 17.5226 16.051C17.1449 16.7453 16.8051 17.4761 16.5029 18.2435C16.2386 18.9012 16.0498 19.559 15.9365 20.2167C15.8232 20.8745 15.7854 21.4957 15.8232 22.1534C15.8232 22.2265 15.8232 22.2996 15.8232 22.3727C15.8232 22.3361 15.7477 22.3361 15.7099 22.3361C14.577 22.0438 13.4818 21.7149 12.3867 21.2764C11.2537 20.8379 10.1963 20.2898 9.21447 19.6321C9.1767 19.5955 9.13894 19.5955 9.10117 19.559Z',
    M11: 'M11.4047 11.4833C11.4047 11.3737 11.4425 11.264 11.4802 11.1544C11.6313 10.8986 11.7823 10.6063 11.9712 10.3505C12.16 10.0216 12.5754 9.94856 12.9153 10.1313C13.3684 10.3871 13.8216 10.6428 14.3125 10.8986C15.672 11.666 17.0316 12.4334 18.4288 13.2007C18.6176 13.3104 18.7309 13.42 18.8065 13.6392C18.8442 13.7854 18.8442 13.9681 18.7687 14.1143C18.6176 14.4066 18.4288 14.6624 18.2778 14.9547C18.089 15.2471 17.6735 15.3567 17.3337 15.174C16.805 14.8816 16.2763 14.5893 15.7098 14.2604C14.3881 13.5662 13.0663 12.8353 11.7823 12.1045C11.6691 12.0314 11.5558 11.9583 11.4802 11.8853C11.4425 11.7756 11.3669 11.6295 11.4047 11.4833Z',
    M16: 'M16.8428 11.7391C16.2386 11.4102 15.6344 11.0448 15.0302 10.7159C15.0302 10.6794 15.0679 10.6794 15.0679 10.6428C15.8232 9.40043 16.5785 8.12148 17.3338 6.87908C17.4471 6.69637 17.5981 6.55021 17.8247 6.51366C17.9758 6.47712 18.1268 6.51366 18.2779 6.58675C18.5045 6.69637 18.731 6.84254 18.9576 6.9887C19.2597 7.17141 19.373 7.53682 19.1842 7.90224C19.0331 8.19457 18.8443 8.4869 18.6933 8.74269C18.2401 9.5466 17.7492 10.314 17.296 11.1179C17.1827 11.3006 17.0694 11.5198 16.9561 11.7025C16.8806 11.666 16.8806 11.7025 16.8428 11.7391ZM18.4667 7.02524C18.3534 6.91562 18.1646 6.76945 18.0513 6.69637C18.0135 6.69637 17.9758 6.65983 17.938 6.65983C17.8625 6.62329 17.7869 6.65983 17.7114 6.73291C17.6736 6.76945 17.5981 6.84254 17.5603 6.91562C17.3715 7.20795 17.2205 7.50028 17.1072 7.82915C17.1072 7.82915 17.1072 7.82915 17.1072 7.86569C17.1449 7.82915 17.1827 7.79261 17.2205 7.75607C17.3338 7.6099 17.4093 7.46374 17.5226 7.31757C17.5981 7.20795 17.6736 7.13487 17.7492 7.06178C17.9002 6.91562 18.0513 6.87908 18.2779 6.95216C18.3156 6.9887 18.3912 6.9887 18.4667 7.02524Z',
    M27: 'M27.9832 13.7854C27.9832 13.5297 27.9455 13.3104 27.9455 13.0546C27.8699 12.2873 27.7189 11.5564 27.53 10.7891C26.3971 6.95221 23.5648 3.73657 19.7884 2.05567C18.1645 1.36138 16.4652 0.959427 14.6902 0.886345C13.8972 0.849803 13.1419 0.886345 12.3489 0.959427C11.6691 1.03251 11.0271 1.14213 10.3851 1.32484C8.98786 1.69025 7.66612 2.23838 6.49543 2.9692L6.79754 3.66349C8.87457 2.348 11.367 1.58063 14.0105 1.58063C21.3745 1.58063 27.2657 7.35417 27.2279 14.4432C27.1902 21.4591 21.3367 27.1961 13.935 27.1596C6.64649 27.1961 0.755282 21.4226 0.793047 14.297C0.793047 14.0778 0.793047 13.8951 0.830811 13.6758C0.604226 13.6758 0.339877 13.6758 0.0377641 13.6758C0.0377641 13.8951 0 14.1143 0 14.3336C0 14.7721 0.0377641 15.174 0.0755282 15.6125C0.151056 16.3799 0.264349 17.1107 0.490933 17.8415C1.73715 22.4458 5.36251 26.0633 10.0453 27.4154C11.0649 27.7077 12.0845 27.8904 13.1419 27.9635C13.293 27.9635 13.4063 28 13.5573 28C13.8972 28 14.1993 28 14.5392 28C14.6902 28 14.8413 27.9635 14.9546 27.9635C16.0875 27.8904 17.1827 27.7077 18.2401 27.3788C22.2431 26.0999 25.453 23.2131 27.0391 19.4494C27.4545 18.4627 27.7189 17.4396 27.8699 16.4164C27.9454 15.9414 27.9832 15.5029 27.9832 15.0279C27.9832 14.9913 27.9832 14.9548 27.9832 14.9182C27.9832 14.5894 27.9832 14.297 27.9832 13.9682C28.021 13.8951 27.9832 13.8585 27.9832 13.7854Z'
}

const styles = {
    wordPre: {
        whiteSpace: 'pre'
    },
    baseMargin: {
        margin: '5% 25% 5%',
        textAlign: 'center',
    },
    margint: {
        marginTop: '2%'
    },
    targetStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    targetStyle1:
    {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
    },
    lHeight: {
        height: '20px' 
    },

    formStyle: {
        border: '1px solid #ccc',
        padding: "1em",
            backgroundColor: "white"          
    },
    flexB: {
        display: 'flex',
       justifyContent: 'space-between'
   },

   flexC: {
       display: 'flex',
       flexDirection: 'column',
       justifyContent: 'space-between'
   },
   fSize: {
    fontSize: "18px",
    fontWeight: '500' 
   },
   fWrap: {
     fontSize: "18px",
     fontWeight: '500' 
   },
   fCenter: {
    display: 'flex',
    alignItems: 'center'
   },
   tCapse: {
    textTransform: 'capitalize'
   },
   hitConv: {
    display: 'flex',
     alignItems: 'flex-start',
    flexDirection: 'column',
     margin: '5px 0px'
   },
   tagedE: {
    display: "inline",
     fontSize: "1rem"
   },
   lineHeight: {
    lineHeight: '1.0rem'
   },
   mDiv: {
    padding: "20px",
     minHeight: "max-content"
   },
   dLabel: {
    border: 'none',
    padding: 5,
    marginBottom:15,
    width: '100%',
    borderBottom: '1px solid #ccc'
   },
   sLabel: {
    border: 'none',
    padding: 5,
    marginBottom:5,
    width: '100%',
    fontSize: '12px',
    color: 'red'
   },
   arsStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap', 
    maxHeight: '10em', 
    overflow: 'auto'
   },
   icnStyle: {
    float: 'none', 
    color: '#fff', 
    textShadow: 'none'
   },
   clasifyStyle: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "center",
    alignItems: 'center',
    padding: '0.5rem',
    flexWrap: 'wrap'
   },
   flexEven: {
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'space-evenly'
   },
   divBase: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "space-evenly",
    padding: '0.5rem'
   },
   flexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
   },
   sPadding: {
    padding: "0.5em 0"
   },
   customLab : {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between', 
    marginBottom: '5px'
   },
   labelTagg: {
    fonntWeight: 'bold', 
    color: '#12c4fc'
   },
   pSpace: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    backgroundColor: "white",
    width: "100%"
   },
   rSpacebetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between'
   },
   fStart: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
   },
   dFlex: {
    display: 'flex'
   },
   posTag: {
    width: "50%",
    border: "1px solid black",
    padding: "0.5rem",
    fontSize: "14px",
    opacity: "0.7",
    lineHeight: "1.2em"
   },
   helpStyle: {
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent:" center",
    background: "#12c4fc",
    borderRadius: "20px",
    marginRight: "5px"
   },
   helpStylePos: {
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent:" center",
    background: "#12c4fc",
    borderRadius: "20px",
    marginRight: "5px",
    marginLeft: "540px",
   },
   mRight: {
       marginRight: "5px !important"
   },
   svg: {
    fillcolor: '#5B5B5B'
   },
   modalHeight: {
    height: 'max-content'
   },
   icnWidth: {
       width: '30px'
   }
}

export default {
    styles,
    svgPath
}