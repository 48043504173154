import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import 'semantic-ui-css/components/reset.min.css';
import 'semantic-ui-css/components/site.min.css';
import 'semantic-ui-css/components/container.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/message.min.css';
import "../src/css/custom.css";
import "../src/css/semantic.css"
import AppRoute from './routes/routes'
import {Provider} from 'react-redux'; 
import './App.css'
import { history,store } from './redux/store/newstore'; 

class App extends React.Component {
  render() {    
    return (
      <Provider store={store}>
        <AppRoute history={history} store={store} example={"Example"}/>
      </Provider>
    );
  }  
}
ReactDOM.render(<App />, document.getElementById('root'));