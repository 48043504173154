
const styles = {
    sInitial: {
        marginTop: '2px', 
        overflow: 'initial'
    },
    buttonBG: {
        backgroundColor: 'white', 
        cursor: 'pointer'
    },
    setContainer: {
        cursor: 'pointer', 
        overflow: 'initial', 
        width: '100%', 
        display: 'flex', 
        alignItems: 'center', 
        padding: '0px 5px'
    },
    comboStyle: { 
        fontSize: '0.6rem', 
        marginLeft: '5px' 
    },
    flexCo: { 
        display: 'flex', 
        backgroundColor: '#464646', 
        overflow: 'auto', 
        flexDirection: 'column'
    },
    textC: { 
        textTransform: 'capitalize' 
    }, 
    flexC: {
        display: 'flex', 
        alignItems: 'center'
    },
    fSize: {
        fontSize: '12px'
    },
    mBase: {
        margin: '5% 25% 5%', 
        textAlign: 'center'
    },
    filterF: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between', 
        marginLeft:"6%"
    },
    dFlex: {
        display: 'flex'
    },
    flexR: { 
        display: "flex", 
        flexDirection: "row" ,
        marginLeft:"6%"
    },
    fBetween: {
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-between'
    },
    fWrap: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '100%'
      },
    divContainer: {
        backgroundColor: "#ffffff",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    linebHeight: { 
        lineHeight: 0, 
        display: 'block', 
        position: 'relative' 
    },
    zoomPan: {
        width: '100%', 
        height: '100%'
    },
    posR: { 
        position: 'relative'
    },
    cardB: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        marginTop: '5px'
    },
    boxWidth: {
        width: '95%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    boxSize: { 
        display: 'flex', 
        boxSizing: 'border-box', 
        flexWrap: 'wrap' 
    },
    hPadding: {
        padding: '20px',
        minHeight: 'max-content'
    },
    inputBStyle: {
        border: 'none',
        borderBottom: '0.5px solid',
        padding: 0,
        marginBottom: 15,
        width: '100%'
    },
    rightBox: {
        height: '100%', 
        overflow: 'auto', 
        background: '#2e2e2e'
    },
    rHolder: { 
        display: 'flex', 
        flexDirection: 'column', 
        paddingLeft: '10px', 
        paddingRight: '10px', 
        borderTop: '1px solid #5a5858', 
        backgroundColor: '#2e2e2e', 
        boxSizing: 'border-box', 
        width: '100%'        
    },
    pTextColor: { 
        padding: '5px', 
        color: '#fff' 
    },
    segRaised: { 
        width: '20%', 
        padding: '0', 
        marginTop: '0px', 
        background: '#464646' 
    },
    bPadding: {
        padding: '5px'
    },
    tMenu: {
        color: '#fff',
        cursor: 'pointer'
    },
    mCenter: {
        width: '30px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    eLabel: {
        color : 'red', 
        fontWeight: '400'
    },
    modalHeight: {
        height: 'max-content'
    },
    dLabel: {
        border: 'none',
        padding: 5,
        marginBottom:15,
        width: '100%',
        borderBottom: '1px solid #ccc'
       },
       sLabel: {
        border: 'none',
        padding: 5,
        marginBottom:5,
        width: '100%',
        fontSize: '12px',
        color: 'red',
        fontWeight: '300'
       }
}

export default {
    styles
}