import React, { Component, PropTypes } from 'react';
import { Button, Dropdown, Label, Icon } from 'semantic-ui-react';
import { Modal, Radio, LocaleProvider} from 'antd'
import { convertKeyToString,DOCUMENT_ANNOTATION, CHILD_NODE_LIMIT, DOC_ANNO_ALERT_TITLE, DOC_ANNO_CHILDNODEALERT, DOC_ANNO_OK_TEXT, POS_TAGGING, POS_TAGGING_GENERIC, displaySettingsByRole, ROLES_TYPES,  } from '../../helpers/Utils';
import rangy from 'rangy';
import EntityTree from './EntityTree'
import "antd/dist/antd.css";
import DynamicAdvanceEntity from './DynamicAdvanceEntity.js'
import DynamicBasicEntity from './DynamicBasicEntity.js';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css' 
import { getUidToken,showAlert } from '../../helpers/commonFunctions.js';
import { editProject } from '../../Services/TaggerApis.js';
import en_US from 'antd/lib/locale-provider/en_US';
import helper from "../../Pages/ImportProjects/ImportProjectHelpers.js"
const uuidv1 = require('uuid/v1')
const Mousetrap = require('mousetrap');

const UNKNOWN_CAT = 'UNKNOWN';
const UNKNOWN_COLOR = 'grey';

export const DOC_ANNOTATOR_CONSTANTS = {
    CLEAR_ALL_TAGGED_ITEM: 1,
    CLEAR_ALL_YOUR_TAGGING: 2,
    TAGGED_ITEM_MESSAGE: 'Are you sure you wish to clear all tagged items?',
    TAGGING_MESSAGE: 'Are you sure you wish to clear your taggings?'
};

export default class DocumentAnnotator extends Component {
  constructor(props) {
    super(props)
    this.handleSelection = this.handleSelection.bind(this)
    this.removeSelection = this.removeSelection.bind(this)
    this.handleMarkerClick = this.handleMarkerClick.bind(this)
    this.saveCategory = this.saveCategory.bind(this)
    this.selectCategory = this.selectCategory.bind(this)
    this.showClearAllModal = this.showClearAllModal.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.clearOnlyUserTaggings = this.clearOnlyUserTaggings.bind(this);   
    this.unbindAllShortcuts = this.unbindAllShortcuts.bind(this); 
    let documentText = this.props.documentText;
    let emojiIndexMap = this.props.emojiIndexMap;
    let taskType = this.props.taskType;
    if (this.props.urlData) {
      documentText = this.getDocumentText(this.props.documentText);
    }

    let basicEntities = '';
    let advanceEntities = '';
    if (taskType === DOCUMENT_ANNOTATION) {
      basicEntities = this.callBasicEntity(this.props.taskRules) // Calling basic entity onload
      advanceEntities = this.callAdvanceEntity(this.props.taskRules) // Calling advance entity onload
    }
    this.state = {
      annotations: props.annotations,
      documentText,
      emojiIndexMap,
      annotationCatMap: props.annotationCatMap,
      currentAnnotations:props.annotations,
      entities: props.entities,
      newEntities: [],
      undoAnnotations: [],
      redoAnnotations:[],
      topIndex:-1,
      autoLabel: props.autoLabel,
      postitionAnnotationMap: {},
      firstTime: false,
      emojiModalShow : false,
      annotateFromSelection : false,
      basicEntities,
      dynamicTags: [],
      advanceEntities,
      savedTags : [],
      tag: '',
      allowAutoClose : false,
      userRole: this.props.userRole,
      taskType: taskType,
      clearOptionValue: 1,
      clearModalVisible: false,
      annotateAfterClearAll : false,
      categorySelected : false,
      checkCloseButton: false,
      enterto: false,
      lastUndoAnnotationsLength: 1,
      undoAnnotationsHistory:[],
      redoAnnotationsHistory:[]
    };
  }
  dynamicTagswithIds = []
  advanceLabelDeletedIds = []
  taggsToDelete = []
  advancedOperation = []
  emptyCheck = false
  submitDisabled = true
  checkForChangesAdv = false
  checkForAdvanceNodata = false
  checkForBasicNodata = false
  mousetrapBindings = [];
  componentDidMount() {
    if (this.props.annotateCallback) {
      let combo = '';
      if (this.props.shortcuts && 'save' in this.props.shortcuts) {
        combo = convertKeyToString(this.props.shortcuts.save);
        Mousetrap.bind(combo, this.saveCategory);
        this.mousetrapBindings.push(combo);
      }
      if (this.props.shortcuts && 'close' in this.props.shortcuts) {
        combo = convertKeyToString(this.props.shortcuts.close);
        if(this.props.allowDynamiclabelling)
        	Mousetrap.bind(combo, this.callCancelChanges);
        else
          Mousetrap.bind(combo, this.saveCategory);
        this.mousetrapBindings.push(combo);
      }
    }
    Mousetrap.bind("ctrl+z",this.undo);
  }

  componentWillReceiveProps(nextProps) {
    
    if (nextProps.annotations && (this.props.documentText !== nextProps.documentText)) {
      this.setState({
        annotations: nextProps.annotations,
        autoLabel: nextProps.autoLabel,
        newEntities: [],
        menuOpen: false,
        showModal: false,
        showAnnotation: {},
        top: 0
      });
      if (nextProps.urlData) {
        this.setState({ documentText: this.getDocumentText(nextProps.documentText) });
      } else {
        this.setState({ documentText: nextProps.documentText });
      }
    }
    
    if(!this.state.annotateAfterClearAll){
    	this.setState({
            annotations: nextProps.annotations});
    }

    this.setState({ newEntities: [], autoLabel: nextProps.autoLabel ,categorySelected : false, userRole : nextProps.userRole });
    if(nextProps.entities && nextProps.entities.length > 0 && this.state.taskType === POS_TAGGING_GENERIC){
     this.setState({ entities: nextProps.entities })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.mouseupHandle);
    Mousetrap.unbind("ctrl+z")
    this.unbindAllShortcuts();
  }

  unbindAllShortcuts() {
    if (this.mousetrapBindings.length < 1) {
      return;
    }
    
    this.mousetrapBindings.forEach((binding) => {
      Mousetrap.unbind(binding);
    });
  }

  getDocumentText(url) {
    fetch(url)
      .then(function (response) {
        if (response.ok) {
          return response.text();
        }
        throw new Error('Error message.');
      })
      .then(function (data) {
        this.setState({ documentText: data });
      }.bind(this))
      .catch(function (err) {
        showAlert(' Error in Accessing Text File', 'error');
      });
  }
  getOffsets(e) {
    const target3 = this.parentDiv;
    const rect3 = target3.getBoundingClientRect();
    const offsetY3 = (e.clientY - rect3.top);
    return offsetY3;
  }

  getLabels() {
    const arrs = [];
    const { annotations } = this.state;
    let index = 0;
    for (let index = 0; index < annotations.length; index++) {
      let size = 'mini';
      arrs.push(<Label size={size} id={index} style={{
        color: 'white',
        overflow: 'auto',
        marginBottom: '0.4rem',
        padding: '0.4rem',
        backgroundColor: `${annotations[index].color[0]}`
      }}>
        {annotations[index].category.join()}
        <br />
        <p style={{
          fontSize: 'xx-small'
        }}>
          {annotations[index].start}
          to {annotations[index].end}
          <br /> {annotations[index].text}</p>
        {this.props.annotateCallback && <Icon name="delete" id={index} onClick={this.removeLabel.bind(this, index)} />}
      </Label>);
    }
    return (<div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      {arrs}
    </div>);
  }

  getSelectionCharOffsetsWithin(element) {
    let start = 0;
    let end = 0;
    const sel = document.selection;
    let range;
    let priorRange;
    if (typeof window.getSelection !== 'undefined') {
      range = window.getSelection().getRangeAt(0);
      priorRange = range.cloneRange();
      priorRange.selectNodeContents(element);
      priorRange.setEnd(range.startContainer, range.startOffset);
      start = priorRange.toString().length;
      end = start + range.toString().length;
    } else if (typeof sel !== 'undefined' && sel.type !== 'Control') {
      range = sel.createRange();
      priorRange = document.body.createTextRange();
      priorRange.moveToElementText(element);
      priorRange.setEndPoint('EndToStart', range);
      start = priorRange.text.length;
      end = start + range.text.length;
    }
    return { start: start, end: end };
  }

  getCharOffsetRelativeTo(container, node, offset, fnode, foffset) {
    const range = rangy.createRange();
    range.selectNodeContents(container);
    range.setEnd(fnode, foffset);
    if (rangy.getSelection().isBackwards()) {
      range.setEnd(fnode, foffset);
      return range.toString().length;
    }
    range.setEnd(node, offset);
    return range.toString().length;
  }

  removeLabel(index) {
    const { annotateCallback } = this.props;
    const annotations = this.state.annotations;
    annotations[index] = {};
    annotations.splice(index, 1);
    this.setState({
      annotations
    }, () => {
      if (annotateCallback) {
        annotateCallback(this.state);
      }
    });
  }

  showClearAll() {
    this.setState({
      currentAnnotations:[],
      undoAnnotations:[],
      redoAnnotations:[],
      clearModalVisible: true
    });
  }

  clearAll() {
     // Id - 1 for clear all tagged items
     this.clearTagConfirm(DOC_ANNOTATOR_CONSTANTS.CLEAR_ALL_TAGGED_ITEM);
  }

  clearOnlyUserTaggings() {
    // Id - 2 for clear your taggings
    this.clearTagConfirm(DOC_ANNOTATOR_CONSTANTS.CLEAR_ALL_YOUR_TAGGING);
  }

  clearTagConfirm = (tagId) => {
    const that = this
    const confirm = Modal.confirm;
    const content = (tagId === DOC_ANNOTATOR_CONSTANTS.CLEAR_ALL_TAGGED_ITEM) ?
    DOC_ANNOTATOR_CONSTANTS.TAGGED_ITEM_MESSAGE :
    DOC_ANNOTATOR_CONSTANTS.TAGGING_MESSAGE
    confirm({
        title: 'Confirmation',
        content: content,
        okText: 'OK',
        cancelText: 'CANCEL',
        onOk() {that.confirmAlertOkTapped(tagId)},
        onCancel() {return false},
      });
  }

confirmAlertOkTapped = (tagId) => {
  const { annotateCallback, showClearButton } = this.props;
  const { annotations } = this.state;
  if(tagId == DOC_ANNOTATOR_CONSTANTS.CLEAR_ALL_TAGGED_ITEM) {
    this.setState({ annotations: [], annotationCatMap: {} }, () => {
        annotateCallback(this.state);
        showClearButton(true)
    });
  } else if(tagId == DOC_ANNOTATOR_CONSTANTS.CLEAR_ALL_YOUR_TAGGING) {
    let clientAnnotations = annotations.filter( annotation => annotation.category.filter(cat => cat.value === cat.key).length > 0);
      this.setState({ annotations: clientAnnotations, annotationCatMap: {} }, () => {
        annotateCallback(this.state);
      });
  }
}

  onClearOptionChange = e => {
    this.setState({
      clearOptionValue: e.target.value
    });
  };
  handleClearOk = e => { 
    this.setState({
      clearModalVisible: false
    }, ()=>{
      if ( this.state.clearOptionValue === 1 ) {
        this.clearAll();
      } else if ( this.state.clearOptionValue === 2 ) {
        this.clearOnlyUserTaggings();
      }
      this.setState({
        clearOptionValue: 1
      });
    });
  };

  handleClearCancel = e => {   
    this.setState({
      clearModalVisible: false,
      clearOptionValue: 1
    });
  };
  showClearAllModal(){
    const { clearOptionValue } = this.state;
    return(
      <LocaleProvider locale={en_US}>   
        <Modal
          title="Clear Annotations"
          visible={this.state.clearModalVisible}
          onOk={this.handleClearOk}
          onCancel={this.handleClearCancel}
        >
        <Radio.Group onChange={this.onClearOptionChange} value={clearOptionValue}>
          <Radio value={1}>
            Clear All Taggings
          </Radio>
          <Radio value={2}>
            Clear Project User Taggings only
          </Radio>
        </Radio.Group>
        </Modal>
      </LocaleProvider>
    );
  }
  
   mapToJson(inputMap) {
	    let obj = {};

	    inputMap.forEach(function(value, key){
	        obj[key] = value
	    });

	    return obj;
	}
   
   getLengthOfEmoji(startIndex){
	   let jObject  = this.props.emojiIndexMap;
	   let keys = Object.keys(jObject); 
	   let length = 0 ;
	   for(let i= 0; i<keys.length;i++ ){	  
		   if(keys[i] == startIndex ){
			   length =  jObject[keys[i]].length;
			   break;
		   }
	   }
	   return length;
   }
   
   compareEmojiIndexMap(offset){
	      let jObject  = this.props.emojiIndexMap; 
	      jObject = this.reOrderEmojiIndex(jObject);
		  let keys = Object.keys(jObject); 
		  let count = 0;
		  let length = 0;	  
		  for(let i= 0; i<keys.length;i++ ){	  
			  if(keys[i] < offset ){
				  count = count +1;
				  length = length + jObject[keys[i]].length;
			  }  
		  }
		  
		  offset = offset - (length-count);
		  
		  // for handle color emoji after text
		  let jObjectOrgJson = this.props.emojiIndexMap;
		  let keyOrg = Object.keys(jObjectOrgJson); 		  
			  for(let k= 0; k < keyOrg.length; k++ ){	
				  if(keyOrg[k] < offset && jObjectOrgJson[keyOrg[k]].length > 2){
					  offset = offset + 1;
				  }
			  }	  
		  
		  return offset;	  
	  }
   
  getReorderLength(jObject){
	  let keys = Object.keys(jObject); 
	  let length = 0;	 
	  for(let i= 0; i<keys.length;i++ ){		  
		let size = jObject[keys[i]].length;
		if(size > 1){
			  length = length + (Math.floor(jObject[keys[i]].length/2));
		}
	  }
	  
	  return length;
  }
  
  reOrderEmojiIndex(jObject){
	  let keys = Object.keys(jObject); 
	  let jsonObj = {};
	  jsonObj[keys[0]] = jObject[keys[0]];
	  let length = 0;
	  for(let i= 1; i<keys.length;i++ ){
		  let key = keys[i]
		  let value = jObject[keys[i]];				 
		  length = this.getReorderLength(jsonObj);
		  let index_key = parseInt(key)+length;
		  jsonObj[index_key] = value;
	  }
	  return jsonObj;
  }

  unicodeToChar(text) {
	   return text.replace(/\\u[\dA-F]{4}/gi, 
	          function (match) {
	               return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
	          });
	}
  
   containsNonLatinCodepoints(s) {
	    return /[^\u0000-\u00ff]/.test(s);
	}
   
   replaceString(text){
	   return text;
   }
   
   checkStartWithSpace(selectedText){
	   selectedText = [...selectedText];
	   if(selectedText[0] === " "){
		   return true;
	   }
   }
   
   getEnterSymbol(selectedTest){
	   let strArr = selectedTest.split("\n");
	   return strArr.length;
   }
   removeSelection(event) {
    const{showUndoButton,showRedoButton} = this.props;      
    if (event.detail > 1) {
      document.getElementById('annotationDoc').classList.add('noselection');
      showUndoButton(true)
      showRedoButton(true)
    }
   } 

  handleSelection(event) {
    if (event.detail > 1 && window.getSelection().toString().indexOf('\n') == 0) {
      event.preventDefault();
      window.getSelection().removeAllRanges();
      return;
    }
    document.getElementById('annotationDoc').classList.remove('noselection');
    if (!this.props.annotateCallback) {
      return;
    }
    if (window.getSelection().toString().trim().length > 0 && window.getSelection().toString().trim() !== ' ' && this.props.selectedLabel === '') {
      const top = this.getOffsets(event)
      let getselectedText = window.getSelection().toString().trim();
      let orginalText  = window.getSelection().toString();
      // Function to remove unwanted appends when last line clicks
      let selectedTextArr = getselectedText.split("\n")
      let selectedText = ""
      selectedTextArr.map((text, index)=>{
        if (text !== "Previous (left)" && text !== "Skip (ctrl+q)" &&  text !== "Move To Done (ctrl+enter)" && text !== "Next (right)" && text !== "Save (enter)"){
          if (selectedText !== "") {
            selectedText = selectedText + "\n"
          }
          selectedText = selectedText + text
        }
      })
      this.setState({annotateFromSelection: true});
      this.setState({annotateAfterClearAll: true});
      
      // END
      const parentText = document.querySelector('.document').innerText.toLowerCase();
      let dup_parentText = parentText.replace(/(\r\n|\n|\r)/gm, "").toLowerCase().toString().trim();
      const indices = [];
      let startIndex = 0;
      let index = 0;
      let dup_index = 0;
      let emojiIndex = 0;
      if (this.props.autoLabel) {
        index = parentText.indexOf(selectedText.toLowerCase(), startIndex);     
        if (startIndex === 0 && index === -1) {
        	 let sel_text = window.getSelection();
             const pre_text = document.getElementById('annotationDoc');
             let offset_text = this.getCharOffsetRelativeTo(pre_text, sel_text.anchorNode, sel_text.anchorOffset, sel_text.focusNode, sel_text.focusOffset);
         	if (this.containsNonLatinCodepoints(selectedText.trim())) {
        		let selectedText1 =   this.replaceString(selectedText.trim());
            	if (selectedText1.length === 1) {
        			let length = this.getLengthOfEmoji(index);
        			if (length === selectedText.trim().length) {
        				indices.push(offset_text); // to prevent selection of color emoji
        			}                 	                 
            	} else {
            		 indices.push(offset_text);
            	}
        	} else {
        		 indices.push(offset_text);
        	}

        }
        while (index > -1) {
            dup_index = index;
            	if (indices.includes(index)) {
            		break;
              }
              if (this.containsNonLatinCodepoints(selectedText.trim())) {
            		let selectedText1 = this.replaceString(selectedText.trim());
            		if (selectedText1.length === 1) {
            			let length = this.getLengthOfEmoji(index);
                    	if (length === selectedText.trim().length) {
                    		 indices.push(index); // to prevent selection of color emoji
                    	}
            		} else {
            			indices.push(index);
            		}
            	} else {
            		indices.push(index);             
              }
              startIndex = dup_index + selectedText.length;
              index = parentText.indexOf(selectedText.toLowerCase(), startIndex);
        }
      } else {
        const sel = window.getSelection();       
        const pre = document.getElementById('annotationDoc');
        let offset = this.getCharOffsetRelativeTo(pre, sel.anchorNode, sel.anchorOffset, sel.focusNode, sel.focusOffset)       
        if (this.checkStartWithSpace(orginalText)) {
        	offset = offset + 1;
        }
        
        indices.push(offset)
      }
      let annotations = this.state.annotations;
      const newAnnotations = [];
      const undoAnnotations = [];
      const postitionAnnotationMap = this.state.postitionAnnotationMap;
      var newArray = annotations.map(e => {
        return e.id;
      });
      const existingAnnotationSet = new Set(newArray);
      const annotationSet = new Set();
      for (index = 0; index < indices.length; index++) {
        const start = parseInt(indices[index], 10);
        let end = parseInt(start + selectedText.trim().length - 1, 10);
        if (this.containsNonLatinCodepoints(selectedText.trim())) {
          var textArr = getselectedText.split("\n");
          if (textArr.length > 1) {
            end = end + (textArr.length - 1)
          }
        	 let s_text =   this.replaceString(selectedText.trim());
       	     let strArr = [...selectedText.trim()];
       	     if (s_text.length === strArr.length) {
            	end = parseInt(start + s_text.length - 1, 10);
       	     } else {
       	    	end = parseInt(start + strArr.length - 1, 10);
       	     }
        }
        
     if (this.state.taskType === DOCUMENT_ANNOTATION){
    	 if (end >= start) {
             const category = [];
             const color = [];
             category.push({
               key: UNKNOWN_CAT,
               value: UNKNOWN_CAT
             })
             color.push(UNKNOWN_COLOR)
             const id = start + '-' + end;
             if (!existingAnnotationSet.has(id)) {
               annotationSet.add(id);
               newAnnotations.push({
                 start,
                 end,
                 text: selectedText,
                 category,
                 color,
                 id
               });
               if (this.state.entities.length === 1) {
                 annotations.push({
                   start,
                   end,
                   text: selectedText,
                   category,
                   color,
                   id
                 });
                 undoAnnotations.push(id);
               }
               for (let jindex = start; jindex <= end; jindex++) {
                 const currenvalue = [
                   {
                     id,
                     color
                   }
                 ];
                 postitionAnnotationMap[jindex] = currenvalue;
               }
             }
           }
     }else if(this.state.taskType === POS_TAGGING_GENERIC){
    	 if (end >= start) {
             const category = [];
             const color = [];
             if (this.state.entities.length === 1) {
               category.push(this.state.entities[0]);
               color.push(this.props.entityColorMap[this.state.entities[0]]);
             } else {
               category.push(UNKNOWN_CAT);
               color.push(UNKNOWN_COLOR);
             }
             const id = start + '-' + end;
             if (!existingAnnotationSet.has(id)) {
               annotationSet.add(id);
               newAnnotations.push({
                 start,
                 end,
                 text: selectedText,
                 category,
                 color,
                 id
               });
               if (this.state.entities.length === 1) {
                 annotations.push({
                   start,
                   end,
                   text: selectedText,
                   category,
                   color,
                   id
                 });
                 undoAnnotations.push(id);
               }
               for (let jindex = start; jindex <= end; jindex++) {
                 const currenvalue = [
                   {
                     id,
                     color
                   }
                 ];
                 postitionAnnotationMap[jindex] = currenvalue;
               }
             }
           }
     }

      }
      let currentAnnotations = [...annotationSet];
      this.state.undoAnnotationsHistory.push(currentAnnotations);

      if (this.state.entities.length !== 1) {
        annotations = annotations.concat(newAnnotations);
      }

       if((parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR)){
        this.setState({
          annotations,
          selecting: true,
          undoAnnotations,
          postitionAnnotationMap,
          menuOpen: true,
          showModal: true,
          firstTime: true,
          showAnnotation: {
            annotation: newAnnotations[0],
            annotationSet,
            top
          }
        }, () => { this.props.annotateCallback(this.state) })
      }
    }
  }

  handleMarkerClick(event) {
    
    event.preventDefault();
    if (!this.props.annotateCallback) {
      return;
    }
    if (event.target.nodeName === 'SPAN' && window.getSelection().toString().length === 0 && this.props.selectedLabel === '') {
      const annotation = this.state.annotations.filter((annotation1) => {
        if (event.target.dataset.id === annotation1.id) {
          return annotation1;
        }
      });
      if (annotation) {
        const annotationSet = new Set();
        annotationSet.add(annotation[0].id);
        const top = this.getOffsets(event);
        this.setState({
          showModal: true,
          firstTime: false,
          showAnnotation: {
            annotation: annotation[0],
            top,
            annotationSet,
            annotationList: annotation
          }
        }, () => {
        	this.props.disableFilterOnClickSpan(this.state.showModal);
            	  });
      }
    } else if (this.dropDown && !this.state.selecting) {
      this.saveCategory();
    }
  }

  undo=() => {
    const newAnnotations = []
    let undoAnnotation=[]
    let topIndex=this.state.topIndex
    const{ showClearButton,showUndoButton,showRedoButton} = this.props;
    this.state.lastUndoAnnotationsLength = this.state.undoAnnotations.length;
    for (let index = 0; index < this.state.annotations.length; index++) {
      if (this.state.undoAnnotations.indexOf(this.state.annotations[index].id) < 0) {
        newAnnotations.push(this.state.annotations[index]);
      }
      else{
        topIndex=topIndex + 1
        this.state.redoAnnotations.push(this.state.annotations[index])
      }
    }

    this.state.redoAnnotationsHistory.push(this.state.undoAnnotationsHistory.pop());
    
    if (this.state.currentAnnotations === undefined || newAnnotations.length === 0 ) {
      undoAnnotation=[]
    } else if ((this.state.currentAnnotations.length!==newAnnotations.length) ){    
      undoAnnotation.push(newAnnotations[newAnnotations.length-1].id)
    }
    if(newAnnotations.length === 0){
      undoAnnotation=[]
      showClearButton(true)
      showUndoButton(true)
      showRedoButton(true)
    }
    if(undoAnnotation.length === 0){
      showUndoButton(true)
      showRedoButton(false)
    }
    if(this.state.redoAnnotations.length === 0){
      showRedoButton(true)
    }

    this.setState({
      annotations: newAnnotations,
      undoAnnotations:this.state.undoAnnotationsHistory[this.state.undoAnnotationsHistory.length-1]
    }, () => {
      this.props.annotateCallback(this.state);
    });
  }

  redo(){
    let undoAnnotation=[];
    const{showRedoButton} = this.props;
    let newAnnotation=this.state.annotations;

    while(this.state.lastUndoAnnotationsLength > 0){  
    newAnnotation.push(this.state.redoAnnotations[this.state.redoAnnotations.length-1])
    undoAnnotation.push(this.state.redoAnnotations[this.state.redoAnnotations.length-1].id)
    this.state.redoAnnotations.pop();
    this.state.lastUndoAnnotationsLength--;
    }

    this.state.undoAnnotationsHistory.push(this.state.redoAnnotationsHistory.pop());

    if(this.state.redoAnnotations.length <= 0){
      showRedoButton(true)
    } else {
      this.state.lastUndoAnnotationsLength = this.state.redoAnnotationsHistory[this.state.redoAnnotationsHistory.length - 1].length;
    }
    this.setState({
      annotations:newAnnotation,
      undoAnnotations:undoAnnotation
    },()=>{
      this.props.annotateCallback(this.state);
    })

  }

  saveCategory(callbackFlag) {
    const annotations = this.state.annotations;
    const newAnnotations = [];
    for (let index1 = 0; index1 < annotations.length; index1++) {
      if (annotations[index1].color.length !== 0 && (annotations[index1].color.indexOf(UNKNOWN_COLOR) < 0)) {
        newAnnotations.push(annotations[index1]);
      }
    }
    this.setState({annotateAfterClearAll: true});
    if(annotations.length !== newAnnotations.length){
      this.setState({enterto: true});
      this.setState({
        showModal: false,
        showAnnotation: {},
        annotations: newAnnotations
      }, () => {
        this.props.annotateCallbackForCancellingEntity(this.state);
        this.setState({enterto: false});
      });
    } else if (callbackFlag) {
      this.state.annotations = newAnnotations;
      this.setState({ showModal: false, showAnnotation: {} })
      this.props.annotateCallback(this.state);
    } else {
      this.setState({
        showModal: false,
        showAnnotation: {},
        annotations: newAnnotations
      }, () => {
        this.props.annotateCallback(this.state);
      });
    }
  }

  /**
   * calls when selected tags for annotation from entity tree
   * updates according to the new json object structure
   * --> savecategory()
   * @param {values, labels}
   */
  selectCategory(values, label, e, Flag) {
    const showAnnotation = this.state.showAnnotation;
    var annotationList = showAnnotation.annotationList;
    var annotation = {};
    
    const newCats = []
    const newCols = []
    const undoAnnotations = []
    var isAnnotationList = false;
    
    
    if(undefined != annotationList) {
    	for(var index = 0;index < this.state.showAnnotation.annotationList.length;index++) {
  		  if (undefined != this.state.showAnnotation.annotationList[index].category) {
  			for(var categoryIndex = 0;categoryIndex <this.state.showAnnotation.annotationList[index].category.length;categoryIndex++) {
  				if(undefined != this.state.showAnnotation.annotationList[index].category[categoryIndex] && this.state.showAnnotation.annotationList[index].category[categoryIndex] == e.triggerValue) {
  					annotation = annotationList[index];
  					if(values.length === 1) {
  					values.forEach((it, index) => {
              if(it !==undefined && null !=it ){
  		    	      newCats.push({
  		    	        key: it,
  		    	        value: label[index].props ? label[index].props.children : label[index]
  		    	      })
                  newCols.push(UNKNOWN_COLOR);
                }
  					})
  					} else if(values.length > 1){
  						values.forEach((it, index) => {
                if(it !==undefined && null !=it ){
  	  		    	      newCats.push({
  	  		    	        key: it,
  	  		    	        value: label[index].props ? label[index].props.children : label[index]
  	  		    	      })
                      newCols.push('#A52A2A');
                }
                })
  					} 
  					isAnnotationList = true;
  				}
  				
  			}
  		  }
    	}
    } 
    
    if(!isAnnotationList){
    	annotation = showAnnotation.annotation;
    	values.forEach((it, index) => {
    	      newCats.push({
    	        key: it,
    	        value: label[index].props ? label[index].props.children : label[index]
    	      })
    	      newCols.push('#A52A2A');
    	})
    }
    
    annotation.category = newCats;
    annotation.color = newCols;
    showAnnotation.annotation = annotation;
    const annotationSet = this.state.showAnnotation.annotationSet;
    const annotations = this.state.annotations;
    const postitionAnnotationMap = this.state.postitionAnnotationMap;
    for (let index1 = 0; index1 < annotations.length; index1++) {
      if (annotationSet.has(annotations[index1].id)) {
        if(isAnnotationList) {
    	for (var categoryIndex1 = 0; categoryIndex1 < annotations[index1].category.length; categoryIndex1++) {	
    	  for (var categoryIndex2 = 0; categoryIndex2 < annotation.category.length; categoryIndex2++) {	
    		  if(annotations[index1].category[categoryIndex1] === annotation.category[categoryIndex2]) {
    	      annotations[index1].category = [].concat(annotation.category);
    		    annotations[index1].color = [].concat(annotation.color);
    		    const start = annotations[index1].start;
    		    const end = annotations[index1].end;
    		    undoAnnotations.push(annotations[index1].id);
    		    for (let jindex = start; jindex <= end; jindex++) {
    			    const currenvalue = [
    				  {
    					  id: annotation.id,
    					  color: annotation.color
    				  }
    				  ];
    			    postitionAnnotationMap[jindex] = currenvalue;
    		    }
    		  }
    		}
        }
        } else {
        		annotations[index1].category = [].concat(annotation.category);
            	annotations[index1].color = [].concat(annotation.color);
            	const start = annotations[index1].start;
            	const end = annotations[index1].end;
            	undoAnnotations.push(annotations[index1].id);
            	for (let jindex = start; jindex <= end; jindex++) {
            		const currenvalue = [
            			{
            				id: annotation.id,
            				color: annotation.color
            			}
            		];
            	postitionAnnotationMap[jindex] = currenvalue;
            }
        }
      }
    }

    this.setState({ showAnnotation, annotations, undoAnnotations, postitionAnnotationMap, selecting: false , categorySelected : true }, () => {
    });
    if ((this.props.autoClose && !this.props.autocloseDisable) ||  Flag === "true")  {
      
      this.saveCategory();
    }
  }

  hideEmojiModal = e => {
    this.setState({
      emojiModalShow: false,
    });
  };
	  
  showModal() {
    this.setState({
      emojiModalShow: true,
    });
  }

  resetValuesForHitsOverview=(newAnnotation)=>{
    const {showUndoButton,showRedoButton}=this.props

    console.log(typeof newAnnotation)
    this.setState({
      currentAnnotations:newAnnotation.annotationResult,
      undoAnnotations:[],
      redoAnnotations:[],
    },()=>{
      showRedoButton(true);
      showUndoButton(true);
    });
  }

  resetvalues=()=>{
    const {showUndoButton,showRedoButton}=this.props
    this.setState({
      currentAnnotations:[],
      undoAnnotations:[],
      redoAnnotations:[],
    },()=>{
      showRedoButton(true);
      showUndoButton(true);
      this.props.saveTagAndNextRowForDoc();
    });
  }
  resetValuesBack=()=>{
    const {showUndoButton,showRedoButton}=this.props
    this.setState({
      currentAnnotations:[],
      undoAnnotations:[],
      redoAnnotations:[],
    },()=>{
      showRedoButton(true);
      showUndoButton(true);
      this.props.getBackTopreviousRowForDoc();
    });
  }

	  
  showEmojiModal() {
    return (
      <Modal className="emoji_model"
        title="View Original Content"
        visible={this.state.emojiModalShow}
        onOk={this.handleOk}
        onCancel={this.hideEmojiModal}
        footer={[
          <Button key="cancel" onClick={() => this.hideEmojiModal()}>
            Cancel
	            </Button>,
        ]}
      >
        <div>
          <pre
            id="annotationDocTest"
            className="documentTest"
            style={{ lineHeight: '2.0rem', wordBreak: 'keep-all', whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={
              {
                __html: this.props.documentDataText
              }
            }
            br>
          </pre>
        </div>
      </Modal>
    );
  }

  showButtons() {
    let nextButton = 'Next';
    let prevButton = 'Previous';
    let skipButton = 'Skip';
    let saveButton = 'Move to Done';
    let annotationSaveButton = 'Save';
    const nextButtonDisabled =
    ((this.props.hitScrollCompleted && this.props.currentIndex >= this.props.hits.length - 1) ||
    (this.props.notDoneHits - 1 <= this.props.currentIndex))
    && !this.state.showModal 
    || this.props.currentIndex === this.props.hits.length-1;
    
    if ('shortcuts' in this.props) {
      const shortcuts = this.props.shortcuts;
      if ('next' in shortcuts) {
        const combo = convertKeyToString(shortcuts.next);
        nextButton = 'Next (' + combo + ')';
        if (!nextButtonDisabled && !this.state.showModal) {
          Mousetrap.bind(combo, this.props.saveTagAndNextRow);
          this.mousetrapBindings.push(combo);
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if ('previous' in shortcuts) {
        const combo = convertKeyToString(shortcuts.previous);
        prevButton = 'Previous (' + combo + ')';
        if (this.props.currentIndex > 0 && !this.state.showModal) {
          Mousetrap.bind(combo, this.props.getBackTopreviousRow);
          this.mousetrapBindings.push(combo);
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if ('skip' in shortcuts && (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR)) {
        const combo = convertKeyToString(shortcuts.skip);
        skipButton = 'Skip (' + combo + ')';
        if (this.props.currentIndex >= 0 && !this.state.showModal) {
          Mousetrap.bind(combo, this.props.skipRow);
          this.mousetrapBindings.push(combo);
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if ('save' in shortcuts && (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR)) {
        const combo = convertKeyToString(shortcuts.save);
        annotationSaveButton = 'Save (' + combo + ')';
        if ( !this.state.showModal) {
          Mousetrap.bind(combo, this.props.saveAnnotations);
          this.mousetrapBindings.push(combo);
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if ('moveToDone' in shortcuts && (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR)) {
        const combo = convertKeyToString(shortcuts.moveToDone);
        saveButton = 'Move To Done (' + combo + ')';
        if (this.state.showModal) {
          Mousetrap.unbind(combo);
        } else {
          Mousetrap.bind(combo, this.props.saveRow.bind(this, 'saveToDone'));
          this.mousetrapBindings.push(combo);
        }
      }
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start" }}>
        {(parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR) ? 
        <div title={prevButton} className={this.props.currentIndex <= 0 || this.state.showModal ? 'eventnone' : ''}>
          <Button size="mini" className="btn_green"  icon onClick={()=> this.resetValuesBack()} disabled={this.props.currentIndex <= 0 || this.state.showModal}>
            <Icon id="icon_l" name="left arrow" />
            {prevButton}
          </Button>
        </div> :
        <div title={prevButton} className={this.props.currentIndex <= 0 || this.state.showModal ? 'eventnone' : ''}>
        <Button size="mini" className="btn_green"  icon onClick={this.props.getBackTopreviousRowForDoc} disabled={this.props.currentIndex <= 0 }>
          <Icon id="icon_l" name="left arrow" />
          {prevButton}
        </Button>
      </div>}
        <div title={skipButton} className={this.props.currentIndex < 0 || this.state.showModal ? 'eventnone' : ''} style = {{display: displaySettingsByRole(getUidToken().roleId)}}>
          <Button size="mini" className="btn_green" icon onClick={this.props.skipRow} disabled={this.props.currentIndex < 0 || this.state.showModal} >
            <Icon id="icon_l" name="mail forward" />
            {skipButton}
          </Button>
        </div>
        <div title={annotationSaveButton} className={"text-center " + (this.props.currentIndex < 0 || this.state.showModal ? 'eventnone' : '')} style = {{display: displaySettingsByRole(getUidToken().roleId)}}>
          <Button size="mini" className="btn_green" icon onClick={this.props.saveAnnotations.bind(this)} disabled={this.props.currentIndex < 0 || this.state.showModal} >
            <Icon id="icon_l" name="save" />
            {annotationSaveButton}
          </Button>
        </div>
        <div title={saveButton} className={"text-center " + (this.props.currentIndex < 0 || this.state.showModal ? 'eventnone' : '')} style = {{display: displaySettingsByRole(getUidToken().roleId)}}>
          <Button size="mini" className="btn_green" icon onClick={this.props.saveRow.bind(this, 'saveToDone')} disabled={this.props.currentIndex < 0 || this.state.showModal} style = {{display: displaySettingsByRole(getUidToken().roleId)}}>
          <Icon id="icon_l" name="check" />
            {saveButton}
          </Button>
        </div>
        {(parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR) ? 
        <div title={nextButton} className={nextButtonDisabled || this.state.showModal ? 'eventnone' : ''}>
          <Button size="mini" className="btn_green" icon onClick={()=> this.resetvalues()} disabled={nextButtonDisabled || this.state.showModal}>
            <Icon id="icon_l" name="right arrow" />
            {nextButton}
          </Button>
        </div> :
        <div title={nextButton} className={nextButtonDisabled || this.state.showModal ? 'eventnone' : ''}>
        <Button size="mini" className="btn_green" icon onClick={()=>this.props.saveTagAndNextRowForDoc}  disabled={nextButtonDisabled }>
          <Icon id="icon_l" name="right arrow" />
          {nextButton}
        </Button>
      </div>}

      </div>
    );
  }

  getSpan(id, color, height) {
    if((parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR)){
    let spanElement = `<span data-id="${id}" class="annotated-span" style="display:inline; background-color:${(color)}; color: white; font-size:${height}rem">`;
    return spanElement;
    } else{
      return "";
    }
  }

  addBasicEntityChild = (labid) => {
   this.state.basicEntities.map((ent,index)=>{
      if(ent.id === labid){
        
        let dynamicValue = {id:  uuidv1(), name: '', newLabel : true}
        this.advancedOperation.push(dynamicValue.id)
        ent.entities.push(dynamicValue)
        this.state.basicEntities[index].expand = true
        this.state.basicEntities[index].showexpand = true
        
      }
    })
    this.setState({basicEntities: this.state.basicEntities})
    this.getDynamicLabels();

    this.props.changeAutoClose(true)
    
  }

  callAdvanceEntity = ( taskRules ) => {
    let advanceEntity = []
    const rulesJson = JSON.parse(taskRules);
    const tags = rulesJson.tags; 
    advanceEntity = tags.filter(it => ("entities" in it.entities[0]))
    return advanceEntity
  }
  

  callBasicEntity = (taskRules) => {
    let basicEntity = []
    const rulesJson = JSON.parse(taskRules);
    const tags = rulesJson.tags;
    tags
      .filter(it => !("entities" in it.entities[0]))
      .forEach(it => {
        if (it.entities) {
          it.entities = it.entities
          basicEntity.push(it)
        }
      })
    return basicEntity
  }

  

  handleDynamicClick = (entity, id) =>{
    
    let idsList = []
    let valuesList = []
    let event = {triggerValue : id}
    this.state.tag = "";
    if(this.state.showAnnotation.annotationList != undefined && this.state.showAnnotation.annotationList.length > 0) {
      for(var index = 0;index < this.state.showAnnotation.annotationList.length;index++) {
        if (this.state.showAnnotation.annotationList[index].category) {
          let taggedList = this.state.showAnnotation.annotationList[index].category
          taggedList.map ( (tag) => {
            idsList.push(tag.key)
            valuesList.push(tag.value)
          })
        }
      }
    }      
    else if (this.state.showAnnotation.annotation.category) {
      this.state.showAnnotation.annotation.category
      .filter(it => it !== UNKNOWN_CAT)
        .forEach(category => {
          
          if(category.value !== UNKNOWN_CAT){
            idsList.push(category.key)
            valuesList.push(category.value)
          }
        })
    }
      
    //Removing tag, if already selected, this is to avoid duplicate rendering of tag in select box
    let addFlag = false
    let indexToRemove = 0
    idsList.map((idList, index) =>{
      if(idList === id){
        addFlag = true
        indexToRemove = index
      }
    })

    if (addFlag){
      idsList.splice(indexToRemove, 1)
      valuesList.splice(indexToRemove, 1)
    }
    else
    {
      idsList.push(id)
      valuesList.push(entity)
    }
    this.selectCategory(idsList, valuesList, event)
    this.setActiveTabs(idsList)

  }

  // Removing ALL tab active in beginning
  clearPropsBasic = (key) =>{
    this.state.basicEntities.map((ent, index) =>{
     
      if(key === 'removeMatch') {
        delete ent['matchSearch']
        delete ent['showBold']
        delete ent['showexpand']
        
      }
      else {
        delete ent['setActive']
        delete ent['expand']
      }
      
      ent.entities.map((innerent) => {
        if(key === 'removeMatch') {
          delete innerent['matchSearch']
          delete innerent['showBold']
        }
        else {
          delete innerent['setActive']
        }
      })
    })
  }

  //TO remove all selected advance entity tab
  clearPropsAdvance = () =>{
    this.state.advanceEntities.map((advent) => {
      delete advent['setActive'];
      delete advent['showexpand'];
      if (advent.entities && advent.entities.length > 0) {
          this.setClearActive(advent);
      }
    })
  }

  setClearActive = (entity) =>{
    delete entity['showexpand'];
    delete entity['setActive']
    if ( entity.entities.length > 0) {
      entity.entities.forEach(enitity => {
        this.setClearActive(enitity)
      })
    }
  }

  setActiveTabs = (idsList) => {
    // Removing ALL tab active in beginning
    this.clearPropsBasic()
    this.clearPropsAdvance();

    if(idsList.length !== 0){
      idsList.forEach (element => {
        //For basic
        this.state.basicEntities.map((ent, basicindex) => {
          if (element === ent.id) {
            ent.setActive = true
            ent.expand = true //Setting Expand key to true, to expand this tree if any of its family is selected
          }
          ent.entities.map((innerent, index) => {
            if (element === innerent.id) {
              innerent.setActive = true
              this.state.basicEntities[basicindex].expand = true //Setting Expand key to true, to expand this tree if any of its family is selected
            }
          })
        })
      })
    }
      //For Advance
    if(idsList.length !== 0){
        idsList.forEach (element => {
      this.state.advanceEntities.map((advent, index) => {
        if(advent.id === element){
          advent.setActive = true
        }
        if (advent.entities && advent.entities.length > 0) {
            let parentFlag=this.setActiveFlag(advent, element,advent);
            if(parentFlag)
              advent.showexpand = parentFlag
        }
      })
      })
    }
  }

  setActiveFlag = (child, element,parent) => {
    if(child.id === element) {
      child.setActive = true;
      child = child;
      parent.showexpand=true;
      parent = parent;
      return true;
    }
    else if ( child.entities.length > 0) {
      child.entities.forEach(subChild => {
        let childFlag =this.setActiveFlag(subChild, element,child);
        if(childFlag)
          child.showexpand= childFlag;
      })
    }
    return (child.expand === true || child.showexpand === true);
  }

  // Function for dynamic labelling
  getDynamicLabels = () => {

    let dynamicTags = []
    let dynamicIds = []
    let dynamicTagswithIdsArr = []
      if(this.state.showAnnotation.annotationList != undefined && this.state.showAnnotation.annotationList.length > 0) {
        for(var index = 0;index < this.state.showAnnotation.annotationList.length;index++) {
          if (this.state.showAnnotation.annotationList[index].category) {
            let taggedList = this.state.showAnnotation.annotationList[index].category
            taggedList.map ( (tag) => {
              if(tag.key !== UNKNOWN_CAT){
              dynamicTags.push(tag.value)
              dynamicTagswithIdsArr.push(tag)
              dynamicIds.push(tag.key)
              }
              })
            }
          }
        }
      else if (this.state.showAnnotation.annotation.category) {
        this.state.showAnnotation.annotation.category
        .filter(it => it !== UNKNOWN_CAT)
          .forEach(category => {
            if(category.key !== UNKNOWN_CAT){
              dynamicTags.push(category.value)
              dynamicTagswithIdsArr.push(category)
              dynamicIds.push(category.key)
            }
          })
        }
        this.dynamicTagswithIds = dynamicTagswithIdsArr
        this.setActiveTabs(dynamicIds)
    return dynamicTags
  }

escapeRegExp = (str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

convertWildcardStringToRegExp = (expression) => {
    var terms = expression.split('*');

    var trailingWildcard = false;

    var expr = '';
    for (var i = 0; i < terms.length; i++) {
        if (terms[i]) {
            if (i > 0 && terms[i - 1]) {
                expr += '.*';
            }
            trailingWildcard = false;
            expr += this.escapeRegExp(terms[i]);
        } else {
            trailingWildcard = true;
            expr += '.*';
        }
    }

    if (!trailingWildcard) {
        expr += '.*';
    }

    return new RegExp('^' + expr + '$', 'i');
}

//Removes showBold and search text in advacaned labels
clearPropsAdvanceSearch = () =>{
  this.state.advanceEntities.map((advent) => {
    delete advent['showBold']
    delete advent['showexpand']
    delete advent['searchexpand']
    delete advent['isNotMatched']

    if (advent.entities && advent.entities.length > 0) {
        advent.entities.map((value) =>{
          this.setClearSearch(value);
        })
    }
  })
}

setClearSearch = (value) =>{
  delete value['showBold']
  delete value['showexpand']
  delete value['searchexpand']
  delete value['isNotMatched']
  if (value.entities && value.entities.length > 0) {
    value.entities.map((valueAd) =>{
    this.setClearSearch(valueAd);
    })
  }
}
//end

  handleChangeInput = (tag) => {
    this.setState({tag})
    let  regexTag = this.convertWildcardStringToRegExp('*'+tag);
    //Search for basic entities
    this.clearPropsBasic('removeMatch') // Removing any unwanted keys.
    this.clearPropsAdvanceSearch() //removing advance search
    this.checkForAdvanceNodata = false
    this.checkForBasicNodata = false
    if(tag.trim() !== "") {
       
        this.state.basicEntities.map ( (basicElement,index) => {
          //The labels with key matchSearch will not be displayed while doing search funtion
          let checkforParentMatch = false
          if (regexTag.test(basicElement.label)) {
            delete basicElement['matchSearch']
            checkforParentMatch = true   //If parent Matchs
            basicElement.showBold = true  // add bold style for matching label
            this.checkForBasicNodata = true
            basicElement.showexpand = true
          }
          else {
              basicElement.matchSearch = true //Deletes the flag
              delete this.state.basicEntities[index]['showBold']
          }
          //Checks for child
          if(basicElement.entities.length > 0) {
            let checkForChildMatch =  false
            basicElement.entities.forEach ((element,childIndex) =>{
              if(checkforParentMatch) {
                delete element['matchSearch'] //If parent matchs , sets to view all child
                delete element['showBold']
              }
              if(regexTag.test(element.name)) {
                basicElement.showexpand = true
                this.checkForBasicNodata = true
                element.showBold = true
                checkForChildMatch = true
                delete element['matchSearch']
                delete this.state.basicEntities[index]['matchSearch'] //If any one of the child matchs , then show parent also
              }
              else{
                      element.matchSearch = true
                      delete element['showBold']
                      
                  }
            })
            if(!checkForChildMatch && checkforParentMatch) {
              basicElement.entities.forEach((elementIns) => {
                delete elementIns['matchSearch'] 
                basicElement.showexpand = true
              })
            }
          }
        });
       
        //Search FUntion for advanced
        this.searchAdvancedEntity(regexTag)
    }

  }

  searchAdvancedEntity = (tag) => {

    let advanceEntities= this.state.advanceEntities;

    advanceEntities.map((adEntity, index) => {
      
      if (tag.test(adEntity.name)) {
        adEntity.showBold = true
        adEntity.showexpand = true
        adEntity.searchexpand = true
        adEntity = adEntity;
        this.checkForAdvanceNodata = true
      }
      if (adEntity.entities && adEntity.entities.length > 0) {
        adEntity.entities.map ((childEntity) => {
          if(adEntity.showexpand === undefined){
            adEntity.showexpand = false;
            adEntity.searchexpand = false;
           
          }
          if(childEntity.showexpand === undefined){
            childEntity.showexpand = false;
            childEntity.searchexpand = false
          }
          var parentFlag=this.setBoldInSearchAdvanceEntity(childEntity, tag,adEntity);
          if(parentFlag === true && adEntity.showexpand === false){
            adEntity.showexpand=true;
            adEntity.searchexpand = true
            adEntity = adEntity
          }
        })
        let searchedElements = adEntity.entities.filter(f=>f.searchexpand === true)
        if(searchedElements.length <= 0 && adEntity.searchexpand === false){
          adEntity.isNotMatched = true
        }
      }
    })
    this.state.advanceEntities=advanceEntities;
  }

  setBoldInSearchAdvanceEntity = (adEntity, element,parentEntity) => {
    if (element.test(adEntity.name)) {
      adEntity.showBold = true
      adEntity.showexpand = true
      adEntity.searchexpand = true
      adEntity = adEntity;
      parentEntity.showexpand = true
      parentEntity.searchexpand = true
      parentEntity = parentEntity;
      this.checkForAdvanceNodata = true;
      return true;
    }
    if (adEntity.entities && adEntity.entities.length > 0) {
      adEntity.entities.map ((childEntity) => {
        if(childEntity.showexpand === undefined){
          childEntity.showexpand = false;
          childEntity.searchexpand = false
        }
        var parentFlag = this.setBoldInSearchAdvanceEntity(childEntity, element,adEntity);
        if(parentFlag === true && adEntity.showexpand === false){
          adEntity.showexpand=true;
          adEntity.searchexpand = true
          adEntity = adEntity;
        }
      });
    }
    return adEntity.showexpand;
  }

  getAdvanceOptions = () => {
    const dynamicTags = this.getDynamicLabels().filter(item=>item !== UNKNOWN_CAT);
    const rulesJson = JSON.parse(this.props.taskRules);
    const tags = rulesJson.tags; 
    return (
    
      <div className="custom_taginput">
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <TagsInput inputValue={this.state.tag} inputProps={{className: 'react-tagsinput-input',placeholder: 'Select Entity'}}
       onChangeInput={this.handleChangeInput} value={dynamicTags} onChange={this.handleTagsChange} />  {/* //Show selected Inputs  */}
          <div style = {{ height:'350px' ,
                          overflow: 'auto', fontSize: '12px', marginTop: '5px'}}>
            {(!this.checkForBasicNodata && !this.checkForAdvanceNodata && this.state.tag !== "") && <div>Not Found</div>}
            <DynamicBasicEntity
                list={this.state.basicEntities}
                showAdvanceIcons={this.state.userRole !== 'OWNER' ? false : true}
                updateInput={(e, id, key) => this.updateBasicFormInput(e, id, key)}
                addEntityChild={id => this.addBasicEntityChild(id)}
                handleDynamicClick={(entity,id) => this.handleDynamicClick(entity,id)} //Select enitiy from list
              />
              <div className="column-reverse m-t-10"> {this.renderAdvanceEntities(this.state.advanceEntities)} </div>
          </div>
          
      </div>
      {
        this.state.userRole === 'OWNER' && 
        <div style = {{ textAlign: 'right', marginTop: '5px'}}>
            <Button size="mini" className="doc_successbtn" disabled={this.submitDisabled} onClick={this.callSaveDynamicLabelsAPI}>
              <Icon name="save" />
                Submit
            </Button>
            <Button size="mini" className="doc_dangerbtn" onClick={this.callCancelChanges}>
              <Icon name="cancel" />
                Cancel
            </Button>
          </div>
      }  
      </div>
    )
  }

 handleTagsChange = (tags, changed, changedIndexes) => {
    let event = {triggerValue : this.dynamicTagswithIds[changedIndexes].key}
    this.dynamicTagswithIds.splice(changedIndexes, 1);
    //To Remove the taggs in select box start
    let idsList = []
    let valuesList = []
    this.dynamicTagswithIds.map((tag)=>{
      idsList.push(tag.key)
      valuesList.push(tag.value)
    })
    this.selectCategory(idsList, valuesList, event)
    //end
  }

  
  removeFlag = (entity) => {
    if(entity.newLabel || entity.showDelete || entity.setActive || entity.expand || entity.searchexpand ) {
      delete entity["newLabel"]
      delete entity["showDelete"]
      delete entity["setActive"]
      delete entity["expand"]
      delete entity["showexpand"]
      delete entity["showBold"]
      delete entity["searchexpand"]
      delete entity["isNotMatched"]
    }
    if ( entity.entities.length > 0) {
      entity.entities.forEach(enitity => {
        this.removeFlag(enitity)
      })
    }
  }
  //To save the dynamicly created Labels
  callSaveDynamicLabelsAPI = () => {
    let rules = '';
    let checkforChangeInBasic = false
    this.state.basicEntities.map ((ent, index) => {
      let parentBasicEnt = {id: ent.id, label: ent.label, entities: ent.entities}

      this.state.basicEntities[index] = parentBasicEnt
      let basicEntity = ent.entities;
      let bIndex = 0;
      while( bIndex < basicEntity.length){
        let entity = basicEntity[bIndex];
        if(("newLabel" in entity || "showDelete" in entity || "setActive" in entity || "expand" in entity
         || "matchSearch" in entity || "showBold" in entity || "showexpand" in entity) && entity.name !=="" && entity.name !=null){
         
          let val = {id: entity.id, name:  entity.name};
          ent.entities[bIndex] =  val;
          bIndex ++;
          if("newLabel" in entity || "showDelete" in entity){
            checkforChangeInBasic = true   //if anychanges made only update api calls 
          }
        }else if(entity.name ==null || entity.name == ""){
          ent.entities.splice(bIndex,1);
        }else {
          bIndex ++;
        }
      }
    });
    this.state.advanceEntities.map((enitity) => {
        delete enitity["showBold"]
        delete enitity["showexpand"]
        delete enitity["searchexpand"]
        delete enitity["isNotMatched"]
      if (enitity.entities && enitity.entities.length > 0) {
        enitity.entities.forEach((ent)=>{
          this.removeFlag(ent);
        })
        
      }
    })
    this.renderAdvanceEntities(this.state.advanceEntities)

    rules = {
      tags: [...this.state.basicEntities, ...this.state.advanceEntities]
    }
    const ruleJson = JSON.parse(this.props.taskRules);
    if ('autoClose' in ruleJson) {
      rules.autoClose = ruleJson.autoClose
    }
    if ('classification' in ruleJson) {
      rules.classification =  ruleJson.classification
    }
    if ('instructions' in ruleJson) {
      rules.instructions =  ruleJson.instructions
    }
    editProject(this.props.currentProject, {name: this.props.projectName, taskType: DOCUMENT_ANNOTATION, accessType: 'RESTRICTED', rules: JSON.stringify(rules) }, this.projectEditedCallback);
    this.props.changeAutoClose(false)
    this.setState({tag:''})
  }
  
  getEmptyInput = (enitity) => {
	    if(enitity.name === "" || enitity.name.trim().length == 0) {
	      this.emptyCheck = true;
	      return true;
	    }
	    else if ( enitity.entities.length === 1) {
	      this.getEmptyInput(enitity.entities[0])
	      if(enitity.entities[0].parentId === null && enitity.entities[0].entities.length === 0){
	        this.emptyCheck = true;
	        return true;
	      }
	    }
	    else if ( enitity.entities.length > 0) {
	      enitity.entities.forEach(enitity => {
	        this.getEmptyInput(enitity)
	      })
	    }
	  }
  
  projectEditedCallback = (error, response) => {
    if (!error) {
      this.saveCategory();
      this.setState({savedTags : response.body.taskRules})
      this.props.refreshSpace(response.body.taskRules);
    } else {
      console.log('Error in update',error)
    }
  }


  callCancelChanges = () => {
    this.props.changeAutoClose(false)
    let tagsToValidate = []
    if(null != this.state.savedTags && this.state.savedTags.length !== 0){
      tagsToValidate = this.state.savedTags
    }
    else{
      tagsToValidate = this.props.taskRules
    }

    const basicEntities = this.callBasicEntity(tagsToValidate)
    const advanceEntities = this.callAdvanceEntity(tagsToValidate)
    this.setState ({basicEntities:basicEntities, advanceEntities: advanceEntities })
    const{showUndoButton,showRedoButton} = this.props;
      showUndoButton(true)
      showRedoButton(true)
    //Removing taggs in selected text, that got binds when adding new label
    let idsList = []
    let valuesList = []
    let event = {triggerValue : ''}
      //advancedOperation stores newly added ids, untils cancel button clicks
      this.dynamicTagswithIds.map((element) => {
        if(this.advancedOperation.length !== 0){
          this.advancedOperation.map( (newLabel) => {
            event = {triggerValue : element.key}
            if(newLabel !== element.key && !idsList.includes(element.key)){
              idsList.push(element.key)
              valuesList.push(element.value)
            }
          })
        }
        else {
            event = {triggerValue : element.key}
            idsList.push(element.key)
            valuesList.push(element.value)
        }
      })
    this.selectCategory(idsList, valuesList, event,'true')
    this.advancedOperation = []
    this.setState({tag:''})
  }  

  renderAdvanceEntities = (advanceEntities) => {
    return (
      advanceEntities.map(label => {

        if(label.isNotMatched === undefined) {
          return (
          <DynamicAdvanceEntity
            formId={label.id}
            label={label.name}
            showexpand = {label.showexpand ? true : false}
            searchexpand = {label.searchexpand ? true : false}
            showAdvanceIcons={this.state.userRole !== 'OWNER' ? false : true}
            list={label.entities}
            serNum={label.count}
            setActive = {label.setActive ? true : false}
            addParent={id => this.addParent(id)}
            bindAdvanceSelectBox = {entityId => this.updateAdvanceFormInput(label.id, entityId, label.entities)}
            addChild={entityId => this.addAdvanceFormEntity(label.id, entityId, label.entities)} // formId, entityId, data
            updateInput={(e,entityId, key) => this.updateAvanceFormInput(label.id, e, entityId , key, label.entities)}
            removeLabel={id => this.removeAdvanceFormLabel(label.id, label.entities)} 
            removeChild={entityId => this.removeAdvanceFormEntity(label.id, entityId, label.entities)}
            handleDynamicClick={(entity,id) => this.handleDynamicClick(entity,id)}
            />
        )
        }
      })
    )
  
  }
  checkforAutoCloseAdvanced = () => {
    this.checkForChangesAdv =  false
    this.state.advanceEntities.map((ent)=>{
      if("showDelete" in ent || "newLabel" in ent){
        this.checkForChanges =  true
      }
      if(ent.entities.length > 0){
        ent.entities.map((childEnt) =>{
          this.checkForChangesAdvFun(childEnt)
        })
      }
    })
  }
  checkForChangesAdvFun = (childEnt) =>{
    if("showDelete" in childEnt || "newLabel" in childEnt){
      this.checkForChangesAdv =  true
    }
    if(childEnt.entities.length > 0) {
      childEnt.entities.map((child)=>{
        this.checkForChangesAdvFun(child)
      })
    }
  }

  checkforAutoCloseBasic = () => {
    let checkForChanges =  false
    this.state.basicEntities.map((ent)=>{
      if(ent.entities.length > 0){
        ent.entities.map((childEnt) => {
          if("showDelete" in childEnt || "newLabel" in childEnt){
            checkForChanges =  true
          }
        })
      }
    })
    return checkForChanges
  }
 
  updateBasicFormInput = (event, id, key) => {
    const updatedList = this.editBasicForm(event, id, key)
    this.setState({ basicEntities: updatedList })
    if(key === 'delete'){
      //check for autoclose in basic entity
      const checkChangesBasic = this.checkforAutoCloseBasic()
      this.checkforAutoCloseAdvanced()
      if(!this.checkForChangesAdv && !checkChangesBasic) {
        this.props.changeAutoClose(false)
      }
    }
    this.getAdvanceOptions()
  }

  //Function for ADD, DELETE and UPDATE basic enitity child
  editBasicForm = (event, id, type) => {

    let list = this.state.basicEntities
    const editIndex = list.map((entity) => entity.entities.findIndex(it => it.id === id))
    const basicentityIndex = editIndex.findIndex(id => id !== -1) //BasicEntity index to update
    const entityIndex = editIndex[basicentityIndex] // selected basic entity's chils entities list

    if (type === 'save') { //To save the basic new entity record when clicks on tick icon
      let saveEntity = list[basicentityIndex].entities[entityIndex]
      if (saveEntity.name.trim() !== "") {
        let value = {
          id: saveEntity.id,
          name: saveEntity.name,
          showDelete: true
        }
        list[basicentityIndex].entities[entityIndex] = value
        this.handleDynamicClick(saveEntity.name, saveEntity.id) //To render the newly added child into selected tagged box.
      }
    } else if (type === 'update') { //To update the basic new entity record when typing
      const val = event.target.value
      list[basicentityIndex].entities.map((it, index) => {
        if (index === entityIndex) {
          it.name = val
        }
      })
    } else if (type === 'delete') { //To delete the basic new entity record when typing
      
      let entityname = list[basicentityIndex].entities[entityIndex].name
      let entityId = list[basicentityIndex].entities[entityIndex].id
      this.dynamicTagswithIds.forEach (tag =>{
        if(tag.key === entityId){
          this.handleDynamicClick (entityname, entityId) 
        }
      })
      
      list[basicentityIndex].entities.splice(entityIndex, 1)
    }
    return list
  }

  //Functions for Advance Labelling START
  addParent = (formId) => {
    const newList = this.state.advanceEntities;
    const formIndex = newList.findIndex(it => it.id === formId)
    this.state.advanceEntities[formIndex].showexpand = true

    const parentId = uuidv1()
    newList[formIndex].entities.push({
      id: parentId,
      text: '',
      parentId: null,
      level: 0,
      entities: [],
      name: '',
      count:newList.length,
      newLabel:true    
    });
    
    this.setState({ advanceEntities: newList });
    this.renderAdvanceEntities(newList)
    this.props.changeAutoClose(true)
  }

  updateAdvanceFormInput = (formId, entityId, data) => {
    const updatedList = this.editAdvanceForm(formId, entityId, null, data, null, 'render') 
    this.setState({ advanceEntities: updatedList })
    this.renderAdvanceEntities(updatedList)
  }

  addAdvanceFormEntity = (formId, entityId, data) => {
    this.props.changeAutoClose(true)
    const updatedList = this.editAdvanceForm(formId, entityId, null, data, null, 'add') //formId, entityId,event,list,action
    this.setState({ advanceEntities: updatedList })
    this.renderAdvanceEntities(updatedList)
  }

  removeAdvanceFormEntity(formId, entityId, data) {
    const updatedList = this.editAdvanceForm(formId, entityId, null, data, null, 'delete')
    this.setState({ advanceEntities: updatedList })

    // advanceLabelDeletedIds will have the deleted newly created advance 
    // label with parent child sturcture.From that  took id and name alone and pushed in taggsToDelete
    // for every delete button clicked in advance label row
    this.advanceLabelDeletedIds.map((enty) => {
        this.taggsToDelete.push({key:enty.id, value: enty.name})
        if(enty.entities.length > 0) {
          this.findDeletedAdvanceLabels(enty.entities)
        }
    })
    //Removing taggs in select entity box
    this.dynamicTagswithIds.forEach (TagID =>{
      this.taggsToDelete.forEach(DeleteId =>{
        if(TagID.key === DeleteId.key){
          //handleDynamicClick this function is to remove the tag in the select entity box
          this.handleDynamicClick (DeleteId.value, DeleteId.key) 
        }
      })
    })
    
    this.advanceLabelDeletedIds = []
    this.taggsToDelete = []
    //check for autoclose in basic entity
    const checkChangesBasic = this.checkforAutoCloseBasic()
    this.checkforAutoCloseAdvanced()
    if(!this.checkForChangesAdv && !checkChangesBasic) {
      this.props.changeAutoClose(false)
    }
    
  }

  //taking the id alone from the advance structure
  findDeletedAdvanceLabels = (entity) => {
    entity.forEach (element =>{
      this.taggsToDelete.push({key:element.id, value: element.name})
      if(element.entities.length > 0) {
        this.findDeletedAdvanceLabels(element.entities)
      }
    })
  }

  updateAvanceFormInput(formId, event, entityId, key, data) {
    const updatedList = this.editAdvanceForm(formId, entityId, event , data, key, 'update')
    this.setState({ advanceEntities: updatedList })
  }
   // @params input (formId,entityId,event,data, action), output (data)
   editAdvanceForm(formId, entityId, event, data, key, action) {
    const dataset = this.state.advanceEntities
    const formIndex = dataset.findIndex(it => it.id === formId)
    if(action === 'delete') {
      if(!entityId) {
        this.advanceLabelDeletedIds.push(dataset[formIndex])
        dataset.splice(formIndex, 1)
        let count = 0;
        dataset.forEach(entity => {
        	count++;
        	entity.count = count;
        })
      } else {
        data.forEach(entity => {
          if(entity.entities.length > 0) {
            this.editAdvanceForm(formId, entityId, null, entity.entities, null, 'delete')
          }
          if(entityId === entity.id) {
            const deleteIndex = data.findIndex(it => it.id === entityId)
            this.advanceLabelDeletedIds.push(data[deleteIndex])
            data.splice(deleteIndex, 1)
          }
        })
      }
    } else if (action === 'add') {
      data.forEach(entity => {
        if(entity.entities.length > 0) {
          this.editAdvanceForm(formId, entityId, null, entity.entities, null, 'add')
        }
        if(entityId === entity.id) {
        	if(entity.level > CHILD_NODE_LIMIT) {
            const confirm = Modal.warning;
            confirm({
    			    title: DOC_ANNO_ALERT_TITLE,
    			    content: DOC_ANNO_CHILDNODEALERT,
    			    okText: DOC_ANNO_OK_TEXT,
    			    onOk() {},
    			  });
            	return false;
           }	
           let genrateId = uuidv1()
           entity.expand = true
          entity.entities.push({
            id: genrateId,
            text: '',
            parentId: entityId,
            level: entity.level + 1,
            entities: [],
            name:'',
            newLabel: true,
            expand : true
          })
          this.advancedOperation.push(genrateId)
        }
      })
    } else if (action === 'update') {
      if(key === 'label') {
        dataset[formIndex].name = event.target.value
        dataset[formIndex].count = dataset.length
      } else {
        data.forEach(entity => {
          if(entityId === entity.id) {
            if(event) {
              entity.name = event.target.value
              entity.text = event.target.value
              entity.count=data.length
            }
          } else if(entity.entities.length > 0) {
            this.editAdvanceForm(formId, entityId, event, entity.entities, key, 'update')
          }
        })
      }
    }
    else if (action === 'render') {
      data.forEach((entity, index) => {
        if(entityId === entity.id) {
          if(entity.name.trim() !== ""){
            this.handleDynamicClick(entity.name, entity.id)
            let entityos = {
              count: entity.count,
              entities: entity.entities,
              id: entity.id,
              level: entity.level,
              name: entity.name,
              parentId: entity.parentId,
              text: entity.text,
              showDelete : true  // to show the cross symbol after binds data
              }
            data[index]  = entityos
          }
        } else if(entity.entities.length > 0) {
          this.editAdvanceForm(formId, entityId, event, entity.entities, key, 'render')
        }
      })
    }
    if (entityId) {
      dataset[formIndex].entities = data
    }
    
    return dataset
  }
   
    checkIfArrayIsUnique = (tags) => {
        let tagsArr = [];
        if (tags instanceof Array) {
            tags.forEach(element => tagsArr.push(element.name));
        } else {
            let splitArr = tags.split(',');
            splitArr.forEach(arr => {
                tagsArr.push(arr.trim());
            });
        }

        return tagsArr.length === new Set(tagsArr).size;
    }

    checkUnique = (tagsArr) => {
        return tagsArr.length === new Set(tagsArr).size;
    }

    checkBascicEntityUnique = (tags) => {
        let labelList = [];
        let disable = false;
        for (var i = 0; i < tags.length; i++) {
            labelList.push(tags[i].label.trim());
            let unique = this.checkIfArrayIsUnique(tags[i].entities);
            if (!unique) {
                disable = true;
                break;
            }
        }

        if (disable || (!disable && !this.checkUnique(labelList))) {
            this.displayErrorNotification('error', "Duplicate labels or Entities not Allowed.")
            return true;
        }

        return false;
    }

    displayErrorNotification(type, message) {
        let isNotification = document.getElementsByClassName('ant-notification-notice');
        if (isNotification.length == 0) {
            showAlert(message, type)
        }
    }

    checkOcuurenceUnique = (entities) => {
        let nameList = [];
        for (var i = 0; i < entities.length; i++) {
            nameList.push(entities[i].name.trim())
        }
        if (nameList.length > 0 && !this.checkUnique(nameList)) {
            this.emptyCheck = true;
            this.displayErrorNotification('error', "Duplicate labels or Entities not Allowed.")
            return true;
        } else {
            entities.forEach(element => {
                this.checkOcuurenceUnique(element.entities);
            });
        }

    }

    checkAdvancedEntityUnique = (tags) => {
        tags.forEach(element => {
            this.checkOcuurenceUnique(element.entities);
        });
    }

  // END

  render() {
    if (this.state.taskType === DOCUMENT_ANNOTATION) {

      /**
       * renders the entity tree for annotating text
       * function triggers only before mounting of popup component
       **/
      let submitDisabled = true;
      //Validation for empty label in document annotation
      if(submitDisabled) {
        this.emptyCheck = false
        if(this.state.advanceEntities.length === 0 && this.state.basicEntities.length === 0){
          this.emptyCheck = true;
        }
        else{

            let validateBasic = helper.validateBasicEntityDOC(this.state.basicEntities)
            this.state.advanceEntities.forEach(enitity => {
              if (enitity.entities && enitity.entities.length > 0) {
                this.getEmptyInput(enitity);
              }
              else{
                this.emptyCheck = true;
              }
            });

            if(!this.emptyCheck)
          	  this.checkAdvancedEntityUnique(this.state.advanceEntities);

            if(validateBasic){
              this.emptyCheck = true
            }else if(!this.emptyCheck && this.state.basicEntities.length > 0 && !validateBasic){
            	this.emptyCheck = this.checkBascicEntityUnique(this.state.basicEntities);
            }
        }
        this.submitDisabled  = this.emptyCheck; 
      }
    
      const getOptions = () => {
        const values = []
        if(this.state.showAnnotation.annotationList != undefined && this.state.showAnnotation.annotationList.length > 0 ) {
          for(var index = 0;index < this.state.showAnnotation.annotationList.length;index++) {
            if (this.state.showAnnotation.annotationList[index].category) {
            this.state.showAnnotation.annotationList[index].category
                .filter(it => it !== UNKNOWN_CAT)
                  .forEach(category => {
                    if (category.value) {
                      values.push(category.key)
                    } else {
                      values.push(category)
                    }
                  })
            }
          }
          
        }
        else if (this.state.showAnnotation.annotation.category) {
          this.state.showAnnotation.annotation.category
          .filter(it => it !== UNKNOWN_CAT)
            .forEach(category => {
              if (category.value) {
                values.push(category.key)
              } else {
                values.push(category)
              }
            })
        }
        return (
          <EntityTree
            onSelect={(value, label, event) => this.selectCategory(value, label, event)}
            entities={this.state.entities}
            annotations={this.state.annotations}
            values={values[0] === UNKNOWN_CAT ? [] : values}
          />
        )
      }

      const highlightAnnotationsdoc = (doc, annotations, postitionAnnotationMap, offset) => {
          doc = [...doc];
        let highlighted = '';
        const tempAnnotations = [];
        const tempPosnAnnotationMap = {};

        if(!this.state.undoAnnotations && !this.state.redoAnnotations){
          annotations.sort((aa, bb) => {
            if (aa.start === bb.start) {
              return bb.end - aa.end;
            }
            return bb.start - aa.start;
          });
        }
        
        for (let jindex = 0; jindex < annotations.length; jindex++) {
          if (annotations[jindex].color.length > 0) {
            tempAnnotations.push(annotations[jindex]);
            
            let start = annotations[jindex].start;
            
            if(!this.state.annotateFromSelection){
              start = annotations[jindex].start-1;
            }
            if(this.state.annotateFromSelection && annotations[jindex].text === this.state.AnnotatedText){
              start = annotations[jindex].start;
            }
          
            let end = annotations[jindex].end;
            const id = annotations[jindex].id;
            const color = annotations[jindex].color;
            
            for (let kindex = start; kindex <= end; kindex++) {
              if (kindex in tempPosnAnnotationMap) {
                const currenvalue = tempPosnAnnotationMap[kindex];
                currenvalue.push({ id, color });
                tempPosnAnnotationMap[kindex] = currenvalue;
              } else {
                const currenvalue = [{id, color}]
                tempPosnAnnotationMap[kindex] = currenvalue
              }
            }
          }
        }
        tempAnnotations.sort((aa, bb) => {
          if (aa.start === bb.start) {
            return bb.end - aa.end;
          }
          return aa.start - bb.start;
        });
        let spanCount = 0;
        let lastDiv = '';
        for (let cursor = 0; cursor < doc.length; cursor++) {
          let endIsZero = 0;
          if (cursor > 0) {
            if (doc[cursor] === '\n' && spanCount > 0) {
              highlighted += '</span>' + doc[cursor] + lastDiv;
            } else {
              highlighted += doc[cursor];
            }
          } else {
            console.log('cursor mismatch', cursor);
          }
          const removeIds = [];
          const docColors = ['#A52A2A', '#399310', '#1a7082', '#82193e', '#c9bc28'];
          tempAnnotations.forEach((annotation, index) => {
            let start = annotation.start;
            let end = annotation.end;                                	    	  
            const posnAnnotation = tempPosnAnnotationMap[start];
            const nextAnnotation = tempPosnAnnotationMap[end + 1];
                      
            start = (start !== 0)
              ? start - offset
              : start;
            
            if (cursor === start && posnAnnotation && posnAnnotation.length > 0 && posnAnnotation[0].color.length > 0) {
              
              let height = 1.4 - spanCount * 0.5;
              if (height < 0.6) {
                height = 0.6;
              }

              if(this.props.selectedLabel !== ''){
                lastDiv = this.getSpan(annotation.id, annotation.color[0], height);
              }
              else{
                lastDiv = this.getSpan(annotation.id, docColors[spanCount], height);
              }

              
              highlighted += lastDiv; 
              spanCount = spanCount + 1;
              if (start === end && end === 0) {
                endIsZero = endIsZero + 1;
              }
            }
            if (cursor === end && annotation.color.length > 0 && end !== 0) {
              for (let jindex = 0; jindex < spanCount; jindex++) {
                highlighted += '</span>';
              }
              /* remove the annotation from the array after it's not needed.
              ** This help with performance for subsequent runs through the loop
              */
              removeIds.push(annotation.id);
              spanCount = spanCount - 1;
              let height = 1.6 - spanCount * 0.2;
              if (height < 0.6) {
                height = 0.6;
              }
              for (let jindex = 0; jindex < spanCount; jindex++) {
                if (nextAnnotation && nextAnnotation.length > 0 && nextAnnotation[0].color.length > 0) {
                  if(this.props.selectedLabel !== ''){
                    lastDiv = this.getSpan(nextAnnotation[0].id, nextAnnotation[0].color[0], height);
                  }
                  else{
                    lastDiv = this.getSpan(nextAnnotation[0].id, (docColors[(spanCount > 0 ? spanCount - 1 : spanCount)]), height);
                  }
                  
                  highlighted += lastDiv;
                }
              }
            }

          });

          for (let kindex = 0; kindex < removeIds.length; kindex++) {
            for (let yindex = 0; yindex < tempAnnotations.length; yindex++) {
              if (removeIds[kindex] === tempAnnotations[yindex].id) {
                tempAnnotations.splice(yindex, 1);
              }
            }
          }
          if (cursor === 0) {
            highlighted += doc[cursor];
            for (let kindex = 0; kindex < endIsZero; kindex++) {
              highlighted += '</span>';
              spanCount = spanCount - 1;
            }
            endIsZero = 0;
          }
        }

        return highlighted;
      }

      const handleAddition = (event, { value }) => {
        this.setState({
          entities: [
            ...this.state.entities,
            value
          ],
          newEntities: [
            ...this.state.newEntities,
            value
          ]
        }, () => {
          if (this.props.annotateCallback) {
            this.props.annotateCallback(this.state);
          }
        });
      }

      let annotated = ''
      if (this.state.documentText) {   	
        this.state.documentText = this.unicodeToChar(this.state.documentText);
        annotated = highlightAnnotationsdoc(this.state.documentText, this.state.annotations, this.state.postitionAnnotationMap, 1);
      }
      let saveButton = 'Save';
      if (this.props.shortcuts && 'save' in this.props.shortcuts) {
        saveButton = saveButton + ' (' + convertKeyToString(this.props.shortcuts.save) + ')';
      }

      let closeButton = '';
      if (this.props.shortcuts && 'close' in this.props.shortcuts) {
        closeButton = closeButton + ' (' + convertKeyToString(this.props.shortcuts.close) + ')';
      }
      return (<div className="b_section" style={{
        minHeight: '300px'
      }}>
      <div>
        {
          (this.state.showModal && this.state.showAnnotation && this.state.showAnnotation.annotation && this.state.showAnnotation.annotation.id )
            ? <div id="doc_model" className={this.props.allowDynamiclabelling ? "annotation-modal col-xs-8 col-sm-6 col-md-4 col-lg-6" 
                              : "annotation-modal col-xs-8 col-sm-6 col-md-4 col-lg-4"} //col-xs-8 col-sm-6 col-md-4 col-lg-4 for normal modal 
            style={this.props.allowDynamiclabelling ? {   
              minHeight: '488px' // Only for dynamic labelling
            } :
            {
            }
          } style = {{display: displaySettingsByRole(getUidToken().roleId)}}
            >
              <Button className="annotation-modal-save" onClick={this.saveCategory} style={{
                backgroundColor: 'white'
              }}>
                <Icon name="save" />{saveButton}
              </Button>
              <Button className="annotation-modal-close" 
                  onClick={this.props.allowDynamiclabelling ? this.callCancelChanges : this.saveCategory} style={{
                backgroundColor: 'white'
              }}>
                <Icon name="close"/> {closeButton}
              </Button>
              <div className="annotation m-b-5 m-t-5" key={this.state.showAnnotation.annotation.id}>
                <span className="annotation-text">
                  <p className="annotation-text">{this.state.showAnnotation.annotation.text} </p>
                </span>
                {/* adds category list pop up in tree structure */}
                {!this.props.allowDynamiclabelling && getOptions()}
              </div>
              
              {/* Add new funtion for dynamic label start */}
                {this.props.allowDynamiclabelling && this.getAdvanceOptions() }
              {/* END */}
              
            </div>
            : false
        }
        <pre
          id="annotationDoc"
          ref={(parentDiv) => this.parentDiv = parentDiv}
          className="document m-b-0"
          style={{ lineHeight: '2.0rem', wordBreak: 'keep-all', whiteSpace: 'pre-wrap' }}          
          onClick={this.handleMarkerClick}
          onMouseUp={this.handleSelection}
          onMouseDown={this.removeSelection}
          dangerouslySetInnerHTML={
            {
              __html: annotated
            }
          }
          br>
        </pre>
        {
                this.showClearAllModal()
        }
      </div>
      {this.props.space && this.showButtons()}
      {this.state.emojiModalShow && this.showEmojiModal()}
    </div>);
    }
    if (this.state.taskType === POS_TAGGING || this.state.taskType === POS_TAGGING_GENERIC) {
      const highlightAnnotationsdoc = (doc, annotations, postitionAnnotationMap, offset) => {
        console.log("Entered in highlightAnnotationsdoc",doc,annotations,offset)
        let highlighted = '';
        const tempAnnotations = [];
        const tempPosnAnnotationMap = {};
        if(!this.state.undoAnnotations && !this.state.redoAnnotations){
          annotations.sort((aa, bb) => {
            if (aa.start === bb.start) {
              return bb.end - aa.end;
            }
            return bb.start - aa.start;
          });
        }
        
        for (let jindex = 0; jindex < annotations.length; jindex++) {
          if (annotations[jindex].color.length > 0) {
            tempAnnotations.push(annotations[jindex]);
            const start = annotations[jindex].start;
            const end = annotations[jindex].end;
            const id = annotations[jindex].id;
            const color = annotations[jindex].color;
            for (let kindex = start; kindex <= end; kindex++) {
              if (kindex in tempPosnAnnotationMap) {
                const currenvalue = tempPosnAnnotationMap[kindex];
                currenvalue.push({id, color});
                tempPosnAnnotationMap[kindex] = currenvalue;
              } else {
                const currenvalue = [
                  {
                    id,
                    color
                  }
                ];
                tempPosnAnnotationMap[kindex] = currenvalue;
              }
            }
          }
        }
        tempAnnotations.sort(function(aa, bb) {
          if (aa.start === bb.start) {
            return bb.end - aa.end;
          }
          return aa.start - bb.start;
        });

        let spanCount = 0;
        let lastDiv = '';
        for (let cursor = 0; cursor < doc.length; cursor++) {
          let endIsZero = 0;
          if (cursor > 0) {
            if (doc[cursor] === '\n' && spanCount > 0) {
              highlighted += '</span>' + doc[cursor] + lastDiv;
            } else {
              highlighted += doc[cursor];
            }
          } else {
            console.log('cursor mismatch', cursor);
          }
          const removeIds = [];          
          tempAnnotations.forEach((annotation, index) => {
            let start = annotation.start;
            const end = annotation.end;
            const posnAnnotation = tempPosnAnnotationMap[start];
            const nextAnnotation = tempPosnAnnotationMap[end + 1];
              
            start = (start !== 0)
              ? start - offset
              : start;
            if (cursor === start && posnAnnotation && posnAnnotation.length > 0 && posnAnnotation[0].color.length > 0) {
              let height = 1.4 - spanCount * 0.5;
              if (height < 0.6) {
                height = 0.6;
              }
              lastDiv = this.getSpan(annotation.id, annotation.color[0], height);
              highlighted += lastDiv;
              spanCount = spanCount + 1;
              if (start === end && end === 0) {
                endIsZero = endIsZero + 1;
              }
            }
            if (cursor === end && annotation.color.length > 0 && end !== 0) {
              for (let jindex = 0; jindex < spanCount; jindex++) {
                highlighted += '</span>';
              }
              /* remove the annotation from the array after it's not needed.
              ** This help with performance for subsequent runs through the loop
              */
              removeIds.push(annotation.id);
              spanCount = spanCount - 1;
              let height = 1.6 - spanCount * 0.2;
              if (height < 0.6) {
                height = 0.6;
              }
              for (let jindex = 0; jindex < spanCount; jindex++) {
                if (nextAnnotation && nextAnnotation.length > 0 && nextAnnotation[0].color.length > 0 ) {
                  
                  lastDiv = this.getSpan(nextAnnotation[0].id, (nextAnnotation[0].color)[0], height );
                  highlighted += lastDiv;
                }
              }
            }
          });
  
          for (let kindex = 0; kindex < removeIds.length; kindex++) {
            for (let yindex = 0; yindex < tempAnnotations.length; yindex++) {
              if (removeIds[kindex] === tempAnnotations[yindex].id) {
                tempAnnotations.splice(yindex, 1);
              }
            }

          }
          if (cursor === 0) {
            highlighted += doc[cursor];
            for (let kindex = 0; kindex < endIsZero; kindex++) {
              highlighted += '</span>';
              spanCount = spanCount - 1;
            }
            endIsZero = 0;
          }
        }
  
        return highlighted;
      };
      const selectCategory = (event1, index) => {

        const values = index.value;
        const showAnnotation = this.state.showAnnotation;
        const annotation = showAnnotation.annotation;

        const newCats = [];
        const newCols = [];
        const undoAnnotations = [];
        for (let index1 = 0; index1 < values.length; index1++) {
          if (values[index1] !== UNKNOWN_CAT) {
            newCats.push(values[index1]);
            if (values[index1] in this.props.entityColorMap) {
              newCols.push(this.props.entityColorMap[values[index1]]);
            }
          }
        }

        annotation.category = newCats;
        annotation.color = newCols;
        showAnnotation.annotation = annotation;
        const annotationSet = this.state.showAnnotation.annotationSet;
        const annotations = this.state.annotations;
        const postitionAnnotationMap = this.state.postitionAnnotationMap;
        for (let index1 = 0; index1 < annotations.length; index1++) {
          if (annotationSet.has(annotations[index1].id)) {
            annotations[index1].category = [].concat(annotation.category);
            annotations[index1].color = [].concat(annotation.color);
            const start = annotations[index1].start;
            const end = annotations[index1].end;
            undoAnnotations.push(annotations[index1].id);
            for (let jindex = start; jindex <= end; jindex++) {

              const currenvalue = [
                {
                  id: annotations[index1].id,
                  color: annotations[index1].color
                }
              ];
              postitionAnnotationMap[jindex] = currenvalue;
            }
          }
        }
  
        this.setState({showAnnotation, annotations, undoAnnotations, postitionAnnotationMap, selecting: false});
        if (this.props.autoClose) {
          this.saveCategory();
        }
      };
      const handleAddition = (event, {value}) => {
        this.setState({
          entities: [
            ...this.state.entities,
            value
          ],
          newEntities: [
            ...this.state.newEntities,
            value
          ]
        }, () => {
          if (this.props.annotateCallback) {
            this.props.annotateCallback(this.state);
          }
        });
      };
      const getOptions = (index) => {
        const arrs = [];
        const values = [];
        let index1 = 0;
        console.log('getOptions', this.state);
        const categories = this.state.showAnnotation.annotation.category;
        for (index1 = 0; index1 < categories.length; index1++) {
          if (categories[index] !== UNKNOWN_CAT) {
            values.push(categories[index1]);
          }
        }
        index1 = 0;
        for (index1 = 0; index1 < this.state.entities.length; index1++) {
          const key = this.state.entities[index1];
          arrs.push({key: index1, text: key, value: key});
        }

        console.log('values', values);
        return (<Dropdown tabIndex="1" ref={node => this.dropDown = node} closeOnChange="closeOnChange" closeOnBlur={false} open={this.state.menuOpen} selectOnNavigation="selectOnNavigation" scrolling="scrolling" allowAdditions="allowAdditions" additionPosition="bottom" fluid="fluid" multiple="multiple" selection="selection" className="tiny" onAddItem={handleAddition} onChange={selectCategory} options={arrs} value={values} search={this.state.menuOpen} searchInput={{
            autoFocus: this.state.menuOpen
          }} placeholder="Add Labels" id={index} onClose={() => {
            console.log('onclose');
            this.setState({menuOpacity: '0.4', menuOpen: false});
          }} onFocus={() => {
            console.log('onfocus');
            this.setState({menuOpacity: '1.0', menuOpen: true});
          }} button="button" additionLabel="New Item: "/>);
      };
      console.log('DocumentAnnotator state', this.state, this.props);
      let annotated = ''
      if (this.state.documentText) {
        annotated = highlightAnnotationsdoc(this.props.documentText, this.state.annotations, this.state.postitionAnnotationMap, 1);
      }
      let saveButton = 'Save';
      if (this.props.shortcuts && 'save' in this.props.shortcuts) {
        saveButton = saveButton + ' (' + convertKeyToString(this.props.shortcuts.save) + ' )';
      }

      let closeButton = '';
      if (this.props.shortcuts && 'close' in this.props.shortcuts) {
        closeButton = closeButton + ' (' + convertKeyToString(this.props.shortcuts.close) + ' )';
      }
      for (let index = 0; index < this.state.annotations.length; index++) {
        console.log('render annotation', this.state.annotations[index]);
      }      
      const showLabels = () => {
        const renderArrs = [];
        let index = 0;
        for (let index = 0; index < this.state.entities.length; index++){
          const key = this.state.entities[index];
          renderArrs.push(
            <Label className='box_chipset' style={{ padding: '5px', color: 'white', backgroundColor: this.props.entityColorMap[key]}}>                 
                {key}                        
            </Label>            
          );
        }
        return (<div style={{display:"flex"}}>{renderArrs}</div>);
      };
      return ( 
        <div className="border_box">
          <h3 className="m-b-10">Entities</h3>            
           <div className="box_chip">  
            {showLabels()}                      
          </div>       
          <div style={{
              minHeight: '300px'
            }}>
            <div className="m-b-10">
              {
                (this.state.showModal && this.state.showAnnotation && this.state.showAnnotation.annotation && this.state.showAnnotation.annotation.id && (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR))
                  ? <div className="annotation-modal col-xs-8 col-sm-6 col-md-4 col-lg-4" style={{
                        top: this.state.showAnnotation.top,
                        left: this.state.showAnnotation.left
                      }}>
                      <Button className="annotation-modal-save" onClick={this.saveCategory} style={{
                          backgroundColor: 'white'
                        }}>
                        <Icon name="save"/>{saveButton}
                      </Button>
                      <Button className="annotation-modal-close" onClick={this.saveCategory} style={{
                          backgroundColor: 'white'
                        }}>
                        <Icon name="close"/> {closeButton}
                      </Button>
                      <div className="annotation" key={this.state.showAnnotation.annotation.id}>
                        <span className="annotation-text">
                          <p className="annotation-text">{this.state.showAnnotation.annotation.text}</p>
                        </span>
                        {getOptions()}
                      </div>
                    </div>
                  : false
              }
              
              <pre id="annotationDoc" ref={(parentDiv) => this.parentDiv = parentDiv} className="document" style={{ lineHeight: '2.0rem', wordBreak: 'keep-all', whiteSpace: 'pre-wrap' }} onClick={ this.handleMarkerClick } onMouseUp={ this.handleSelection } onMouseDown={ this.removeSelection }
                  dangerouslySetInnerHTML = {
                    {
                      __html: annotated
                    }
                  }>
              </pre>
              {
                this.showClearAllModal()
              }
            </div>
            {this.props.space && this.showButtons()}
          </div> 
        </div>                  
      );
    }
  }
}
DocumentAnnotator.propTypes = {
  documentText: PropTypes.string,
  annotateCallback: PropTypes.func,
  entityColorMap: PropTypes.object,
  shortcuts: PropTypes.object,
  annotations: PropTypes.array,
  space: PropTypes.bool,
  autoLabel: PropTypes.bool,
  autoClose: PropTypes.bool,
  urlData: PropTypes.bool,
  annotationCatMap: PropTypes.object,
  skipRow: PropTypes.func,
  saveTagAndNextRow: PropTypes.func,
  saveTagAndNextRowForDoc: PropTypes.func,
  getBackTopreviousRow: PropTypes.func,
  getBackTopreviousRowForDoc: PropTypes.func,
  saveRow: PropTypes.func,
  hits: PropTypes.object,
  annotateCallbackForCancellingEntity: PropTypes.func
};
