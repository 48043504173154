import React, {Component, PropTypes} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Helmet from 'react-helmet';
import { login,signIn,logout,resetFlags } from '../../redux/reducer/loginReducer';
import { Icon, Input } from 'antd';
import { replace } from 'react-router-redux';
import { showAlert } from '../../helpers/commonFunctions';
import { getUserInfoForResetPassword,resetPassword } from '../../Services/LoginApis';
import { checkPassword } from '../../helpers/Utils';
import 'semantic-ui-css/semantic.min.css';
import 'semantic-ui-css/components/reset.min.css';
import 'semantic-ui-css/components/site.min.css';
import 'semantic-ui-css/components/container.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/message.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import '../../css/custom.css'
import logo from '../../images/logo1.svg';
import EYEHIDE from '../../images/eye_hide.svg';
import EYEOPEN from '../../images/eye_show.svg';
import { MESSAGES, ALERT_TYPES, COPYRIGHT } from '../../helpers/Constants';

class PasswordReset extends Component {
  static propTypes = {
    user: PropTypes.object,
    login: PropTypes.func,
    logout: PropTypes.func,
    signIn: PropTypes.func,
    replaceState: PropTypes.func,
    userCreated: PropTypes.bool,
    resetFlags: PropTypes.func,
  }

  state = {
    hidden: true,
    email: '',
    password: '',
    rePassword: '',
    linkExpired: false,
    userId: ""
  }

    componentWillMount() {
        if (this.props.location.query.token) {           
            const token = atob(decodeURIComponent(this.props.location.query.token));
            const splitted = token.split("=")[1].split("__");
            const id = splitted[0];
            getUserInfoForResetPassword(id, this.userCallback.bind(this))
        }
    }

    userCallback = (err, response) => {
        if (!err) {
            let user = response.body;
            var diffInSeconds = Math.abs(new Date(Date.now()) - new Date(user.pResetTimestamp)) / 1000;
            if(diffInSeconds>=3600){
              this.setState({linkExpired: true})
            }else{
              this.setState({ email: user.email, userId: user.oauthId })
            }
        }
    }

  handlePasswordChange=()=>{
    this.setState({hidden: !this.state.hidden});
  }

  handleCreateChange = (event) => {
    if (event.target.name === 'email') {
      this.setState({ email: event.target.value });
    } else if (event.target.name === 'password') {
      this.setState({ password: event.target.value });
    } else if (event.target.name === 'rePassword') {
      this.setState({ rePassword: event.target.value });
    }
  }

  cancelRequest = () => {
    this.props.replaceState('/projects/login');
  }

    handlePasswordReset = () => {
        const {password,rePassword,userId} = this.state
        if (password.length === 0) {
            showAlert(MESSAGES.PASSWORD_REQUIRED, ALERT_TYPES.ERROR);
        } else if (rePassword.length === 0) {
            showAlert(MESSAGES.RE_PASSWORD_REQUIRED, ALERT_TYPES.ERROR);
        } else if (password && !checkPassword(password)) {
            showAlert(MESSAGES.PASSWORD_COMPLEXITY_MESSAGE, ALERT_TYPES.ERROR);
        } else if (password !== rePassword) {
            showAlert(MESSAGES.PASSWORD_MISMATCH, ALERT_TYPES.ERROR);
        } else {
            resetPassword(userId, password, this.resetPasswordCallback.bind(this))
        }
    }

    resetPasswordCallback = (err, response) => {
        if (!err) {
            let message = response.text;
            showAlert(message, ALERT_TYPES.SUCCESS);
            this.cancelRequest();
        }
    }

  render() {
      const emailDisabled = true;
      const {linkExpired,emailSignup,email,hidden,rePassword,password} = this.state
    return (
      <div>
        <Helmet title="Reset Password"/>
        {!linkExpired &&(
        <div className="pr_login_section">
        <div className="sec_left w-50">
          <div className="logo_main">
            <img alt='logo' src={logo} />
            {!emailSignup &&
            <div className="login_box w-100">
              <div className="login_base w-55">
                <h6 className="fw-600">Welcome to pronotate</h6>
                <h4 className="fw-600">Reset your password </h4>
                <div className="form_field">
                  <label>E-mail</label>
                  <div className="input_box">
                  <Input disabled = {emailDisabled} autoComplete="off" prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}   ref={(email) => {this.email = email;}} type="email"  value={email} name="email" placeholder="Your E-mail" />
                  </div>
                </div>
                <div className="form_field">
                  <label>Password</label>
                  <div className="input_box">
                  <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} ref={(password) => {this.password = password;}} value={password} onChange={(event) => this.handleCreateChange(event)} name="password" type={hidden ? "password" : "text"} placeholder="Your Password"  suffix={ <span onClick={() => this.handlePasswordChange()} className={ hidden ? 'img_hide' : 'img_show' }><img alt='show password' className="eye_hide" title="Show password" src={EYEHIDE} /> <img alt='hide password' className="eye_show" title="Hide password" src={EYEOPEN} /></span> }/>
                  </div>
                </div>
                <div className="form_field">
                  <label>Re-Enter Password</label>
                  <div className="input_box">
                  <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} ref={(rePassword) => {this.rePassword = rePassword;}} value={rePassword} onChange={(event) => this.handleCreateChange(event)} name="rePassword" type={hidden ? "password" : "text"} placeholder="Your Re-Password"/>
                  </div>
                </div>
                <div className="action_btn">
                  <button className="btn_base btn_borderd" onClick={() => this.cancelRequest()}>Cancel </button>
                  <button className="btn_base btn_primary" onClick={() => this.handlePasswordReset()} >Reset</button>
                </div>
              </div>
            </div>
            }
            <div className="login_footer">
            <h6 className="txt_lowercase">{COPYRIGHT.YEAR}</h6>
             <h6 className="txt-upper">Privacy policy<span className="div_bar"> | </span>Terms and Conditions</h6>

            </div>
          </div>
        </div>
        <div className="sec_right w-50">
          <div className="screen_overlay">
            <h1>Super Easy Data Annotations</h1>
            <h5>
              Invite your team and generate high quality labeled data in minutes
            </h5>
          </div>
        </div>
      </div>
        )}
            {linkExpired &&
                <p>Link has been expired !!!</p>
            }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
    user: state.loginReducer.user, 
    userCreated: state.loginReducer.userCreated
    };
  };
  
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        login, 
        signIn, 
        logout, 
        replaceState: replace, 
        resetFlags
      },
      dispatch
    );
  
export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);