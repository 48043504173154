import { getUidToken } from '../helpers/commonFunctions';
import { middleCallback } from "./commonApis";
import { BASE_URL } from '../helpers/Constants';
const superagent = require("superagent");

export const downloadfile = (pid, option, callback, format, filterInputparam, downloadfile) => {
    const { uid, token } = getUidToken();
    let url = BASE_URL + pid + "/download?";
    if (null !== filterInputparam && filterInputparam.length > 0) {
      url = url + filterInputparam;
    }
    if (format && format.toUpperCase() === "YOLO"){
      url = url + "&format=YOLO";  
    }
    if (format && format.toUpperCase() === "SINGLEJSON"){
      url = url + "&format=SINGLEJSON";
    }
    if (format && format.toUpperCase() === "RAW"){
      url = url + "&format=RAW";
    }
    if (option.toUpperCase() === "ALL") {
      url = url + "&items=ALL";
    } else if (option.toUpperCase() === "TAGGED") {
      url = url + "&items=DONE";
    } else if (option.toUpperCase() === "NONTAGGED") {
      url = url + "&items=NOT_DONE";
    }
    if (format && format.toUpperCase() === "DICOM_JSON")
      url = url + "&format=DICOM_JSON";
    else if (format && format.toUpperCase() === "CSV")
      url = url + "&format=CSV";
    if (format && format.toUpperCase() === "STANFORD") {
      url = url + "&format=STANFORD_NER";
      superagent
        .post(url)
        .responseType('blob')
        .set("uid", uid)
        .set("token", token)
        .end((err, res) => {
          middleCallback(err, res, callback)
        });
    } else {
      superagent
        .post(url)
        .responseType('blob')
        .set("uid", uid)
        .set("token", token)
        .end((err, res) => {
          middleCallback(err, res, callback)
        });
    }
  };


  export const downloadfilecsv = (pid, option, callback, format, filterInputparam, downloadfile) => {
    const { uid, token } = getUidToken();
    let url = BASE_URL + pid + "/download/csv?";
    if (null !== filterInputparam && filterInputparam.length > 0) {
      url = url + filterInputparam;
    }
    if (option.toUpperCase() === "ALL") {
      url = url + "&items=ALL";
    } else if (option.toUpperCase() === "TAGGED") {
      url = url + "&items=DONE";
    } else if (option.toUpperCase() === "NONTAGGED") {
      url = url + "&items=NOT_DONE";
    }
    if (format && format.toUpperCase() === "DICOM_JSON")
      url = url + "&format=DICOM_JSON";
    else if (format && format.toUpperCase() === "CSV")
      url = url + "&format=CSV";
    else if (format && format.toUpperCase() === "yolo")
      url = url + "&format=YOLO";
    if (format && format.toUpperCase() === "STANFORD") {
      url = url + "&format=STANFORD_NER";
      superagent
        .post(url)
        .set("uid", uid)
        .set("token", token)
        .end((err, res) => {
          middleCallback(err, res, callback)
        });
    } else {
      superagent
        .post(url)
        .set("uid", uid)
        .set("token", token)
        .end((err, res) => {
          middleCallback(err, res, callback)
        });
    }
  };


  export const imageMaskDownloadZip = (projectId, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + projectId + "/image/masking/download/zip" )
      .set("uid", uid)
      .set("token", token)
      .responseType('blob')
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };


  export const checkS3BucketWithDateFilter = (projectId, color, type, filterInputparam, callback,downloadUrl) => {
    const { uid, token } = getUidToken();
    let url = BASE_URL + projectId + "/" + color + "/" + type + "/checkS3BucketWithDateFilter";
    if (downloadUrl) {url = url + `?downloadUrl=${downloadUrl}`};
    if (null !== filterInputparam && filterInputparam.length > 0) {
       url = url + filterInputparam;
    }
    if(downloadUrl)
    {
      superagent
      .post(url)
      .set("uid", uid)
      .set("token", token)
      .set("Content-Type", 'application/json')
      .end((err, res) => {
         middleCallback(err, res, callback)
      });
    }
    else {
      superagent
      .post(url)
      .set("uid", uid)
      .set("token", token)
      .responseType('blob')
      .end((err, res) => {
         middleCallback(err, res, callback)
    });
    }
  };


  export const getRawUrls = (pid, option, callback, format, filterInputparam, downloadfile) => {
    const { uid, token } = getUidToken();
    let url = BASE_URL + pid + "/downloadraw?";
    if (format && format.toUpperCase() === "RAW"){
      url = url + "&format=RAW";
    }
    if (option.toUpperCase() === "ALL") {
      url = url + "&items=ALL";
    } else if (option.toUpperCase() === "TAGGED") {
      url = url + "&items=DONE";
    } else if (option.toUpperCase() === "NONTAGGED") {
      url = url + "&items=NOT_DONE";
    }
      superagent
        .post(url)
        .set("Content-Type", 'application/json')
        .set("uid", uid)
        .set("token", token)
        .end((err, res) => {
          middleCallback(err, res, callback)
        });
  };

  export const checkS3BucketS3Download = async(downloadUrlLink) => {
    if (downloadUrlLink) {
      // Use the signed URL to trigger the download
      window.open(downloadUrlLink, '_blank');
    }
  };

  export const checkS3Bucket = ( projectId, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + projectId + "/checkS3Bucket" )
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };

  export const fetchS3URLImage = async (url,) => {
    try {
      const response = await fetch(url);
      return await response.arrayBuffer();
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };


  export const evaluationCorrectFilter = (projectId, callback) => {
    const { uid, token } = getUidToken();
    let url = BASE_URL + projectId + "/evaluationCorrectFilter";
    superagent
      .post(url)
      .set("uid", uid)
      .set("token", token)
      .responseType('blob')
      .end((err, res) => {
         middleCallback(err, res, callback)
    });
  };

  export const downloadProjectsSets = (projects, option, callback, format, filterInputparam, searchType) => {
    const { uid, token } = getUidToken();
    let url = BASE_URL + "downloadProjectsSets?";

    url = url + "projectType=" + searchType;

    if (option.toUpperCase() === "ALL") {
      url = url + "&items=ALL";
    } else if (option.toUpperCase() === "TAGGED") {
      url = url + "&items=DONE";
    } else if (option.toUpperCase() === "NONTAGGED") {
      url = url + "&items=NOT_DONE";
    }

    if (null !== filterInputparam && filterInputparam.length > 0) {
      url = url + filterInputparam;
    }

    if (format && format.toUpperCase() === "DICOM_JSON")
      url = url + "&format=DICOM_JSON";
    if (format && format.toUpperCase() === "STANFORD") {
      url = url + "&format=STANFORD_NER";

      superagent
        .post(url)
        .responseType('blob')
        .set("uid", uid)
        .set("token", token)
        .send( projects)
        .end((err, res) => {
          middleCallback(err, res, callback)
        });
    } else {
      superagent
        .post(url)
        .responseType('blob')
        .set("uid", uid)
        .set("token", token)
        .send( projects)
        .end((err, res) => {
          middleCallback(err, res, callback)
        });
    }
  };

