import React from "react";
import "antd/dist/antd.css";
import { TreeSelect } from "antd"
const { TreeNode } = TreeSelect;

class EntityTree extends React.Component {
    constructor(props) {
        super(props)
    }
  renderTree(label) {
    const uniqueValue = label.id
    if (label.entities !== undefined && label.entities.length !== 0) {
    return (
      <TreeNode
        value={uniqueValue}
        title={label.text || label.name || label.label || label }
        key={uniqueValue}
      >
        {label.entities &&
            label.entities.map((child) => {
                return this.renderTree(child);
        })}
      </TreeNode>
    );
    } else {
    	return (<TreeNode
        value={uniqueValue}
        title={label.text || label.name || label.label || label }
        key={uniqueValue}
        >
      </TreeNode>);
    }
  }
  render() {
    console.log('props value====>',this.props)
    const {entities, onSelect, values} = this.props
    const treeSelectFilterTreeNode = (input, treeNode) => {
      const compare1 = treeNode.props.title.toLowerCase();
      const compare2 = input.toLowerCase();
      return compare1.indexOf(compare2) >= 0;
    };
    return (
      <TreeSelect
        showSearch
        style={{ width: '100%' }}
        onChange={onSelect}
        value={values}
        allowClear
        treeNodeFilterProp="title"
        multiple
        searchPlaceholder="Select Entity"
        dropdownStyle={{ maxHeight: 350, overflow: 'auto' }}
        filterTreeNode={treeSelectFilterTreeNode}
      >
        {entities.map((label) => {
          return this.renderTree(label);
        })}
      </TreeSelect>
    );
  }
}

export default EntityTree

