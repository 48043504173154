import React from 'react';
import { getUidToken } from '../../helpers/commonFunctions';
import { Pagination, Icon } from "semantic-ui-react";
import {
    IMAGE_CLASSIFICATION,
    HIT_STATE_SKIPPED,
    HIT_STATE_DONE,
    HIT_STATE_DELETED,
    captureException,
    HIT_STATE_NOT_DONE,
    HIT_ID,
    ROLES_TYPES,
  } from "../../helpers/Utils";
  import {
    Icon as AntIcon,
    message,
    Dropdown as AntDropdown,
    Menu,
    Select as AntSelect,
    Modal as AntModal,
    Popover as AntPopover,
  } from "antd";

import { updateHitStatus,filterHits } from '../../Services/TaggerApis';
import { refreshUidToken } from '../../helpers/commonFunctions';

import USERIMG from "../../images/User.svg";
import LINK from "../../images/link_small1.svg";

class CardView extends React.Component {

    convertDate(date) {
        var dt = new Date(date);
        var month = dt.getMonth() + 1; // this returns 0 to 11 only
        var day = dt.getDate();
        var year = dt.getFullYear();
        var formedDate = month + "/" + day + "/" + year;
        return formedDate;
    }

    getPopovervalue = (hit) => {
      console.log("getPopovervalue",hit)
        let labelArr = [];
        const result = [];
        if(hit.result){
          labelArr = JSON.parse(hit.result).labels
          labelArr.map(label => {
          result.push(<p className="hits_val" key={label}>{label}</p>);
          });
        }
        return result;
      }

      renderEdit = (imageId) => {
        const {propsState,propsData} = this.props;
    	  let searchHitUrl = { "searchHitUrl":  propsState.hitUrl ? propsState.hitUrl : null}
        let parameter = {
            selectedDate: propsState.selecteddate,
            from: propsState.hitCompletedStartDate,
            to: propsState.hitCompletedEndDate,
            status: propsState.hitStatus ? propsState.hitStatus : propsState.status !== 'all' ? [propsState.status] : null,
            contributorId: propsState.hitAnalystId,
            isDetailView: true,
            selectedImageId: imageId,
            label: propsState.label,
            evaluationType: propsState.hitEvaluationType,
            searchHitUrl: searchHitUrl,
            redirectContributionDetails: propsState.projectDetails.contributorDetails ? propsState.projectDetails.contributorDetails : propsData.selectedHitsDetails.redirectContributionDetails,
            totalHits: propsState.totalHits
        }
        propsData.updateHitsDeatailsData(parameter)
        propsData.updatePrevPageDeatails("HitList")
        let type = propsState.hitStatus ? propsState.hitStatus : propsState.status;
        if(type === ""){
          type = "all";
        }
        let from = propsState.hitCompletedStartDate
        let to = propsState.hitCompletedEndDate
        let currentHitPage = propsState.pageStart
        let currentHitSize = propsState.pageSize
        let screenName = 'space';
        let totalHitsDoneData = propsState.totalHits
        propsData.pushState({
            pathname:
                "/projects/" +
                propsData.params.orgName +
                "/" +
                propsData.params.projectName +
                "/" +
                propsData.params.projectId +
                "/" +
                screenName,
            query: { type, from, to, currentHitPage, currentHitSize,totalHitsDoneData }
        });
    }

    copyUrl = (hit) => {
        let copyText = document.getElementById(hit.hits.id);
        let button1 = document.getElementById("btn" + hit.hits.id);
        var textArea = document.createElement("textarea");
        textArea.value = copyText.textContent;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        textArea.remove();
        button1.focus();
        message.info('Link copied to clipboard.', 1);
    }

    renderPagination() {
        const {propsState,handlePaginationChange} = this.props;
        if (propsState.totalPages === 0) {
            return <div className="no_result w-100">No Results Found.</div>;
        } else {
            return (
                <div className="pagination">
                    <Pagination
                        onPageChange={handlePaginationChange}
                        size='mini'
                        pointing
                        secondary
                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                        activePage={propsState.activePage}
                        totalPages={propsState.totalPages}
                    />
                </div>
            );
        }
    }

    handleLabelChange = (valueArr, hit) => {
      const {propsData}  =this.props
      let resultJson = {"labels":[], "notes":""};
      if(hit.result){
        resultJson = JSON.parse(hit.result);
      }
      resultJson.labels = valueArr
      resultJson = JSON.stringify(resultJson);
      console.log("check hitstatus 382", hit.hits.id, propsData);
      updateHitStatus(hit.hits.id, propsData.projectDetails.id, (hit.hits.status === HIT_STATE_NOT_DONE?HIT_STATE_DONE:hit.hits.status) , resultJson, this.updateStatusCallback);
    }

    updateStatusCallback = (err, response) => {
      const {propsState, fetchResult} = this.props
      if(!err){
        filterHits(propsState.orgName, propsState.projectId, propsState.hitUrl, propsState.hitStatus ? propsState.hitStatus : propsState.status !== 'all' ? [propsState.status] : null, propsState.pageSize, propsState.pageStart, propsState.hitCompletedStartDate, propsState.hitCompletedEndDate, propsState.hitCreatedStartDate, propsState.hitCreatedEndDate, propsState.hitAnalystId, propsState.pageCurrent, HIT_ID, propsState.hitEvaluationType, propsState.label, fetchResult)
      }
      else {
        this.setState({ loading: false });
        if (response && response.body && response.body.code && response.body.code === 401) {
          refreshUidToken(() => { this.setState({ loading: false }) });
        } else {
          captureException(err);
        }
      }
    }

    updateStatus = (hits, projectDetails, status) => {
      let resultJson = {"labels":[], "notes":""};
      if(hits.result){
        resultJson = JSON.parse(hits.result);
      }

      if(resultJson.labels.length === 0 && status === HIT_STATE_DONE){
        const confirm = AntModal.confirm;
        const that = this
        confirm({
          title: 'Confirmation',
          content: 'Unfortunately, there are no annotations. Please add annotation',
          okText: 'OK',
          cancelText: 'CANCEL',
          onOk() { },
          onCancel() { },
        });
      }
      else{
        console.log("check hitstatus 339");
        updateHitStatus(hits.hits.id, projectDetails.id, (status === HIT_STATE_NOT_DONE?HIT_STATE_DONE:status), hits.result, this.updateStatusCallback);
      }
    }

    getStatusMenu = (projectDetails, hit) => {
      if(parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR){
      const menu = (
        <Menu>
          {hit.hits.status !== HIT_STATE_DONE && hit.hits.status !== HIT_STATE_DELETED &&
            <Menu.Item key={HIT_STATE_DONE} >
            <a onClick={(event) => this.updateStatus(hit, projectDetails, HIT_STATE_DONE)}>
            Move to Done
          </a>
           </Menu.Item>
          }
          {hit.hits.status !== HIT_STATE_SKIPPED &&
            <Menu.Item key={HIT_STATE_SKIPPED}>
            <a onClick={(event) => this.updateStatus(hit, projectDetails, HIT_STATE_SKIPPED)}>
              Skip
            </a>
            </Menu.Item>
          }
          {hit.hits.status !== HIT_STATE_DELETED &&
          <Menu.Item key={HIT_STATE_DELETED}>
          <a onClick={(event) => this.updateStatus(hit, projectDetails, HIT_STATE_DELETED)}>
          Delete
          </a>
          </Menu.Item>
          }
        </Menu>
      );
      return menu;
    }
  }

    getSelectedValue = (hit) => {
      let labelArr = [];
      if(hit.result){
        labelArr = JSON.parse(hit.result).labels
      }
      return labelArr;
    }


    getLabelList = (hit, projectDetails) => {
      const children = [];
      let labelArr = [];
      const {Option } = AntSelect;

      if(projectDetails.taskRules){
        const resultJson = JSON.parse( projectDetails.taskRules);
        if(resultJson){
          labelArr = resultJson.tags.toString().split(",")
          labelArr.map(label => {
            children.push(<Option key={label} data-toggle="tooltip" data-placement="top" title={label} style={{ width: 320 }}>{label}</Option>);
          });
        }
      }
      return children;
    }

  render() {
    const {propsState,propsData } = this.props;
    console.log("Props in cardview", propsState)
    return (
      <div className="imgCardView">
          <div className="card_imgsec w-100">
            <ul className="dashboardSet c4 w-100">
              {propsState.projectHits
                ? propsState.projectHits.map((hit,index) => (
                    <li key={index}>
                      <div className="cardCover">
                        <div
                          className="imgCover"
                          onClick={() => this.renderEdit(hit.hits.id)}
                        >
                          <img alt="data" src={hit.hits.data} />
                          {hit.hits.status !== "notDone" && (
                            <span className="label">
                              <img alt="userImage" src={USERIMG} /> {hit.analystName}
                            </span>
                          )}
                          {hit.hits.status !== "notDone" ? (
                            <span className="status">{hit.hits.status}</span>
                          ) : (
                            <span className="status">Not Done</span>
                          )}
                        </div>
                        <div className="imgInfo">
                          <span
                            title="Copy URL to clipboard"
                            id={"btn" + hit.hits.id}
                            onClick={() => this.copyUrl(hit)}
                          >
                            <img alt="link" src={LINK} />
                          </span>
                          <span id={hit.hits.id} title={hit.hits.data}>
                            {hit.hits.data}
                          </span>
                          <span>
                            {hit.updatedTime === null
                              ? ""
                              : this.convertDate(hit.updatedTime)}
                          </span>
                        </div>
                        {propsData.projectDetails.task_type === IMAGE_CLASSIFICATION && (
                          <div className="optionShape">
                            <AntPopover
                              content={this.getPopovervalue(hit)}
                              overlayClassName={
                                hit?.result !== null &&
                                JSON.parse(hit?.result)?.labels.length === 0
                                  ? "hide_popover"
                                  : ""
                              }
                            >
                              <div className="w-100 c_relative">
                                <AntSelect
                                  className="w-100 optionControl"
                                  onChange={(event) =>
                                    this.handleLabelChange(event, hit)
                                  }
                                  value={this.getSelectedValue(hit)}
                                  placeholder="Select an Entity"
                                  notFoundContent="Not Found"
                                  mode="multiple"
                                >
                                  {this.getLabelList(hit, propsData.projectDetails)}
                                </AntSelect>
                                <span
                                  className={
                                    "b_viewmore " +
                                    (hit.result !== null &&
                                    JSON.parse(hit.result).labels.length > 2
                                      ? "display_b"
                                      : "display_n")
                                  }
                                  title="View More"
                                >
                                  ...
                                </span>
                              </div>
                            </AntPopover>
                            <AntDropdown
                              overlay={this.getStatusMenu(propsData.projectDetails, hit)}
                              placement="bottomRight"
                            >
                              <Icon
                                className="dropIcn"
                                name="ellipsis vertical"
                              />
                            </AntDropdown>
                          </div>
                        )}
                      </div>
                    </li>
                  ))
                : null}
            </ul>
            {this.renderPagination()}
          </div>
        </div> 
    );
  }
}

export default CardView

