import * as cornerstoneTools from "cornerstone-tools";
import cloneDeep from "lodash/cloneDeep";
import * as Constants from "../helpers/Constants"; 

export function updateValues(currentObj, measurementData) {
    for (let i = 0; i < measurementData.handles.length; i++) {
        measurementData.handles[i].x += currentObj.pasteCount * Constants.PASTE_PIXEL;
        measurementData.handles[i].y += currentObj.pasteCount * Constants.PASTE_PIXEL;
        measurementData.handles[i].lines[0].x +=
            currentObj.pasteCount * Constants.PASTE_PIXEL;
        measurementData.handles[i].lines[0].y +=
            currentObj.pasteCount * Constants.PASTE_PIXEL;
    }
    return measurementData;
}

export function getToolDataHandle(newHandle, pointArrays, i) {
    if (i + 1 < pointArrays.length) {
        newHandle.x = pointArrays[i][0];
        newHandle.y = pointArrays[i][1];
        newHandle.lines[0].x = pointArrays[i + 1][0];
        newHandle.lines[0].y = pointArrays[i + 1][1];
    }
    return newHandle;
}

export function svgToPointsConvert(points) {
    var commands = points.split(/(?=[LMC])/);
    return commands.map(function (d) {
        var pointsArray = d.slice(1, d.length).split(",");
        var pairsArray = [];
        for (var i = 0; i < pointsArray.length; i += 2) {
            if (pointsArray[i + 1].indexOf("Z") !== -1) {
                pointsArray[i + 1] = pointsArray[i + 1].substring(
                    0,
                    pointsArray[i + 1].indexOf("Z")
                );
            }
            pairsArray.push(+pointsArray[i], +pointsArray[i + 1]);
        }
        return pairsArray;
    });
}

export function getImages(currentObject, element) {
    let jsonResult = currentObject.state.hits
    let imageIds = [];
            imageIds = jsonResult;
            imageIds.map((element) => {
                if(element.result !== undefined  && element.result !== "" && element.result !== null) {
                   
                    let result = element.result 
                    element.points = JSON.parse(result)
                    element.toolData = element.points[0];
                    element = element
                }
                if(element.hitResults !== null && element.hitResults.length > 0){
                if(element.hitResults[0].result !== undefined  && element.hitResults[0].result !== "" && element.hitResults[0].result !== null){
                   
                    let result = element.hitResults[0].result 
                    element.points = JSON.parse(result)
                    element.toolData = element.points[0];
                    element = element
                }
            }
                else{
                    element.points = [];
                    element.toolData = [];
                }
            });
            console.log('imageIds====>',imageIds)
            currentObject.setState({ images: imageIds });
            handleImageLoading(currentObject, element, imageIds);
}

export function handleImageLoading(currentObj, element, imageIds) {
    console.log('calling form api===>',currentObj.state.currentImageIndex)
    if(currentObj.state.currentImageIndex !== 0){
        currentObj.setState ({ currentImageIndex : currentObj.state.currentImageIndex + 1 })
    }
 
    const imagePromise = currentObj.updateTheImage(element, imageIds, 
        currentObj.state.currentImageIndex );
    imagePromise.then(function () {
        const mouseWheelEvents = ["mousewheel", "DOMMouseScroll"];
        mouseWheelEvents.forEach(function (eventType) {
            element.addEventListener(eventType, function (e) {
                toggleManager(currentObj, 0);
                if (e.wheelDelta < 0 || e.detail > 0) {
                    if(!disableNextButton(currentObj)){
                        currentObj.saveTagAndNextRow('next')
                    }
                } else {
                    if(currentObj.state.currentIndex > 0){
                        currentObj.getBackTopreviousRow()
                    }
                }
                // Prevent page from scrolling
                return false;
            });
        });
    });
}

export function getDrawRulervalues(toolType, color, e, label, currentObj, imageIds) {

    let toolsType =  ['Length', 'Bidirectional']
    currentObj.setState({ rulerdrawregions: []});
    toolsType.map((tool)=>{
        let rulerannotationlistData = cornerstoneTools.getToolState(e.target, tool);
        if(rulerannotationlistData !== undefined){
            if(rulerannotationlistData) {
                let rulerannotationlist = rulerannotationlistData.data
                for(let i=0;i < rulerannotationlist.length; i++){
                    if(rulerannotationlist[i].active && !rulerannotationlist[i].label && label !== ''){
                        rulerannotationlist[i].color = color 
                        rulerannotationlist[i].label = label
                        rulerannotationlist[i].toolType = toolType
                        // cornerstoneTools.toolColors.setToolColor(color)
                    }
                    if((!rulerannotationlist[i].active || currentObj.state.AnnotationsDrawn) && !rulerannotationlist[i].label && label === ''){
                        rulerannotationlist[i].toolType = toolType
                        currentObj.setState({ open: true });
                    }
                    let  rulerdrawregions = []
                    rulerdrawregions = currentObj.state.rulerdrawregions
                    rulerdrawregions.push(rulerannotationlist[i])
                    imageIds[currentObj.state.currentImageIndex].rulerpoints = rulerdrawregions;
                    currentObj.setState({rulerdrawregions : rulerdrawregions})
                }
            }
        }
    })
}



export function getDrawedRegions(currentObj, e, annotationlistarray, imageIds,toolDataConfig) {
   
    var annotationlist = cornerstoneTools.getToolState(e.target, "FreehandRoi");

    currentObj.setState({ drawregions: []});
    // if(currentObj.state.tooltype  === 'Length' || currentObj.state.tooltype  === 'Bidirectional'){
        getDrawRulervalues(currentObj.state.tooltype, toolDataConfig[2], e, currentObj.state.defaultClass, currentObj, imageIds)
    // }
    var activeAnnoIndex = -1;
    //pushes region values into right side for display
    
    var drawregionsArray = [];
    let labelClick = false;
    if (annotationlist) {
        annotationlistarray = annotationlist.data;
        let drawingNotCompleted = false
        for (var i = 0; i < annotationlistarray.length; i++) {
             //setting the color, based on which annotation is drawing and when not moving the text
            if(!annotationlistarray[i].area && toolDataConfig[2] !== null){
                annotationlistarray[i].color = toolDataConfig[2]
            }
               
            if (annotationlistarray[i].area) {
                if(toolDataConfig[3] === i){ // added condition to make the annotation selectable
                    annotationlistarray[i].active = true
                    activeAnnoIndex = i
                 }
                else {
                  if(activeAnnoIndex === -1 &&  annotationlistarray.length === i+1){
                    annotationlistarray[i].active = true
                    activeAnnoIndex = i
                  }
                  else {
                  annotationlistarray[i].active = false
                  }
                }//
                // if completely drawn the annotations
                drawregionsArray.push(annotationlistarray[i]);
                if (
                    imageIds[currentObj.state.currentImageIndex].points.length + 1 ===
                    drawregionsArray.length &&
                    currentObj.isContourSelected
                ) {
                    
                	if(currentObj.state.labelExists){
                		currentObj.setState({ open: false });
                		labelClick = true;
                		let labelText = currentObj.state.labelText;          
                		currentObj.setState({ textValue: labelText });
                	}else if (!currentObj.showhideLabel) {
                        currentObj.setState({ textValue: "" });
                        currentObj.setState({ open: false, collapse: true });
                        currentObj.setState({ open: true });
                        labelClick = false;
                    }
                }
                if (!annotationlistarray[i].label) {
                    drawingNotCompleted = true
                }
            }
            else{
                drawingNotCompleted = true
                if(activeAnnoIndex !== -1)
                    annotationlistarray[activeAnnoIndex].active = false
            }
        }
        
        currentObj.setState({ drawregions: drawregionsArray, drawingNotCompleted : drawingNotCompleted});
        if(toolDataConfig[0] !== -1) //If currently user is annotating
        {
           currentObj.setState({changesInSession : currentObj.state.changesInSession + 1});  // Save Data if only annotation drawn in the image.
        }
    }
    imageIds[currentObj.state.currentImageIndex].points = drawregionsArray;
    if (annotationlist) {
        imageIds[currentObj.state.currentImageIndex].toolData =
            annotationlist.data[0];
    }
    if (currentObj.state.startIndex == null) {
        currentObj.setState({ startIndex: currentObj.state.currentImageIndex });
    }
    currentObj.setState({ images: imageIds });
    
    if(currentObj.state.labelExists && labelClick){
         const index = currentObj.state.labelIndex === undefined ? currentObj.state.images[currentObj.state.currentImageIndex].points.length : currentObj.state.labelIndex;
         setLabelValue(currentObj, currentObj.state.textValue, index, 'freehand');
         var toolData = cornerstoneTools.getToolState(currentObj.dicomImage, 'FreehandRoi').data;
         toolData[index-1].color = currentObj.state.entityColorMap[currentObj.state.textValue];
         const FreehandRoi = cornerstoneTools.FreehandRoiTool;
         cornerstoneTools.addTool(FreehandRoi)
         cornerstoneTools.setToolActive('FreehandRoi', { mouseButtonMask: 1 })
         currentObj.isToolActive = true
         currentObj.setState({defaultClass : ''});
         currentObj.disableAllTools();
    }
}

export function toggleManager(currentObj, state) {
    currentObj.isContourSelected = false;
    currentObj.isEraseSelected = false;
    currentObj.isBubbleSelected = false;
    currentObj.isInvertSelected = false;
    currentObj.isClearSelected = false;
    currentObj.isClearAllSelected = false;
    currentObj.isUnidirectionSelected = false;
    currentObj.isBidirectionSelected = false;
    switch (state) {
        case 1:
            currentObj.isContourSelected = true;
            break;
        case 2:
            currentObj.isEraseSelected = true;
            break;
        case 3:
            currentObj.isBubbleSelected = true;
            break;
        case 4:
            currentObj.isInvertSelected = true;
            break;
        case 5:
            currentObj.isClearSelected = true;
            break;
        case 6:
            currentObj.isClearAllSelected = true;
            break;
        case 7:
            currentObj.isUnidirectionSelected = true;
            break;
        case 8:
            currentObj.isBidirectionSelected = true;
            break;
        default:
            currentObj.isContourSelected = false;
            currentObj.isEraseSelected = false;
            currentObj.isBubbleSelected = false;
            currentObj.isInvertSelected = false;
            currentObj.isClearSelected = false;
            currentObj.isClearAllSelected = false;
            currentObj.isUnidirectionSelected = false;
            currentObj.isBidirectionSelected = false;
    }
}

export function getlabelValue(currentObj, index) {
   if (currentObj.state.images.length === 0 || currentObj.state.images[currentObj.state.currentImageIndex] === undefined ||
    	currentObj.state.images[currentObj.state.currentImageIndex].points === undefined ||
        currentObj.state.images[currentObj.state.currentImageIndex].points[index] === undefined ||
        currentObj.state.images[currentObj.state.currentImageIndex].points[index].label === undefined){
            return "";
        }else{
        	return currentObj.state.images[currentObj.state.currentImageIndex].points[index].label;
        }
/*	if (currentObj.state.images.length > currentObj.state.currentImageIndex &&
			    	currentObj.state.images[currentObj.state.currentImageIndex].points !== undefined){
    	return currentObj.state.images[currentObj.state.currentImageIndex].points[index].label;
	}
	
	return "";*/
}

export function getUpdatedToolData(currentObj, toolData, pointsArray, currentIndex) {
    const values = currentObj.state.images;
    for (let i = 0; i < pointsArray.length; i++) {
        if (i > toolData.handles.points.length - 1) {
            var LastToolData = cloneDeep(
                toolData.handles.points[toolData.handles.points.length - 1]
            );
            var handleData = getToolDataHandle(LastToolData, pointsArray, i);
            toolData.handles.points[i] = handleData;
        } else {
            toolData.handles.points[i] = getToolDataHandle(
                toolData.handles.points[i],
                pointsArray,
                i
            );
        }
    }
    values[currentIndex].points = [toolData];
    values[currentIndex].toolData = toolData;
    let interpolationDone = currentObj.state.interpolationDone
    interpolationDone.push(values[currentIndex])
    currentObj.setState({ images: values ,interpolationDone});
}


export function setLabelValue(currentObj, value, labelIndex, type) {
    const images = currentObj.state.images;
    console.log("Inside the label function");
    if(type === 'freehand'){
        images[currentObj.state.currentImageIndex].points[
            labelIndex - 1
        ].label = value;
    }
    else{
        images[currentObj.state.currentImageIndex].rulerpoints[
            labelIndex - 1
        ].label = value;
    }
    currentObj.setState({ images: images });
}


// to check wheather all the annotations are completely drawn
export function toolDataCompleted(currentObj) {

    let isCompleted = false
    var annotationlist = cornerstoneTools.getToolState(currentObj.dicomImage, "FreehandRoi");
    if(annotationlist!== undefined){
        let annotationlistData = annotationlist.data
        annotationlistData.map((data)=>{
            if(!data.area){
                isCompleted = true
            }
        })
    }
    return isCompleted
}

// condition to hide the next button
export function disableNextButton(currentObj) {
    let disableNext = false
    disableNext =  currentObj.state.currentIndex < 0 ||
                    (currentObj.state.hitScrollCompleted &&
                        currentObj.state.currentIndex > currentObj.state.hits.length - 2)
                    || (currentObj.state.totalHitsCompleted &&
                        currentObj.state.currentIndex > currentObj.state.hits.length - 2)
                    || (currentObj.state.currentIndex === currentObj.state.totalFilteredHits - 1)
    return disableNext
}
