import React from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { bindActionCreators } from "redux";
import {
  getUidToken,
  logEvent,
  showAlert,
  saveactiveTab,
  getactiveName,
} from "../../../helpers/commonFunctions";

import {
  uploadFileDT,
  uploadFileDTCompare,
  uploadModel,
  getNERTagsList,
} from "../../../Services/UploadFileApis";
import {
  getHomeData,
  getAllWorkspaceList,
} from "../../../Services/WorkspaceApis";

import {
  updateHomeData,
  getUserHomeData,
  getUserHomeDataWithFetchOption,
  updateModalView,
  updatePrevPageDeatails,
  updateUserDefinedColorStats,
  updateFileUploadStats,
  selectProject,
} from "../../../redux/reducer/projectReducer";

import {
  uploadDataForm,
  setloaderMenu,
} from "../../../redux/reducer/projectReducer";

import { push } from "react-router-redux";
import {
  DOCUMENT_ANNOTATION_TYPES,
  DOC_ANNO_CHILDNODEALERT,
  DOC_ANNO_ALERT_TITLE,
  DOC_ANNO_OK_TEXT,
  IMAGE_MASKING,
  IMAGE_SEGMENTATION,
} from "../../../helpers/Utils";

import {
  VIDEO_CLASSIFICATION,
  VIDEO_BOUNDING_BOX,
  IMAGE_CLASSIFICATION,
  DOCUMENT_ANNOTATION,
  TEXT_SUMMARIZATION,
  IMAGE_POLYGON_BOUNDING_BOX,
  IMAGE_POLYGON_BOUNDING_BOX_V2,
  POS_TAGGING,
  POS_TAGGING_GENERIC,
  TEXT_CLASSIFICATION,
  TEXT_MODERATION,
  IMAGE_BOUNDING_BOX,
  CHILD_NODE_LIMIT,
  DICOM_CLASSIFICATION,
  DICOM_SEGMENTATION,
  getTaskTypeByShape,
  validEnitity,
} from "../../../helpers/Utils";

import { Button,Form } from "semantic-ui-react";
import EntityOptions from "./EntityOptions";
import helper from "../ImportProjectHelpers";
import {
  Modal as AntModal,
  Button as AntButton,
  Select,
  LocaleProvider,
  Icon as AntIcon,
  Upload as AntUpload,
  Slider,
  Radio,
} from "antd";
import en_US from "antd/lib/locale-provider/en_US";
import _ from "lodash";
import BACK from "../../../images/Arrow_Simple_left1.svg";
import ToggleSwitch from "./ToggleSwitch";
import queryString from "query-string";
import "../ImportProjects.css";
import TaggerImportFormView from "./TaggerImportFormView";
import TaggerImportFileUpload from "./TaggerImportFileUpload";
import NERTagsModal from "../../../Components/NERTagsModal/NERTagsModal";

const bytes = require("bytes");
const uuidv1 = require("uuid/v1");
class TaggerImport extends React.Component {
  constructor(props) {
    super(props);
    let basicEntitiesOld = [];
    let basicEntities = [];
    let advanceEntities = [];
    let allowDynamiclabelling = false;
    let createUserDefinedColor = false;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileUploaded = this.fileUploaded.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.handleAntUploadFile = this.handleAntUploadFile.bind(this);
    this.handleAntUploadFileModel = this.handleAntUploadFileModel.bind(this);
    this.handleAntUploadFileCompare = this.handleAntUploadFileCompare.bind(
      this
    );
    this.startTagging = this.startTagging.bind(this);
    this.projectDetailsFetched = this.projectDetailsFetched.bind(this);
    this.fileUploadProgressCallback = this.fileUploadProgressCallback.bind(
      this
    );
    this.docOptionTypeChange = this.docOptionTypeChange.bind(this);
    this.addAdvanceEntityForm = this.addAdvanceEntityForm.bind(this);
    this.addParent = this.addParent.bind(this);
    this.updateAvanceFormInput = this.updateAvanceFormInput.bind(this);
    this.updateBasicFormInput = this.updateBasicFormInput.bind(this);
    this.removeAdvanceFormLabel = this.removeAdvanceFormLabel.bind(this);
    this.removeBasicFormEntity = this.removeBasicFormEntity.bind(this);
    this.modelfileUploaded = this.modelfileUploaded.bind(this);
    this.removeTagSpaces = this.removeTagSpaces.bind(this);
    let defaultShape = "polygon";
    let search = new URLSearchParams(this.props.location.search);
    let currentpPageNo =
      this.props.location.search !== "" && search !== ""
        ? parseInt(search.get("currentpPage"))
        : 1;
    let currentwPageNo =
      this.props.location.search !== "" && search !== ""
        ? parseInt(search.get("currentwPage"))
        : 1;
    let workspaceName = "";
    if (this.props.location.query && this.props.location.query.shape) {
      defaultShape = this.props.location.query.shape;
    }
    let fields = {};
    const { query } = this.props.location;
    fields.classification_name = "";
    fields.classification_disp_name = "";
    fields.classification_classes = "";

    let addClassificationGroup = false;
    if (this.props.projectDetails && currentwPageNo) {
      workspaceName = props.projectDetails.workspaceName;
      fields.project_name = "Copy of " + props.projectDetails.name;
      const taskRules = JSON.parse(props.projectDetails.taskRules);
      if (typeof taskRules.tags === "string") {
        fields.tags = taskRules.tags;
      } else {
        let tags = [];
        fields.tagObject = taskRules.tags;
        for (let index = 0; index < taskRules.tags.length; index++) {
          tags.push(taskRules.tags[index].label);
        }
        fields.tags = tags.join(",");
      }
      fields.instructions = taskRules.instructions;
      if (taskRules.classification && taskRules.classification.length > 0) {
        fields.classification_disp_name =
          taskRules.classification[0].displayName;
        fields.classification_name = taskRules.classification[0].name;
        fields.classification_classes = taskRules.classification[0].classes.join(
          ","
        );
        addClassificationGroup = true;
      }
      allowDynamiclabelling = props.projectDetails.allowDynamiclabelling;
      createUserDefinedColor = props.projectDetails.createUserDefinedColor;

      if (query && query.type === IMAGE_MASKING && createUserDefinedColor) {
        fields.coloredTags = taskRules.coloredTags;
      }

      // adds to identify basic and advance enitites list from available tags
      if (Array.isArray(taskRules.tags)) {
        basicEntitiesOld = taskRules.tags.filter(
          (it) => !("entities" in it.entities[0])
        );
        advanceEntities = taskRules.tags.filter(
          (it) => "entities" in it.entities[0]
        );
        taskRules.tags
          .filter((it) => !("entities" in it.entities[0]))
          .forEach((it) => {
            if (it.entities) {
              const newTags = [];
              it.entities.forEach((tag) => {
                newTags.push(tag.name);
              });
              it.entities = newTags.join();
              basicEntities.push(it);
            }
          });
      }
    }
    let docAnnotationLabelType = undefined;
    this.props.updateUserDefinedColorStats(createUserDefinedColor);
    let selectClassicType = "news";
    this.state = {
      projectDetails: this.props.projectDetails,
      fields,
      errors: {},
      loading: false,
      error: false,
      addClassificationGroup,
      fileUploaded: false,
      autoClose: true,
      defaultShape,
      notes: false,
      hideLabels: false,
      projectCreationComplete: false,
      fileUploadProgress: 0,
      newEntity: "",
      tagTypes: ["tags"],
      newEntityForm: [],
      imageAttributes: [],
      dropDownAttributes: [],
      objectAttribute: "",
      addNewEntityForm: false,
      docCategoryType: {
        Basic: false,
        Advance: false,
      },
      basicEntities,
      advanceEntities,
      allowDynamiclabelling,
      isShowAdvanceLabelTop: false,
      workspaceList: [],
      selectedWorkspace: "",
      docAnnotationLabelType,
      isLoagin: false,
      fileName: "",
      fileNameCompare: undefined,
      fileCompare: undefined,
      isModelUpload: false,
      modelfileName: "",
      predictionValue: 0.0,
      selectedModelType: undefined,
      isRunModel: true,
      disableSubmitButton: true,
      createUserDefinedColor,
      modelEntity: false,
      NERTags: "",
      nermodelVisible: false,
      editNERTag: {},
      selectClassicType,
      currentpPage: currentpPageNo,
      currentwPage: currentwPageNo,
      workspaceName,
      compress: false,
    };
  }
  emptyCheck = false;

  componentWillMount() {
    let isFilter = window.localStorage.getItem("isFilter");
    if (isFilter === undefined) {
      window.localStorage.setItem(
        "isFilter",
        this.props.location.query.isFilter
      );
      window.localStorage.setItem(
        "projectType",
        this.props.location.query.projectType
      );
      window.localStorage.setItem(
        "currentProjectPage",
        this.props.location.query.currentpPage
      );
    }
  }

  componentDidMount() {
    this.getWorkspaceDetails();
    const editor = document.getElementById("instruction");
    editor.setAttribute("data-gramm", "false");
    if (this.state.advanceEntities && this.state.advanceEntities.length === 0) {
      this.docOptionTypeChange(DOCUMENT_ANNOTATION_TYPES.ADVANCE);
    }
    if (this.state.basicEntities && this.state.basicEntities.length === 0) {
      this.docOptionTypeChange(DOCUMENT_ANNOTATION_TYPES.BASIC);
    }
    if (this.props.location.query.type === POS_TAGGING_GENERIC) {
      getNERTagsList(this.props.user.orgId, this.getNERTagsListCallBack);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { projectCreated, projectCreateError } = this.props;
    this.props.setloaderMenu(false);
    if (
      !projectCreated &&
      nextProps.projectCreated &&
      nextProps.currentProject
    ) {
      console.log("project created, upload file");
      this.props.getUserHomeDataWithFetchOption(getUidToken());
      this.setState({ loading: false, errors: {} });
    } else if (!projectCreateError && nextProps.projectCreateError) {
      let error_message = nextProps.projectCreateError;
      showAlert(error_message, "error");
      this.setState({
        errors: { submit: nextProps.projectCreateError },
        loading: false,
        error: true,
      });
    } else {
      this.setState({ loading: false, error: true });
    }
  }

  getTagsInput() {
    let tagObject = this.state.fields.tagObject;
    let renderArrs = [];
    for (let index = 0; index < tagObject.length; index++) {
      let tag = tagObject[index];
      renderArrs.push(
        <div>
          <Form.Input
            style={{ width: "50%" }}
            onChange={this.handleTagChange.bind(this, index, "name")}
            id={index}
            compact
            label="Name"
            value={tag.label}
            placeholder="Label Name"
          />
          <Form.Input
            style={{ width: "50%" }}
            onChange={this.handleTagChange.bind(this, index, "imageUrl")}
            id={index}
            compact
            label="Thumbnail Image URL"
            value={tag.imageUrl}
            placeholder="http://s3/image_name.jpg"
          />
          <br />
        </div>
      );
    }
    return (
      <div
        style={{
          backgroundColor: "aliceBlue",
          width: "50%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          textAlign: "center",
        }}
      >
        {renderArrs}
      </div>
    );
  }


  handleTagChange(index, event) {
    console.log("handleTagChange ", index, event);
  }

  startTagging(type) {
    const { orgName, currentProject } = this.props;
    const { fields } = this.state;
    this.props.selectProject(this.props.currentProject);
    if (type === DICOM_SEGMENTATION) {
      this.props.pushState(
        "/projects/" +
          orgName +
          "/" +
          fields.project_name +
          "/" +
          currentProject +
          "/view"
      );
      this.props.updatePrevPageDeatails("leaderBoard");
    } else {
      this.props.pushState(
        "/projects/" +
          orgName +
          "/" +
          fields.project_name +
          "/" +
          currentProject +
          "/space"
      );
      this.props.updatePrevPageDeatails("leaderBoard");
    }
  }

  projectDetailsFetched(error, response) {
    if (!error) {
      const {
        userDetails,
        projects,
        planName,
        labelsAllowed,
        labelsDone,
        subscriptionExpiryTimestamp,
        hasSubscriptionExpired,
      } = response.body;
      this.props.updateHomeData(
        userDetails,
        projects,
        planName,
        labelsAllowed,
        labelsDone,
        subscriptionExpiryTimestamp,
        hasSubscriptionExpired
      );
    } else {
      if (!getUidToken().uid) {
        this.props.pushState("/projects/login");
      }
    }
  }

  loadHomeData() {
    getHomeData(this.projectDetailsFetched);
  }

  fileUploaded(error, response) {
    console.log("Entered in the file uploaded", response);
    this.props.setloaderMenu(true)
    if (response.statusCode && response.statusCode === 200) {
      logEvent("buttons", "file upload sucessful");
      if (
        this.state.uploadType === "Raw" ||
        this.state.uploadType === "Pre-Annotated"
      ) {
        this.setState({ fileUploadStats: response.body });
      }
      if (this.state.isModelUpload && this.state.uploadType !== "uploadModel") {
        //if upload model is selected move to upload screen
        this.setState({ uploadType: "uploadModel", errors: {} });
      } else {
        this.setState({ uploadType: "" });
      }
      this.props.setloaderMenu(false);
      this.setState({
        loading: false,
        projectCreationComplete: true,
        fileUploaded: true,
      });
      this.props.updateFileUploadStats(response.body);
    } else {
      const errors = this.state.errors;
      logEvent("buttons", "file upload failed");
      errors.submit = response.body
        ? response.body.message
        : response.statusText;
      this.props.setloaderMenu(false);
      this.setState({ loading: false, fileUploaded: false, errors });
    }
  }

  fileUploadProgressCallback(event) {
    console.log("file upload progress", event.percent);
    this.setState({ fileUploadProgress: event.percent });
  }

  removeEmptySpace = (tags) => {
    let arr = tags.split(",").map(function(item) {
      return item.trim();
    });
    return arr.toString();
  };

  handleSubmit = () => {
    if (
      this.state.fields.tags &&
      !validEnitity(this.state.fields.tags) &&
      !this.props.showUserDefinedColor
    ) {
      this.displayErrorNotification(
        "error",
        "Entities should not end with comma"
      );
      return false;
    }
    if (this.props.location.query.type === POS_TAGGING) {
      let tagWords = false;
      if (tagWords) {
        this.displayErrorNotification(
          "error",
          "Tag shoud be single word for PoS Tagging project"
        );
        return false;
      }
    }
    const {
      basicEntities,
      advanceEntities,
      selectedWorkspace,
      uploadType,
      file,
      selectedFormat,
      isURLs,
      fields,
      hideLabels,
      notes,
      autoClose,
      defaultShape,
      allowDynamiclabelling,
      createUserDefinedColor,
      selectClassicType,
    } = this.state;
    const {
      projectCreated,
      location,
      currentProject,
      uploadDataForm,
    } = this.props;

    if (currentProject && projectCreated) {
      logEvent("buttons", "file uploading");
      if (!this.state.file) {
        showAlert("Select a file.", "warning");
      } else {
        console.log("Entered in the setloader 3");
        this.props.setloaderMenu(true);
        this.setState({ loading: true });
        if (uploadType === "Pre-Annotated") {
          if (
            location.query.type === TEXT_SUMMARIZATION ||
            location.query.type === TEXT_MODERATION ||
            location.query.type === VIDEO_CLASSIFICATION ||
            location.query.type === DICOM_CLASSIFICATION
          ) {
            uploadFileDT(
              file,
              currentProject,
              this.fileUploaded,
              this.fileUploadProgressCallback,
              "PRE_TAGGED_TSV"
            );
          } else if (location.query.type === TEXT_CLASSIFICATION) {
            let format = "PRE_TAGGED_TSV";
            if (selectedFormat && selectedFormat === "json") {
              format = "PRE_TAGGED_JSON";
            }
            uploadFileDT(
              file,
              currentProject,
              this.fileUploaded,
              this.fileUploadProgressCallback,
              format
            );
          } else {
            uploadFileDT(
              file,
              currentProject,
              this.fileUploaded,
              this.fileUploadProgressCallback,
              "PRE_TAGGED_JSON"
            );
          }
        } else {
          if (
            location.query.type === DOCUMENT_ANNOTATION ||
            location.query.type === POS_TAGGING_GENERIC
          ) {
            if (isURLs) {
              uploadFileDT(
                file,
                currentProject,
                this.fileUploaded,
                this.fileUploadProgressCallback,
                "URL_FILE"
              );
            } else {
              uploadFileDT(
                file,
                currentProject,
                this.fileUploaded,
                this.fileUploadProgressCallback,
                null,
                this.state.modelEntity
              );
            }
          } else {
            if (
              location.query.type === IMAGE_CLASSIFICATION &&
              this.state.isModelUpload
            ) {
              uploadFileDT(
                file,
                currentProject,
                this.fileUploaded,
                this.fileUploadProgressCallback
              );
            } else if (location.query.type === TEXT_CLASSIFICATION) {
              uploadFileDT(
                file,
                currentProject,
                this.fileUploaded,
                this.fileUploadProgressCallback,
                null,
                this.state.isModelUpload,
                this.state.selectClassicType
              );
            } else {
              if (
                this.state.fileName !== null &&
                this.state.fileNameCompare !== undefined
              ) {
                uploadFileDT(
                  this.state.file,
                  this.props.currentProject,
                  this.fileUploaded,
                  this.fileUploadProgressCallback
                );
                uploadFileDTCompare(
                  this.state.fileCompare,
                  this.props.currentProject,
                  this.fileUploaded,
                  this.fileUploadProgressCallback
                );
              } else {
                uploadFileDT(
                  this.state.file,
                  this.props.currentProject,
                  this.fileUploaded,
                  this.fileUploadProgressCallback
                );
              }
            }
          }
        }
      }
    } else {
      logEvent("buttons", "project created");
      // In case of more tagging types update tag rule as array insted of strings
      basicEntities.forEach((it, index) => {
        if (typeof it.entities === "string") {
          basicEntities[index].entities = it.entities
            .split(",")
            .map(Function.prototype.call, String.prototype.trim)
            .map((it) => {
              return {
                id: uuidv1(),
                name: it,
              };
            });
        }
      });

      if (location.query.type !== DOCUMENT_ANNOTATION)
        this.removeTagSpaces(fields.tags);

      const rules = {
        tags:
          location.query.type === DOCUMENT_ANNOTATION
            ? [...basicEntities, ...advanceEntities]
            : this.removeEmptySpace(fields.tags),
        instructions: fields.instructions,
      };

      if (
        location.query.type === DOCUMENT_ANNOTATION ||
        location.query.type === POS_TAGGING_GENERIC ||
        location.query.type === IMAGE_BOUNDING_BOX
      ) {
        rules.autoClose = autoClose;
      }
      if (location.query.type === IMAGE_BOUNDING_BOX) {
        rules.hideLabels = hideLabels;
        rules.notes = notes;
      }
      if (location.query.type === IMAGE_POLYGON_BOUNDING_BOX_V2) {
        rules.defaultShape = defaultShape;
      }
      if (
        location.query.type === IMAGE_SEGMENTATION ||
        location.query.type === IMAGE_MASKING ||
        location.query.type === VIDEO_BOUNDING_BOX 
      ) {
        let imageAttribute = [];
        let dropDownAttribute = [];
        let checkClasses = false;
        let checkClassesdrop = false;
        this.state.imageAttributes.map((value) => {
          if (value.classes.length === 0) {
            checkClasses = true;
          } else if (Array.isArray(value.classes)) {
            imageAttribute.push(value);
          } else {
            value.classes = this.getClassificationClass(value.classes);
            imageAttribute.push(value);
          }
        });
        this.state.dropDownAttributes.map(value => {
          if (value.classes.length === 0) {
            checkClassesdrop = true;
          } else if (Array.isArray(value.classes)) { 
            dropDownAttribute.push(value);
          } else {
            value.classes = this.getClassificationClass(value.classes)
            dropDownAttribute.push(value);
          }
        })
        if (!checkClasses) rules.classification = imageAttribute;
        if (this.state.objectAttribute && this.state.objectAttribute.length > 0)
          rules.attributes = this.state.objectAttribute;
          rules.editClassAttribute = dropDownAttribute;
      }
      if (
        fields &&
        fields.classification_name &&
        fields.classification_disp_name &&
        fields.classification_classes &&
        fields.classification_name.length > 0 &&
        fields.classification_classes.length > 0
      ) {
        rules.classification = [
          {
            name: fields.classification_name,
            displayName: fields.classification_disp_name,
            classes: this.getClassificationClass(fields.classification_classes),
          },
        ];
      }
      if (location.query.type === IMAGE_MASKING && createUserDefinedColor) {
        rules.coloredTags = fields.coloredTags;
        let tagsArray = [];
        rules.coloredTags.map((coloredTag) => {
          tagsArray.push(coloredTag.label);
        });
        rules.tags = tagsArray.join(",");
      }

      let input = {
        name: fields.project_name,
        taskType: location.query.type,
        rules: JSON.stringify(rules),
      };
      if (location.query.type === DOCUMENT_ANNOTATION) {
        input.allowDynamiclabelling = allowDynamiclabelling;
      }
      if (location.query.type === IMAGE_MASKING) {
        input.createUserDefinedColor = createUserDefinedColor;
      }

      if (
        location.query.type === POS_TAGGING_GENERIC &&
        this.state.modelEntity &&
        !this.state.isModelUpload
      ) {
        input.nerTags = this.state.NERTags;
      }

      if (location.query.type === TEXT_CLASSIFICATION) {
        input.classicType = selectClassicType;
      }

      input.workspaceId =
        selectedWorkspace === "" && selectedWorkspace.length === 0
          ? 1
          : selectedWorkspace;

      input.enableAutoTag = this.state.modelEntity;
      input.enableAutoTag = this.state.isModelUpload;
      input.compressedImage = this.state.compress;
      uploadDataForm(input, getUidToken());
      this.props.setloaderMenu(true);
      this.setState({ loading: true });
    }
  };

  getClassificationClass = (classsification) => {
    let splitArr = [];
    if (classsification.indexOf(",") > 0) {
      splitArr = classsification.split(",");
      splitArr = splitArr.filter((entry) => entry.trim() !== "");
      splitArr = splitArr.map((item) => (item = item.trim()));
    }
    return splitArr;
  };

  handleUploadFile(event) {
    console.log("handle upload file", event.target.files[0]);
    const file = event.target.files[0];
    this.setState({ file });
  }

  handleAntUploadFile(info) {
    const file = info.file.originFileObj;
    this.setState({ file });
    this.setState({ fileName: file.name });
  }

  handleAntUploadFileModel(type, info) {
    const file = info.file.originFileObj;
    this.setState({ modelfile: file });
    this.setState({ modelfileName: file.name });
  }

  handleAntUploadFileCompare(info) {
    const fileCompare = info.file.originFileObj;
    this.setState({ fileCompare, fileNameCompare: fileCompare.name });
  }


  isUserDefinedColorSwitchChange = (checked) => {
    this.props.updateUserDefinedColorStats(checked);
    let fields = this.state.fields;
    let { coloredTags } = this.state.fields;

    if (
      coloredTags == undefined ||
      (coloredTags != undefined && fields.coloredTags.length == 0)
    ) {
      coloredTags = [{ color: helper.getDefaultColor, label: "" }];
      fields.coloredTags = coloredTags;
      fields.tags = "";
    }
    this.setState({ fields, createUserDefinedColor: checked });
  };

  advancedOptionChange = (e1, e3) => {
    const { advanceOptions } = helper;
    if (e1 === advanceOptions.AUTOCLOSE) {
      this.setState({ autoClose: e3.checked });
    } else if (e1 === advanceOptions.HIDELABELS) {
      this.setState({ hideLabels: e3.checked });
    } else if (e1 === advanceOptions.NOTES) {
      this.setState({ notes: e3.checked });
    } else if (e1 === advanceOptions.DEFAULTSHAPE) {
      if (e3.checked) {
        this.setState({ defaultShape: e3.value });
      }
    } else if (e1 === advanceOptions.SELECTEDFORMAT) {
      if (e3.checked) {
        this.setState({ selectedFormat: e3.value });
      }
    } else if (e1 === advanceOptions.ISURLS) {
      this.setState({ isURLs: e3.checked });
    } else if (e1 === advanceOptions.ADVANCETAGS) {
      this.setState({ advancedTags: e3.checked });
    } else if (e1 === advanceOptions.UPLOADMODEL) {
      this.setState({ isModelUpload: e3.checked });
    } else if (e1 === advanceOptions.RUNMODEL) {
      this.setState({ isRunModel: e3.checked });
    }
  };

  addAdvanceEntityForm() {
    const { advanceEntities } = this.state;
    const newList = advanceEntities;
    const masterId = uuidv1();
    const formId = uuidv1();
    let count = 0;
    if (0 !== newList.length) {
      count = newList[newList.length - 1].count + 1;
    } else {
      count = newList.length + 1;
    }
    newList.push({
      id: formId,
      name: "",
      entities: [
        {
          id: masterId,
          name: "",
          labelName: "",
          parentId: null,
          level: 0,
          entities: [
            {
              id: uuidv1(),
              name: "",
              parentId: masterId,
              level: 1,
              entities: [],
            },
          ],
        },
      ],
      count: count,
    });
    this.setState({ isShowAdvanceLabelTop: true });
    return newList;
  }

  // @params input (formId,entityId,event,data, action), output (data)
  editAdvanceForm(formId, entityId, event, data, key, action) {
    const { advanceEntities } = this.state;
    const dataset = advanceEntities;
    const formIndex = dataset.findIndex((it) => it.id === formId);
    if (action === "delete") {
      if (!entityId) {
        dataset.splice(formIndex, 1);
        let count = 0;
        dataset.forEach((entity) => {
          count++;
          entity.count = count;
        });
      } else {
        let deleteIndex = -1;
        let entityValue;
        data.forEach((entity) => {
          if (entity.entities.length > 0) {
            this.editAdvanceForm(
              formId,
              entityId,
              null,
              entity.entities,
              null,
              "delete"
            );
          }
          if (entityId === entity.id) {
            deleteIndex = data.findIndex((it) => it.id === entityId);
            entityValue = data[deleteIndex];
            data.splice(deleteIndex, 1);
          }
        });
      }
    } else if (action === "add") {
      data.forEach((entity) => {
        if (entity.entities.length > 0) {
          this.editAdvanceForm(
            formId,
            entityId,
            null,
            entity.entities,
            null,
            "add"
          );
        }
        if (entityId === entity.id) {
          if (entity.level > CHILD_NODE_LIMIT) {
            const confirm = AntModal.warning;
            confirm({
              title: DOC_ANNO_ALERT_TITLE,
              content: DOC_ANNO_CHILDNODEALERT,
              okText: DOC_ANNO_OK_TEXT,
              onOk() {},
            });
            return false;
          }
          entity.entities.unshift({
            id: uuidv1(),
            name: "",
            parentId: entityId,
            level: entity.level + 1,
            entities: [],
          });
        }
      });
    } else if (action === "update") {
      if (key === "label") {
        dataset[formIndex].name = event.target.value;
      } else {
        data.forEach((entity) => {
          if (entityId === entity.id) {
            if (event) {
              entity.name = event.target.value;
              entity.count = data.length;
            }
          } else if (entity.entities.length > 0) {
            this.editAdvanceForm(
              formId,
              entityId,
              event,
              entity.entities,
              key,
              "update"
            );
          }
        });
      }
    }
    if (entityId) {
      dataset[formIndex].entities = data;
    }
    return dataset;
  }


  addParent(formId) {
    const { advanceEntities } = this.state;
    const newList = advanceEntities;
    const formIndex = newList.findIndex((it) => it.id === formId);
    const parentId = uuidv1();

    newList[formIndex].entities.unshift({
      id: parentId,
      text: "",
      parentId: null,
      level: 0,
      entities: [],
      name: "",
      count: newList.length,
    });
    this.setState({ advanceEntities: newList });
  }


  updateAvanceFormInput(formId, event, entityId, key, data) {
    const updatedList = this.editAdvanceForm(
      formId,
      entityId,
      event,
      data,
      key,
      "update"
    );
    this.setState({ advanceEntities: updatedList });
  }

  updateBasicFormInput(event, id, key) {
    const updatedList = this.editBasicForm(event, id, key, "update");
    this.setState({ basicEntities: updatedList });
  }

  editBasicForm(event, id, key, type) {
    let { basicEntities } = this.state;
    let list = basicEntities;
    const editIndex = list.findIndex((it) => it.id === id);
    if (type === "add") {
      list.push({
        id: uuidv1(),
        label: "",
        entities: "",
        isEntityDuplicate: false,
        isLabelDuplicate: false,
      });
      this.setState({ isShowAdvanceLabelTop: false });
    } else if (type === "update") {
      const val = event.target.value;
      list[editIndex][key] = val;
    } else {
      if (id === "all") {
        list = [];
      } else {
        list.splice(editIndex, 1);
      }
    }
    return list;
  }

  removeBasicFormEntity(id){
    const updatedList = this.editBasicForm(null, id, null, "delete");
    this.setState({ basicEntities: updatedList });
  }

  //formId, entityId,event,list,action
  removeAdvanceFormLabel(formId, data) {
    const updatedList = this.editAdvanceForm(
      formId,
      null,
      null,
      data,
      null,
      "delete"
    );
    this.setState({ advanceEntities: updatedList });
  }

  // adds basic/ advance entity form inputs

  docOptionTypeChange(value) {
    if (value === DOCUMENT_ANNOTATION_TYPES.ADVANCE) {
      const updatedList = this.addAdvanceEntityForm();
      this.setState({
        advanceEntities: updatedList,
        docAnnotationLabelType: "ADVANCED LABEL",
      });
    } else {
      const updatedList = this.editBasicForm(null, null, null, "add");
      this.setState({
        basicEntities: updatedList,
        docAnnotationLabelType: "BASIC LABEL",
      });
    }
  }

  getWorkspaceDetails = () => {
    if (this.props.user && this.props.user.orgId) {
      getAllWorkspaceList(
        this.props.user.orgId,
        this.getWorkspaceDetailsCallBack
      );
    }
  };

  getWorkspaceDetailsCallBack = (error, response) => {
    if (!error) {
      let workspaceId = "";
      if (
        this.props.location.query.workspaceId &&
        this.props.location.query.workspaceId !== "1"
      ) {
        workspaceId = this.props.location.query.workspaceId.toString();
      }
      this.setState({
        workspaceList: response.body,
        selectedWorkspace: workspaceId,
      });
    } else {
      console.log("Error to get workspace", error);
    }
  };

  getNERTagsListCallBack = (error, response) => {
    if (!error) {
      this.setState({
        NERTags: JSON.stringify(response.body),
        isModelUpload: false,
      });
    } else {
      console.log("Error to get NER Tags", error);
    }
  };

  handleWorkspaceChange = (value) => {
    this.setState({ selectedWorkspace: value });
  };

  renderWorkspace = () => {
    const options = this.state.workspaceList.map((workspace) => (
      <option key={workspace.id}>{workspace.name}</option>
    ));
    return (
      <div className="workspace_select">
        <LocaleProvider locale={en_US}>
          <Select
            defaultValue={this.state.workspaceName}
            className="select_width"
            showSearch
            placeholder="Select a workspace"
            optionFilterProp="children"
            onChange={this.handleWorkspaceChange}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {options}
          </Select>
        </LocaleProvider>
      </div>
    );
  };

  sortByCount(field, reverse, primer) {
    var key = primer
      ? function(x) {
          return primer(x[field]);
        }
      : function(x) {
          return x[field];
        };

    reverse = !reverse ? 1 : -1;

    return function(a, b) {
      return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
    };
  }

  tagsToArray = (tags) => {
    let tagsArr = [];
    if (tags instanceof Array) {
      tags.forEach((element) => tagsArr.push(element.name.trim()));
    } else {
      if (tags != undefined) {
        let splitArr = tags.split(",");
        splitArr.forEach((arr) => {
          tagsArr.push(arr.trim());
        });
      }
    }
    return tagsArr;
  };

  removeTagSpaces = (tags) => {
    let tagsArr = this.tagsToArray(tags);
    this.state.fields.tags = tagsArr.toString();
  };

  checkIfArrayIsUnique = (tags) => {
    let tagsArr = this.tagsToArray(tags);
    return tagsArr.length === new Set(tagsArr).size;
  };

  checkUnique = (tagsArr) => {
    return tagsArr.length === new Set(tagsArr).size;
  };

  checkBascicEntityUnique = (tags) => {
    let labelList = [];
    let disable = false;
    for (var i = 0; i < tags.length; i++) {
      labelList.push(tags[i].label.trim());
      let unique = this.checkIfArrayIsUnique(tags[i].entities);
      if (!unique) {
        tags[i].isEntityDuplicate = true;
        disable = true;
      } else {
        tags[i].isEntityDuplicate = false;
      }
    }
    const labelIdList = _.uniqBy(tags, "label");
    if (labelIdList.length > 0) {
      if (tags.length > 1) {
        if (tags.length !== labelIdList.length) {
          for (var i = 0; i < tags.length; i++) {
            for (var j = 0; j < labelIdList.length; j++) {
              if (tags[i].id !== labelIdList[j].id) {
                tags[i].isLabelDuplicate = true;
              } else {
                tags[i].isLabelDuplicate = false;
                break;
              }
            }
          }
        } else {
          for (var i = 0; i < tags.length; i++) {
            tags[i].isLabelDuplicate = false;
          }
        }
      }
    }
    if (disable || (!disable && !this.checkUnique(labelList))) {
      this.displayErrorNotification(
        "error",
        "Duplicate labels or Entities not Allowed."
      );
      return true;
    }
    return false;
  };

  checkOcuurenceUnique = (entities) => {
    let nameList = [];
    for (var i = 0; i < entities.length; i++) {
      nameList.push(entities[i].name.trim());
    }
    if (nameList.length > 0 && !this.checkUnique(nameList)) {
      this.emptyCheck = true;
      this.displayErrorNotification(
        "error",
        "Duplicate labels or Entities not Allowed."
      );
      return true;
    } else {
      entities.forEach((element) => {
        this.checkOcuurenceUnique(element.entities);
      });
    }
  };

  displayErrorNotification(type, message) {
    let isNotification = document.getElementsByClassName(
      "ant-notification-notice"
    );
    if (isNotification.length === 0) {
      showAlert(message, type);
    }
  }

  checkAdvancedEntityUnique = (tags) => {
    tags.forEach((element) => {
      this.checkOcuurenceUnique(element.entities);
    });
  };

  getEmptyInput = (enitity) => {
    if (enitity.name === "") {
      this.emptyCheck = true;
      return true;
    } else if (enitity.entities.length === 1) {
      this.getEmptyInput(enitity.entities[0]);
      if (
        enitity.entities[0].parentId === null &&
        enitity.entities[0].entities.length === 0
      ) {
        this.emptyCheck = true;
        return true;
      }
    } else if (enitity.entities.length > 0) {
      enitity.entities.forEach((enitity) => {
        this.getEmptyInput(enitity);
      });
    }
  };

  toggleInput = (e) => {
    this.setState({ isLoagin: true });
  };
  // render advance label form
  renderAdvanceEntities() {}

  onPredictionFilter = (value) => {
    this.setState({ predictionValue: value });
  };

  handleSubmitModel = () => {
    //Submit model
    const {
      modelfile,
      selectedModelType,
      predictionValue,
      isRunModel,
    } = this.state;
    const { currentProject } = this.props;
    console.log("Entered in the setloader 2");
    this.props.setloaderMenu(true);
    this.setState({ loading: true });
    uploadModel(
      modelfile,
      currentProject,
      this.fileUploaded,
      this.fileUploadProgressCallback,
      selectedModelType,
      predictionValue,
      isRunModel
    );
  };

  modelfileUploaded(res, err) {
    console.log("res, err=====>", res, err);
  }

  skipToBoundPages = () => {
    this.setState({ uploadType: "", isRunModel: false, modelEntity: false });
  };

  handleModelTypeChange = (value) => {
    this.setState({ selectedModelType: value });
  };

  selectClassicTypes = (e) => {
    this.setState({
      selectClassicType: e.target.value,
    });
  };

  getClassificationTypes = () => {
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px",
    };
    return (
      <Radio.Group
        onChange={this.selectClassicTypes}
        value={this.state.selectClassicType}
      >
        <Radio style={radioStyle} value="news">
          Categories by news
        </Radio>
        <Radio style={radioStyle} value="sentiment">
          Categories by sentiment
        </Radio>
      </Radio.Group>
    );
  };


  updateValue = (tags) => {
    let renderNERTags = [];
    tags.map((tag, index) => {
      let data = {};
      data.key = index.toString();
      data.desc = tag.desc;
      data.slabel = tag.slabel;
      renderNERTags.push(data);
    });
    this.setState({
      NERTags: JSON.stringify(renderNERTags),
      nermodelVisible: false,
    });
    showAlert("Changes saved successfully.", "success");
  };

  render() {
    const { projectDetails } = this.state;
    const { location, projectCreated, showUserDefinedColor } = this.props;
    const {
      basicEntities,
      advanceEntities,
      loading,
      advancedTags,
      projectCreationComplete,
      fileUploadStats,
      isShowAdvanceLabelTop,
      docCategoryType,
      uploadType,
    } = this.state;
    const {
      getSubmitDisabled,
      getIgnoreClass,
      getPlaceHolder,
      getNamePlaceHolder,
      tagPlaceHolder,
      getButtonText,
      validateFirstLastIndex,
      checkColoredTagEmpty,
    } = helper;
    const { type, wName, workspaceId, orgId } = location.query;
    let { coloredTags, imageAttributes } = this.state.fields;
    let submitDisabled = getSubmitDisabled(type, this.state);
    if (showUserDefinedColor) {
      if (!coloredTags) {
        coloredTags = [{ color: helper.getDefaultColor, label: "" }];
      }
      submitDisabled = checkColoredTagEmpty(submitDisabled, coloredTags);
    }
    if (
      this.props.location.query.type === IMAGE_SEGMENTATION ||
      this.props.location.query.type === IMAGE_MASKING ||
      this.props.location.query.type === VIDEO_BOUNDING_BOX
    ) {
      console.log(this.state.imageAttributes.length);
      if (this.state.imageAttributes.length === 0) {
        const imageAttributes = this.state.imageAttributes;
        imageAttributes.push({ name: "", displayName: "", classes: [] });
        this.setState({ imageAttributes });
      }
      if (this.state.dropDownAttributes.length === 0) {
        const dropDownAttributes = this.state.dropDownAttributes;
        dropDownAttributes.push({name: '', displayName: '', classes: [] });
        this.setState({ dropDownAttributes })
       }
    }
    const ignoreClass = getIgnoreClass(this.state);
    const placeholder = getPlaceHolder(type);
    const namePlaceHolder = getNamePlaceHolder(type);
    let backUrl = "/projects/create";
    if (wName) {
      backUrl =
        "/workspace/" +
        wName +
        "/" +
        workspaceId +
        "/" +
        orgId +
        "/All?currentwPage=" +
        this.props.location.query.currentwPage +
        "&currentpPage=" +
        this.props.location.query.currentpPage;
    } else {
      if (performance.navigation.type != 1) {
        saveactiveTab("create");
      }
    }

    if (
      (type === POS_TAGGING ||
        type === POS_TAGGING_GENERIC ||
        type === TEXT_CLASSIFICATION ||
        type === IMAGE_CLASSIFICATION ||
        type === IMAGE_BOUNDING_BOX ||
        type === IMAGE_POLYGON_BOUNDING_BOX ||
        type === VIDEO_CLASSIFICATION ||
        type === VIDEO_BOUNDING_BOX ||
        type === IMAGE_SEGMENTATION ||
        type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
        (type === IMAGE_MASKING && !showUserDefinedColor) ||
        type === DICOM_CLASSIFICATION ||
        type === DICOM_SEGMENTATION ||
        type === TEXT_SUMMARIZATION ||
        type === TEXT_MODERATION) &&
      this.state.fields.tags &&
      !this.checkIfArrayIsUnique(this.state.fields.tags)
    ) {
      submitDisabled = true;
      this.displayErrorNotification(
        "error",
        "Duplicate labels or Entities not Allowed."
      );
    }

    if (
      type === IMAGE_MASKING &&
      showUserDefinedColor &&
      !helper.checkIfColoredArrayIsUnique(this.state.fields.coloredTags)
    ) {
      submitDisabled = true;
      this.displayErrorNotification(
        "error",
        "Duplicate labels or entities are not Allowed."
      );
    }

    if (
      type === IMAGE_MASKING &&
      showUserDefinedColor &&
      helper.checkForCommaSeparator(this.state.fields.coloredTags)
    ) {
      submitDisabled = true;
      this.displayErrorNotification("error", "Comma separators not Allowed.");
    }

    // Validation for empty label in document annotation
    if (type === DOCUMENT_ANNOTATION && !submitDisabled) {
      this.emptyCheck = false;
      if (
        this.state.advanceEntities.length === 0 &&
        basicEntities.length === 0
      ) {
        this.emptyCheck = true;
      } else {
        let validateBasic = helper.validateBasicEntity(basicEntities);
        this.state.advanceEntities.forEach((enitity) => {
          if (enitity.entities && enitity.entities.length > 0) {
            this.getEmptyInput(enitity);
          } else {
            this.emptyCheck = true;
          }
        });

        if (!this.emptyCheck)
          this.checkAdvancedEntityUnique(this.state.advanceEntities);

        if (validateBasic) {
          this.emptyCheck = true;

          const labelIdList = _.uniqBy(basicEntities, "label");
          if (labelIdList.length > 0) {
            if (basicEntities.length > 1) {
              if (basicEntities.length !== labelIdList.length) {
                for (var i = 0; i < basicEntities.length; i++) {
                  for (var j = 0; j < labelIdList.length; j++) {
                    if (basicEntities[i].id !== labelIdList[j].id) {
                      basicEntities[i].isLabelDuplicate = true;
                    } else {
                      basicEntities[i].isLabelDuplicate = false;
                      break;
                    }
                  }
                }
              } else {
                for (var i = 0; i < basicEntities.length; i++) {
                  basicEntities[i].isLabelDuplicate = false;
                }
              }
            }
          }
          for (var i = 0; i < basicEntities.length; i++) {
            let isCommaIndex = validateFirstLastIndex(basicEntities[i]);
            let unique = this.checkIfArrayIsUnique(basicEntities[i].entities);
            if (isCommaIndex || !unique) {
              basicEntities[i].isEntityDuplicate = true;
            } else {
              basicEntities[i].isEntityDuplicate = false;
            }
          }
        } else if (
          !this.emptyCheck &&
          basicEntities.length > 0 &&
          !validateBasic
        ) {
          this.emptyCheck = this.checkBascicEntityUnique(basicEntities);
        }
      }
      submitDisabled = this.emptyCheck;
    }

    const advPanels = [
      {
        key: "details",
        title: "Advanced Details",
        content: {
          as: Form.Checkbox,
          onClick: this.advancedOptionChange.bind(this, "isURLs"),
          value: this.state.isURLs,
          label: "URLs ?",
        },
      },
    ];

    const uploadprops = {
      name: "file",
      //   action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      headers: {
        authorization: "authorization-text",
      },
      showUploadList: false,
    };

    const advTagsPanels = [
      {
        key: "details",
        title: "Advanced",
        content: {
          as: Form.Checkbox,
          onClick: this.advancedOptionChange.bind(this, "advancedTags"),
          value: advancedTags,
          label: "Advanced Options",
        },
      },
    ];

    let modelOption = helper.modelOptions();
    let isModelRunned =
      this.state.isModelUpload &&
      this.state.isRunModel &&
      type === IMAGE_CLASSIFICATION;
    const modeloptions = modelOption.map((model) => (
      <option key={model.key}>{model.value}</option>
    ));
    const disablesubmitModel =
      (type === POS_TAGGING_GENERIC && this.state.modelfile === undefined) ||
      (type === IMAGE_CLASSIFICATION &&
        (this.state.modelfile === undefined ||
          this.state.selectedModelType === undefined))
        ? true
        : false;


        const ViewBox = ({ value, label, ignoreClass }) => (
          <div className={`viewbox ${ignoreClass ? 'ignoreClass' : ''}`}>
            <h3>{value}</h3>
            <h4>{label}</h4>
          </div>
        );

        const resetClassification=()=> {
          let fieldsData = this.state.fields;
          fieldsData.classification_name = "";
          fieldsData.classification_disp_name = "";
          fieldsData.classification_classes = "";
          this.setState({ fieldsData });
        }



        const handleChange=(field, element)=> {
          console.log(" handle change ", field, element.target.value);
          console.log("State fields ",this.state)
          if (element.target.value.length > 0)
            this.setState({
              disableSubmitButton: false,
            });
          const fields = this.state.fields;
          fields[field] = element.target.value;
          const errs = this.state.errors;
          errs[field] = false;
          this.setState({
            fields,
            errors: errs,
          });
        }

  // #update
  const compressionHandle=(element)=> {
    element.checked
      ? this.setState({ compress: true })
      : this.setState({ compress: false });
  }

  const handleObjectAttributeChange=(element)=> {
    this.setState({ objectAttribute: element.target.value });
  }

  const addAttribute = () => {
    const imageAttributes = this.state.imageAttributes;
    imageAttributes.push({ name: "", displayName: "", classes: [] });
    this.setState({ imageAttributes });
  };

    const addAttributeList = () => {
      const dropDownAttributes = this.state.dropDownAttributes;
      dropDownAttributes.push({ name: '', displayName: '', classes: [] });
      this.setState({ dropDownAttributes })
    }

  const removeAttribute = (index) => {
    const imageAttributes = this.state.imageAttributes;
    console.log(imageAttributes);
    imageAttributes.splice(index, 1);
    console.log(imageAttributes);
    this.setState({ imageAttributes });
  };

    const removeAttributeList = (index) => {
      const dropDownAttributes = this.state.dropDownAttributes;
      console.log(dropDownAttributes)
      dropDownAttributes.splice(index, 1);
      console.log(dropDownAttributes)
      this.setState({ dropDownAttributes });
    }

  const handleAttributeChange =(field, element, index)=> {
    console.log("handleAttributeChange",field,element,index);
    if (element.target.value.length > 0)
      this.setState({
        disableSubmitButton: false,
      });
    const imageAttributes = this.state.imageAttributes;
    const imageAttribute = imageAttributes[index];
    imageAttribute[field] = element.target.value;
    imageAttributes[index] = imageAttribute;
    this.setState({
      imageAttributes,
    });
  }

    const handleEditAttributeChange = (field, element, index) => {
      if (element.target.value.length > 0)
        this.setState({ disableSubmitButton: false });
      const dropDownAttributes = this.state.dropDownAttributes;
      const dropDownAttribute = dropDownAttributes[index];
      dropDownAttribute[field] = element.target.value
      dropDownAttributes[index] = dropDownAttribute;
      this.setState({ dropDownAttributes });
    }

  const addColorTag = () => {
    const fields = this.state.fields;
    fields.coloredTags.push({ color: helper.getDefaultColor, label: "" });
    this.setState({ fields });
  };

  const removeColorTag = (index) => {
    const fields = this.state.fields;
    fields.coloredTags.splice(index, 1);
    this.setState({ fields });
  };

  const handleColorChange = (color, index) => {
    const fields = this.state.fields;
    let coloredTag = fields.coloredTags[index];
    coloredTag.color = color.hex;
    this.setState({ fields });
  };

  const handleColorLabelChange=(field, element, index)=> {
    if (element.target.value.length > 0)
      this.setState({
        disableSubmitButton: false,
      });
    const fields = this.state.fields;
    let coloredTag = fields.coloredTags[index];
    coloredTag.label = element.target.value;

    const errs = this.state.errors;
    errs[field] = false;
    this.setState({
      fields,
      errors: errs,
    });
  }


  const dynamicLabelOptionChange = () => {
    console.log(
      "buttons",
      "Allow Dynamic Labelling",
      !this.state.allowDynamiclabelling
    );
    if (this.state.allowDynamiclabelling) {
      this.setState({ allowDynamiclabelling: false });
    } else {
      this.setState({ allowDynamiclabelling: true });
    }
  };

  const NEREntityOptionChange = (val, e) => {
    if (val === "modelEntity") {
      this.setState({ modelEntity: e.checked });
      getNERTagsList(this.props.user.orgId, this.getNERTagsListCallBack);
    } else if (val === "isModelUpload") {
      this.setState({ isModelUpload: e.checked });
    }
  };


  const advancedOptionChangeData = (val, e) => {
    console.log("advancedOptionChange",val,e)
    const { advanceOptions } = helper;
    if (val === advanceOptions.AUTOCLOSE) {
      this.setState({ autoClose: e.checked });
    } else if (val === advanceOptions.HIDELABELS) {
      this.setState({ hideLabels: e.checked });
    } else if (val === advanceOptions.NOTES) {
      this.setState({ notes: e.checked });
    } else if (val === advanceOptions.DEFAULTSHAPE) {
      if (e.checked) {
        this.setState({ defaultShape: e.value });
      }
    } else if (val === advanceOptions.SELECTEDFORMAT) {
      if (e.checked) {
        this.setState({ selectedFormat: e.value });
      }
    } else if (val === advanceOptions.ISURLS) {
      this.setState({ isURLs: e.checked });
    } else if (val === advanceOptions.ADVANCETAGS) {
      this.setState({ advancedTags: e.checked });
    } else if (val === advanceOptions.UPLOADMODEL) {
      this.setState({ isModelUpload: e.checked });
    } else if (val === advanceOptions.RUNMODEL) {
      this.setState({ isRunModel: e.checked });
    }
  };


  const addAdvanceFormEntity=(formId, entityId, data)=> {
    const updatedList = this.editAdvanceForm(
      formId,
      entityId,
      null,
      data,
      null,
      "add"
    ); // formId, entityId,event,list,action
    this.setState({ advanceEntities: updatedList });
  }
  const removeAdvanceFormEntity=(formId, entityId, data)=> {
    let updatedList = this.editAdvanceForm(
      formId,
      entityId,
      null,
      data,
      null,
      "delete"
    );
    this.setState({ advanceEntities: updatedList });
  }


  const selectFilewithPreannoInputdata = ()=>{
    this.setState({
      uploadType: undefined,
      file: undefined,
      fileCompare: undefined,
      fileName: "",
      fileNameCompare: "",
      errors: {},
    });
  }

    return (
      <div style={{ margin: "0 6%" }}>
        <Helmet title="Create Dataset" />
        {
          <div>
        {/* refactored */}
        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        {projectCreationComplete && (
          <div className="csuceess_holder m-t-30">
            <h2 className="m-t-15">
              {isModelRunned ||
              (this.state.uploadType === "uploadModel" &&
                (type === POS_TAGGING || type === TEXT_CLASSIFICATION)) ||
              (this.state.modelEntity && type === POS_TAGGING_GENERIC)
                ? "Project creation is successful but AI-assisted prediction is in-progress for the project. Please wait for at least 5 minutes before starting to work with the project."
                : "Project created successfully"}
            </h2>
            <div className="success_view">
              <ViewBox value={fileUploadStats.numHitsCreated} label="Number of HITs Created" />
              <ViewBox value={fileUploadStats.numHitsIgnored} label="Number of HITs Ignored" ignoreClass />
              <ViewBox value={bytes(fileUploadStats.totalUploadSizeInBytes)} label="File Size" />
            </div>
            <div style={{ textAlign: "center" }}>
              <Button className="successSubmit m-b-15" onClick={() => this.startTagging(type)}>
                {isModelRunned ? "Continue" : getButtonText(type)}
              </Button>
            </div>
          </div>
        )}
        </div>

            {!projectCreationComplete && !projectCreated && (
              <div className="pr_labelset w-100">
                <div className="project_sec m-t-20">
                  <div className="project_title f-column singletxt">
                    {projectDetails && (
                      <div className="f-center">
                        <div
                          className="m-r-5"
                          onClick={() => {
                            let backUrl = "/workspace";
                            let params = queryString.parse(
                              this.props.location.search
                            );
                            let currentPage = localStorage.getItem(
                              "currentProjectPage"
                            );
                            let currentpPage =
                              params.length > 0 &&
                              this.props.location.query.currentpPage !== ""
                                ? this.props.location.query.currentpPage
                                : currentPage == undefined
                                ? 1
                                : currentPage;
                            let currentwPage =
                              params.length > 0 &&
                              this.props.location.query.currentwPage !== ""
                                ? this.props.location.query.currentwPage
                                : 1;
                            if (
                              getactiveName() !== "shared" &&
                              parseInt(getUidToken().roleId) === 2
                            ) {
                              backUrl =
                                "/workspace/" +
                                projectDetails.workspaceName +
                                "/" +
                                projectDetails.workspaceId +
                                "/" +
                                projectDetails.orgId +
                                "/" +
                                (getactiveName() === "home"
                                  ? "All"
                                  : "Archived") +
                                "?currentpPage=" +
                                currentpPage +
                                "&currentwPage=" +
                                currentwPage;
                            } else {
                              backUrl =
                                "/projects/" +
                                3 +
                                "/list?currentpPage=" +
                                currentpPage;
                            }
                            this.props.pushState(backUrl);
                          }}
                        >
                          <span className="t_btn_back">
                            <img alt="back" src={BACK} />
                          </span>
                        </div>
                      </div>
                    )}
                    {!projectDetails && (
                      <div className="f-center">
                        <div onClick={() => this.props.pushState(backUrl)}>
                          <span className="btn_back m-r-5">
                            <img alt="back" src={BACK} />
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="breadcrum-sec">
                      <div className="sec_holder">
                        <div className="tilte_display fw-400">
                          {getTaskTypeByShape(type, this.state.defaultShape)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="project_set">
                    {type === DOCUMENT_ANNOTATION && (
                      <EntityOptions
                        value={docCategoryType}
                        onInputChange={this.docOptionTypeChange}
                      />
                    )}
                    {type === IMAGE_MASKING && (
                      <div>
                        <ToggleSwitch
                          createUserDefinedColor={
                            this.state.createUserDefinedColor
                          }
                          isUserDefinedColorSwitchChange={this.isUserDefinedColorSwitchChange.bind(
                            this
                          )}
                        />
                        <span className="m-l-10">
                          Create User Defined Color{" "}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
             <TaggerImportFormView
             showUserDefinedColor={showUserDefinedColor}
             type={type}
             advancedTags={advancedTags}
             advTagsPanels={advTagsPanels}
             tagPlaceHolder={tagPlaceHolder}
             namePlaceHolder={namePlaceHolder}
             coloredTags={coloredTags}
             loading={loading}
             isShowAdvanceLabelTop={isShowAdvanceLabelTop}
             basicEntities={basicEntities}
             advanceEntities={advanceEntities}
             submitDisabled={submitDisabled}
             placeholder={placeholder}
             propsState={this.state}
             handleChange={handleChange}
             renderWorkspace={this.renderWorkspace}
             compressionHandle={compressionHandle}
             handleObjectAttributeChange={handleObjectAttributeChange}
             addAttribute={addAttribute}
             addAttributeList={addAttributeList}
             removeAttribute={removeAttribute}
             removeAttributeList={removeAttributeList}
             handleAttributeChange={handleAttributeChange}
             handleEditAttributeChange={handleEditAttributeChange}
             addColorTag={addColorTag}
             removeColorTag={removeColorTag}
             handleColorChange={handleColorChange}
             handleColorLabelChange={handleColorLabelChange}
             dynamicLabelOptionChange={dynamicLabelOptionChange}
             advancedOptionChangeData={advancedOptionChangeData}
             NEREntityOptionChange={NEREntityOptionChange}
             handleSubmit={this.handleSubmit}
             resetClassification={resetClassification}
             getTagsInput={this.getTagsInput}
             updateBasicFormInput={this.updateBasicFormInput}
             removeBasicFormEntity={this.removeBasicFormEntity}
             updateAvanceFormInput={this.updateAvanceFormInput}
             removeAdvanceFormLabel={this.removeAdvanceFormLabel}
             addAdvanceFormEntity={addAdvanceFormEntity}
             removeAdvanceFormEntity={removeAdvanceFormEntity}
             addParent={this.addParent}
             getClassificationTypes={this.getClassificationTypes}
             />
              </div>
            )}
            
            {projectCreated && !uploadType && !projectCreationComplete && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginTop: "30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                >
                  <div onClick={() => this.props.pushState(backUrl)}>
                    <span className="t_btn_back m-r-5">
                      <img alt="back" src={BACK} />
                    </span>
                  </div>
                  <h2
                    className="tilte_display fw-400"
                    style={{ marginTop: "0" }}
                  >
                    {" "}
                    Upload
                  </h2>
                </div>
                <div className="pr_uploadsec m-t-30">
                  <h2 className="m-b-20 m-t-5">Select Upload Type</h2>
                  <div className="upload_holder">
                    <div className="raw_data">
                      <div>
                        <h4 className="sec_tilte">Raw Data</h4>
                      </div>
                      <div className="txt-center">
                        <AntButton
                          className="pr_upload m-t-10"
                          icon="upload"
                          onClick={() => {
                            this.setState({ uploadType: "Raw" });
                          }}
                        >
                          {" "}
                          Upload{" "}
                        </AntButton>
                      </div>
                    </div>
                    <div className="upload_pre">
                      <div>
                        <h4 className="sec_tilte">Pre-Annotated Data</h4>
                        <p>
                          If you have some data which is already pre-annotated
                          and want to go through annotations and correct them.
                        </p>
                      </div>
                      <div className="txt-center">
                        <AntButton
                          className="pr_upload"
                          icon="upload"
                          onClick={() => {
                            this.setState({ uploadType: "Pre-Annotated" });
                          }}
                        >
                          {" "}
                          Upload{" "}
                        </AntButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* Upload Data Files */}
            <TaggerImportFileUpload
            propsState={this.state}
            propsData={this.props}
            type={type}
            projectCreationComplete={projectCreationComplete}
            projectCreated={projectCreated}
            uploadType={uploadType}
            uploadprops={uploadprops}
            advPanels={advPanels}
            loading={loading}
            backUrl={backUrl}
            handleSubmit={this.handleSubmit}
            handleAntUploadFile={this.handleAntUploadFile}
            handleAntUploadFileCompare={this.handleAntUploadFileCompare}
            advancedOptionChange={this.advancedOptionChange}
            selectFilewithPreannoInputdata={selectFilewithPreannoInputdata}
            />

            {/* Upload Model for Image Classification part */}

            {projectCreated &&
              this.state.projectCreationComplete &&
              this.state.uploadType === "uploadModel" &&
                type === IMAGE_CLASSIFICATION && (
                <div className="pr_selectfile m-t-30">
                  <div className="f-center">
                    <div
                      className="m-r-5"
                      onClick={() => this.props.pushState(backUrl)}
                    >
                      <span className="t_btn_back">
                        <img alt="back" src={BACK} />
                      </span>
                    </div>
                    <h3 className="sec_tilte" style={{ marginTop: "0" }}>
                      Import Model
                    </h3>
                  </div>
                  <div className="select_holder m-b-15">
                    <div className="b_l">
                      <div className="w-100">
                        <p>Upload a Keras model file with extension .h5 </p>
                        <strong> OR </strong>
                        <p>
                          A zip file with the model. Max file size is 200 MB{" "}
                        </p>

                        <div>
                          <b>Model Prediction Accuracy Filter</b>
                          <Slider
                            step={0.1}
                            min={0}
                            max={1}
                            value={this.state.predictionValue}
                            onChange={this.onPredictionFilter}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="b_r">
                      <form
                        encType="multipart/form-data"
                        action=""
                        key="importFile"
                        className="text-center"
                      >
                        <AntUpload
                          {...uploadprops}
                          disabled={loading}
                          onChange={this.handleAntUploadFileModel.bind(
                            this,
                            "model"
                          )}
                          className="upload_filebtn"
                        >
                          <AntButton>
                            <AntIcon type="upload" /> Select Model
                          </AntButton>
                        </AntUpload>
                        <div className="upload_info">
                          <h4>{this.state.modelfileName}</h4>
                          {this.state.modelfile && (
                            <p>
                              {" "}
                              File Size: {bytes(this.state.modelfile.size)}{" "}
                            </p>
                          )}
                          <p
                            className="error"
                            disabled={!this.state.errors.file}
                          >
                            {this.state.errors.file}
                          </p>
                        </div>
                      </form>
                      <div
                        className="m-t-20 w-100"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div className="w-50 m-r-5">
                          <b>Model Upload Type</b>
                          <LocaleProvider locale={en_US}>
                            <Select
                              className="select_width"
                              placeholder="Select a model type"
                              onChange={this.handleModelTypeChange}
                              value={this.state.selectedModelType}
                            >
                              {modeloptions}
                            </Select>
                          </LocaleProvider>
                        </div>
                        <div className="w-50 m-l-5 m-t-20">
                          <Form>
                            <Form.Checkbox
                              label="Run Model"
                              name="runmodel"
                              checked={this.state.isRunModel}
                              value="Run Model"
                              onChange={this.advancedOptionChange.bind(
                                this,
                                "runModel"
                              )}
                            />
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="txt-center msg_txt">
                    <p className="error" disabled={!this.state.errors.submit}>
                      {this.state.errors.submit}
                    </p>
                    <Button
                      className="base_btnsubmit m-b-15"
                      type="submit"
                      disabled={disablesubmitModel ? true : false}
                      onClick={this.handleSubmitModel}
                    >
                      Submit 6
                    </Button>
                    <Button
                      className="btn_base btn_danger m-l-5 base_btnsubmit ant-btn-danger"
                      type="danger"
                      onClick={this.skipToBoundPages}
                    >
                      Skip
                    </Button>
                  </div>
                </div>
              )}

            {projectCreated &&
              this.state.projectCreationComplete &&
              this.state.uploadType === "uploadModel" &&
                type === POS_TAGGING_GENERIC && (
                <div className="pr_selectfile m-t-30">
                  <div className="f-center">
                    <div
                      className="m-r-5"
                      onClick={() => this.props.pushState(backUrl)}
                    >
                      <span className="t_btn_back">
                        <img alt="back" src={BACK} />
                      </span>
                    </div>
                    <h3 className="sec_tilte" style={{ marginTop: "0" }}>
                      Import Model
                    </h3>
                  </div>
                  <div className="select_holder m-b-15">
                    <div className="b_l">
                      <div className="w-100">
                        <p>
                          Upload a compressed Spacy model file with extension
                          .zip
                        </p>
                      </div>
                    </div>

                    <div className="b_r">
                      <form
                        encType="multipart/form-data"
                        action=""
                        key="importFile"
                        className="text-center"
                      >
                        <AntUpload
                          {...uploadprops}
                          accept=".zip"
                          disabled={loading}
                          onChange={this.handleAntUploadFileModel.bind(
                            this,
                            "model"
                          )}
                          className="upload_filebtn"
                        >
                          <AntButton>
                            <AntIcon type="upload" /> Select Model
                          </AntButton>
                        </AntUpload>
                        <div className="upload_info">
                          <h4>{this.state.modelfileName}</h4>
                          {this.state.modelfile && (
                            <p>
                              {" "}
                              File Size: {bytes(this.state.modelfile.size)}{" "}
                            </p>
                          )}
                          <p
                            className="error"
                            disabled={!this.state.errors.file}
                          >
                            {this.state.errors.file}
                          </p>
                        </div>
                      </form>
                      <div
                        className="m-t-20 w-100"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div className="w-50 m-l-5 m-t-20">
                          <Form>
                            <Form.Checkbox
                              label="Run Model"
                              name="runmodel"
                              checked={this.state.isRunModel}
                              value="Run Model"
                              onChange={this.advancedOptionChange.bind(
                                this,
                                "runModel"
                              )}
                            />
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="txt-center msg_txt">
                    <p className="error" disabled={!this.state.errors.submit}>
                      {this.state.errors.submit}
                    </p>
                    <Button
                      className="base_btnsubmit m-b-15"
                      type="submit"
                      disabled={disablesubmitModel ? true : false}
                      onClick={this.handleSubmitModel}
                    >
                      Submit 7
                    </Button>
                    <Button
                      className="btn_base btn_danger m-l-5 base_btnsubmit ant-btn-danger"
                      type="danger"
                      onClick={this.skipToBoundPages}
                    >
                      Skip
                    </Button>
                  </div>
                </div>
              )}
          </div>
        }
        <div>
          {this.state.nermodelVisible && 
          <NERTagsModal
          nermodelVisible = {this.state.nermodelVisible}
          editNERTag = {this.state.editNERTag}
          closeModel = {()=>this.setState({nermodelVisible: false})}
          NERTags = {this.state.NERTags}
          updateValue = {this.updateValue}
          />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("mapStateToProps Import", state);
  return {
    user: state.loginReducer.user,
    orgName: state.projectReducer.orgName,
    currentProject: state.projectReducer.currentProject,
    projectDetails: state.projectReducer.projectDetails,
    projectCreateError: state.projectReducer.projectCreateError,
    projectCreated: state.projectReducer.projectCreated,
    showUserDefinedColor: state.projectReducer.showUserDefinedColor,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      uploadDataForm,
      updateFileUploadStats,
      getUserHomeData,
      getUserHomeDataWithFetchOption,
      selectProject,
      pushState: push,
      updateModalView,
      updateHomeData,
      updatePrevPageDeatails,
      updateUserDefinedColorStats,
      setloaderMenu,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TaggerImport);
