import React, { Component } from 'react';
import Helmet from 'react-helmet';
import moment from 'moment';
import queryString from 'query-string';
import Modal from 'react-awesome-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { Table, Icon, Button, Input, Modal as AntModal } from 'antd';
import { getactiveName } from '../../Services/WorkspaceApis.js';
import { trimSpaces } from '../../helpers/Utils';
import { showAlert } from '../../helpers/commonFunctions.js';
import { logout } from '../../redux/reducer/loginReducer.js';
import Search from './Search.js';
import 'antd/dist/antd.css';
import '../../App.css';
class WorkSpaceListComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showCreateWorkSpace : false,
            workspaceName : '',
            showWorkSpaceError : false,
            isEmptyWorkSpaceName :  false,
            editFlag : false,
            workspaceLabel : "Create New Workspace",
            workespace : null,
            selectedRows : [],
            showWorkspaceCount : false,
            selectedRowKeys : [],
            inputErrorMessage:'',
            loading: false,
            toggleSearch: false,
            isFilter: 0
        }
    }
    componentWillReceiveProps(nextProps) {
      const { toggleSearch } = this.state
      if (toggleSearch) this.setState({toggleSearch: false})
      if(nextProps.existingWorkspace === true){
        this.setState({existWorkspace : nextProps.message, existWorkspaceFlag: true, showCreateWorkSpace: true})
      }
      if(nextProps.existingWorkspace ===  false){
        this.setState({existWorkspaceFlag : false})
      }
      if(nextProps.removeCheckBox ===  true){
        this.setState({ selectedRows : [] , selectedRowKeys : []})
      }
      if(this.props.currentwPage !== undefined){
        this.setState({currentwPage: this.props.currentwPage})
      }
    }
    componentDidMount(){
      if(this.props.location !== undefined){
        let params = queryString.parse(this.props.location.search)
        let current_page = (params.location !== undefined && params.location.search !== undefined && params.location.search !== '') ? parseInt(params.currenpPage) : 1
        this.setState({currentpPage: current_page})
      } else {
        this.setState({currentpPage: 1})
      }
    }
    showCreateWorkSpace = () => {
      const { selectedRows } = this.state;
      // To Create New Workspace
      if(getactiveName() === "home") {
        this.setState ({showCreateWorkSpace : true,
                        workspaceName : '',
                        workspaceLabel : "Create New Workspace"})
      }

      // To Recover the archived workspaces
      if(getactiveName() === "archived") {
       console.log('archived')
        if(selectedRows.length === 0){
          showAlert("Please select at least one workspace.", 'warning')
        }
        else {
          const that = this
          const confirm = AntModal.confirm;
          confirm({
              title: 'Confirmation',
              content: 'Are you sure you want to recover the selected workspace(s)?',
              okText: 'OK',
              cancelText: 'CANCEL',
              onOk() {that.RecoverSelectedWorkspace();},
                onCancel() {that.cancelarchiveworkspace();},
            });
        }
      }
    }

    showCreateProject = ()=>{
      console.log('showCreateProject')
      this.props.pushState({
        pathname:
            "projects/create",
    });
    }

    showArchiveProject = ()=>{
      console.log('showArchiveProject')
      this.props.pushState({
        pathname: '/archived',
        query: {
          currentpPage: 1
        }
      });
    }

    handleLogout = (event) => {
      event.preventDefault();
      window.sessionStorage.removeItem('lastTimeAccessAPI');
      this.props.logout();
    };

    showSharedProject = ()=>{
      let roleId = 3;
      console.log('showSharedProject')
      this.props.pushState({
        pathname: '/projects/' + roleId + '/list',
        query: {
          currentpPage: 1
        }
      });
    }

    showUsers =()=>{
      console.log("show users")
      this.props.pushState({pathname:"projects/users"})
    }

    RecoverSelectedWorkspace = () => {
      const { selectedRows } = this.state;
      console.log('recover selected workpsace====>',selectedRows)
      let recoverListStr = selectedRows.map((workspace)=>{
        return workspace.key
      })
      this.props.RecoverSelectedWorkspace(recoverListStr)
      this.setState({selectedRows : [], selectedRowKeys : []})
    }

    cancelCreateWorkSpace = () => {
        this.setState ({showCreateWorkSpace : false,
        workspaceLabel : "Create New Workspace",
        isEmptyWorkSpaceName: false, showWorkSpaceError: false,
        editFlag : false,
        existWorkspaceFlag : false,
        inputErrorMessage:''})
        this.props.cancelShowWorkspace();
    }

    handleKeyPress = (e) => {
      const { showCreateWorkSpace } = this.state;
      if (e.key === 'Enter' && showCreateWorkSpace ) {
        this.saveCreateWorkSpace(e);
      }
    }

    saveCreateWorkSpace = () =>{
      const { workspaceName, editFlag, workspace } = this.state;
        // Validating the Workspace Name
        if (workspaceName.trim() === "" || workspaceName === undefined) {
            this.setState({inputErrorMessage:'Workspace name cannot be empty.', isEmptyWorkSpaceName: false, existWorkspaceFlag : false,showWorkSpaceError: false  })
         }
        else if(workspaceName.trim().length>100){
            this.setState({inputErrorMessage:'Workspace name must be less than 100 characters.', isEmptyWorkSpaceName: false, existWorkspaceFlag : false,showWorkSpaceError: false  })
          }
        else
        {
          this.setState({ inputErrorMessage: '', isEmptyWorkSpaceName: false, showWorkSpaceError: false, showCreateWorkSpace: false })
          if (editFlag) { // Edit WorkspaceName
                let editWorkSpace = {}
                editWorkSpace.name = trimSpaces( workspaceName)
                editWorkSpace.id = workspace.id
                editWorkSpace.orgId = workspace.orgId
                this.props.updateWorkspace(editWorkSpace);
                this.setState({editFlag: false})
              } else { // Save New Workspace
                this.props.createNewWorkspace(trimSpaces(workspaceName));
              }
              return false;
            }
          
    }

    setworkspaceName = (e) => {
      console.log("Workspace name",e.target.value)
        this.setState({workspaceName: e.target.value})
    }

    columns = () => {
      const { currentpPage, isFilter, currentwPage } = this.state;
      return ([{
      title: 'NAME',
      dataIndex: 'workspace',
      className: 'w_name',
      sorter: true,
      render: workspace =>
            <div className="space_wrap">
              {getactiveName() === 'home' &&
                <div onClick={()=> this.props.goToProjects(workspace.workspace.id, workspace.workspace.name, "All", currentpPage, 'currentwPage='+currentwPage+'&currentpPage='+currentpPage+'&isFilter='+isFilter)}>
                  {workspace.workspace.name}
                </div>}
                {getactiveName() === 'archived' && 
              <div onClick={()=> {if(workspace.projectsCount > 0)
                this.props.goToProjects(workspace.workspace.id, workspace.workspace.name, "Archived", currentpPage, 'currentwPage='+currentwPage+'&currentpPage='+currentpPage+'&isFilter='+isFilter)}}>
                {workspace.workspace.name}
              </div>}
            </div>
      },
      {
        title: 'PROJECTS',
        dataIndex: 'project',
        sorter: true,
        className: 'number_format'
      }, {
        title: 'DATE',
        sorter: true,
        dataIndex: 'date',
      }, {
        title: 'ACTION',
        dataIndex: 'copyanddelete',
        render: copyicon =>

                <div className="action_start">{(copyicon.workspace.id !== 1 && copyicon.workspace.id !== 2) &&
                    <div className="table_actionbtn">
                        { getactiveName() === "archived" &&
                        <div style={{display: 'flex'}}>
                          {(copyicon.projectsCount > 0 || copyicon.workspace.id !== 3) &&
                            <Icon type="rollback" title="Recover" onClick={() => this.recoverSingleWorkSpace(copyicon)}/>
                          }
                          {(copyicon.workspace.id !== 3) &&
                          <Icon type="delete" title="Delete" onClick={() => this.deleteSingleRow(copyicon, "delete")} />
                          }
                        </div>
                        }
                        { getactiveName() === "home" &&
                          <div style={{display: 'flex'}}>
                            <Icon type="edit" title="Edit" onClick={() => this.editWorkSpace(copyicon)}/>
                            <Icon type="inbox" title="Archive" onClick={() => this.deleteSingleRow(copyicon, "archive")} />
                          </div>
                        }
                    </div>}
                </div>
      }
    ])
    }

    recoverSingleWorkSpace = (workspace) =>{
      console.log('single=====>', workspace)
      const that = this
      const confirm = AntModal.confirm;
      confirm({
          title: 'Confirmation',
          content: 'Are you sure you want to recover the workspace \'' + workspace.workspace.name + '\'?',
          okText: 'OK',
          cancelText: 'CANCEL',
          onOk() {that.recoverSingleWs(workspace);},
            onCancel() {that.cancelaarchiveSingleWorkSpace();},
        });
    }

    recoverSingleWs = (workspace) =>{
      let workespacerecover = [workspace.workspace.id]
      this.props.RecoverSelectedWorkspace(workespacerecover)
      this.setState({ selectedRows: [], selectedRowKeys: [] })
    }

    editWorkSpace = (editRecord) => {
      this.setState({ showCreateWorkSpace: true,
                      editFlag: true,
                      workspaceLabel: "Edit Workspace Name",
                      workspace: editRecord.workspace,
                      workspaceName: editRecord.workspace.name })
    }

    deleteSingleRow = (deleteRec, type) => {
          const that = this
          const confirm = AntModal.confirm;
          const content = (type === "archive") ?
            `Are you sure you want to archive the workspace '${deleteRec.workspace.name}'?` :
            `Are you sure you want to delete workspace '${deleteRec.workspace.name}' permanently?`
          confirm({
              title: 'Confirmation',
              content: content,
              okText: 'OK',
              cancelText: 'CANCEL',
              onOk() {that.archiveSingleWorkSpace(deleteRec);},
                onCancel() {that.cancelaarchiveSingleWorkSpace();},
            });
    }

    cancelaarchiveSingleWorkSpace = () => {

    }

    archiveSingleWorkSpace = (deleteRec) =>{
      let deleteRecList = [deleteRec.workspace.id]

      let deleteType = (getactiveName() === 'archived') ? "hard" : "soft"
      this.props.deleteWorkspace(deleteRecList, deleteType)
      console.log('selectedRows====>', this.state.selectedRows)
      console.log('selectedRowKeys====>', this.state.selectedRowKeys)
      this.setState({ selectedRows: [], selectedRowKeys: []})
    }

    workspaceRecord = (workspace) => {
      let workspaceRecord = []
      if (workspace !== null) {
        if (workspace.workspaceList !== undefined) {
          workspace.workspaceList.map(( record )=>{
            let workspaceMap = {}
            workspaceMap.key = record.workspace.id
            workspaceMap.workspace = record
            workspaceMap.project = record.projectsCount
            var date = new Date(record.workspace.updatedTimestamp)
            var formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
            workspaceMap.date =  formattedDate
            workspaceMap.copyanddelete = record
            workspaceRecord.push(workspaceMap)
          })
        }
      }
      return workspaceRecord
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
      this.setState({ selectedRows, showWorkspaceCount: true, selectedRowKeys})
    };

    getWorkSpaceDetails = () => {
      const { currentwPage } = this.state;
      const columns = this.columns()
      const data = this.workspaceRecord(this.props.workspaceList)
      let selectedRowKeys = this.state.selectedRowKeys
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps: record =>
         ({
          disabled: record.key === 1 || record.key === 2 || record.key === 3
        }),
      }


      let arcResult  =  `No ${getactiveName() === "home" ? '' : 'archived'} workspaces found`

      if (this.props.workspaceList !== null) {
        const paginationPosition = data.length > 5 ? "bottom" : "none";
        if (((this.props.workspaceList.workspaceList === null) || (this.props.workspaceList.workspaceList !== undefined && this.props.workspaceList.workspaceList.length === 0)) && data.length === 0) {
          return <div className="no_result">{arcResult}</div>
        }
        else {
        
          return <div id="urs"> 
                <Table className="table_workspace" rowSelection={rowSelection} columns={columns} dataSource={data}
                        onChange={this.handleTableChange}
                        loading={this.props.tableDataLoading}
                        pagination={{defaultPageSize: 5, total: this.props.total, defaultCurrent: currentwPage,
                        position: paginationPosition, showQuickJumper: (this.props.workspaceList.totalPages > 1 ? true : false)}}
                        >
                </Table>
          </div>
        }
      }
    }
    handleTableChange = ( pagination, filters, sorter ) =>{
      this.setState({selectedRows: [], selectedRowKeys: [], currentwPage: pagination.current});
      this.props.callWorkspaceLists(pagination.current, sorter, pagination.current);
    }

    navigateNext = (page, pageSize) =>{
      this.setState({selectedRows: [], selectedRowKeys: []})
    }
    archiveworkspace = () => {
      const { selectedRows } = this.state;
      let workspaceIdsList = selectedRows.map((row)=>{
        return row.key
      })

      let deleteType = (getactiveName() === 'archived') ? "hard" : "soft"

      this.props.deleteWorkspace(workspaceIdsList, deleteType)
      this.setState({selectedRows: [], selectedRowKeys: []})
    }

    cancelarchiveworkspace = () =>{
      this.setState({selectedRowKeys: [], selectedRows: []})
    }

    archiveSelectedWorkspace = (type) => {
      const { selectedRows } = this.state;
      // permanent delete
      if(selectedRows.length > 0){
        const that = this
        const confirm = AntModal.confirm;
        const content = (type === "Archive") ?
          'Are you sure you want to archive selected workspaces ?' :
          'Are you sure you want to delete selected workspaces permanently?'
         confirm({
             title: 'Confirmation',
             content: content,
             okText: 'OK',
             cancelText: 'CANCEL',
             onOk() {that.archiveworkspace();},
               onCancel() {that.cancelarchiveworkspace();},
           });
      }
      else {
        showAlert("Please select at least one workspace.", 'warning')
      }
    }

    showDemoProjects = () => {
    	this.props.routeToProjects(2, "Demo - ProNotate", "All");
    }
    toggleSearchIcon = () => {
      this.setState({toggleSearch: true})
    }
    render (){
      console.log("Workspace Data from props",this.props)
      const isHome = getactiveName() === 'home' ? true : false
      const { selectedRows, showCreateWorkSpace, workspaceLabel, existWorkspaceFlag, existWorkspace,
        workspaceName, inputErrorMessage, showWorkSpaceError, isEmptyWorkSpaceName, 
       } = this.state;
      console.log("IS HOME",isHome,getactiveName())
      const isWorkspace = getactiveName() === 'home' ? "Workspace" : "Recover";
      const isArchive = getactiveName() === 'home' ? "Archive" : "Delete";
      let workspaceLists = false
      if(this.props.workspaceList !== null && this.props.workspaceList.workspaceList !== null){
        if(this.props.workspaceList.workspaceList !== undefined && this.props.workspaceList.workspaceList.length !== 0){
          workspaceLists = true
        }
      }
      return (
            <div className="taggerPages">
                <Helmet title="My WorkSpace(s)" />
                    <div className="t_workspace m-b-15 m-t-20">
                      <div className="f-center w-50">
                   
                        <div>
                          {isHome &&
                            <h2 className="tilte_display fw-600 m-r-10">My WorkSpaces</h2>}
                            {!isHome &&
                            <h2><span className="tilte_display fw-600 m-r-10">Archived WorkSpaces</span></h2>}
                        </div>
                        {isHome &&
                          <div className="search_h">
                            <Search />
                          
                          </div>
                        }

                      </div>
                        { workspaceLists &&
                            <div className="w_btn">
                                {selectedRows.length > 0 && <div className="m-r-5" ><span style={{fontWeight: '600', color:'#12c4fc'}}>{selectedRows.length}</span> workspace(s) selected</div>}
                                <Button className="btn_base btn_success m-l-5" title={isHome ? "Create workspace" : "Recover"} onClick={this.showCreateWorkSpace}>
                                    {isHome && <Icon type="plus" />}{ isHome && isWorkspace}
                                    {!isHome && <Icon type="rollback"/>}{ !isHome && isWorkspace}
                                </Button>

                                <Button className="btn_base btn_danger m-l-5"  type="danger"  title={isHome ? "Archive" : "Delete"} onClick={() => this.archiveSelectedWorkspace(isArchive)}>
                                    {isHome && <Icon type="inbox"/>}{ isHome && isArchive}
                                    {!isHome && <Icon type="delete"/>}{ !isHome && isArchive}
                                </Button>
                                
                            </div>
                            }
                    </div>
                    {!this.props.pageLoading && this.getWorkSpaceDetails()}
                    <div className="text-center">
                    </div>

                <Modal visible={showCreateWorkSpace} width="400"   style={{minHeight: "200px"}}effect="fadeInUp" onClickAway={() => this.cancelCreateWorkSpace}>
                    <div
                        style={{
                        padding: "15px",align :"center"
                        }}
                    >
                        <h1 style={{ textTransform: "capitalize", marginBottom: "10px",fontWeight: "500" }}>
                            {workspaceLabel}
                        </h1>
                        <label style={{ fontSize: "14px", marginBottom: "10px", fontWeight: '300' }}>Workspace Name</label>
                        <Input placeholder="Enter Workspace Name" style={{
                                           border: 'none',
                                           borderBottom: '0.5px solid',
                                           padding: 5,
                                           marginBottom: 20,
                                           width: '100%',
                                           borderRadius: '0'
                                         }}
                                         onChange={this.setworkspaceName}
                                         maxLength="100"
                        value={workspaceName}
                        autoFocus onKeyPress={this.handleKeyPress} id="txtWorkspaceName" ref={(input) => input && input.focus()}/>
                        {inputErrorMessage !=null && inputErrorMessage !== undefined && inputErrorMessage !== "" ? <p style={{ "color": "red", fontSize: "12px", margin: "5px 0px" }}>{inputErrorMessage}</p> : null}
                        {showWorkSpaceError ? <p style={{ "color": "red", fontSize: "12px", margin: "5px 0px" }}>Special characters are not allowed</p> : null}
                        {isEmptyWorkSpaceName ? <p style={{ "color": "red", fontSize: "12px", margin: "5px 0px" }}>Workspace Name cannot be empty</p> : null}
                        {existWorkspaceFlag ? <p style={{ "color": "red", fontSize: "12px", margin: "5px 0px" }}>{existWorkspace}</p> : null}
                        <div className="model_submit m-b-10 m-t-10">
                            <Button className="btn_cancel h-35" onClick={this.cancelCreateWorkSpace}> Cancel </Button>
                            <Button className="btn_apply h-35 m-l-5" onClick={this.saveCreateWorkSpace}> Submit </Button>
                      </div>

                    </div>
                </Modal>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
  console.log("workspace list from redux",state?.searchReducer)
  return {
      projectWorkspaceList: state?.searchReducer?.projectWorkspaceList,
      searchText: state.searchReducer.searchText,
      searchCriteria: state.searchReducer.searchCriteria,
      searchType: state.searchReducer.searchType
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
      pushState: push,
      logout
  },
  dispatch,
)

export default connect(mapStateToProps, mapDispatchToProps) (WorkSpaceListComponent);