
const initialState = {
    loaded: false,
    loggingIn: false,
    user: null,
    menuHidden: false,
    orgName: undefined,
    projectCreated: false,
    currentProject: undefined,
    projectCreateError: undefined,
    projectDetails: null,
    orgData: null,
    currentHit: null,
    currentTagCategories: [],
    plan: undefined,
    labelsDone: undefined,
    labelsAllowed: undefined,
    subscriptionExpiryTimestamp: undefined,
    hasSubscriptionExpired: false,
    projects: null,
    globalError: undefined,
    orgDataFailure: null,
    currentPathProject: undefined,
    currentHitsData: null,
    currentPathOrg: undefined,
    currentPageStatus: null,
    selectedHitsDetails:null,
    modals: {
        taggerCategoryInputBox: false,
    },
    isLoading: false,
    totalHits: 0,
    showUserDefinedColor: false

  };

  export default initialState