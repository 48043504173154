// As of now, default would be 2 X 2
export const defaultLayout = '22'
const TOGGLE_LAYOUT = "TOGGLE_LAYOUT"
const GET_DICOMHITS = "GET_DICOMHITS"
const RESET = "RESET"
const HANDLE_SELECTED_GRID = "HANDLE_SELECTED_GRID"

const initialState = { dicomHits: [], layout: defaultLayout, gridLayout: [], selectedGrid: 0 }

export default function dicomViewerReducer(state = initialState, action = {}) {
  console.log('dicom reduce called ', state, action);
  let dr=document.getElementById("urs");
  if(dr){
    let dd=dr.querySelectorAll("input");
    for(var i=0;i<dd.length;i++){
      if(dd[i].tagName==="INPUT" && dd[i].className===""){
        dd[i].type="number";
      }
    }
  }
  switch (action.type) {
    case TOGGLE_LAYOUT:
      return {
        ...state,
        layout: action.value
      };
    case GET_DICOMHITS:
      return {
        ...state,
        dicomHits: action.value
      }
    case RESET:
      return {
        ...initialState
      }
    case HANDLE_SELECTED_GRID:
      return {
        ...state,
        selectedGrid: action.value
      }
    default:
      return state
    //END
  }
}

export function toggleLayout(value) {
  return {
    type: TOGGLE_LAYOUT,
    value
  };
}

export function getDicomHits(value) {
    return {
        type: GET_DICOMHITS,
        value
    };
}

export function reset() {
  return {
      type: RESET
  };
}

export function handleSelectedGrid(value) {
  return {
      type: HANDLE_SELECTED_GRID,
      value
  };
}