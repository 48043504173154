import React, { Component } from 'react'
import { Router, Route, IndexRoute } from 'react-router';
import { history } from '../redux/store/newstore';
import Login from '../Components/Login/Login'
import WorkSpaceComponent from '../Components/WorkspaceContainer/WorkspaceContainer'
import UpdatePassword from '../Pages/UpdatePassword/UpdatePassword';
import SearchResult from '../Pages/SearchResult/SearchResult';
import ProjectsView from '../Pages/ProjectsView/ProjectsView';
import TaggerOrgProject from '../Pages/OverviewPage/OverviewPage'
import Taggerpage from '../Pages/TaggerPage/Taggerpage';
import HitsOverviewPage from '../Pages/HitsOverviewPage/HitsOverviewPage';
import CreateProject from '../Pages/CreateProject/CreateProject';
import ImportProjects from '../Pages/ImportProjects/TaggerImport/TaggerImport';
import ExportProjects from '../Pages/ExportProjects/ExportProjects';
import ProjectVisualize from '../Components/ProjectVisualize/ProjectVisualize';
import Users from '../Pages/Users/Users';
import AddUsers from '../Pages/Users/AddUsers';
import UserDetails from '../Pages/UserDetails/UserDetails';
import TaggerEdit from '../Pages/TaggerEdit/TaggerEdit';
import PasswordReset from '../Pages/PasswordReset/PasswordReset';
import Layout from '../Pages/Layout/Layout';
import NotFound from '../Pages/NotFound/NotFound';
import DeleteHistory from '../Pages/DeleteHistory/DeleteHistory';
import DicomOverviewContainer from '../Pages/Dicom/DicomOverviewContainer';
import TaggerViewer from '../Components/TaggerViewer/TaggerViewer';
import DicomViewerContainer from '../Components/Dicom/DicomViewerContainer';
import Organization from '../Pages/Organization/Organization';
import AddorModifyOrganization from '../Pages/Organization/AddorModifyOrganization';
import ErrorPage from '../Pages/ErrorPage/ErrorPage';
import Contributors from '../Pages/Contributors/Contributors';
import Stats from '../Pages/Stats/Stats';
export default class AppRoute extends Component {
  render() {
    const {store} = this.props
    console.log("Route props",this.props)
    console.log("Route location props",this.props.location)
    console.log("Auth user",store.getState())
    const requireLogin = (nextState, replace, cb) => {
      const { loginReducer: { user }} = store.getState();
      console.log("Data user",user)
      if (!user) {
        replace('/projects/login');
      }
      cb();
    };
    console.log("require Login",requireLogin)
    return (
      <div>
      <Router history={history}>
         <Route path="/" >
          <IndexRoute component={Login} />
          <Route path="projects/password/reset" component={PasswordReset}/>
          <Route onEnter={requireLogin} >
          <Route component={Layout} user={"Akash"} >
            <IndexRoute component={WorkSpaceComponent} />
              <Route path="workspace" component={WorkSpaceComponent} />
              <Route path="projects/updatePassword" component={UpdatePassword}/>
              <Route path="projects/organizations" component={Organization}/>
              <Route path="projects/addOrganization" component={AddorModifyOrganization}/>
              <Route path="projects/:orgId/modifyOrganizations" component={AddorModifyOrganization}/>
              <Route path="searchview" component={SearchResult}/>
              <Route path="projects" component={ProjectsView}/>
              <Route path="workspace/:workspaceName/:workspaceId/:orgId/:type" component={ProjectsView} />
              <Route path="projects/:roleId/list" component={ProjectsView} />
              <Route path="projects/:orgName/:projectName/:projectId/contributors" component={Contributors}/>
              <Route path="projects/:orgName/:projectName/:projectId" component={TaggerOrgProject} />
              <Route path="projects/:orgName/:projectName/:projectId/space" component={Taggerpage}/>
              <Route path="projects/space" component={Taggerpage}/>
              <Route path="projects/hitslist/:orgName/:projectName/:projectId/:status" component={HitsOverviewPage}/>
              <Route path="projects/:orgName/:projectName/:projectId/visualize" component={ProjectVisualize}/>
              <Route path="projects/visualize" component={ProjectVisualize}/>
              <Route path="projects/create" component={CreateProject}/>
              <Route path="projects/:orgName/create" component={CreateProject}/>
              <Route path="projects/import" component={ImportProjects}/>
              <Route path="projects/:orgName/import" component={ImportProjects}/>
              <Route path="projects/export" component={ExportProjects}/>
              <Route path="projects/:orgName/:projectName/:projectId/export" component={ExportProjects}/>
              <Route path="archived" component={WorkSpaceComponent} />

              <Route path="projects/errors" component={ErrorPage}/>
           
              <Route path="projects/users" component={Users}/>
              <Route path="projects/:orgId/users" component={Users}/>
              <Route path="projects/:orgId/users/:roleIdCheck" component={Users}/>

              <Route path="projects/:uId/editUsers" component={AddUsers}/>
              <Route path="projects/addUsers" component={AddUsers}/>
              <Route path="projects/addUsers/:roleIdCheck" component={AddUsers}/>

              <Route path="projects/:userId/userDetails" component={UserDetails}/>
              <Route path="projects/:userId/userDetails/:view" component={UserDetails}/>
              {/* <Route path="projects/:userId/userDetails/1" component={UserDetails}/> */}

              <Route path="projects/edit" component={TaggerEdit}/>
              <Route path="projects/:orgName/:projectName/:projectId/edit" component={TaggerEdit}/>
              <Route path="projects/deletehistory" component={DeleteHistory}/>
              <Route path="projects/stats" component={Stats}/>


              <Route path="dicomprojects/:orgName/:projectName/:projectId" component={DicomOverviewContainer} />
              <Route path="projects/:orgName/:projectName/:projectId/view" component={TaggerViewer}/>
              <Route path="projects/view" component={TaggerViewer}/>
              <Route path="projects/:orgName/:projectName/:projectId/dicomviewer" component={DicomViewerContainer} />
            </Route>
          </Route>
          <Route path="projects/login" component={Login} />
          <Route path="*" component={NotFound} status={404} />
        </Route> 
      </Router>
      </div>
    );
  }
}