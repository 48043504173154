import { getUidToken } from '../helpers/commonFunctions';
import { middleCallback } from "./commonApis";
import { BASE_URL } from '../helpers/Constants';
const superagent = require("superagent");

  export const uploadFileDT = (file, pid, callbackfn, progressCallback, type, modelUploadType,selectClassicType) => {
    const { uid, token } = getUidToken();
    const data = new FormData();
    data.append("file", file);
    data.append("filename", file.name);
    console.log("loadFile input is", data);
    if (type) {
      superagent
        .post(BASE_URL + pid + "/upload")
        .set("itemStatus", "preTagged")
        .set("format", type)
        .set("uid", uid)
        .set("token", token)
        .set("uploadFormat", type)
        .attach("file", file)
        .on("progress", function (event) {
          console.log("Percentage done: ", event.percent);
          progressCallback(event);
        })
        .end((err, res) => {
          middleCallback(err, res, callbackfn)
          // callbackfn(err, res);
        });
    }
    else {
      let url = BASE_URL + pid + "/upload"
      if(modelUploadType){
        url = url + "?runModel=" + modelUploadType + "&classicType=" + selectClassicType
      }
      superagent
        .post(url)
        .set("uid", uid)
        .set("token", token)
        .attach("file", file)
        .on("progress", function (event) {
          console.log("Percentage done: ", event.percent);
          progressCallback(event);
        })
        .end((err, res) => {
          middleCallback(err, res, callbackfn)
          // callbackfn(err, res);
        });
    }
  };


  export const uploadFileDTCompare = (file, pid, callbackfn, progressCallback, type, modelUploadType,selectClassicType) => {
    const { uid, token } = getUidToken();
    const data = new FormData();
    console.log("uploadFileDTCompare file",file)
    data.append("file", file);
    data.append("filename", file.name);
    console.log("loadFile input is", data);
    if (type) {
      superagent
        .post(BASE_URL + pid + "/uploadCompare")
        .set("itemStatus", "preTagged")
        .set("format", type)
        .set("uid", uid)
        .set("token", token)
        .set("uploadFormat", type)
        .attach("file", file)
        .on("progress", function (event) {
          console.log("Percentage done: ", event.percent);
          progressCallback(event);
        })
        .end((err, res) => {
          middleCallback(err, res, callbackfn)
          // callbackfn(err, res);
        });
    }
    else {
      let url = BASE_URL + pid + "/uploadCompare"
      if(modelUploadType){
        url = url + "?runModel=" + modelUploadType + "&classicType=" + selectClassicType
      }
      superagent
        .post(url)
        .set("uid", uid)
        .set("token", token)
        .attach("file", file)
        .on("progress", function (event) {
          console.log("Percentage done: ", event.percent);
          progressCallback(event);
        })
        .end((err, res) => {
          middleCallback(err, res, callbackfn)
          // callbackfn(err, res);
        });
    }
  };


  export const uploadModel = (file, pid, callbackfn, progressCallback, modelUploadType, weightFilter, isRunModel) => {
    console.log('uploading file ', file, modelUploadType);
    const { uid, token } = getUidToken();
    const data = new FormData();
    data.append("file", file);
    data.append("filename", file.name);
    console.log("loadFile input is", data);
      superagent
        .post(BASE_URL + "model/" +pid + "/uploadModel?modelUploadType="
              +modelUploadType+ "&weightFilter=" +weightFilter+ "&isRunModel=" +isRunModel)
        .set("uid", uid)
        .set("token", token)
        .attach("file", file)
        .on("progress", function (event) {
          console.log("Percentage done: ", event.percent);
          progressCallback(event);
        })
        .end((err, res) => {
          middleCallback(err, res, callbackfn)
        });
  };

  export const getNERTagsList = (orgId, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + orgId + "/getNERTagsList" )
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
      middleCallback(err, res, callback)
      });
};

export const manualUpload = (projectId,taskType,callback) =>{
  const { uid, token } = getUidToken();
  let url = BASE_URL + projectId + "/manualupload"
  superagent
    .post(url)
    .set("uid",uid)
    .set("token",token)
    .send({"task_type":taskType})
    .end((err,res)=>{
      callback(res);
    });
}