import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateProjectDetails,setCurrentHit,getProjectDetails,updatePrevPageDeatails } from "../../redux/reducer/projectReducer";
import { fetchHits } from "../../Services/TaggerApis";
import { getUidToken,logEvent } from "../../helpers/commonFunctions";
import { clearSessionStorage } from "../../Services/LoginApis";
import { push, goBack } from "react-router-redux";
import {
    createDocEntityColorMap,
    DICOM_SEGMENTATION,
    ENTITY_COLORS,
    createEntities,
    createEntitiesJson,
    getDetaultShortcuts,
    hitStateNameMap,
    projectTypePath,
  } from "../../helpers/Utils";

import { Button, Icon } from 'semantic-ui-react';
import { defaultLayout,toggleLayout,getDicomHits,reset } from "../../redux/reducer/dicomReducer";
import { Tooltip } from "antd";
import img33 from '../../images/3x3.svg';
import img11 from '../../images/1x1.svg';
import img12 from '../../images/1x2.svg';
import img13 from '../../images/1x3.svg';
import img21 from '../../images/2x1.svg';
import img22 from '../../images/2x2.svg';
import img23 from '../../images/2x3.svg';
import img31 from '../../images/3x1.svg';
import img32 from '../../images/3x2.svg';

class DicomViewerContainer extends Component {
    constructor(props) {
        super(props);
        let evaluationType = undefined;
        let contributorId = undefined;
        let selecteddate = undefined;
        let fromLeaderBoard = false;
        let fromHitList = false;
        let type = "all";
        let label = undefined;
        let btnClick = undefined;
        let contributorName = undefined;
        if (props.location && props.location.query) {
            
            if (props.prevPageDetails === "leaderBoard") {
              fromLeaderBoard = true;
              props.updatePrevPageDeatails("")
            }else if (props.prevPageDetails === "HitList") {
              fromHitList = true;
              fromLeaderBoard = false;
              props.updatePrevPageDeatails("")
            }
            if (props.location.query.label)
              label = props.location.query.label;
    
            if (props.location.query.evaluationType)
              evaluationType = props.location.query.evaluationType;
    
            if (props.location.query.contributorId)
              contributorId = props.location.query.contributorId;
    
            if (props.location.query.date)
              selecteddate = props.location.query.date;
          }
    
        if (
          this.props.projectDetails &&
          this.props.currentHit &&
          (  this.props.projectDetails.task_type === DICOM_SEGMENTATION)
        ) {
          const rules = JSON.parse(this.props.projectDetails.taskRules);
          const entities = createEntities(this.props.projectDetails.taskRules).entities;
          const entityColorMap = createDocEntityColorMap(entities, ENTITY_COLORS);
          const hits = [];
          let shortcuts = getDetaultShortcuts(
            this.props.projectDetails.task_type,
            entities
          );
          let autoClose = true;
          let notes = false;
          let classification = undefined;
          let defaultShape = "polygon";
          if ("shortcuts" in rules) {
            shortcuts = rules.shortcuts;
          }
          if ("autoClose" in rules) {
            autoClose = rules.autoClose;
          }
          if ("notes" in rules) {
            notes = rules.notes;
          }
          if ("defaultShape" in rules) {
            defaultShape = rules.defaultShape;
          }
          hits.push(this.props.currentHit);
          const ch = this.props.currentHit;
          let boundingBoxMap = {};
          let classificationResponse = [];
          if (ch.hitResults[0].result.length > 0) {
            if ("classification" in rules) {
              classification = rules.classification;
              if (ch.hitResults) {
                const resultObject = JSON.parse(ch.hitResults[0].result);
                if ("annotationResult" in resultObject) {
                  boundingBoxMap = resultObject.annotationResult;
                } else {
                  boundingBoxMap = JSON.parse(ch.hitResults[0].result);
                }
              }
            } else {
              if (ch.hitResults) {
                boundingBoxMap = JSON.parse(ch.hitResults[0].result);
              }
            }
          }
          if (
            this.props.projectDetails.task_type === DICOM_SEGMENTATION
          ) {
            ch.data = ch.data + "?ts=" + new Date();
          }
          this.state = {
            toggleDropdown: false,
            type,
            btnClick: btnClick,
            defaultShape,
            classification,
            classificationResponse,
            contributorId,
            evaluationType,
            label,
            selectIds: [],
            entities,
            entityColorMap,
            boundingBoxMap,
            shortcuts,
            autoLabel: true,
            autoClose,
            notes,
            rules: rules,
            currentIndex: 0,
            hits,
            newEntities: [],
            currentHit: ch,
            changesInSession: 0,
            projectDetails: this.props.projectDetails,
            loading: false,
            currentStart: 0,
            currentCount: 10,
            startTime: new Date().getTime(),
            contrast: 1.0, 
            brightness: 1.0,
            saturation: 1.0,
            opacity: 0.4,
            translate: {
              x: 0,
              y: 0
            },
            images: [],
            open: false,
            visible: false,
            textValue: null,
            currentImageIndex: 0,
            historyArray: [],
            undoredoHistory: 0,
            count: 0,
            collapse: false,
            drawregions: [],
            activeFlag: false,
            indexvalue: 0,
            interpolationDone: [],
            labelExists: false,
            labelText: null,
            searchQuery: '',
            showOrHideEyeMap: {},
            defaultClass: '',
            interpolationStartIndex: 0,
            showOrHideEye: true,
            saveButtonDisabled: true,
            hitUserName: '',
            hitUserEmail: '',
            hitFileName: '',
            hitStatus: '',
            hitEvaluation: '',
            hitUpdatedTime: '',
            updateAnnotatorInfo: true,
            fromLeaderBoard: fromLeaderBoard,
            fromHitList: fromHitList,
            pageSortBy: "NEW_FIRST",
            dateFrom: props.selectedHitsDetails ? props.selectedHitsDetails.from ? props.selectedHitsDetails.from : null : null,
            dateTo: props.selectedHitsDetails ? props.selectedHitsDetails.to ? props.selectedHitsDetails.to : null : null,
            selecteddate: props.selectedHitsDetails ? props.selectedHitsDetails.selectedDate ? props.selectedHitsDetails.selectedDate : null : null,
            contributer: props.selectedHitsDetails ? props.selectedHitsDetails.contributorId ? props.selectedHitsDetails.contributorId[0] : null : null,
            downloadFormat: 'json',
            itemOption: 'ALL',
            confirmDownloadModalVisible: false,
            contributorName
            };
        } else {
        const ch = this.props.currentHit;
        this.state = {
          toggleDropdown: false,
          type,
          btnClick: btnClick,
          contributorId,
          label,
          evaluationType,
          drag: {
            marginTop: '1%',
            marginLeft: '26%',
            width: 512,
            height: 512,
            position: "relative",
            display: "inline-block",
            color: "white"
          },
          isFullScreen: false,
          currentHit: ch,
          hits: [],
          projectDetails: this.props.projectDetails,
          currentStart: 0,
          currentCount: 10,
          DEFAULT_COUNT: 10,
          currentIndex: -1,
          contrast: 1.0, 
          brightness: 1.0, 
          saturation: 1.0, 
          opacity: 0.4,
          translate: {
            x: 0,
            y: 0
          },
          images: [],
          startTime: new Date().getTime(),
          open: false,
          textValue: null,
          currentImageIndex: 0,
          historyArray: [],
          undoredoHistory: 0,
          count: 0,
          collapse: false,
          drawregions: [],
          activeFlag: false,
          indexvalue: 0,
          changesInSession: 0,
          interpolationDone: [],
          interpolationNext: [],
          labelExists: false,
          labelText: null,
          searchQuery: '',
          showOrHideEyeMap: {},
          defaultClass: '',
          interpolationStartIndex: 0,
          showOrHideEye: true,
          saveButtonDisabled: true,
          hitUserName: '',
          hitUserEmail: '',
          hitFileName: '',
          hitStatus: '',
          hitEvaluation: '',
          hitUpdatedTime: '',
          updateAnnotatorInfo: true,
          fromLeaderBoard: fromLeaderBoard,
          pageSortBy: "NEW_FIRST",
          fromHitList: fromHitList,
          downloadFormat: 'json',
          itemOption: 'ALL',
          confirmDownloadModalVisible: false,
          contributorName
          };
      }
        this.props.setCurrentHit(undefined);
      }
    componentDidMount() {
        this.loadProjectDetails(this.props.currentProject);
    }
    componentWillUnmount() {
      if(this.props.layout !== defaultLayout) {
          this.props.toggleLayout(defaultLayout)
      }
      this.props.reset()
    }
    loadProjectDetails = (pid, start, count) => {
        const { type, label, contributorId, evaluationType, dateFrom, pageSortBy, dateTo } = this.state;
        this.setState({updateAnnotatorInfo: true});
    
        this.setState({ loading: true });
        fetchHits(
          pid ? pid : this.props.currentProject,
          0,
          this.props.totalHits,
          this.projectDetailsFetched,
          type,
          label,
          contributorId,
          evaluationType,
          dateFrom,
          pageSortBy,
          dateTo
        );
    }
    projectDetailsFetched = (error, response) => {
      const { currentCount, hits, type } = this.state;
        if (!error) {
            if (this.props.selectedHitsDetails) {
                let index = response.body.hits.findIndex(x => x.id === this.props.selectedHitsDetails.selectedImageId);
                response.body.hits.unshift(response.body.hits.splice(index, 1)[0]);
              }
              let notDoneHits = response.body.projectDetails.totalHits -
              (response.body.projectDetails.totalHitsDeleted + response.body.projectDetails.totalHitsDone + response.body.projectDetails.totalHitsSkipped);
              let totalFilteredHits = response.body.totalFilteredHits;
              let hitsNotAnnotatedCount = response.body.hitsNotAnnotatedCount;
              this.setState({ hitsNotAnnotatedCount: hitsNotAnnotatedCount, totalFilteredHits: totalFilteredHits, notDoneHits: notDoneHits })
    
          if (response.body.hits.length < currentCount) {
            this.setState({ hitScrollCompleted: true });
          }else {
            this.setState({ hitScrollCompleted: false });
          }
          let projectDetails = this.props.projectDetails;
          if (response.body.projectDetails) {
            this.props.updateProjectDetails(response.body.projectDetails);
            projectDetails = response.body.projectDetails;
          }
          console.log('response.body.hits',response.body.hits)
          if (response.body.hits) {
            this.setState({ images : response.body.hits })
            this.props.getDicomHits(response.body.hits)
          }
    
          const rules = JSON.parse(projectDetails.taskRules);
          const entitiesObject = createEntitiesJson(projectDetails.taskRules);
          const entities = entitiesObject.entities;
          const entityJson = entitiesObject.entityJson;
          let shortcuts = getDetaultShortcuts(projectDetails.task_type, entities);
          const entityColorMap = createDocEntityColorMap(entities, ENTITY_COLORS);
          this.state.entityColorMap = entityColorMap;
          if ("shortcuts" in rules) {
            shortcuts = rules.shortcuts;
          }
          let currentHits = hits;
          let currentIndex = this.state.currentIndex;
          currentIndex = currentIndex + 1;
          currentHits = [...currentHits, ...response.body.hits];
          const currentHit = currentHits[currentIndex];
          if (response.body.hits.length === 0 || !currentHit) {
            this.setState({
              hits: currentHits,
              currentIndex: currentHits.length - 1,
              entityColorMap,
              entities,
              entityJson,
              startTime: new Date().getTime(),
              projectDetails,
              loading: false,
              action: "",
              rules,
              showOrHideEyeMap: {}
            });
            if ((!type || type === "notDone")) {
              this.setState({ hitsCompleted: true });
            }
          } else {
              if (
              projectDetails.task_type === DICOM_SEGMENTATION
            ) {
              let hideLabels = false;
              let autoClose = true;
              let notes = false;
              let defaultShape = "polygon";
              let keepEntitySelected = false;
              if ("notes" in rules) {
                notes = rules.notes;
              }
              if ("autoClose" in rules) {
                autoClose = rules.autoClose;
              }
              if ("hideLabels" in rules) {
                hideLabels = rules.hideLabels;
              }
              if ("defaultShape" in rules) {
                defaultShape = rules.defaultShape;
              }
              if ("keepEntitySelected" in rules) {
                keepEntitySelected = rules.keepEntitySelected;
              }
              if (response.body.hits.length > 0) {
                let boundingBoxMap = [];
                if (
                  currentHit.hitResults &&
                  currentHit.hitResults[0].result.length > 0
                ) {
                }
                this.setState({
                  boundingBoxMap,
                  defaultShape,
                  autoClose,
                  notes,
                  hideLabels,
                  entityJson,
                  newEntities: [],
                  shortcuts,
                  rules,
                  entities,
                  entityColorMap,
                  keepEntitySelected,
                  currentIndex,
                  hits: currentHits,
                  currentHit,
                  projectDetails,
                  loading: false,
                  action: "",
                  showOrHideEyeMap: {}
                });
              }
            }
          }
        } else {
          alert("Error in fetching project details, please try again");
          clearSessionStorage();
          this.props.pushState("/projects/login");
          if (error && error.message) {
            logEvent("project_detail_fetch_error", error.message);
          } else {
            logEvent("project_detail_fetch_error", "Error");
          }
          this.setState({ loading: false });
        }
      }
    redirectBackImage(e) {
        if (undefined !== e)
          e.preventDefault();
        const { projectDetails } = this.state;
        this.props.pushState({
            pathname: projectTypePath(projectDetails) + this.props.params.orgName + "/" + this.props.params.projectName + "/" + this.props.params.projectId
        });
    }
    routeToHome = () => {
        if (getUidToken().roleId === "2") {
          this.props.pushState("/workspace")
        } else {
          let roleId = getUidToken().roleId
          this.props.pushState("/projects/"+roleId+"/list")
        }
    }
    showTaggingInstructions() {
        return (
          <div className="project_nameinfo">
            <div className="proj_sec">
              <label>Project Guidelines</label>
              
            </div>
          </div>
        );
    }
    getHitInfo(hit) {
        if (hit) {
          const fileName = hit.fileName;
          let status = hit.status
          if (hit.status === 'reQueued') { status = hit.status }
          else if (hit.result && JSON.parse(hit.result).length === 0) { status = 'notDone' }
          else if (hit.result && JSON.parse(hit.result).labels && JSON.parse(hit.result).labels.length === 0) { status = 'notDone' }
          else { status = hit.status }
          return (
            <div style={{ display: "flex"}}>
            <div className="project_nameinfo">
              <div className="proj_sec">
                  {fileName && <label>File Name</label>}
                  <p style={{fontSize: '12px'}} className="file_wrap" title={fileName}>{fileName}</p>
              </div>
            </div>
            <div className="project_nameinfo">
              <div className="proj_sec">
                  {status && <label style={{ textTransform: 'capitalize' }}> HIT status </label>}
                  <p style={{fontSize: '12px'}}>{hitStateNameMap[status]}</p>
              </div>
            </div>
          </div>
          );
        }
    }
    handleToggleDropdown = () => {
      const { toggleDropdown } = this.state;
      this.setState({toggleDropdown: !toggleDropdown})
    }
    handleToggleLayout = (e) => {
      e.preventDefault()
      this.handleToggleDropdown()
      this.props.toggleLayout(e.target.id)
    }
    render() {
        const { dicomHits } = this.props
        const { toggleDropdown, currentHit } = this.state
        return (
            <div>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:"50px"}} className="m-t-10">
                    <div className={"seg_w80 w-100"}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: 'space-between'
                            }} >
                            <div style={{
                                display: "flex",
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                width: '100%'
                            }}>
                                <div style={{
                                display: "flex"}}>
                                  <div style={{display: 'flex', alignItems: 'center'}}>   
                                    <div>
                                    <Button
                                        icon
                                        title="Back"
                                        className="pull-left back_btn"
                                        onClick={this.redirectBackImage.bind(this)}
                                        compact
                                    >
                                    <Icon name="arrow left" />
                                    </Button>
                                    <Button
                                        icon
                                        title="Home"
                                        className="pull-left back_btn"
                                        onClick={() => this.routeToHome()}
                                        compact>
                                    <Icon size="large" name="home" />
                                    </Button>
                                    </div>
                                  </div>
                                  <div style={{display: 'flex', alignItems: 'center'}}>
                                  {this.getHitInfo(currentHit)} 
                                  </div>
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                  <div className="topBar">
                    <Tooltip title="Layout">
                      <img alt="toggle" src={img33} className="showOptions" onClick={() => this.handleToggleDropdown()} />
                    </Tooltip>
                    {toggleDropdown && <ul className="dropOptions">
                        <li>
                            <a id='11' onClick={(e) => this.handleToggleLayout(e)} href="#"><img src={img11} className="showIcon" />1 x 1 Screen Layout</a>
                        </li>
                        <li>
                            <a id='12' onClick={(e) => this.handleToggleLayout(e)} href="#"><img src={img12} className="showIcon" />1 x 2 Screen Layout</a>
                        </li>
                        <li>
                            <a id='13' onClick={(e) => this.handleToggleLayout(e)} href="#"><img src={img13} className="showIcon" />1 x 3 Screen Layout</a>
                        </li>
                        <li>
                            <a id='21' onClick={(e) => this.handleToggleLayout(e)} href="#"><img src={img21} className="showIcon" />2 x 1 Screen Layout</a>
                        </li>
                        <li>
                            <a id='22' onClick={(e) => this.handleToggleLayout(e)} href="#"><img src={img22} className="showIcon" />2 x 2 Screen Layout</a>
                        </li>
                        <li>
                            <a id='23' onClick={(e) => this.handleToggleLayout(e)} href="#"><img src={img23} className="showIcon" />2 x 3 Screen Layout</a>
                        </li>
                        <li>
                            <a id='31' onClick={(e) => this.handleToggleLayout(e)} href="#"><img src={img31} className="showIcon" />3 x 1 Screen Layout</a>
                        </li>
                        <li>
                            <a id='32' onClick={(e) => this.handleToggleLayout(e)} href="#"><img src={img32} className="showIcon" />3 x 2 Screen Layout</a>
                        </li>
                        <li>
                            <a id='33' onClick={(e) => this.handleToggleLayout(e)} href="#"><img src={img33} className="showIcon" />3 x 3 Screen Layout</a>
                        </li>
                    </ul>
                  }
                  </div>
                    { dicomHits.length > 0 ? <h1>DicomViewer</h1> : null }
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    console.log("mapStateToProps taggerpage", state);
    return {
        currentHit: state.projectReducer.currentHit,
        currentProject: state.projectReducer.currentProject,
        projectDetails: state.projectReducer.projectDetails,
        selectedHitsDetails: state.projectReducer.selectedHitsDetails,
        prevPageDetails: state.projectReducer.prevPageDetails,
        layout: state.dicomViewerReducer.layout,
        dicomHits: state.dicomViewerReducer.dicomHits,
        totalHits: state.projectReducer.totalHits
    };
  };
  
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
    getProjectDetails,
    goBack,
    setCurrentHit,
    updateProjectDetails,
    updatePrevPageDeatails,
    pushState: push,
    toggleLayout,
    getDicomHits,
    reset
      },
      dispatch
    );
  
  export default connect(mapStateToProps, mapDispatchToProps)(DicomViewerContainer);

