import React from "react";
import { AutoComplete } from "antd";
import { Button } from "semantic-ui-react";
import "./AnnotationLableModel.css";

class AnnotationLableModel extends React.Component {
  render() {
    const {
      entitySuggestList,
      handleDialogClickEvent,
      setLabelValue,
      value,
      isEmptyLabel,
      handleDialogClose,
      showLabelError,
    } = this.props;
    return (
      <div align="center" className="mDiv">
        <h1>Annotation Label</h1>
        <p className="m-t-5 m-b-10">Please enter your Annotation Label.</p>
        <AutoComplete
          className="m_input dLabel"
          dataSource={entitySuggestList}
          placeholder="Label"
          onSearch={setLabelValue} //Enter Label input
          onChange={setLabelValue} //Select Label from AutoSuggest
          value={value}
          dropdownClassName="d_labeling"
          autoFocus="false"
          getPopupContainer={(node) => node.parentNode}
          filterOption={(inputValue, option) =>
            option.props.children
              .toUpperCase()
              .indexOf(inputValue.toUpperCase()) !== -1
          }
        >
          <input onKeyDown={this.handleKeyPress} />
        </AutoComplete>
        {showLabelError ? (
          <p className="sLabel">Special characters are not allowed</p>
        ) : null}
        {isEmptyLabel ? <p className="sLabel">Label cannot be empty</p> : null}

        <div className="model_submit m-b-10">
          <Button className="btn_cancel" onClick={handleDialogClose}>
            Cancel
          </Button>
          <Button className="btn_apply" onClick={handleDialogClickEvent}>
            {" "}
            Apply
          </Button>
        </div>
      </div>
    );
  }
}

export default AnnotationLableModel