import actionTypes from './app-action-types'

const updateOrgName = (name) => ({
    type: actionTypes.updateOrgName,
    payload: name
})

const updateActiveMenu = (menu) => ({
    type: actionTypes.updateActiveMenu,
    payload: menu
})

const createNewWorkSpace = (workspaceName) => ({
    type: actionTypes.createNewWorkSpace,
    payload: workspaceName
})

export default {
    updateOrgName,
    updateActiveMenu,
    createNewWorkSpace
}