// Filter.js
import React from 'react';
import {
    IMAGE_BOUNDING_BOX,
    IMAGE_POLYGON_BOUNDING_BOX,
    IMAGE_POLYGON_BOUNDING_BOX_V2,
    IMAGE_CLASSIFICATION,
    IMAGE_SEGMENTATION,
  } from "../../helpers/Utils";

  import {
    Icon as AntIcon,
    Dropdown as AntDropdown,
    Select as AntSelect,
    Modal as AntModal,
    Popover as AntPopover,
  } from "antd";
import SingleDatePicker from '../SingleDatePicker.js/SingleDatePicker';

class Filters extends React.Component {
  render() {
    const {list,propsState,propsData,toggleMenu,handleAllFilterChanges,getEntities,
        setSelectedDate
        ,clearFromDisable
      } = this.props;
    return (
        <div className="hits_filter">
        <div className="dropBox_set">
          <div className="f_between">
            <h3 className="sec_tilte">
              <span>Filter By</span>
            </h3>
            <AntIcon
              className="f_close"
              type="close"
              onClick={toggleMenu}
            />
          </div>
          <ul className="filterTools">
            <li>
              <label>HITs STATUS</label>
              <select
                name="hitsDone"
                defaultValue={propsState.status}
                onChange={handleAllFilterChanges}
              >
                <option value="done">Done</option>
                <option value="notDone">Not Done</option>
                <option value="skipped">Skipped</option>
                <option value="deleted">Deleted</option>
                <option value="preTagged">Pre-Tagged</option>
                <option value="reQueued">Re-Tagging Queue</option>
                <option value="">Total</option>
              </select>
            </li>
            <li>
              <label>CONTRIBUTORS</label>
              <select
                name="contributor"
                value={propsState.Contributor}
                onChange={handleAllFilterChanges}
                placeholder="Contributor"
              >
                {list}
              </select>
            </li>
            <li className="custom_filterdate">
              <label>DATE</label>
              <SingleDatePicker
                setSelectedDate={setSelectedDate}
                clearDisable={clearFromDisable}
              />
            </li>
            {propsData.projectDetails &&
              (propsData.projectDetails.task_type ===
                IMAGE_BOUNDING_BOX ||
                propsData.projectDetails.task_type ===
                  IMAGE_POLYGON_BOUNDING_BOX ||
                  propsData.projectDetails.task_type ===
                  IMAGE_POLYGON_BOUNDING_BOX_V2 ||
                  propsData.projectDetails.task_type ===
                  IMAGE_SEGMENTATION ||
                propsData.projectDetails.task_type ===
                  IMAGE_CLASSIFICATION) && (
                <li>
                  <span>
                    <label>EVALUATION STATUS</label>
                    <select
                      className="select_left"
                      name="evaluation"
                      defaultValue={propsState.hitEvaluationType}
                      onChange={handleAllFilterChanges}
                    >
                      <option value="correct">Correct</option>
                      <option value="incorrect">Incorrect</option>
                      <option value="none">Not Evaluated</option>
                      <option value="null" selected>
                        All
                      </option>
                    </select>
                  </span>
                  
                </li>
              )}
            <li style={{display:"flex",flexDirection:"column",gap:"2px"}}  className="custom_boxset">
              <label>TAGGED ENTITY</label>
              {getEntities(propsState.entities)}
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Filters