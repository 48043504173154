// TaggerSearch
import React, {Component, PropTypes} from "react";
import Helmet from 'react-helmet';
import {connect} from 'react-redux';
import moment from 'moment';
import { Table, Icon, Button as AntButton, Modal as AntModal, Form, Radio } from 'antd';
import { getUidToken, getOrgId,   } from "../../helpers/commonFunctions";
import { downloadProjectsSets } from "../../Services/DownloadApis";
import { addContributor } from "../../Services/OverviewApis";
import { TimePicker, DatePicker , LocaleProvider } from 'antd';
import {Icon as SematicIcon } from "semantic-ui-react";
import { TEXT_SUMMARIZATION, DOCUMENT_ANNOTATION, POS_TAGGING_GENERIC, POS_TAGGING, TEXT_CLASSIFICATION, TEXT_MODERATION, IMAGE_POLYGON_BOUNDING_BOX, IMAGE_POLYGON_BOUNDING_BOX_V2,IMAGE_SEGMENTATION, IMAGE_BOUNDING_BOX, IMAGE_CLASSIFICATION, DICOM_SEGMENTATION, DICOM_CLASSIFICATION,  projectTypePath} from '../../helpers/Utils';
import {bindActionCreators} from 'redux';
import { push } from 'react-router-redux';
import { getSearchProjectWorkspace, archiveProjectWorkspaceProcess,setArchiveStatus, reset } from '../../redux/reducer/searchReducer';
import { showAlert,logEvent } from "../../helpers/commonFunctions";
import SHARE from '../../images/share-01.svg';
import { fromDateTimestamp, toDateTimestamp } from "../../helpers/Utils"
import { setloaderMenu } from "../../redux/reducer/projectReducer";
import en_US from 'antd/lib/locale-provider/en_US';
import Search from '../../Components/WorkspaceContainer/Search'
import ShareProjects from "../../Components/ShareProjects/ShareProjects";
const FileSaver = require('file-saver');
class SearchResult extends Component {
    static propTypes = {
        pushState: PropTypes.func,
        orgName: PropTypes.string,
        getSearchProjectWorkspace: PropTypes.func,
        archiveProjectWorkspaceProcess: PropTypes.func,
        setArchiveStatus: PropTypes.func
    };
    constructor(props) {
        super(props);
        const  orgId  = getOrgId()
         this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.state = {
            selectedRows: [],
            showWorkspaceCount: false,
            selectedRowKeys: [],
            inputErrorMessage: '',
            loading: false,
            pagecount: 0,
            sizecount: 5,
            searchTerm: '',
            inviteModal: false,
            pid: undefined,
            isLoading: false,
            orgId,
            searchType: '',
            isArchive: false,
            selectedButtonType:'',
            exportVisible: false,
            downloadFormat: 'json',
            itemOption: 'ALL',  //As per the requirement, by default made all items options as selected one
            fromDate: null,
            toDate: null,
            fromTime: null,
            toTime: null,
            downloadProjectIds: [],
            isFilter: ''
        }
    }
    componentDidMount() {
        const {getSearchProjectWorkspace} = this.props
        console.log("Component did mount searchresult",getSearchProjectWorkspace)

        if (!getUidToken().uid) {
            this.props.pushState('/projects/login');
        }
        if (this.props.location && this.props.location.query) {
          this.setState({searchTerm: this.props.location.query.query})
          this.setState({searchType: this.props.location.query.type})
          this.setState({ isFilter: this.props.location.query.isFilter})
        }

        const searchTerm = this.state.searchTerm ? this.state.searchTerm : this.props.location.query.query
        const searchType = this.state.searchType ? this.state.searchType : this.props.location.query.type
        getSearchProjectWorkspace(this.state.orgId, this.state.pagecount, this.state.sizecount, searchTerm, undefined, undefined, searchType)
    }
    componentDidUpdate(prevProps) {
        const {getSearchProjectWorkspace} = this.props
        const {orgId,pagecount,sizecount} = this.state
        console.log("Component did update searchresult",getSearchProjectWorkspace)
       if (prevProps.location.query.query !== this.props.location.query.query || prevProps.location.query.type !== this.props.location.query.type) {
            const searchTerm = this.props.location.query.query
            const searchType = this.props.location.query.type
            getSearchProjectWorkspace(orgId, pagecount, sizecount, searchTerm, undefined, undefined, searchType)
        }
    }
    componentWillUnmount() {
        this.props.reset()
        console.log("componentWillUnmount : ", this.props.location.query.query, this.props.location.query.type)
      }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRows, showWorkspaceCount: true, selectedRowKeys})
    };
    getProjectWorkSpaceDetails = () => {
        const {selectedRowKeys} = this.state
        const columns = this.columns()
        const data = this.projectWorkspaceRecord(this.props.projectWorkspaceList)
        const rowSelection = {
          selectedRowKeys,
          onChange: this.onSelectChange,
          getCheckboxProps: record =>
           ({
            disabled: record.key === 1 || record.key === 2 || record.key === 3
          }),
        }

        if (this.props.projectWorkspaceList !== null) {
            if ((!this.props.projectWorkspaceList || this.props.projectWorkspaceList === null || this.props.projectWorkspaceList.length === 0) && data.length === 0) {
                return <div className="no_result">{"No results found"}</div>
            } else {
                return <Table className="table_workspace table_result" rowSelection={rowSelection} columns={columns} dataSource={data}
                            locale={{ emptyText: "No matching records found" }}>
                    </Table>
            }
        }
    }
    projectWorkspaceRecord = (projectWorkspaceData) => {
        let projectWorkspaceRecord = []
        if (projectWorkspaceData !== null) {
          if (projectWorkspaceData !== undefined) {
            projectWorkspaceData.map(( record )=>{
              if (record.details  !== undefined) {
                let projectworkspaceMap = {}
                var date;
                let archiveStatus = false;
                
                if (record.type === "workspace") {
                  archiveStatus = record.details.workspace.archived;
                  projectworkspaceMap.key = record.details.workspace.id
                  projectworkspaceMap.owner = {"ownerName": record.details.ownerName, "archiveStatus": archiveStatus}
                  projectworkspaceMap.projectWorkspace = Object.assign(record.details.workspace, {"ownerId": record.details.ownerId},
                    {"appType": record.type}, {"archiveStatus": archiveStatus});
                  projectworkspaceMap.projectCount = {
                    "projectsCount": record.details.projectsCount,
                    "archiveStatus": archiveStatus
                  }
                  date = moment(new Date(record.details.workspace.updatedTimestamp)).format('YYYY-MM-DD HH:mm:ss')
                  projectworkspaceMap.date = {"date": date, "archiveStatus": archiveStatus}
                  projectworkspaceMap.copyanddelete = Object.assign(record.details.workspace, {"ownerId": record.details.ownerId},
                    {"appType": record.type});
                } else {
                  if (record.details.project.status === "DELETED" || record.details.project.workspacdDeleted === true)
                    archiveStatus = true
                  projectworkspaceMap.key = record.details.project.id
                  projectworkspaceMap.owner = {"ownerName": record.details.ownerName, "archiveStatus": archiveStatus}
                  projectworkspaceMap.projectWorkspace = Object.assign(record.details.project, {"orgName": record.details.orgName},
                    {"workspaceName": record.details.workspaceName}, {"ownerId": record.details.ownerId}, {"appType": record.type},
                    {"archiveStatus": archiveStatus});
                  projectworkspaceMap.projectCount = {"projectsCount": "-", "archiveStatus": archiveStatus}
                  date = moment(new Date(record.details.project.updated_timestamp)).format('YYYY-MM-DD HH:mm:ss')
                  projectworkspaceMap.date = {"date": date, "archiveStatus": archiveStatus}
                  projectworkspaceMap.copyanddelete = Object.assign(record.details.project, {"ownerId": record.details.ownerId},
                    {"appType": record.type});
                }
                if(archiveStatus === false){
                  projectWorkspaceRecord.push(projectworkspaceMap)
                }
              } })
          }
        }
        return projectWorkspaceRecord
    }
    columns = () => {
        const { uid } = getUidToken();
        return ([{
        title: 'NAME',
        dataIndex: 'projectWorkspace',
        className: 'w_name',
        sorter: true,
        render: projectWorkspace =>
              <div className="space_wrap table_v">
                  <a className={"m_right table_link " + (projectWorkspace.archiveStatus ? 'r' : '')} onClick={()=> this.routeToProjects(projectWorkspace)}>
                  <span className={"s_word " + (projectWorkspace.appType === 'workspace' ? 'w_word' : 'p_word')} >{projectWorkspace.appType === 'workspace' ? 'W' : 'P'}</span>  {projectWorkspace.name}
                  {(projectWorkspace.appType === "project" && projectWorkspace.ownerId !== uid) && <img alt="shareimg" className="share_img" src={SHARE} title="shared project" />}
                  </a>
              </div>
        },
        {
          title: 'OWNER',
          dataIndex: 'owner',
          sorter: true,
          className: 'owner_name',
          render: owner =>
          <div className={"m_right table_link " + (owner.archiveStatus ? 'r' : '')}> {owner.ownerName}
          </div>
        }, {
          title: 'LAST MODIFIED',
          sorter: true,
          dataIndex: 'date',
          render: date =>
          <div className={"m_right table_link " + (date.archiveStatus ? 'r' : '')}> {date.date}
          </div>
        }, {
            title: 'PROJECTS',
            dataIndex: 'projectCount',
            sorter: true,
            className: 'number_format',
            render: projectCount =>
            <div className={"m_right table_link " + (projectCount.archiveStatus ? 'r' : '')}> {projectCount.projectsCount}
            </div>
        }, {
          title: 'ACTION',
          dataIndex: 'copyanddelete',
          render: copyicon =>
                  <div className="action_start">
                      <div className="table_actionbtn">
                          {(copyicon.appType === "project" && copyicon.ownerId === uid) &&
                          <div style={{display: 'flex'}}>
                            {
                                ((copyicon.appType === "workspace" && !copyicon.archived) || (copyicon.appType === "project" && (copyicon.status !== "DELETED" || copyicon.workspacdDeleted === true))) &&
                                <button type="submit"  className="table_sButton" onClick={this.openInviteModal.bind(this, copyicon.id)}
                                >
                                <SematicIcon title="Share" className="share_icn" name="share square outline" />
                                </button>
                            }
                            {
                            	((copyicon.appType === "workspace" && !copyicon.archived) || (copyicon.appType === "project" && (copyicon.status !== "DELETED" || copyicon.workspacdDeleted === true))) &&
                            	<button type="submit"  className="table_sButton" onClick={() => this.deleteSingleRow(copyicon)}>
                                <Icon type="inbox" title="Archive"
                                className={(copyicon.appType === "project" && (copyicon.status === "DELETED" || copyicon.ownerId !== uid))}
                                />
                                </button>
                            }

                            {
                              ((copyicon.appType === "workspace" && copyicon.archived) || (copyicon.appType === "project" && (copyicon.status === "DELETED" || copyicon.workspacdDeleted === true || copyicon.ownerId !== uid))) &&
                              <button type="submit"  className="table_sButton m-l-35" onClick={() => this.recoverySingleRow(copyicon)}>
                                <Icon type="rollback" title="Recover"
                                      className={(copyicon.appType === "project" && (copyicon.status === "DELETED" || copyicon.ownerId !== uid))}
                                />
                              </button>
                            }
                          </div>
                          }
                      </div>
                  </div>
        }
      ])
    }

    routeToProjects = (projectWorkspace) => {
      const {searchType,isFilter} = this.state
      if (projectWorkspace.archived)
        window.sessionStorage.setItem('activeName', "archived");
      else
        window.sessionStorage.setItem('activeName', "home");
        const { roleId } = getUidToken();
        const workspaceId = (projectWorkspace.appType === "project") ? projectWorkspace.workspaceId : projectWorkspace.id
        const workspaceName = (projectWorkspace.appType === "project") ? projectWorkspace.workspaceName : projectWorkspace.name
        const projectId = (projectWorkspace.appType === "project") ? projectWorkspace.id : ""
        const projectName = (projectWorkspace.appType === "project") ? projectWorkspace.name : ""
        const taskType = (projectWorkspace.appType === "project") ? projectWorkspace.taskType : ""
        const orgId = projectWorkspace.orgId
        const orgName = (projectWorkspace.appType === "project") ? projectWorkspace.orgName : ""
        const type = "All"
        if (projectWorkspace.appType === 'workspace') {
            let queryParam = "";
            if ( searchType && searchType !== '')
                queryParam = queryParam + "?projectType=" + searchType + "&currentwPage=1&currentpPage=1" + "&isFilter=" + isFilter;
            this.props.pushState('/workspace/' + workspaceName + '/' + workspaceId + '/' + orgId + '/' + type + queryParam);
        } else {
            let workspaceType = "All";
            if (roleId === "3") {
                workspaceType = "shared"
            }
            let queryParam = "?";
            if ( workspaceName !== '')
                queryParam = queryParam + "wName=" + workspaceName;
            if ( workspaceId !== '')
                queryParam = queryParam + "&wId=" + workspaceId;
            if ( orgId !== '')
                queryParam = queryParam + "&orgId=" + orgId;
            if ( workspaceType !== '')
                queryParam = queryParam + (queryParam.includes('&') ? "&" : "" ) + "wType=" + workspaceType;
            if (projectWorkspace.appType === "project" && (projectWorkspace.status === "DELETED" || projectWorkspace.workspacdDeleted === true)) {
            } else {
                if ( (taskType === DICOM_SEGMENTATION) || (taskType === DICOM_CLASSIFICATION)) {
                    queryParam = queryParam + "&projectType=" + searchType + "&currentwPage=1&currentpPage=1" + "&isFilter=" + isFilter;
                    this.props.pushState('/dicomprojects/' + orgName + '/' + projectName + '/' + projectId + queryParam);
                } else {
                    queryParam = queryParam + "&projectType=" + searchType + "&currentwPage=1&currentpPage=1" + "&isFilter=" + isFilter;
                    this.props.pushState('/projects/' + orgName + '/' + projectName + '/' + projectId + queryParam);
                }
            }
        }
    }
    openInviteModal(data, event) {
        event.preventDefault();
        logEvent("buttons", "Open invite modal");
        this.setState({ inviteModal: true, pid: data});
        document.body.classList.add('c-modal');
    }
    openModal() {
        document.body.classList.add('c-modal');
        this.setState({ inviteModal: true });
    }
    closeModal() {
        document.body.classList.remove('c-modal');
        this.setState({ inviteModal: false });
    }
    inviteByEmail = (email, isAdmin) => {
        logEvent("buttons", "Sending invite");
        if (email.length > 0) {
          this.setState({isLoading: true})
          addContributor(this.state.pid, email, isAdmin, this.inviteSent)
        }
    }
    inviteSent = (error, response) => {
        if (!error) {
          logEvent("buttons", "Invite sent success");
          showAlert("Contributors are successfully added to the project.", "success")
          this.setState({isLoading: false});
        } else {
          logEvent("buttons", "Invite sent fail");
          this.setState({ inviteModal: false, error: true});
        }
    }
    deleteSingleRow = (deleteRec) => {
        const that = this
        const confirm = AntModal.confirm;
        const content = `Are you sure you want to archive ${deleteRec.appType} '${deleteRec.name}'?`
        confirm({
            title: 'Confirmation',
            content: content,
            okText: 'OK',
            cancelText: 'CANCEL',
            onOk() {that.archiveSingleProjectWorkSpace(deleteRec , true);},
              onCancel() {that.cancelaarchiveSingleWorkSpace();},
          });
    }
  recoverySingleRow = (deleteRec) => {
    const that = this
    const confirm = AntModal.confirm;
    const content = `Are you sure you want to recover  ${deleteRec.appType} '${deleteRec.name}'?`
    confirm({
      title: 'Confirmation',
      content: content,
      okText: 'OK',
      cancelText: 'CANCEL',
      onOk() {that.archiveSingleProjectWorkSpace(deleteRec , false);},
      onCancel() {that.cancelaarchiveSingleWorkSpace();},
    });
  }
    cancelaarchiveSingleWorkSpace = () => {

    }
    cancelarchiveworkspace = () =>{
        this.setState({selectedRows: [], selectedRowKeys: []})
    }

    archiveSingleProjectWorkSpace = (deleteRec , isArchive) =>{
      const {orgId,pagecount,sizecount} = this.state
        this.setState({isArchive: isArchive})
        let deleteRecList =  [{"id": deleteRec.id, "type": deleteRec.appType}] //: [{"id": deleteRec.appType === "workspace" ? deleteRec.workspace.id : deleteRec.project.id, "type": deleteRec.appType}]
        this.props.archiveProjectWorkspaceProcess(deleteRecList, orgId, pagecount, sizecount, this.props.location.query.query, this.props.location.query.type,isArchive)
        this.setState({ selectedRows: [], selectedRowKeys: []})
    }
    archiveMultiProjectWorkspace = async (buttonType) => {
      const {selectedRows,orgId,pagecount,sizecount} = this.state
        let deleteRecList = []
        if(buttonType === "Archive"){
        	deleteRecList = selectedRows.filter((row)=>
                					!((row.projectWorkspace.appType === "workspace" &&
                							row.projectWorkspace.archived === true) ||
                							(row.projectWorkspace.appType === "project" &&
                									(row.projectWorkspace.status === "DELETED" ||
                											row.projectWorkspace.ownerId !== getUidToken().uid))))
                											.map(filteredRow => ({
                												id: filteredRow.key,
                												type: filteredRow.projectWorkspace.appType
                											}))
        }else{
        	deleteRecList = selectedRows.filter((row)=>
                					((row.projectWorkspace.appType === "workspace" &&
                							row.projectWorkspace.archived === true) ||
                							(row.projectWorkspace.appType === "project" &&
                									(row.projectWorkspace.status === "DELETED" ||
                											row.projectWorkspace.ownerId !== getUidToken().uid))))
                											.map(filteredRow => ({
                												id: filteredRow.key,
                												type: filteredRow.projectWorkspace.appType
                											}))
        }

        await this.props.archiveProjectWorkspaceProcess(deleteRecList, orgId, pagecount, sizecount, this.props.location.query.query, this.props.location.query.type,buttonType === "Archive" ? true : false)
        this.setState({selectedRows: [], selectedRowKeys: []})
    }

    archiveMultiProjectWorkspaceCheckForArchive = (buttonType) => {
      const {selectedRows} = this.state
    	let deleteRecList = [];
    	this.setState({selectedButtonType : buttonType})
    	if(buttonType === "Archive"){
    		deleteRecList = selectedRows.filter((row)=>
            	((row.projectWorkspace.appType === "workspace" &&
            			row.projectWorkspace.archived === true) ||
            			(row.projectWorkspace.appType === "project" &&
            					(row.projectWorkspace.status === "DELETED" ||
            							row.projectWorkspace.ownerId !== getUidToken().uid))))
    	}else{
    		deleteRecList = selectedRows.filter((row)=>
        	((row.projectWorkspace.appType === "workspace" &&
        			row.projectWorkspace.archived === false) ||
        			(row.projectWorkspace.appType === "project" &&
        					(row.projectWorkspace.status !== "DELETED" ||
        							row.projectWorkspace.ownerId !== getUidToken().uid))))
    	}


            if(deleteRecList && deleteRecList.length > 0){
            	if(buttonType === "Archive")
                {
                	showAlert("Cannot archive the Project(s) or Workspace(s) which is already archived. Please check your selections", 'error')
                }
            	else
                {
                	showAlert("Cannot recover the Project(s) or Workspace(s) which is not archived. Please check your selections", 'error')
                }
            }else
            	this.archiveSelectedWorkspace(buttonType);
    }

    archiveSelectedWorkspace = (buttonType) => {
        if (this.state.selectedRows.length > 0) {
          const that = this
          const confirm = AntModal.confirm;
          const content = buttonType === "Archive" ? 'Are you sure you want to archive selected items ?' : 'Are you sure you want to recover selected items ?';
           confirm({
               title: 'Confirmation',
               content: content,
               okText: 'OK',
               cancelText: 'CANCEL',
               onOk() {that.archiveMultiProjectWorkspace(buttonType);},
                onCancel() {that.cancelarchiveworkspace();},
             });
        } else {
          showAlert("Please select at least one item.", 'warning')
        }
    }

    downloadJSON = () => {
      const {selectedRows} = this.state
        let selectedList = []
        if (selectedRows.length > 0) {
            selectedList = selectedRows.filter((row) =>
                ((row.projectWorkspace.appType === "workspace") ||
                (row.projectWorkspace.appType === "project" &&
                    row.projectWorkspace.status === "DELETED")))
                .map(filteredRow => ({
                    id: filteredRow.key
                }))
            if (selectedList.length > 0)
            {

                showAlert("Workspace Or Deleted Projects are not allowed to download. Please check your selection", 'error')
            }
            else
                this.downloadJSONWIthProjects();
        } else
        {

            showAlert("Please select at least one project.", 'warning')
        }
    }

    handleChange = (event) => {
        console.log('handle change', event, event.target.value);
        this.setState({ itemOption: event.target.value});
        if (event.target.value === "ALL") {
          this.setState({ fromDate: null, fromTime: null, toDate: null, toTime: null });
        }
      }

      isShowDateFilter = (taskType) =>{
        if (taskType === IMAGE_CLASSIFICATION  || taskType === IMAGE_BOUNDING_BOX || taskType === IMAGE_POLYGON_BOUNDING_BOX
          || taskType === IMAGE_POLYGON_BOUNDING_BOX_V2  || taskType === IMAGE_SEGMENTATION || taskType === DOCUMENT_ANNOTATION  || taskType === DICOM_SEGMENTATION
          ) {
            if (taskType === DOCUMENT_ANNOTATION && this.state.downloadFormat !== 'json') {
              return false;
            }
            return true;
          }
          return false;
      }

      setSelectedFromDate = (date, dateString) =>{
        this.setState({ fromDate: dateString });
      }

      setSelectedToDate = (date, dateString) =>{
        this.setState({ toDate: dateString });
      }

      fromTimeChange = (time) =>{
         this.setState({ fromTime: time });
      }

      toTimeChange = (time) =>{
        this.setState({ toTime: time });
      }

      handleJsonChange = (event) => {
        this.setState({ downloadFormat: event.target.value })
      }

      showvalidationMessage = (message) => {
        this.setState({ loading: false });
        showAlert(message, 'error');
      }

    validateDateSelection = () => {
      const {itemOption,fromDate,toDate,fromTime,toTime} = this.state
      if (itemOption === 'TAGGED') {
            if (fromDate !== null && fromDate.length > 0
                && toDate !== null && toDate.length > 0) {
                if (fromDate >= toDate) {
                    if (fromDate === toDate) {
                        if (fromTime > toTime) {
                            this.showvalidationMessage("Please select valid date range");
                            return false;
                        }
                    }
                    else if (fromDate > toDate) {
                        this.showvalidationMessage("Please select valid date range");
                        return false;
                    }
                }
            }
        }
        return true;
    }

    addTimeToDate = (dateInput, timeInput, isFromDate) => {
        if (null !== dateInput) {
            dateInput = new Date(dateInput);
            if (undefined !== timeInput && null !== timeInput) {
                dateInput.setHours(timeInput.hour())
                dateInput.setMinutes(timeInput.minutes())
                dateInput.setSeconds((isFromDate) ? 0 : 59);
                dateInput = dateInput.getTime();
            }
            else {
                if (isFromDate) {
                    dateInput = fromDateTimestamp(dateInput)
                }
                else {
                    dateInput = toDateTimestamp(dateInput)
                }
            }
        }
        return dateInput;
    }

    getDateFilterParam = () => {
        let param = '';
        let fromDateStr = '';
        let toDateStr = '';
        const {itemOption,fromDate,toDate,fromTime,toTime} = this.state
        if (itemOption === 'TAGGED' || itemOption === 'NONTAGGED') {
            if (undefined !== fromDate && null !== fromDate && fromDate.length > 0) {
              fromDateStr = this.addTimeToDate(fromDate, fromTime, true)
                param += "&fromDate=" + fromDateStr;
            }
            if (undefined !== toDate && null !== toDate && toDate.length > 0) {
              toDateStr = this.addTimeToDate(toDate, toTime, false)
                param += "&toDate=" + toDateStr;
            }
            console.log(param);
        }
        return param;
    }

      downloadFile = () => {
        console.log('downloadfile ', this.state);
        const {downloadProjectIds,itemOption,downloadFormat,searchType} = this.state
        this.props.setloaderMenu(true);
        this.setState({ loading: true });
        if (this.validateDateSelection()) {
          downloadProjectsSets(downloadProjectIds, itemOption, this.downloadCallback, downloadFormat, this.getDateFilterParam(), searchType);
        }
      }

      downloadCallback = (error, response) => {
        console.log(' download call back ', error, response, this.state);
        if (!error) {
          this.props.setloaderMenu(false);
          this.setState({ loading: false });
          FileSaver.saveAs(response.xhr.response, this.state.searchType + '.zip');
        } else {
          showAlert(response.body.message, 'error');
        }
      }

    downloadJSONWIthProjects = () => {
      const {selectedRows} = this.state
        let selectedList = []
        if (selectedRows.length > 0) {
            selectedList = selectedRows.filter((row) =>
                (row.projectWorkspace.appType === "project" &&
                    row.projectWorkspace.status !== "DELETED"))
                .map(filteredRow => ({
                    id: filteredRow.key
                }))

            if (this.props.location && this.props.location.query) {
                this.setState({ searchType: this.props.location.query.type })
            }

        this.setState({exportVisible: true, downloadProjectIds: selectedList})
        }
    }

    handleCancel = () => {
        this.setState({exportVisible: false})
    }

    showDownloadModal = () => {
      const {exportVisible} = this.state
        return (
            <div className="text-center">
              {exportVisible && (
                <LocaleProvider locale={en_US}>
                  <AntModal className="s_model"
                    title="Export Data"
                    visible={exportVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[]}
                  >
                    {this.downloadJsonContents()}
                  </AntModal>
                </LocaleProvider>
              )}
            </div>
          );
    }

    downloadJsonContents = () => {
        const { itemOption, downloadFormat,loading,searchType,fromDate,toDate } = this.state;
        const format = 'hh:mm A';
        return (
            <div className="text-center">
              <Form size="small" className="box_fix" key="import1" loading={loading} compact >
                    <Radio.Group inline style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: '20px' }} onChange={e => this.handleChange(e)} value = {itemOption}>
                      <Radio value={"ALL"} checked={itemOption === 'ALL'} >All Items</Radio>
                      <Radio  value={"TAGGED"} checked={itemOption === 'TAGGED'}>Completed Items</Radio>
                      <Radio  value={"NONTAGGED"} checked={itemOption === 'NONTAGGED'}>Not Done Items</Radio>
                    </Radio.Group>
                    {(itemOption === 'TAGGED' || itemOption === 'NONTAGGED') && searchType && this.isShowDateFilter(searchType) &&
                    <LocaleProvider locale={en_US}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                          <DatePicker className="date_time_select" placeholder="From Date" onChange={this.setSelectedFromDate} disabledDate={this.disabledDate}/>
                          { fromDate &&
                            <TimePicker className="date_time_select" placeholder="Select Time" onChange={this.fromTimeChange} format={format} use12Hours={true} defaultOpenValue={moment('00:00', format)} />
                          }

                          <DatePicker className="date_time_select" placeholder="To Date" onChange={this.setSelectedToDate} disabledDate={this.disabledDate}/>
                          { toDate &&
                          <TimePicker placeholder="Select Time" onChange={this.toTimeChange} format={format} use12Hours={true} defaultOpenValue={moment('00:00', format)}/>
                          }

                      </div>
                    </LocaleProvider>
                    }
                    {searchType && searchType === DICOM_SEGMENTATION &&
                      <Radio.Group inline style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} onChange={e => this.handleJsonChange(e)} value = {downloadFormat}>
                        <Radio value={"json"} checked={downloadFormat === 'json'}  >Normal json</Radio>
                        <Radio value={"DICOM_JSON"} checked={downloadFormat === 'DICOM_JSON'}>DICOM json</Radio>
                      </Radio.Group>
                    }

                <br />
                { searchType && searchType === IMAGE_CLASSIFICATION &&
                <p className="export_data_text">Download file would be a text file where each line is a JSON containing the image URL and the classes marked for the image.</p>
                }
                { searchType && ( searchType === IMAGE_BOUNDING_BOX) &&
                <div>
                <p className="export_data_text">Download file would be a text file where each line is a JSON containing the image URL and the coordinates of the bounded objects in the image</p>
                </div>
                }
                { searchType && (searchType === IMAGE_POLYGON_BOUNDING_BOX || searchType === IMAGE_POLYGON_BOUNDING_BOX_V2 || searchType === IMAGE_SEGMENTATION ) &&
                <p className="export_data_text">Download file would be a text file where each line is a JSON containing the image URL and the coordinates of the bounded objects in the image</p>
                }
                { searchType && ( searchType === DOCUMENT_ANNOTATION || searchType === POS_TAGGING_GENERIC ) &&
                <p className="export_data_text">Download file would be a text file where each line is a JSON containing the selected text, start index, end index and marked category</p>
                }
                { searchType && searchType === TEXT_SUMMARIZATION &&
                <p className="export_data_text">Download file would be a tab seperated file with input in first column and output row in second column.</p>
                }
                { searchType && searchType === TEXT_MODERATION &&
                <p className="export_data_text">Download file would be a tab seperated file with input in first column and output row in second column.</p>
                }
                { searchType && searchType === POS_TAGGING &&
                <p className="export_data_text">Download file would be a text file where each line is a JSON containing the input text and annotated text.</p>
                }
                { searchType && searchType === TEXT_CLASSIFICATION &&
                <p className="export_data_text">Download file would be a text file where each line is a JSON containing the input text, associated label and notes.</p>
                }
                {
                  searchType && (searchType === POS_TAGGING || searchType === DOCUMENT_ANNOTATION || searchType === POS_TAGGING_GENERIC) &&
                  <div>
                    <Radio.Group inline style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} onChange={e => this.handleJsonChange(e)} value = {downloadFormat}>
                      <Radio value="json" checked={downloadFormat === 'json'}>Json Format</Radio>
                    </ Radio.Group>
                  </div>
                }
                <br />
                <AntButton primary size="mini" className="btn_download_custom btn_base btn_succes_recovers m-l-5" onClick={this.downloadFile}>
                  Download
                </AntButton>
                <br />
                <br />
                <br />
              </Form>
              </div>
        )
    }

    render() {
        console.log("Result props",this.props)
        const {selectedButtonType,inviteModal,orgId,searchTerm,searchType,selectedRows,isArchive } = this.state
        const {projectWorkspaceList} = this.props
            if (this.props.archivedSuccess) {
            showAlert(isArchive ? "Moved to Archive successfully." : (selectedButtonType && selectedButtonType === "Archive") ? "The selected workspace(s) or projects(s) archived successfully."
            		: (selectedButtonType && selectedButtonType === "Recover") ? "The selected workspace(s) or projects(s) recovered successfully."
            		: "The selected workspace(s) recovered successfully."
            		, "success")
            this.props.setArchiveStatus()
            this.setState({selectedButtonType : ''})
        }

        return (
            <div className="taggerPages">
                <Helmet title="My WorkSpace(s)" />
                    <div className="t_workspace m-b-15 m-t-20">
                        <div className="archPlaceFix">
                        <div className="search_h w-45">
                            <Search/>
                        </div>
                        { projectWorkspaceList &&
                            <div className="sarc_btn">
                                {selectedRows.length > 0 && <div className="m-r-5" ><span style={{fontWeight: '600', color:'#12c4fc'}}>{selectedRows.length}</span> selected</div>}
                                {(searchType ||  searchTerm) && 
                                    <AntButton className="btn_base btn_succes_recovers m-l-5"  title="Download" onClick={() => this.downloadJSON()}>
                                        <Icon type="download"/> Download
                                    </AntButton>
                                }
                                  <AntButton className="btn_base btn_danger m-l-5"  type="danger"  title="Archive" onClick={() => this.archiveMultiProjectWorkspaceCheckForArchive("Archive")}>
                                      <Icon type="inbox"/> Archive
                                  </AntButton>
                                  <AntButton className="btn_base btn_succes_recovers m-l-5"   title="Recover" onClick={() => this.archiveMultiProjectWorkspaceCheckForArchive("Recover")}>
                                      <Icon type="rollback"/> Recover
                                  </AntButton>
                            </div>
                        }
                        </div>
                       <div className="f-center f_between w-100 m-t-10 m-b-10">
                        <h3 className="fw-500">Search Results</h3>
                        </div>
                    </div>
                    {this.props.loading === true ? <div className="overlay_dom" >
          <div className="cssload-wraper">
            <div className="cssload-dots"></div>
          </div>
        </div> : this.getProjectWorkSpaceDetails()}
        {this.showDownloadModal()}
        {inviteModal &&
                    <ShareProjects
                    submitEmail={this.inviteByEmail}
                    modalOpen={this.openModal}
                    orgId = {orgId}
                    modalClose={this.closeModal}
                />       
        }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    console.log("search Result state",state)
  return {
    loading: state.searchReducer.loading,
    archivedSuccess: state.searchReducer.archivedSuccess,
    projectWorkspaceList: state.searchReducer.searchResult ? state.searchReducer.searchResult.projectWorkspaceList : null,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
      pushState: push,
      getSearchProjectWorkspace,
      archiveProjectWorkspaceProcess,
      setArchiveStatus,
      reset,
      setloaderMenu,
     },
  dispatch
)

export default connect(mapStateToProps, mapDispatchToProps) (SearchResult);
