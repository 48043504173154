import React, {Component, PropTypes} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { getOrgId,showAlert,getUidToken } from '../../helpers/commonFunctions';
import { Form,  Message} from 'semantic-ui-react';
import { Button as AntButton } from 'antd';
import { createNewUserByAdmin,createNewUserBySuperAdmin,getUsersById,updateUserByAdmin,
    getAllOrganizationsWithOutPagination,getOrgDetailsForAdmin,getAdminsByOrgId } from '../../Services/usersApis';
import { push, replace } from 'react-router-redux';
import { setloaderMenu } from '../../redux/reducer/projectReducer';
import { validEmail,RoleOptions } from '../../helpers/Utils';
import {SUCCESS, USER_UPDATED_SUCCESS, ERROR} from '../../helpers/Constants'
import BACK from '../../images/Arrow_Simple_left1.svg';
class UsersAdd extends Component {
  static propTypes = {
    user: PropTypes.object,
    login: PropTypes.func,
    logout: PropTypes.func,
    pushState: PropTypes.func,
    replaceState: PropTypes.func
  }

  static userPropTypes = {
    firstName: PropTypes.string.isRequired,
    secondName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }

  constructor(props) {
   super(props);
   this.createNewuser=this.createNewuser.bind(this);
   this.makeCreateNewUser = this.makeCreateNewUser.bind(this)
   let search = window.location.search;
   let params = new URLSearchParams(search);
   let currentPage = params.get('currentpage');
  this.state={
   currentpage:currentPage?currentPage:1,
   activeMenu: 'users',
   firstName: '',
   secondName: '',
   email: '',
   role: this.props.params.roleIdCheck ? toString(this.props.params.roleIdCheck) : "0",
   orgName: "0",
   orgId: "0",
   managerId: null,
   userId: null,
   error: null,
   success: null,
   profileLink: null,
   profilePic: null,
   status: '',
   notificationToken: null,
   notes: null,
   mAuthorized: false,
   passwordChanged: false,
   deleted: false,
   approved: true,
   oauthId: null,
   oauthType: null,
   orgData: [],
   orgOptions: [],
   defaultOrgOptions: null,
   adminUserOptions: [],
   isDisabled: false,
   orgRefId: null,
   orgChangeSuccess: false
}
  }
getAllOrganizationCallBack(error,response) {
  const {orgOptions,status,role,approved,deleted} = this.state
	if(!error) {
    this.props.setloaderMenu(false);
    var data = response.body;
    orgOptions.push({
     key:"0",
     text:"Select Organization",
     value:"0"
  });
  this.setState({defaultOrgOptions: orgOptions[0].value});
  if (getUidToken().roleId==1 || getUidToken().roleId==4) {
    for (let index = 0; index < data.length; index++) {

        orgOptions.push({
          key: data[index].name,
          text: data[index].name,
          value: data[index].id
        });
    }
    if (getUidToken().roleId == 1) {
      const orgsDetails = orgOptions.filter(x => x.value === getUidToken().orgId);
      this.setState({ orgOptions: orgsDetails, orgId: orgsDetails[0].value, orgRefId: orgsDetails[0].value });
      getAdminsByOrgId(orgsDetails[0].value,this.getOrganizationUsersCallback.bind(this));
    }
  }else {
      orgOptions.push({
        key:data.name,
        text:data.name,
        value:data.id
      });
      this.setState({ defaultOrgOptions: orgOptions[1].value});
  }

  if(getUidToken().roleId==1 || getUidToken().roleId==4){
	  if(this.props.params.uId != undefined){
		  getOrgDetailsForAdmin(this.props.params.uId, this.getAdminOrgDetailsCallBack.bind(this));
	  }

    } else{
      this.setState({orgId:response.body.id});
      this.setState({role : "3"});
    }
   }
  //To Select Status by default
  const role_id_check = this.props.params.roleIdCheck >= 1 &&
  status ? 3 : parseInt(role) > 1 &&
  approved && !deleted ? 1 : deleted ? 2 : !deleted && !approved ? 0 : 3 ;
  this.setState({status : role_id_check });
  //To Select Role by default
  if(this.props.params.roleIdCheck ){
    const role_numb1 = this.props.params.roleIdCheck == 1 &&
    role ? '1': this.props.params.roleIdCheck == 2 ? '2' : this.props.params.roleIdCheck == 3 ? '3' : this.props.params.roleIdCheck == 5 ? '5' : this.props.params.roleIdCheck == 6 ? '6' : 0;
    this.setState({role : role_numb1 });
  }
}

	getAdminOrgDetailsCallBack(error,response){
		  if(!error){
			  this.setState({orgId: response.body.id});

		  }else{
			  this.setState({orgId:"0"});
		  }
	}

  handleRoleChange = (e, {value}) => {
    if ( parseInt(getUidToken().roleId ) === 1 || parseInt(this.state.roleId) === 1){
      if(value == '3' || value == '5' || value == '6'){
        getAdminsByOrgId( getOrgId(),this.getOrganizationUsersCallback.bind(this));
      }
    }
    this.setState({role: value});
  }

  handleStatusChange = (e, {value}) => {
    console.log(value);
    this.setState({status: value});
  }

  handleOrgChange = (e, {value}) => {
    const {roleId} = this.state
    this.setState({orgId: value, orgRefId: value});
   
	if ( parseInt(getUidToken().roleId ) === 1 || parseInt(roleId) === 1 ||  parseInt(getUidToken().roleId ) === 4 || parseInt(roleId) === 4) {
    	 getAdminsByOrgId(value,this.getOrganizationUsersCallback.bind(this));
    }
  }

  handleManagerChange = (e, {value}) => {
    this.setState({managerId:value});
  }

  getOrganizationUsersCallback(error,response) {
    const { adminUserOptions,role } = this.state
    this.state.adminUserOptions = [];
    this.setState({orgChangeSuccess:false});
    if(!error) {
      adminUserOptions.push({
        key:"0",
        text:"Select Admin User",
         value:"0"
       });

       let data = response.body.userInfos;
       console.log(data);
       for (let index = 0; index < data.length; index++) {

        adminUserOptions.push({
          key:data[index].id,
          text:data[index].firstName + ' '+ data[index].secondName,
          value:data[index].id
        });
      }
      console.log("User OPrions : ", adminUserOptions)

    if(role == '3' || role == '5' || role == '6' ){
        this.setState({orgChangeSuccess:true});
      }
    }else {
      showAlert('error', 'error');
    }
  }


  handleSubmit(event) {
    showAlert('A name was submitted: ' + this.state.value, 'info');
    event.preventDefault();
  }

  handleUserFieldChange = (event)=>{
    console.log('Sims handleCreateChange ',event,  event.target.name, event.target.value);

    if(event.target.name=="firstName"){
        this.setState({firstName:event.target.value});

    }else if(event.target.name=="lastName"){
        this.setState({secondName:event.target.value});
    }else if(event.target.name=="orgName"){
        this.setState({orgName:event.target.value});
    }else if(event.target.name=="email"){
        this.setState({email:event.target.value});
    }else if(event.target.name=="role"){
        this.setState({role:event.target.value});
    }else if(event.target.name=="status"){
        this.setState({status:event.target.value});
    }else if(event.target.name=="managerId"){
      console.log("managerId Event binding", event.target.value);
      this.setState({managerId:event.target.value});
  }
  }
  isGreaterThanZero =(value)=>{
    if(null === value || undefined === value)
      return false;
    try{
      if(parseInt(value)>0)
        return true;
    }catch(e){

    }
    return false;
  }
  validateUserFields = () => {
    const { firstName,secondName,email,role,orgId,status,managerId } = this.state
    var validationMessageHeader="Required Fields: ";
    var validationMessage="";
    if (firstName.length === 0) {
      validationMessage +=( 'First Name, ' );
    }
    if (secondName.length === 0) {
      validationMessage +=( 'Last Name, ' );
    }
    if (role ==="0") {
      validationMessage +=( 'Role, ' );
    }
    if (email.length === 0 ) {
      validationMessage +=( 'Email Address, ' );
    }
    if (email && !validEmail(email)) {
      validationMessage +=( 'Valid Email Address, ' );
    }
    if (role === '4' && !this.isGreaterThanZero(orgId)) {
      validationMessage +=( 'Organization, ' );
    }
    if(role === '3' && managerId === "0"){
        validationMessage +=( 'Admin user, ' );
    }
    if (status === 3) {
      validationMessage +=( 'Status, ' );
    }

    if(validationMessage === ""){
      return true;
    }
    else{
      validationMessage = validationMessageHeader + validationMessage.substr(0,validationMessage.length-2)+"";
      showAlert(validationMessage, 'error');
    }
    return false;
  }
  createNewuser(event){
    const {role,managerId} = this.state
      console.log("CreateNew User", event, this.email, this.firstName);
      console.log("role", role);
      if( parseInt( getUidToken().roleId ) === 2 && managerId === "0" ) {
        this.setState({managerId: getUidToken().uid}, this.makeCreateNewUser);
      }else{
        this.makeCreateNewUser();
      }

  }
  makeCreateNewUser(){
    const { firstName,secondName,email,role,orgName,orgId,status,managerId } = this.state

    if(this.validateUserFields()) {
      this.props.setloaderMenu(true);
      if(orgId == 0)
      {
      this.state.orgId=getOrgId();
      (getUidToken().roleId==1 || getUidToken().roleId==4)?
      createNewUserBySuperAdmin(firstName, secondName, email, orgName, orgId, role, managerId, status, this.dtCreateUserByAdminCallback.bind(this))
      :
      createNewUserByAdmin(firstName, secondName, email, orgName, orgId, role, managerId,status, this.dtCreateUserByAdminCallback.bind(this));
      }
      else{
      (getUidToken().roleId==1 || getUidToken().roleId==4)?
      createNewUserBySuperAdmin(firstName, secondName, email, orgName, orgId, role, managerId, status, this.dtCreateUserByAdminCallback.bind(this))
      :
      createNewUserByAdmin(firstName, secondName, email, orgName, orgId, role, managerId,status, this.dtCreateUserByAdminCallback.bind(this));
      }
    }
  }

  dtCreateUserByAdminCallback(error, response) {
    if (!error) {
      this.setState({ firstName:'', secondName:'', email:'', role:'2', orgId:'0', success:'User created successfully', error:undefined });
      this.props.setloaderMenu(false);
      showAlert('User created successfully.');
      this.props.replaceState('/projects/users');
    } else {
     if(response.statusCode==400){
       this.props.setloaderMenu(false);
      showAlert(response.body.message, 'error');
     }else{
       this.props.setloaderMenu(false);
      showAlert(error, 'error');
     }

    }
  }

  componentWillMount() {
    if (!getUidToken().uid) {
      this.props.pushState('/projects/login');
    }
    
    if(this.props.params.uId != undefined) {
      getUsersById(this.props.params.uId,this.dtgetUsersByIdCallback.bind(this));
    }else{
      console.log("Sims Log123", parseInt(getUidToken().roleId));
      if( parseInt(getUidToken().roleId)==2){
        console.log("Sims Log1234",this.props.user);
        this.setState({isDisabled: true});
       getOrgDetailsForAdmin(getUidToken().userId, this.dtgetOrgDetailsForAdmin.bind(this))
      }else{
        this.setState({isDisabled: false});
      }
    }
    (getUidToken().roleId==1 || getUidToken().roleId==4||getUidToken().roleId==2)?
    getAllOrganizationsWithOutPagination(this.getAllOrganizationCallBack.bind(this))
    :
    getOrgDetailsForAdmin(getUidToken().uid, this.getAllOrganizationCallBack.bind(this))

  }

  dtgetOrgDetailsForAdmin(error, response) {
    console.log('dtgetOrgDetailsForAdminCallback', error, response);
    if (!error) {
      console.log('dtgetOrgDetailsForAdminCallback', error, response);
      this.props.setloaderMenu(false);
      this.setState({ orgId: String(response.body.id) });

    } else {
      this.setState({isDisabled: false})
      this.props.setloaderMenu(false);
    }
  }

  dtgetUsersByIdCallback(error,response) {
    const {role,orgId} = this.state
    if(!error) {
      this.setState({
        firstName:response.body.firstName,
        orgName: response.body.orgName,
        secondName:response.body.secondName,
        email:response.body.email,
        orgId: String(response.body.organizationId),
        orgRefId: String(response.body.organizationId),
        role: String(response.body.roleId),
        userId: response.body.id,
        profileLink:response.body.profileLink,
        profilePic:response.body.profilePic,
        status:response.body.status,
        notificationToken:response.body.notificationToken,
        notes:response.body.notes,
        mAuthorized:response.body.mAuthorized,
        managerId:response.body.managerId,
        passwordChanged:response.body.passwordChanged,
        deleted:response.body.deleted,
        approved:response.body.approved,
        oauthId:response.body.oauthId,
        oauthType:response.body.oauthType
      });

      if(getUidToken().roleId == 1 || getUidToken().roleId == 4){
		  this.setState({ orgId: String(response.body.organizationId)});
    	  if(role == '3' || role == '5' || role == '6'){
        	  this.setState({orgChangeSuccess:true});
        	  this.setState({ managerId: response.body.managerId});
        	  getAdminsByOrgId(orgId,this.getOrganizationUsersCallback.bind(this));
        	  console.log("after getAdminsByOrgId call");
        	  this.setState({isDisabled: true});
    	  }
      }

    }
    this.props.setloaderMenu(false);
  }

  updateUser(event){
    const {status} = this.state
    if(this.validateUserFields()) {
      if(status === 1 ){//Approved
        this.setState({approved:true, deleted:false},() => {
          this.updateUserDetailsData();
        })
      }
      if(status === 2){//Inactive
        this.setState({deleted:true},() => {
          this.updateUserDetailsData();
      })
    }
    if(status === 0){//Pending
      this.setState({approved:false, deleted:false},() => {
        this.updateUserDetailsData();
    })
  }
  }
}

updateUserDetailsData() {
  const {firstName,secondName,email,role,profileLink,userId,status,notificationToken,notes,mAuthorized,passwordChanged,managerId,deleted,approved, oauthId, oauthType, orgRefId} = this.state
  this.props.setloaderMenu(true);
      (getUidToken().roleId==1 || getUidToken().roleId==4)?
      updateUserByAdmin(firstName, secondName, email, role, userId,profileLink, this.profilePic,status,notificationToken,notes,mAuthorized, passwordChanged, managerId, deleted,approved, oauthId, oauthType, orgRefId, this.dtUpdateUserByAdminCallback.bind(this))
      :
      updateUserByAdmin(firstName, secondName, email, role, userId,profileLink, this.profilePic,status,notificationToken,notes,mAuthorized, passwordChanged, managerId, deleted,approved, oauthId, oauthType, null, this.dtUpdateUserByAdminCallback.bind(this))
}

  dtUpdateUserByAdminCallback(error, response) {
    this.props.setloaderMenu(false);
    if (!error) {
      this.setState({  firstName:'', secondName:'', email:'', role:'0', orgId:'0', success: USER_UPDATED_SUCCESS, error:undefined });
      this.props.replaceState('/projects/users');
      showAlert(USER_UPDATED_SUCCESS, SUCCESS);
    } else {
      showAlert(error, ERROR);
    }
  }

  backToUsers(event) {
    this.props.replaceState('/projects/users'+'?currentpage='+this.state.currentpage);
  }


  render() {
    const {approved,deleted,email,orgId,firstName,secondName,orgOptions,orgChangeSuccess,role,managerId,adminUserOptions,status,userId,error,success} = this.state
    let lRoleId=getUidToken().roleId;
    const options = [
        { key: '0', text: 'Select', value: '0' },
      { key: 'c', text: 'Capestart', value: '2' },
      { key: 'd', text: 'DataTurks', value: '1' },
    ]
    let roleOptions = [
        { key: '0', text: 'Select Role', value: '0' },
        { key: '1', text: 'Super Admin', value: '1' },
        { key: '2', text: 'Admin', value: '2' },
        { key: '3', text: 'Annotator', value: '3' },
        { key: '5', text: 'Guest', value: '5' },
        { key: '6', text: 'QA', value: '6' }
      ]
    lRoleId == 4 ?
      roleOptions = [
        { key: '0', text: 'Select Role', value: '0' },
        { key: '1', text: 'Super Admin', value: '1' },
        { key: '2', text: 'Admin', value: '2' },
        { key: '3', text: 'Annotator', value: '3' },
        { key: '5', text: 'Guest', value: '5' },
        { key: '6', text: 'QA', value: '6' }
      ]
      : lRoleId == 1 ?
        roleOptions = [
          { key: '0', text: 'Select Role', value: '0' },
          { key: '1', text: 'Super Admin', value: '1' },
          { key: '2', text: 'Admin', value: '2' },
          { key: '3', text: 'Annotator', value: '3' },
          { key: '5', text: 'Guest', value: '5' },
          { key: '6', text: 'QA', value: '6' }
        ] :
        roleOptions = [
          { key: '0', text: 'Select Role', value: '0' },
          { key: '3', text: 'Annotator', value: '3' },
          { key: '5', text: 'Guest', value: '5' },
         { key: '6', text: 'QA', value: '6' }
        ]
    let statusOptions = [];
    if ((approved && !deleted) || (deleted)) {
      statusOptions = [
        { key: 1, text: 'Approved', value: 1 },
        { key: 2, text: 'Inactive', value: 2 },
        { key: 3, text: 'Select Status', value: 3 },
      ]
    }
    else if (!deleted && !approved) {
       statusOptions = [
        { key: 0, text: 'Pending', value: 0 },
        { key: 1, text: 'Approved', value: 1 },
        { key: 2, text: 'Inactive', value: 2 },
        { key: 3, text: 'Select Status', value: 3 },
      ]
    }
    return (
      <div style={{paddingLeft: '3%', paddingRight: '3%',marginLeft:'3%'}} className="m-t-20">
       <div className="title_section m-b-10 f-center">
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>    
                  <div className="f-column">
                    <div className="f-center">
                         <a className="m-r-5">
                          <span className="t_btn_back" onClick={this.backToUsers.bind(this)}>
                            <img alt='back' src={BACK} />
                          </span>
                        </a>
                    </div>
                    <div className="breadcrum-sec">
                      <div className="sec_holder">
                        <span className="tilte_display">
                        {userId ?
                          <span>Edit User</span>
                          :
                          <span>Add User</span>
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="project_set"></div>
                </div>
      </div>
      <div className="user_form">
      <Form>
      <Form.Group widths='equal' className="form_holder taggercontainer">
        <div> <label style={{color: "black"}}>First Name </label> <label style={{color: "red"}}>*</label></div>
        <Form.Input fluid  name="firstName" value={firstName} onChange={this.handleUserFieldChange.bind(this)} placeholder='First Name' />
        <div> <label style={{color: "black"}}>Last Name </label> <label style={{color: "red"}}>*</label></div>
        <Form.Input fluid  name="lastName" value={secondName} onChange={this.handleUserFieldChange.bind(this)} placeholder='Last Name' />
        <div> <label style={{color: "black"}}>Role </label> <label style={{color: "red"}}>*</label></div>
        <Form.Select fluid name="role" value={role} options={RoleOptions}   onChange={this.handleRoleChange.bind(this)} placeholder='Role' />
        <div> <label style={{color: "black"}}>Email </label> <label style={{color: "red"}}>*</label></div>
        <Form.Input fluid  name="email" value={email} onChange={this.handleUserFieldChange.bind(this)} placeholder='Email' readOnly={userId ? true : false}/>
        {(parseInt(getUidToken().roleId) === 4 || parseInt(getUidToken().roleId) === 1) && <div> <label style={{color: "black"}}>Organization </label> <label style={{color: "red"}}>*</label></div>}
        {(parseInt(getUidToken().roleId) === 4 || parseInt(getUidToken().roleId) === 1) && <Form.Select fluid  name="orgName" value={orgId} onChange={this.handleOrgChange.bind(this)} options={orgOptions} placeholder='Organization' />}
        { ((orgChangeSuccess && (role == '3' || role == '5'|| role == '6')) || (parseInt(getUidToken().roleId) === 1 && role !== '2'))&& <div> <label style={{color: "black"}}>Reporting To </label> <label style={{color: "red"}}>*</label></div>}
        { ((orgChangeSuccess && (role == '3' || role == '5'|| role == '6')) || (parseInt(getUidToken().roleId) === 1 && role !== '2')) &&
         <Form.Select fluid  name="managerId" value={managerId} onChange={this.handleManagerChange.bind(this)} options={adminUserOptions} placeholder='Reporting To' /> }
        <div> <label style={{color: "black"}}>Status </label> <label style={{color: "red"}}>*</label></div>
        <Form.Select fluid  name="status"  onChange={this.handleStatusChange.bind(this)} options = {statusOptions} value = {status}/>
     </Form.Group>
      <div className="text-center model_submit m-t-20">

{!userId?
      <AntButton className="btn_apply h-35" onClick={this.createNewuser.bind(this)}>Submit</AntButton>
      :
      <AntButton  className="btn_apply h-35"  onClick={this.updateUser.bind(this)}>Submit</AntButton>
}
<Message negative className="msg-fix" hidden={!error}>
                              <p>{error}</p>
       </Message>
       <Message positive className="msg-fix" hidden={!success}>
                              <p>{success}</p>
       </Message>
      </div>
    </Form>
    </div>
     </div>

    );
  }
}

const mapStateToProps = (state) => {
    return {
      user: state.loginReducer.user,
    };
  };
  
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        pushState: push, 
        replaceState: replace, 
        setloaderMenu
      },
      dispatch
    );

  export default connect(mapStateToProps, mapDispatchToProps)(UsersAdd);