import { getUidToken } from "../helpers/commonFunctions";
import { middleCallback } from "./commonApis";
import { BASE_URL } from "../helpers/Constants";
const superagent = require("superagent");

export const fetchProjectStats = (pid, callback) => {
  console.log("fetching project stats ", pid);
  const { uid, token } = getUidToken();

  superagent
    .post(BASE_URL + pid + "/getProjectDetails")
    .set("uid", uid)
    .set("token", token)
    .end((err, res) => {
      middleCallback(err, res, callback);
    });
};

export const fetchProjectStatsFIlterbyDate = (pid, hitsCompletedStartDate, hitsCompletedEndDate,selectionType, callback) => {
  console.log("fetching project stats ", pid, hitsCompletedStartDate, hitsCompletedEndDate);
  const { uid, token } = getUidToken();

  superagent
    .post(BASE_URL + pid + "/getProjectDetails")
    .send({ hitsCompletedStartDate: hitsCompletedStartDate, hitsCompletedEndDate: hitsCompletedEndDate, selectionType: selectionType })
    .set("uid", uid)
    .set("token", token)
    .end((err, res) => {
      middleCallback(err, res, callback)
    });
};


export const addContributor = (pid, emailList, isAdmin, callback) => {
  const { uid, token } = getUidToken();
  let role = "CONTRIBUTOR";
  if (isAdmin) {
    role = "ADMIN_CONTRIBUTOR";
  }
  superagent
    .post(BASE_URL + pid + "/addContributor?role=" + role)
    .send(emailList)
    .set("uid", uid)
    .set("token", token)
    .end((err, res) => {
      middleCallback(err, res, callback);
    });
};

export const fetchHitsDetails = (
  pid,
  start,
  count,
  callback,
  type,
  label,
  userId
) => {
  console.log("fetching project stats ", pid, type, label, userId);
  const { uid, token } = getUidToken();
  let status = "done";
  if (type && type === "skipped") {
    status = "skipped";
  }
  if (type && type === "all") {
    status = null;
  }
  let url = BASE_URL + pid + "/getHits?start=" + start + "&count=" + count;
  if (status !== null) url = url + "&status=" + status;
  if (label) {
    url = url + "&label=" + label.replace(" ", "+");
  }
  if (userId) {
    url = url + "&userId=" + userId;
  }
  superagent
    .post(url)
    .set("uid", uid)
    .set("token", token)
    .end((err, res) => {
      middleCallback(err, res, callback);
    });
};

export const deleteProjectDt = (pid, callback) => {
    console.log("delete proejct ", pid);
    const { uid, token } = getUidToken();
  
    superagent
      .post(BASE_URL + pid + "/deleteProject")
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
        // callback(err, res);
      });
  };

  export const predictionInsights = (pid, callback) => {
    const { uid, token } = getUidToken();
    let url = BASE_URL + "model/" + pid + "/predictionStats";
    superagent
      .post(url)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };


  export const docLabelChart = (pid, callback) => {
	  const { uid, token } = getUidToken();
	  let url = BASE_URL + pid + "/docLabelChart";
	  superagent
	    .post(url)
	    .set("uid", uid)
	    .set("token", token)
	    .end((err, res) => {
	      middleCallback(err, res, callback)
	    });
	};


  export const fetchStats = (pid, callback, cache) => {
    console.log("fetching project stats ", pid);
    const { uid, token } = getUidToken();
    let url = BASE_URL + pid + "/getProjectStats";
    if (cache !== undefined) {
      url = url + '?cache=' + cache;
    }
    superagent
      .post(url)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
        // callback(err, res);
      });
  };



  export const runModelPrediction = (pid, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL +"model/"+pid+"/startPrediction")
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
};

export const removeContributor = (pid, email, isShare, callback) => {
    console.log("sending invite ", pid);
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + pid + "/removeContributor?userEmail=" + email + "&isShare=" + isShare)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };

  export const changeProjectStatus = (pid, status, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + pid + "/changeProjectStatus?status="+status)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };