
import React from "react";
import * as reactIcon from "react-icons/io";
import { Checkbox, Button, Dropdown, Card, Icon} from 'semantic-ui-react';
import Modal from 'react-bootstrap/lib/Modal';
import { getUidToken } from "../../helpers/commonFunctions";
import { displaySettingsByRole } from "../../helpers/Utils";
class AnnotationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: props.index,
            item: props.item,
            images: props.images,
            currentImageIndex: props.currentImageIndex,
            activeIndex: 0,
            openNote: false
        }
    }

    componentWillReceiveProps(nextProps) {
      const { index, item, images, currentImageIndex } = this.state;
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.index !== index) {
            this.setState({ index: nextProps.index });
        }
        if (nextProps.item !== item) {
            this.setState({ item: nextProps.item });
        }
        if (nextProps.images !== images) {
            console.log('images values=========>',images)
            this.setState({ images: nextProps.images });
        }
        if (nextProps.currentImageIndex !== currentImageIndex) {
            this.setState({ currentImageIndex: nextProps.currentImageIndex });
        }
    }
    
    editLabel = (index) => {
        this.props.editLabel(index);
    }

    callActive = (activeindex) => {
        this.props.callActive(activeindex);
    }
    removeSelected = (activeindex) =>{
    	this.props.removeSelected(activeindex);
    }
    changeAnnoColor = (color) => {
        this.props.changeAnnoColor(color);
    }
    getAnnoIndex = (index) => {
        this.props.getAnnoIndex (index)
    }
    handleClick = (e, titleProps) => {
        console.log('titleProps====>',titleProps)
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
    
        this.setState({ activeIndex: newIndex })
      }
    
     changeLabel = (event, label, index) => {
      const { item } = this.state;
        const currentData = this.state.item.label.split(",");
        const jindex = currentData.indexOf(label);
        if (jindex !== -1) 
        	currentData.splice(jindex, 1);
        else 
        	currentData.push(label);
        if (currentData.length === 0) {
        	this.removeSelected(item.index);
            return;
          }
        let toolDataItem = item
        toolDataItem.label = currentData.join(",");
        this.setState({item : toolDataItem})
        this.props.changesInSessionUpdate();
      };
      
      closeModal(index) {
    	    console.log('closemodal', this.state, index);
    	    this.setState({ openNote: false});
    	  }

      saveModal(index) {
    	    console.log('closemodal', this.state, index);
          this.setState({ openNote: false});
          this.props.changesInSessionUpdate()
    	  }

      handleNoteChange(event,obj) {
        let toolDataNotes = event.state.item
        toolDataNotes.notes = obj.target.value
        event.setState ({ item : toolDataNotes })
        
    	 }
    
     getLabels = (label) => {
      const { item } = this.state;
    	const labelArr = label.split(",");
        const entities = Object.keys(this.props.entityColorMap);
            const options = [];
            for (let jindex = 0; jindex < entities.length; jindex ++) {
              if (labelArr.includes(entities[jindex])) {
                options.push({ key: entities[jindex], text: ( <Checkbox checked onChange={() => this.changeLabel(this, entities[jindex], jindex)} label={entities[jindex]} /> ) });
              } else {
                options.push({ key: entities[jindex], text: ( <Checkbox onChange={() => this.changeLabel(this, entities[jindex], jindex)} label={entities[jindex]} /> ) });
              }
            }
              if (item.notes) {
                  options.push({ key: 'note', text: (<Button size="mini" onClick={() => this.setState({ openNote: true})} > Edit Note </Button>)});
                } else {
                  options.push({ key: 'note', text: (<Button size="mini" onClick={() => this.setState({ openNote: true})} > Add Note </Button>)});
                }
        return (<Dropdown floating inline upward button compact className="icon"  style = {{display: displaySettingsByRole(getUidToken().roleId), fontSize: 'xx-small', backgroundColor: `${item.color}` }} labeled text={label} scrolling options={options} />);
      }
              
    render() {
      const { item, openNote } = this.state;
        return (
            <Card className="view_card">
                <div id="box_tile" style={{backgroundColor: `${item.color}`}}>
                	<div 
                	onClick={() => {this.removeSelected(item.index)}}
                	style = {{color : '#fff', padding: '3px 5px', marginTop: '3px', display: displaySettingsByRole(getUidToken().roleId)}}
                	>
                     <reactIcon.IoIosClose />
                    </div>
                    <div 
                        onClick={() => {this.callActive(item.index)}}
                        style = {{color : '#fff', padding: '3px 5px', marginTop: '3px'}}
                        >
                            {item.visible ?  <reactIcon.IoIosEye /> : <reactIcon.IoIosEyeOff />}
                    </div>
                    {item.label &&
                    	<div>
                    		{this.getLabels(item.label)}
                    	</div>
                    }
                    
                    {
                        openNote &&
                              <div className="static-modal">
                                <Modal.Dialog>
                                  <Modal.Header>
                                    <Modal.Title>Add a Note
                                    <Icon onClick={() => this.closeModal(this)} className="pull-right cursor_pointer" name="close" />
                                    </Modal.Title>
                                  </Modal.Header>

                                  <Modal.Body>
                                    <input type="textarea" value = {item.notes}  autoFocus onChange={(event) => this.handleNoteChange(this,event)} className="form-control" id="note" />
                                  </Modal.Body>

                                  <Modal.Footer>
                                    <Button type="submit" positive onClick={() => this.saveModal(this)}>Save</Button>
                                  </Modal.Footer>
                                </Modal.Dialog>
                              </div>
                      }
                </div>
            </Card>
        );
    }
}
export default AnnotationList;