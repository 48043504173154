import React from 'react';
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color';
import { Tooltip } from 'antd';
import 'antd/dist/antd.css';

class ColorPicker extends React.Component  {

  constructor(props) {
    super(props);
    this.props = props;
 }

    state = {
      background: '#abc',
      displayColorPicker: false
    };

    componentDidMount(){
      const { displayColorPicker, background } = this.state;
      const { pickerColor } = this.props;
      if (!displayColorPicker && background) {
        this.setState({ background: pickerColor })
      }
    }

    componentWillReceiveProps(nextProps){
      const { pickerColor } = this.props;
      if (pickerColor !== nextProps.pickerColor) {
        this.setState({ background: pickerColor })
      }
    }

    handleClick = () => {
      const { displayColorPicker } = this.state;
      this.setState({ displayColorPicker: !displayColorPicker })
    };

    handleClose = () => {
      const { background } = this.state;
      this.props.handleClose(background);
      this.setState({ displayColorPicker: false })
    };

    handleChange = (color,event) => {
      const { index } = this.props;
      this.props.handleColorChange(color, index);
      this.setState({background: color})
    };

  convertHex = (hex) => {
    if (hex) {
      hex = hex.replace('#', '');
      const _r = parseInt(hex.substring(0, 2), 16);
      const _g = parseInt(hex.substring(2, 4), 16);
      const _b = parseInt(hex.substring(4, 6), 16);
      return "rgb( " + _r + " , " + _g + " , " + _b + " )";
    }
  }

    render() {

      const { pickerColor, pickedTop} = this.props;
      const {background,displayColorPicker} = this.state;
      const styles = reactCSS({
        'default': {
          color: {
            height: '14px',
            borderRadius: '2px',
            background: (background ? background : pickerColor),
          },
          swatch: {
            padding: '5px',
            background: (background ? background : pickerColor),
            borderRadius: '1px',
            boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2)',
            display: 'inline-block',
            cursor: 'pointer',
            height: '20px',
            width: '20px',
            marginTop: '-10px',
            marginLeft: '0px',
          },
          swatch2: {
            padding: '5px',
            background: (background ? background : pickerColor),
            borderRadius: '1px',
            boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2)',
            display: 'inline-block',
            cursor: 'pointer',
            height: '20px',
            width: '20px',
            marginTop: '2px',
            marginLeft: '0px',
          },
          popover: {
            position: 'absolute',
            bottom:'15',
            right: '10',
            zIndex: '2',
          },
          popover2:{
            position: 'absolute',
            top:'50',
            right: '10',
            zIndex: '2',
          },
          cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
        },
      });
      return (
        <span>
          <div style={ pickedTop ? styles.swatch2 : styles.swatch } title = {background ? background : pickerColor } onClick={ this.handleClick }>
          <Tooltip title="">
            <div style={ styles.color } />
            </Tooltip>
          </div>
          { displayColorPicker ? <div style={ pickedTop ? styles.popover2 : styles.popover }>
            <div style={styles.cover } onClick={ this.handleClose }/>
            <SketchPicker color={ background ? background : pickerColor } onChangeComplete={ this.handleChange } />
          </div> : null }
        </span>
      )
    }
  }
  export default ColorPicker;