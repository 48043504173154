import React, { Component, PropTypes } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { bindActionCreators } from "redux";
import { getProjectDetails } from "../../redux/reducer/projectReducer";
import { Button,Segment,Breadcrumb,Icon,List,Image,Label } from "semantic-ui-react";
import Modal from "react-bootstrap/lib/Modal";
import { push } from "react-router-redux";
import { removeContributor } from "../../Services/OverviewApis";
import { getUidToken,showAlert,logEvent } from "../../helpers/commonFunctions";
import { projectTypePath } from "../../helpers/Utils";
import { ROLES, COLORS, ICONS, LABELS } from "../../helpers/Constants";

class Contributors extends Component {
  static propTypes = {
    user: PropTypes.object,
    login: PropTypes.func,
    logout: PropTypes.func,
    pushState: PropTypes.func,
    projects: PropTypes.array,
    params: PropTypes.object,
    orgName: PropTypes.string,
    projectName: PropTypes.string,
    getProjectDetails: PropTypes.func,
    projectDetails: PropTypes.object,
    currentProject: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.contributorDeleted = this.contributorDeleted.bind(this);
    this.deleteContributor = this.deleteContributor.bind(this);
    this.state = {
      loading: false,
      showDeleteConfirmation: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.projectDetails !== nextProps.projectDetails) {
      this.setState({ loading: false });
    }
  }

  getItems(contributors) {
    const items = [];
    for (let index = 0; index < contributors.length; index++) {
      let label = "";
      if (contributors[index].role === "OWNER") {
        label = (
          <span style={{ marginLeft: "20px",display:"flex" }}>
            <Label size="mini" color="green">
              Owner
            </Label>
            <Label size="mini" color="orange">
              Admin
            </Label>
          </span>
        );
      } else if (contributors[index].role === "ADMIN_CONTRIBUTOR") {
        label = (
          <span style={{ marginLeft: "20px" }}>
            <Label size="mini" color="orange">
              Admin
            </Label>
          </span>
        );
      } else if (contributors[index].role === "ADMIN") {
        label = (
          <span style={{ marginLeft: "20px" }}>
            <Label size="mini" color="orange">
              Admin
            </Label>
          </span>
        );
      } else if (contributors[index].role === "ANNOTATOR") {
        label = (
          <span style={{ marginLeft: "20px" }}>
            <Label size="mini" color="grey">
              Annotator
            </Label>
          </span>
        );
      } else if (contributors[index].role === "GUEST") {
        label = (
          <span style={{ marginLeft: "20px" }}>
            <Label size="mini" color="olive">
              GUEST
            </Label>
          </span>
        );
      } else if (contributors[index].role === "QA") {
        label = (
          <span style={{ marginLeft: "20px" }}>
            <Label size="mini" color="teal">
              QA
            </Label>
          </span>
        );
      } else {
        label = (
          <span style={{ marginLeft: "20px" }}>
            <Label size="mini" color="red">
              InActive
            </Label>
          </span>
        );
      }
      let imageData = null;
      if (contributors[index].userDetails.profileImage) {
        imageData =
          "data:image/png;base64," +
          contributors[index].userDetails.profileImage;
      }
      items.push(
        <List.Item
          className="custom_item_contribute"
          style={{ display: "flex", height: "45px" }}
        >
          <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <Image avatar src={imageData} />
            {label}
            <div
              style={{
                display: "flex",
                width: "100%",
                marginLeft: "30px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontWeight: "bold" }}>
                {contributors[index].userDetails.firstName}
              </div>
              <div style={{ display: "flex", alignIitems: "center" }}>
                {contributors[index].userDetails.email}
              </div>
              {contributors[index].userDetails.email &&
                contributors[index].role !== ROLES.OWNER && (
                  <Button
                    icon
                    size="mini"
                    disabled={contributors[index].role === null ? true : false}
                    onClick={() =>
                      this.setState({
                        showDeleteConfirmation: true,
                        selectedContributor:
                          contributors[index].userDetails.email,
                      })
                    }
                    color={COLORS.RED}
                    className="pull-right margin-l-5"
                  >
                    <Icon name={ICONS.REMOVE} />
                  </Button>
                )}
            </div>
          </div>
        </List.Item>
      );
    }
    return (
      <List divided relaxed>
        {items}
      </List>
    );
  }

  contributorDeleted(error, response) {
    console.log("invite sent ", error, response);
    if (!error) {
      logEvent("buttons", "Contributor Removed");
      this.setState({ showDeleteConfirmation: false });
      this.props.getProjectDetails(this.props.currentProject, getUidToken());
      this.setState({ loading: true });
    } else {
      logEvent("buttons", "Contributor remove failed");
      this.setState({
        error: true,
        errorMessage: error,
        showDeleteConfirmation: false,
      });
      showAlert(error, "error");
      this.setState({ loading: false });
    }
  }

  deleteContributor() {
    console.log("selectedContributor ", this.state.selectedContributor);
    removeContributor(
      this.props.currentProject,
      this.state.selectedContributor,
      false,
      this.contributorDeleted
    );
    this.setState({ loading: true, showDeleteConfirmation: false });
  }

  render() {
    const { showDeleteConfirmation,loading } = this.state
    return (
      <div style={{ background: "white",marginLeft:"6%" }} className="text-center">
        <Helmet title="Contributor List" />
        {showDeleteConfirmation && (
          <div>
            <Modal.Dialog>
              <Modal.Header>
                <Modal.Title>Remove Contributor</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                Are you sure you want to remove this contributor from project ?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    this.setState({ showDeleteConfirmation: false });
                  }}
                >
                  Close
                </Button>
                <Button negative onClick={this.deleteContributor.bind(this)}>
                  Remove
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )}
        <div>
          <Segment basic size="large">
            <div className={loading ? "overlay_dom" : ""}>
              <div className="cssload-wraper">
                <div className="cssload-dots"></div>
              </div>
            </div>
            <Button
              className="pull-left"
              onClick={() =>
                this.props.pushState(
                  projectTypePath(this.props.projectDetails) +
                    this.props.params.orgName +
                    "/" +
                    this.props.params.projectName +
                    "/" +
                    this.props.params.projectId
                )
              }
              compact
            >
              <Icon name="arrow left" />
              Project
            </Button>
            <div className="text-center">
              <Breadcrumb size="big">
                <Breadcrumb.Section
                  link
                  onClick={() =>
                    this.props.pushState(
                      "/projects/" + this.props.params.orgName
                    )
                  }
                >
                  {this.props.params.orgName}
                </Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section
                  active
                  link
                  onClick={() =>
                    this.props.pushState(
                      projectTypePath(this.props.projectDetails) +
                        this.props.params.orgName +
                        "/" +
                        this.props.params.projectName +
                        "/" +
                        this.props.params.projectId
                    )
                  }
                >
                  {this.props.params.projectName}
                </Breadcrumb.Section>
              </Breadcrumb>
            </div>
          </Segment>
          <h1 className="contrib_title">{LABELS.CONTRIBUTOR_DETAILS}</h1>
          <div
            className="card_shadow"
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "15",
              padding: "15",
            }}
          >
            {this.props.projectDetails &&
              this.getItems(this.props.projectDetails.contributorDetails)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      user: state.loginReducer.user,
      projects: state.projectReducer.projects,
      currentProject: state.projectReducer.currentProject,
      projectDetails: state.projectReducer.projectDetails,
    };
  };
  
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        pushState: push, 
        getProjectDetails
      },
      dispatch
    );

  export default connect(mapStateToProps, mapDispatchToProps)(Contributors);