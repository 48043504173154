import React, { Component, PropTypes } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { bindActionCreators } from "redux";
import { getAllOrganizations,deleteOrganizationById,getOrgDetailsForAdmin,updateSubscription,getSubscriptionPlans } from "../../Services/usersApis";
import { getUidToken,showAlert,saveOrgId } from "../../helpers/commonFunctions";
import { pageSize, APP_CONSTANTS } from "../../helpers/Constants";
import {
  Label,
  Header,
  Button,
  Icon,
  Table,
  Segment,
  Pagination,
  Modal,
  Dropdown,
  Input,
} from "semantic-ui-react";
import { push, replace } from "react-router-redux";
import { Icon as AntIcon, Modal as AntModal } from "antd";

class Organization extends Component {
  static propTypes = {
    user: PropTypes.object,
    login: PropTypes.func,
    logout: PropTypes.func,
    pushState: PropTypes.func,
    replaceState: PropTypes.func,
    getAllOrganizations: PropTypes.func,
  };

  static userPropTypes = {
    firstName: PropTypes.string.isRequired,
    secondName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };

  constructor(props) {
    console.log("props are ", props);
    super(props);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let currentPage = params.get("currentpage");
    this.state = {
      fields: {},
      errors: {},
      inviteModal: false,
      loading: false,
      loaded: false,
      successModal: false,
      userDetails: null,
      status: null,
      userId: null,
      orgId: null,
      open: null,
      data: [],
      showPaginationBar: true,
      offset: 0,
      activePage: currentPage ? currentPage : 1,
      boundaryRange: 1,
      siblingRange: 1,
      showEllipsis: true,
      showFirstNav: false,
      showPreviousNav: false,
      showLastNav: true,
      showNextNav: true,
      navNextItem: {},
      planOptions: [],
      selectedPlan: null,
      currentOrg: null,
      subscriptionType: APP_CONSTANTS.SUBSCRIPTION_TYPE_UPDATE,
      totalPages: 0,
      deleteOrginizationConfirmModalOpen: false,
      orderBy: "name",
      sortOrder: "asc",
      orgTotal: 0,
      query: "",
    };
    this.licensePopup = this.licensePopup.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.loadPlans = this.loadPlans.bind(this);
    this.planChange = this.planChange.bind(this);
    this.deleteOrginizationConfirmModal = this.deleteOrginizationConfirmModal.bind(
      this
    );
  }

  handleOnSearchOrg = (event) => {
    const {orderBy,sortOrder} = this.state
    const query = event.target.value;
    this.state.loaded = true;
    this.setState({ query });
    if (event.target.value.length == 0) {
      getAllOrganizations(
        this.getOrganizationsCallback.bind(this),
        0,
        pageSize,
        orderBy,
        sortOrder,
        ""
      );
    }
  };

  getOrganizationsCallback(error, response) {
    if (!error) {
      this.setState({ data: response.body });
      var data = this.state.data.orgsList;
      if (data.length <= 10 && this.state.totalPages === 1) {
        this.setState({ showPaginationBar: false });
      } else {
        this.setState({ showPaginationBar: true });
      }
    } else {
      showAlert("error", "error");
    }
    this.setState({
      navNextItem: {
        key: null,
        active: false,
        disabled: true,
        type: "nextItem",
      },
    });
  }
  deleteOrganizationAccount(data) {
    if (this.props.user.roleId === 4) {
      deleteOrganizationById(
        data.id,
        this.deleteOrganizationsCallback.bind(this)
      );
    } else {
      try {
        this.props.logout();
      } catch (error) {
        this.props.replaceState("/projects/login");
      }
    }
  }
  deleteOrginization(data) {
    this.deleteOrginizationConfirmModal(data);
  }

  deleteOrginizationConfirmModal = (data) => {
    const that = this;
    const confirm = AntModal.confirm;
    confirm({
      title: "Confirmation",
      content:
        "Are you sure you want to delete the Organization  " +
        "'" +
        data.name +
        "'" +
        "?",
      okText: "OK",
      cancelText: "CANCEL",
      onOk() {
        that.deleteOrganizationAccount(data);
      },
      onCancel() {
        return false;
      },
    });
  };

  getUsersByOrgId(data, roleIdCheck, id) {
    saveOrgId(data.id);
    this.props.replaceState(
      "/projects/" +
        data.id +
        "/users/" +
        roleIdCheck +
        "?currentpage=" +
        this.state.activePage
    );
  }

  getOrganizationsData = () => {
    const arrs = [];
    this.state.orgTotal = this.state.data.total;
    this.state.totalPages = this.state.data.totalPages;
    var data = this.state.data.orgsList;

    for (let index = 0; index < data.length; index++) {
      let superAdminCount = data[index].superAdminCount;
      let adminCount = data[index].adminCount;
      let annotatorCount = data[index].annotatorCount;
      let guestCount = data[index].guestCount;
      let qaCount = data[index].qaCount;
      let licenseAction = data[index].expiredLicenseWithInOneWeek ? (
        <a
          title="Add Subscription"
          onClick={this.handleLicenseAction.bind(this, data[index])}
        >
          <Icon class="item" name="plus square outline" />
        </a>
      ) : (
        ""
      );

      let superAdminCountColData =
        superAdminCount > 0 ? (
          <a onClick={this.getUsersByOrgId.bind(this, data[index], 1)}>
            {superAdminCount}
          </a>
        ) : (
          superAdminCount
        );
      let adminCountColData =
        adminCount > 0 ? (
          <a onClick={this.getUsersByOrgId.bind(this, data[index], 2)}>
            {adminCount}
          </a>
        ) : (
          adminCount
        );
      let annotatorCountColData =
        annotatorCount > 0 ? (
          <a onClick={this.getUsersByOrgId.bind(this, data[index], 3)}>
            {annotatorCount}
          </a>
        ) : (
          annotatorCount
        );
      let guestCountColData =
        guestCount > 0 ? (
          <a onClick={this.getUsersByOrgId.bind(this, data[index], 5)}>
            {guestCount}
          </a>
        ) : (
          guestCount
        );
      let qaCountColData =
        qaCount > 0 ? (
          <a onClick={this.getUsersByOrgId.bind(this, data[index], 6)}>
            {qaCount}
          </a>
        ) : (
          qaCount
        );

      arrs.push(
        <Table.Row key={index}>
          <Table.Cell>{data[index].name}</Table.Cell>
          <Table.Cell>{data[index].contactName}</Table.Cell>
          <Table.Cell>{data[index].contactEmail}</Table.Cell>
          <Table.Cell>{data[index].contactPhone}</Table.Cell>
          <Table.Cell>{superAdminCountColData}</Table.Cell>
          <Table.Cell>{adminCountColData}</Table.Cell>
          <Table.Cell>{annotatorCountColData}</Table.Cell>
          <Table.Cell>{guestCountColData}</Table.Cell>
          <Table.Cell>{qaCountColData}</Table.Cell>
          <Table.Cell>
            {data[index].expiredLicenseWithInOneWeek ? "Y" : "N"}
          </Table.Cell>
          {data[index].deleted && (
            <Table.Cell>
              <span style={{ color: "red" }}>InActive</span>
            </Table.Cell>
          )}
          {!data[index].deleted && (
            <Table.Cell>
              <span style={{ color: "green" }}>Active</span>
            </Table.Cell>
          )}

          <Table.Cell className="table_actionbtn">
            <a className="clr_icon" title="Edit Organization">
              <Icon
                className="anticon anticon-edit"
                onClick={this.editOrganizationAccount.bind(this, data[index])}
              />
            </a>
            <a className="clr_icon" title="Delete Organization">
              <Icon
                className="anticon anticon-delete"
                onClick={this.deleteOrginization.bind(this, data[index])}
              />{" "}
            </a>
            {licenseAction}
          </Table.Cell>
        </Table.Row>
      );
    }
    return <Table.Body className="ant-table-tbody">{arrs}</Table.Body>;
  };

  editOrganizationAccount(data) {
    if (this.props.user.roleId === 4) {
      this.props.replaceState(
        "/projects/" +
          data.id +
          "/modifyOrganizations" +
          "?currentpage=" +
          this.state.activePage
      );
    } else {
      try {
        this.props.logout();
      } catch (error) {
        this.props.replaceState("/projects/login");
      }
    }
  }

  handleLicenseAction(data) {
    this.setState({ showModal: true, currentOrg: data });
    if (!data.subscriptionPlanName) {
      this.setState({ subscriptionType: APP_CONSTANTS.SUBSCRIPTION_TYPE_NEW });
    }
    this.loadPlans();
  }
  closeModal() {
    this.setState({ showModal: false });
    this.setState({ selectedPlan: undefined });
  }

  subscribeLicense(planId) {
    const {currentOrg,subscriptionType} = this.state
    const confirm = AntModal.confirm;
    if (confirm("Are you sure to update the subscription?")) {
      updateSubscription(
        currentOrg.id,
        planId,
        subscriptionType,
        this.subscribeLicenseCallback.bind(this)
      );
    }
  }

  planChange = (e, { value }) => {
    console.log("Selected Plan:" + value);
    this.setState({ selectedPlan: value });
  };
  loadPlans() {
    getSubscriptionPlans((err, response) => {
      let planList = JSON.parse(JSON.stringify(response.body));
      if (!err) {
        let options = [];
        planList.forEach((item) =>
          options.push({
            key: item.planId,
            text: item.planName,
            value: item.planId,
          })
        );
        this.setState({ planOptions: options });
      } else {
        showAlert(
          "Unable to retrieve the plans. Please try after some time.",
          "error"
        );
      }
    });
  }

  licensePopup() {
    const {showModal,currentOrg,planOptions,selectedPlan} = this.state
    return (
      <Modal
        open={showModal}
        onClose={this.closeModal}
        size="fullscreen"
        centered={false}
      >
        <Header icon="archive" content="Plan Selection" />
        <Modal.Content>
          <Label>Current Subscription Plan</Label>
          <Input
            disabled
            value={
              currentOrg &&
              currentOrg.subscriptionPlanName
                ? currentOrg.subscriptionPlanName
                : "No Plan subscribed"
            }
          />
        </Modal.Content>
        <Modal.Content>
          <Modal.Description>
            <Label>Please select a new Subscription Plan : </Label>
            <Dropdown
              placeholder="Select a Plan"
              upward={false}
              basic
              scrolling
              options={planOptions}
              onChange={this.planChange}
              value={selectedPlan}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Content></Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={this.closeModal}>
            <Icon name="remove" /> Cancel
          </Button>
          <Button
            color="green"
            onClick={this.subscribeLicense.bind(this, selectedPlan)}
          >
            <Icon name="checkmark" /> Update
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  deleteOrganizationsCallback(error, response) {
    const {activePage,query} = this.state
    if (!error) {
      showAlert("Organization is deleted successfully.");
      getAllOrganizations(
        this.getOrganizationsCallback.bind(this),
        activePage - 1,
        pageSize,
        "",
        "",
        query
      );
    } else {
      showAlert("Unable to delete the organization.", "error");
    }
  }

  subscribeLicenseCallback(error, response) {
    const {activePage,query} = this.state
    if (!error) {
      showAlert("Subscription is updated successfully.");
      this.setState({
        activePage: 1,
        showModal: false,
        selectedPlan: undefined,
      });
      getAllOrganizations(
        this.getOrganizationsCallback.bind(this),
        activePage - 1,
        pageSize,
        "",
        "",
        query
      );
    } else {
      showAlert("Unable to extend/provide the subscription.", "error");
    }
  }

  componentDidMount() {
    const {activePage,query} = this.state
    if (this.props.user.roleId === 4)
      getAllOrganizations(
        this.getOrganizationsCallback.bind(this),
        activePage - 1,
        pageSize,
        "",
        "",
        query
      );
    else {
      try {
        this.props.logout();
      } catch (error) {
        this.props.replaceState("/projects/login");
      }
    }
  }

  handlePageClick = (data) => {
    if (this.props.user.roleId === 4)
      this.props.replaceState("/projects/addOrganization");
    else {
      try {
        this.props.logout();
      } catch (error) {
        this.props.replaceState("/projects/login");
      }
    }
  };

  handlePageChange(offset) {
    this.setState({ offset });
  }

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage });
    console.log(activePage);
    if (activePage === this.state.totalPages) {
      this.setState({ showLastNav: false, showNextNav: false });
    } else {
      this.setState({ showLastNav: true, showNextNav: true });
    }

    if (activePage === 1) {
      this.setState({ showFirstNav: false, showPreviousNav: false });
    } else {
      this.setState({ showFirstNav: true, showPreviousNav: true });
    }
    getAllOrganizations(
      this.getOrganizationsCallback.bind(this),
      activePage - 1,
      pageSize,
      "",
      "",
      this.state.query
    );
  };

  getSortIcon(fieldName) {
    return (
      <div className="ant-table-column-sorter float-left pad-6-em">
        <span
          className="ant-table-column-sorter-up off"
          title="↑"
          onClick={() =>
            this.sortByOrgs({ orderBy: fieldName, sortOrder: "asc" })
          }
        >
          <i className="anticon anticon-caret-up"></i>
        </span>
        <span
          className="ant-table-column-sorter-down off"
          title="↓"
          onClick={() =>
            this.sortByOrgs({ orderBy: fieldName, sortOrder: "desc" })
          }
        >
          <i className="anticon anticon-caret-down"></i>
        </span>
      </div>
    );
  }
  searchOrg = (e) => {
    const {orderBy,sortOrder,query} = this.state
    e.preventDefault();
    getAllOrganizations(
      this.getOrganizationsCallback.bind(this),
      0,
      pageSize,
      orderBy,
      sortOrder,
      query
    );
  };
  sortByOrgs = (data) => {
    const {orderBy,sortOrder,query} = this.state
    console.log(data);
    if (
      parseInt(getUidToken().roleId) === 1 ||
      parseInt(getUidToken().roleId) === 4
    ) {
      this.setState(
        { orderBy: data.orderBy, sortOrder: data.sortOrder },
        () => {
          getAllOrganizations(
            this.getOrganizationsCallback.bind(this),
            0,
            pageSize,
            orderBy,
            sortOrder,
            query
          );
        }
      );
    } else {
      this.setState(
        { orderBy: data.orderBy, sortOrder: data.sortOrder },
        () => {
          getOrgDetailsForAdmin(
            getUidToken().uid,
            this.dtgetOrgDetailsForAdmin.bind(this)
          );
        }
      );
    }
  };

  dtgetOrgDetailsForAdmin(error, response) {
    if (!error) {
      this.setState({ annotatorData: JSON.parse(response.text) });
    } else {
      showAlert(error, "error");
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
    }
  }

  render() {
    const { query,loaded,showNextNav,showPreviousNav,showLastNav,showFirstNav,showEllipsis,
      siblingRange,boundaryRange,activePage,data,
      loading,orgTotal } = this.state;
    return (
      <div style={{ paddingLeft: "6%", paddingRight: "3%" }}>
        <Helmet title="User Management" />
        {
          <div>
            <div className="title_section flex-between m-b-15 m-t-20">
              <h2 className="tilte_display fw-600">Manage Organizations </h2>

              <div className="table_btn flexCenter">
                <div className="m-r-15">
                  {" "}
                  Total Count: {orgTotal}
                </div>
                <div className="m-r-15">
                  <div className="search_h">
                    <div className="search_wrap">
                      <div className="search_sec">
                        <div className="search_select">
                          <form className="form_search">
                            <input
                              type="text"
                              className="Search Users searchTerm bdrUnset"
                              placeholder="Search Organization"
                              onChange={this.handleOnSearchOrg}
                              value={query}
                            />
                            <button
                              type="submit"
                              onClick={(e) => this.searchOrg(e)}
                              className="searchButton"
                            >
                              <AntIcon type="search" />
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  className="btn_base btn_success m-l-15"
                  title="Add Organization"
                  onClick={this.handlePageClick.bind(this)}
                >
                  <AntIcon type="plus" /> Add Organization
                </Button>
              </div>
            </div>
            <div className="user_form">
              {this.licensePopup()}
              {
                <div className="content_section ant-table-wrapper table_workspace">
                  <Segment basic vertical loading={loading}>
                    <Table compact="very" celled size="small" striped>
                      <Table.Header className="ant-table-thead" fullWidth>
                        <Table.Row>
                          <Table.HeaderCell>
                            <div className="width-100">
                              <span className="float-left">
                                Organization Name
                              </span>
                              {this.getSortIcon("name")}
                            </div>
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <div className="width-100">
                              <span className="float-left">Contact Name</span>
                              {this.getSortIcon("contactName")}
                            </div>
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <div className="width-100">
                              <span className="float-left">Contact E-mail</span>
                              {this.getSortIcon("contactEmail")}
                            </div>
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <div className="width-100">
                              <span className="float-left">Phone</span>
                              {this.getSortIcon("contactPhone")}
                            </div>
                          </Table.HeaderCell>
                          <Table.HeaderCell>Super Admin Count</Table.HeaderCell>
                          <Table.HeaderCell>Admin Count</Table.HeaderCell>
                          <Table.HeaderCell>User Count</Table.HeaderCell>
                          <Table.HeaderCell>Guest Count</Table.HeaderCell>
                          <Table.HeaderCell>QA Count</Table.HeaderCell>
                          <Table.HeaderCell>
                            Subscription Expiry in a week?
                          </Table.HeaderCell>
                          <Table.HeaderCell>Status</Table.HeaderCell>
                          <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      {data.length !== 0
                        ? this.getOrganizationsData()
                        : ""}
                      <Table.Footer fullWidth>
                        <Table.Row>
                          <Table.HeaderCell colSpan="12">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{ width: "80%", textAlign: "center" }}
                              >
                                {orgTotal > 0 ? (
                                  <Pagination
                                    style={{ position: "unset" }}
                                    activePage={activePage}
                                    boundaryRange={boundaryRange}
                                    onPageChange={this.handlePaginationChange}
                                    size="mini"
                                    siblingRange={siblingRange}
                                    totalPages={this.state.totalPages}
                                    ellipsisItem={
                                      showEllipsis ? undefined : null
                                    }
                                    firstItem={
                                      showFirstNav ? undefined : null
                                    }
                                    lastItem={
                                      showLastNav ? undefined : null
                                    }
                                    prevItem={
                                      showPreviousNav
                                        ? undefined
                                        : null
                                    }
                                    nextItem={
                                      showNextNav ? undefined : null
                                    }
                                  />
                                ) : loaded === true ? (
                                  <div>
                                    <h4>No matching records found</h4>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                    </Table>
                  </Segment>
                </div>
              }
            </div>
            <br />
            <br />
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.loginReducer.user,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushState: push,
      getAllOrganizations,
      replaceState: replace,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Organization);
