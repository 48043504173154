import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Icon,Dropdown,Button } from "semantic-ui-react";
import {
    fromDateTimestamp,
    toDateTimestamp,
  hitsBreadCrumbTitle,
  breadcrumbSeperate,
  createEntitiesJson,
  IMAGE_CLASSIFICATION,
  getPageSizeOptions,
  HIT_ID,
} from "../../helpers/Utils";

import { push } from "react-router-redux";
import queryString from "query-string";
import BACK from "../../images/Arrow_Simple_left1.svg";
import filter from "../../images/filter.svg";
import SEARCHSMALL from "../../images/Search-small.svg";
import CardView from "../../Components/CardView/CardView";
import {
  updateCurrentPageStatus,
  updateCurrentHitsData,
  updateHitsDeatailsData,
  updatePrevPageDeatails,
} from "../../redux/reducer/projectReducer";
import { filterHits } from "../../Services/TaggerApis";
import { downloadImageUrl } from "../../Services/commonApis";
import Filters from "../../Components/Filters/Filters";
import { saveData } from "../../helpers/commonFunctions";
import { IMAGE_POLYGON_BOUNDING_BOX,IMAGE_POLYGON_BOUNDING_BOX_V2,IMAGE_SEGMENTATION,IMAGE_BOUNDING_BOX } from "../../helpers/Utils";
import { fetchProjectStats } from "../../Services/OverviewApis";
class HitsOverviewPage extends React.Component {
  constructor(props) {
    super(props);
    const { location, params, projectDetails } = props;
    const { query } = location || {};
    const { label } = query || {};
    const entities =
      projectDetails && createEntitiesJson(projectDetails.taskRules).entities;
    const { currentHitPage = 0, currentHitSize = 8 } =
      location && location.search !== ""
        ? queryString.parse(location.search)
        : {};

    this.state = {
      projectId: params.projectId,
      orgName: params.orgName,
      projectHits: null,
      status: params.status === "all" ? "" : params.status,
      hitUrl: null,
      hitStatus: null,
      pageSize: parseInt(currentHitSize),
      pageStart: parseInt(currentHitPage),
      hitCompletedStartDate: null,
      hitCompletedEndDate: null,
      hitCreatedStartDate: null,
      hitCreatedEndDate: null,
      selecteddate: null,
      hitAnalystId: null,
      pageCurrent: 0,
      pageSortBy:
        projectDetails?.task_type === IMAGE_CLASSIFICATION
          ? HIT_ID
          : "NEW_FIRST",
      hitEvaluationType: null,
      projectDetails,
      totalpages: 10,
      activePage:
        currentHitPage !== undefined && currentHitPage !== ""
          ? parseInt(currentHitPage) + 1
          : 1,
      boundaryRange: 1,
      siblingRange: 1,
      showEllipsis: true,
      showFirstAndLastNav: true,
      visible: false,
      showPreviousAndNextNav: true,
      contributor: null,
      contributorOptions: [],
      label,
      entities,
      clearButton: true,
      clearFromDisable:false
    };
  }


  redirectScreen() {
    let orgName = this.props.params.orgName || window.sessionStorage.getItem("orgName");
    let projectName = this.props.params.projectName || window.sessionStorage.getItem("projectName");
    this.props.pushState({
        pathname:
            "/projects/" +
            orgName +
            "/" +
            projectName
            + "/" + this.props.params.projectId
    });
}

  componentWillMount() {
    if (!this.props.projectDetails) {
        this.redirectScreen();
      }
    fetchProjectStats(this.props.params.projectId, this.projectDetailsFetched);
}

projectDetailsFetched = (error, response) => {
    if (!error) {
      this.setState({projectDetails: response.body});
    }
  }




  componentDidMount() {
    const {orgName,projectId,pageSize,pageStart,projectDetails,hitStatus,hitUrl,status,hitCompletedStartDate,hitCompletedEndDate,hitCreatedStartDate,hitCreatedEndDate,hitAnalystId,pageCurrent,pageSortBy,label} = this.state
    if (projectDetails !== null) {
      saveData(orgName, projectDetails.name);
    }
    let hitStatusArr = [];
    let hitEvaluationTypeData;
    if (status.includes("correct")) {
        hitStatusArr.push(status);
        this.setState({ hitStatus: hitStatusArr,hitEvaluationType:status });
        hitEvaluationTypeData = status;
    }
    filterHits(
      orgName,
      projectId,
      hitUrl,
      hitStatus
        ? hitStatus
        : status !== "all"
        ? [status]
        : null,
      pageSize,
      pageStart,
      hitCompletedStartDate,
      hitCompletedEndDate,
      hitCreatedStartDate,
      hitCreatedEndDate,
      hitAnalystId,
      pageCurrent,
      pageSortBy,
      hitEvaluationTypeData,
      label,
      this.fetchResult
    );
  }

  fetchResult = (err, res) => {
    let projectHits;
    projectHits = res.body;
    let projectHitsList = projectHits.hitsData;
    let niriProjectDetails = [];
    projectHitsList.map((hits, index) => {
      let imageData = hits.hits.data;
      let result = imageData.includes("color");
      let niriImages = 0;
      downloadImageUrl((url, uid, token) => {
        if (
          imageData.includes("hits/images") &&
          !imageData.includes("http") &&
          !imageData.includes("https")
        ) {
          imageData = `${url}${imageData}?token=${token}&uid=${uid}`;
        }
      });
      hits.hits.data = imageData;
      if (result === true) {
        niriImages += 1;
      } else {
        niriProjectDetails.push(hits);
      }
    });
    projectHitsList = projectHitsList.sort((a, b) => {
      return parseInt(b["hits"].id) - parseInt(a["hits"].id);
    });
    this.setState({ projectHits: niriProjectDetails });
    this.setState({ totalHits: projectHits.totalHits });
    this.setState({
      totalPages: Math.ceil(projectHits.totalHits / this.state.pageSize),
    });
  };

  applyFilters = () => {
    const {orgName,projectId,pageSize,hitStatus,pageStart,hitUrl,status,hitCompletedStartDate,hitCompletedEndDate,hitCreatedStartDate,hitCreatedEndDate,hitAnalystId,pageCurrent,pageSortBy,hitEvaluationType,label} = this.state
    filterHits(
      orgName,
      projectId,
      hitUrl,
      hitStatus
        ? hitStatus
        : status !== "all"
        ? [status]
        : null,
      pageSize,
      pageStart,
      hitCompletedStartDate,
      hitCompletedEndDate,
      hitCreatedStartDate,
      hitCreatedEndDate,
      hitAnalystId,
      pageCurrent,
      pageSortBy,
      hitEvaluationType,
      label,
      this.fetchResult
    );
  };

  handleAllFilterChanges = (event) => {
    const {orgName,projectId,pageSize,hitStatus,hitUrl,status,hitCompletedStartDate,hitCompletedEndDate,hitCreatedStartDate,hitCreatedEndDate,hitAnalystId,pageCurrent,pageSortBy,hitEvaluationType,label} = this.state
    console.log("handle all filter changes", event.target.value);
    const currentStatus = hitStatus
      ? hitStatus
      : status !== "all"
      ? [status]
      : null;
    const startPage = 0;
    if (event.target.name === "search") {
      this.setState({ activePage: 1 });
      this.setState({ pageStart: startPage });
      if (event.target.value !== "") {
        this.setState({ hitUrl: event.target.value });
      } else {
        this.setState({ hitUrl: null });
      }
      this.setState({ hitStatus: currentStatus });
    } else if (event.target.name === "date") {
      this.setState({ activePage: 1 });
      const filterDate = event.target.value;
      const startTime = Date.parse(`${filterDate} 00:00:00`);
      const endTime = Date.parse(`${filterDate} 23:59:59`);
      this.setState({
        hitCompletedStartDate: filterDate,
        hitCompletedEndDate: filterDate,
        pageStart: startPage,
      });
      filterHits(
        orgName,
        projectId,
        hitUrl,
        currentStatus,
        pageSize,
        startPage,
        startTime,
        endTime,
        hitCreatedStartDate,
        hitCreatedEndDate,
        hitAnalystId,
        pageCurrent,
        pageSortBy,
        hitEvaluationType,
        label,
        this.fetchResult
      );
    } else if (event.target.name === "hitsDone") {
      this.setState({ activePage: 1 });
      const hitStatus = [event.target.value];
      this.setState({
        hitStatus: hitStatus,
        pageStart: startPage,
        status: event.target.value,
      });
      filterHits(
        orgName,
        projectId,
        hitUrl,
        hitStatus,
        pageSize,
        startPage,
        hitCompletedStartDate,
        hitCompletedEndDate,
        hitCreatedStartDate,
        hitCreatedEndDate,
        hitAnalystId,
        pageCurrent,
        pageSortBy,
        hitEvaluationType,
        label,
        this.fetchResult
      );
    } else if (event.target.name === "contributor") {
      // Filter by Contributor Id
      this.setState({ activePage: 1 });
      let contributorID = null;
      this.setState({ hitAnalystId: null });
      if (event.target.value && event.target.value !== "null") {
        contributorID = [event.target.value];
        this.setState({ hitAnalystId: [event.target.value] });
      }
      filterHits(
        orgName,
        projectId,
        hitUrl,
        currentStatus,
        pageSize,
        startPage,
        hitCompletedStartDate,
        hitCompletedEndDate,
        hitCreatedStartDate,
        hitCreatedEndDate,
        contributorID,
        pageCurrent,
        pageSortBy,
        hitEvaluationType,
        label,
        this.fetchResult
      );
    } else if (event.target.name === "evaluation") {
      this.setState({ activePage: 1 });
      let EvaluationType = null;
      if (event.target.value && event.target.value !== "null") {
        EvaluationType = event.target.value;
        this.setState({ hitEvaluationType: event.target.value });
      } else {
        this.setState({ hitEvaluationType: undefined });
      }
      filterHits(
        orgName,
        projectId,
        hitUrl,
        currentStatus,
        pageSize,
        startPage,
        hitCompletedStartDate,
        hitCompletedEndDate,
        hitCreatedStartDate,
        hitCreatedEndDate,
        hitAnalystId,
        pageCurrent,
        pageSortBy,
        EvaluationType,
        label,
        this.fetchResult
      );
    }
  };


  setSelectedDate=(selecteddate, cDate)=> {
    const {hitStatus,status,orgName,projectId,hitUrl,pageSize,label,hitCreatedStartDate,hitCreatedEndDate,hitAnalystId,pageCurrent,pageSortBy,hitEvaluationType } = this.state
    console.log("Filter date",selecteddate,cDate)
    this.setState({ activePage: 1 });
    const startPage = 0
    let startDate = null;
    let endDate = null;
    const currentStatus = hitStatus ? hitStatus : status !== 'all' ? [status] : null
    if (selecteddate === undefined){
        this.setState({clearFromDisable: true})
         this.state.selecteddate = null;
     } else {
           this.state.selecteddate = selecteddate;
           startDate = fromDateTimestamp(cDate);
           endDate = toDateTimestamp(cDate);
     }
    this.setState({ hitCompletedStartDate: startDate, hitCompletedEndDate: endDate, pageStart: startPage, selecteddate: selecteddate })
    filterHits(orgName, projectId, hitUrl, currentStatus, pageSize, startPage, startDate, endDate, hitCreatedStartDate, hitCreatedEndDate, hitAnalystId, pageCurrent, pageSortBy, hitEvaluationType, label, this.fetchResult)
}

  getEntities=(entities)=> {
    const { hitStatus,status,orgName,label,projectId,hitUrl,pageSize,hitCompletedStartDate,hitCompletedEndDate,hitCreatedStartDate,hitCreatedEndDate,hitAnalystId,pageCurrent,pageSortBy,hitEvaluationType } = this.state
    const currentStatus = hitStatus ? hitStatus : status !== 'all' ? [status] : null
    const startPage = 0
    const options = [];
    if (entities) {
      for (let index = 0; index < entities.length; index++) {
        options.push({
          text: entities[index],
          value: entities[index],
          onClick: () => {
            this.setState({ label: entities[index], pageStart: startPage })
            filterHits(orgName, projectId, hitUrl, currentStatus, pageSize, startPage, hitCompletedStartDate, hitCompletedEndDate, hitCreatedStartDate, hitCreatedEndDate, hitAnalystId, pageCurrent, pageSortBy, hitEvaluationType, entities[index], this.fetchResult)
          }
        });
      }
      options.push({
        text: 'All',
        value: 'All',
        onClick: () => {
          this.setState({ label: undefined, pageStart: startPage })
          filterHits(orgName, projectId, hitUrl, currentStatus, pageSize, startPage, hitCompletedStartDate, hitCompletedEndDate, hitCreatedStartDate, hitCreatedEndDate, hitAnalystId, pageCurrent, pageSortBy, hitEvaluationType, undefined, this.fetchResult)
        }
      });
      return (
        <Dropdown 
          value={(label) ? label : "All"}
          placeholder="TAGGED ENTITY"
          selection
          options={options}
        />
      );
    }
}

  handlePaginationChange = (e, { activePage }) => {
    const {orgName,projectId,pageSize,hitStatus,hitUrl,status,hitCompletedStartDate,hitCompletedEndDate,hitCreatedStartDate,hitCreatedEndDate,hitAnalystId,pageCurrent,pageSortBy,hitEvaluationType,label} = this.state
    this.setState({ activePage: activePage, pageStart: activePage - 1 });
    filterHits(
      orgName,
      projectId,
      hitUrl,
      hitStatus
        ? hitStatus
        : status !== "all"
        ? [status]
        : null,
      pageSize,
      activePage - 1,
      hitCompletedStartDate,
      hitCompletedEndDate,
      hitCreatedStartDate,
      hitCreatedEndDate,
      hitAnalystId,
      pageCurrent,
      pageSortBy,
      hitEvaluationType,
      label,
      this.fetchResult
    );
  };

  searchOnEnterPressed = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      //keycode for enter-key is 13
      this.applyFilters(event);
      console.log("Enter key pressed", event.target.value);
    }
  };

  toggleMenu = () => {
    console.log("Toggle menu");
    this.setState({ visible: !this.state.visible });
  };

  showClearAll = () => {
    const {orgName,projectId,pageSize,hitStatus,pageStart,hitCompletedStartDate,hitCompletedEndDate,hitCreatedStartDate,hitCreatedEndDate,hitAnalystId,pageCurrent,pageSortBy,hitEvaluationType,label} = this.state
    console.log("Show Clear All");
    this.setState({ hitUrl: "" });
    filterHits(
      orgName,
      projectId,
      "",
      hitStatus,
      pageSize,
      pageStart,
      hitCompletedStartDate,
      hitCompletedEndDate,
      hitCreatedStartDate,
      hitCreatedEndDate,
      hitAnalystId,
      pageCurrent,
      pageSortBy,
      hitEvaluationType,
      label,
      this.fetchResult
    );
  };

  redirectBackImage=(e)=> {
	  if (undefined !== e)
		  e.preventDefault();
	  const { type, projectDetails,redirectContributionDetails} = this.state;
	  this.setState({selectedLabel : ''})
    if (type !== 'notDone' && redirectContributionDetails &&
        (projectDetails.task_type === IMAGE_CLASSIFICATION ||
			  projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX ||
			  projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
        projectDetails.task_type === IMAGE_SEGMENTATION ||
			  projectDetails.task_type === IMAGE_BOUNDING_BOX )) {
        let contributorDetails = redirectContributionDetails;
        this.props.pushState({
             pathname:
               "/projects/hitslist/" +
               this.props.params.orgName +
               "/" +
               this.props.params.projectName +
               "/" +
               this.props.params.projectId +
               "/" + type,
             query: { contributorDetails }
           });
	  } else {
		  this.props.pushState({
	          pathname:
	            "/projects/" +
	            this.props.params.orgName +
	            "/" +
              this.props.params.projectName +
              "/" +
              this.props.params.projectId
	        });
	  }
  }

  pageSizeChange = (event) => {
    const {orgName,projectId,hitUrl,hitStatus,status,hitCompletedStartDate,hitCompletedEndDate,hitCreatedStartDate,hitCreatedEndDate,hitAnalystId,pageCurrent,pageSortBy,hitEvaluationType,label} = this.state
    console.log("pageSizeChange called");
    this.setState({ pageSize: event.target.value, activePage: 1 });
    filterHits(
      orgName,
      projectId,
      hitUrl,
      hitStatus
        ? hitStatus
        : status !== "all"
        ? [status]
        : null,
      event.target.value,
      0,
      hitCompletedStartDate,
      hitCompletedEndDate,
      hitCreatedStartDate,
      hitCreatedEndDate,
      hitAnalystId,
      pageCurrent,
      pageSortBy,
      hitEvaluationType,
      label,
      this.fetchResult
    );
  };

  render() {
    const { orgName, projectName, projectId } = this.props.params;
    console.log("Props of HitOverview", this.props);
    const list = [];

    const { projectDetails,contributorOptions,status,hitUrl,pageSize,visible } = this.state;
    if (projectDetails?.contributorDetails) {
      const { contributorDetails } = projectDetails;
      contributorDetails.forEach(({ userDetails }) => {
        contributorOptions.push(userDetails);
        list.push(
          <option key={userDetails.uid} value={userDetails.uid}>
            {`${userDetails.firstName} ${userDetails.secondName}`}
          </option>
        );
      });
      list.push(
        <option key="all" value="null" selected>
          All
        </option>
      );
    }

    return (
      <div className="pr_hitsview m-t-20">
        <div className="project_sec">
          <div className="project_title f-column">
            <div className="f-center">
              <div
                onClick={() => {
                  console.log("CLICKED", orgName, projectName, projectId);
                  this.props.pushState(
                    "/projects/" +
                      this.props.params.orgName +
                      "/" +
                      this.props.params.projectName +
                      "/" +
                      this.props.params.projectId
                  );
                }}
              >
                <span className="btn_back m-r-5">
                  <img alt="back" src={BACK} />
                </span>
              </div>
            </div>
            <div className="breadcrum-sec">
              <div className="sec_holder">
                <a
                  href="data"
                  className={
                    "tilte_display fw-400 " +
                    (projectName.length > 15 ? "pro_width" : "")
                  }
                  title={projectName}
                    onClick={this.redirectBackImage.bind(this)}
                >
                  {" "}
                  {projectName}{" "}
                </a>
                {breadcrumbSeperate()}
                {status !== "all" &&
                  hitsBreadCrumbTitle(status)}
                {status === "all" && (
                  <span className="tilte_display fw-400"> Total HITs</span>
                )}
              </div>
            </div>
          </div>
          <div className="project_set">
            <ul className="tools">
              <li>
                <button
                  className="filterIcon"
                  onClick={this.toggleMenu}
                  title="Filter"
                >
                  <img alt="filter" src={filter} />
                </button>{" "}
              </li>
              <li>
                <div className="searchBox">
                  <input
                    type="text"
                    id="textfield"
                    value={hitUrl}
                    name="search"
                    onChange={this.handleAllFilterChanges}
                    onKeyDown={this.searchOnEnterPressed}
                    placeholder="Search"
                  />
                  <button className="searchIcon" onClick={this.applyFilters}>
                    <img alt="search" src={SEARCHSMALL} />
                  </button>
                </div>
              </li>
              <li>
                <div>
                <Button icon size="mini" className="btn_danger" secondary  onClick={() => this.showClearAll()} disabled={!hitUrl ? true : false}>
                    <Icon name="remove" />
                </Button>
                </div>
              </li>
              <li>
                <select
                  className="selectUI"
                  onChange={this.pageSizeChange}
                  value={pageSize}
                >
                  {getPageSizeOptions().map((x) => (
                    <option key={x}>{x}</option>
                  ))}
                </select>
              </li>
            </ul>
          </div>
        </div>
        <CardView
          propsState={this.state}
          propsData={this.props}
          handlePaginationChange={this.handlePaginationChange}
          fetchResult={this.fetchResult}
        />
        <div className="imgCardView">
          {visible && (
            <Filters
              list={list}
              propsState={this.state}
              propsData={this.props}
              toggleMenu={this.toggleMenu}
              handleAllFilterChanges={this.handleAllFilterChanges}
              getEntities={this.getEntities}
              setSelectedDate={this.setSelectedDate}
              clearFromDisable={this.state.clearFromDisable}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.loginReducer.user,
    projectDetails: state.projectReducer.projectDetails,
    selectedHitsDetails: state.projectReducer.selectedHitsDetails,
    taskType: state.projectReducer.currentPageStatus,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushState: push,
      updateCurrentPageStatus,
      updateCurrentHitsData,
      updateHitsDeatailsData,
      updatePrevPageDeatails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HitsOverviewPage);
