import React from "react";
import { Input, Select, AutoComplete } from "antd";
import { Message } from "semantic-ui-react";
import { RoleOptions } from "../../helpers/Utils";
class Register extends React.Component {
  render() {
    const {
      propsState,
      handleInputChange,
      clearErrorFields,
      setAdminValueOnSearch,
      setAdminValueOnSelect,
      createAccount,
      handleRoleChange,
    } = this.props;

    const roleOptions = RoleOptions.map((element) => (
      <option value={element.value}>{element.text}</option>
    ));

    return (
      <div className="login_box w-100">
        <div className="login_base registerBase">
          <div className="form_field">
            <label>First Name </label> <label style={{ color: "red" }}>*</label>
            <div className="input_box">
              <Input
                className="input100"
                ref={(fname) => {
                  this.fname = fname;
                }}
                type="text"
                onChange={handleInputChange}
                value={propsState.fname}
                name="fname"
                maxLength="20"
                placeholder="First Name"
              />
            </div>
            <div className="errormsg">{propsState.fnameError}</div>
          </div>
          <div className="form_field">
            <label>Last Name </label> <label style={{ color: "red" }}>*</label>
            <div className="input_box">
              <Input
                className="input100"
                ref={(lname) => {
                  this.lname = lname;
                }}
                type="text"
                onChange={handleInputChange}
                value={propsState.lname}
                name="lname"
                maxLength="20"
                placeholder="Last Name"
              />
            </div>
            <div className="errormsg">{propsState.lnameError}</div>
          </div>
          <div className="form_field">
            <label>Role </label> <label style={{ color: "red" }}>*</label>
            <div className="input_box">
              <Select
                value={propsState.role}
                onChange={handleRoleChange}
                style={{ width: "100%" }}
              >
                {roleOptions}
              </Select>
            </div>
            <div className="errormsg">{propsState.roleError}</div>
          </div>
          <div className="form_field">
            <label>Organization Name </label>{" "}
            <label style={{ color: "red" }}>*</label>
            <div className="input_box">
             <Input
                className="input100"
                ref={(organization) => {
                  this.organization = organization;
                }}
                type="text"
                onChange={handleInputChange}
                value={propsState.organization}
                name="organization"
                maxLength="80"
                placeholder="Organization Name"
              />
            </div>
            <div className="errormsg">{propsState.organizationError}</div>
          </div>
          {(propsState.role === "3" ||
            propsState.role === "5" ||
            propsState.role === "6") && (
            <div className="form_field">
              <label>Reporting To </label>{" "}
              <label style={{ color: "red" }}>*</label>
              <div className="input_box">
                <AutoComplete
                  className="m_input"
                  dataSource={
                    propsState.admin && propsState.admin.length > 2
                      ? propsState.adminUserOptions
                      : []
                  }
                  placeholder="Admin"
                  onSearch={setAdminValueOnSearch} 
                  onSelect={setAdminValueOnSelect} //Select Label from AutoSuggest
                  value={propsState.admin}
                  autoFocus="false"
                  getPopupContainer={(node) => node.parentNode}
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </div>
              <div className="errormsg">{propsState.reportingError}</div>
            </div>
          )}
          <div className="form_field">
            <label>Work Email </label> <label style={{ color: "red" }}>*</label>
            <div className="input_box">
              <Input
                className="input100"
                ref={(email) => {
                  this.email = email;
                }}
                type="email"
                onChange={handleInputChange}
                value={propsState.email}
                name="email"
                placeholder="me@Email.com"
              />
            </div>
            <div className="errormsg">{propsState.emailError}</div>
          </div>
          <div className="form_field">
            <label>Password </label> <label style={{ color: "red" }}>*</label>
            <div className="input_box">
              <Input
                className="input100"
                ref={(password) => {
                  this.password = password;
                }}
                value={propsState.password}
                onChange={handleInputChange}
                name="password"
                placeholder="Create a password"
                type="password"
              />
            </div>
            <div className="errormsg">{propsState.passwordError}</div>
          </div>
          <div className="form_field">
            <label>Mobile </label>
            <div className="input_box">
              <Input
                className="input100"
                ref={(mobileNumber) => {
                  this.mobileNumber = mobileNumber;
                }}
                type="text"
                onChange={handleInputChange}
                value={propsState.mobileNumber}
                name="mobileNumber"
                maxLength="10"
                placeholder="Mobile"
              />
            </div>
            <div className="errormsg">{propsState.mobileError}</div>
          </div>
          <Message negative className="msg-align" hidden={!propsState.error}>
            <p>{propsState.error}</p>
          </Message>
          <Message positive className="msg-align" hidden={!propsState.success}>
            <p>{propsState.success}</p>
          </Message>
          <div className="action_btn m-l-5 m-r-5 register_action">
            <button
              className="btn_base btn_borderd m-r-10"
              onClick={() => clearErrorFields(false)}
            >
              Back to Login
            </button>
            <button className="btn_base btn_primary" onClick={createAccount}>
              Register
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Register
