import React, { PropTypes } from "react";
import WorkSpaceListComponent from "../WorkspaceContainer/WorkspaceListComponent";
import { connect } from "react-redux";
import { push } from 'react-router-redux';
import { bindActionCreators } from "redux";
import { createNewWorkSpace, getAllWorkspace, updateWorkspace, deleteWorkspace, getSharedProjects,
         setSharedProjects, getactiveName, undoDeleteWorkspace } from "../../Services/WorkspaceApis";
import { LocaleProvider } from 'antd'
import { setloaderMenu } from "../../redux/reducer/projectReducer";
import { showAlert } from "../../helpers/commonFunctions";
import { setProjectWorkspaceList } from '../../redux/reducer/searchReducer';
import en_US from 'antd/lib/locale-provider/en_US';
import queryString from 'query-string'
class WorkSpaceComponent extends React.Component {
    static propTypes = {
        user: PropTypes.object,
        pushState: PropTypes.func,
        setProjectWorkspaceList: PropTypes.func
      }

      constructor(props) {
        super(props);
        this.state = {
          workspaceList : null,
          pageLoading : false,
          pagecount : 0,
          sizecount : 5,
          type : '',
          currentwPage : (this.props.currentwPage !== undefined) ? this.props.currentwPage : 1,
          totalPagesize : 0,
          total : 0,
          existingWorkspace : '',
          message :'',
          removeCheckBox : false,
          loadingMessages : "",
          tableDataLoading : false,
          workspaceActionCount : 0
        }
      }

      componentDidMount() {
        console.log("Location con",this.props.location)
        console.log('componentdidmount,',getSharedProjects(),getactiveName())
        const { pagecount, sizecount } = this.state;
        this.props.setloaderMenu(true);
        setSharedProjects(this.props.location.pathname.slice(1))
        let funType = "All"
        if (window.sessionStorage.getItem("uid") === null) {
          this.props.pushState('/projects/login');
        }
        if(getactiveName()=== 'archived') {
          funType = "archived"
          this.setState({loadingMessages : "Loading the archived workspaces"})
        }
        else {
          this.setState({loadingMessages : "Loading the workspaces"})
        }
        if(this.props.location !== undefined){
          let params = queryString.parse(this.props.location.search)
          let current_page = (params !== undefined && params.currentwPage !== undefined) ? parseInt(params.currentwPage) : 1
          this.setState({currentwPage: current_page})
         this.callWorkspaceLists(current_page, undefined, current_page)
        } else {
          this.setState({currentwPage: 1})      
          const userOrgId = this.props.user.orgId || window.localStorage.getItem("orgId");
          getAllWorkspace(userOrgId, funType, pagecount, sizecount, undefined, undefined, this.createWorkSpacecallback);
        }
    }

      callAPIBasedONFunCall = (nextProps) =>{
        const { pagecount, sizecount } = this.state;
          if(nextProps.location.pathname.slice(1) === 'archived' || nextProps.location.pathname.slice(1) ==="workspace" ){
          let typeFun = 'All'
          if (window.sessionStorage.getItem("uid") === null) {
            this.props.pushState('/projects/login');
          }
            if(nextProps.location.pathname.slice(1) === 'archived'){
              typeFun = 'archived'
              this.setState({loadingMessages : "Loading the archived workspaces"})
          }
          else {
              this.setState({loadingMessages : "Loading the workspaces"})
          }
            setSharedProjects('')
            this.props.setloaderMenu(true);
            this.setState({ removeCheckBox : true})
            const userOrgId = this.props.user.orgId || window.localStorage.getItem("orgId");
            getAllWorkspace(userOrgId, typeFun, pagecount, sizecount, undefined, undefined, this.createWorkSpacecallback);
        }
      }

      componentWillReceiveProps(nextProps) {
        if(this.props.location !== undefined){
          let params = queryString.parse(this.props.location.search)
          let current_page = (params !== undefined && params.currentwPage !== undefined) ? parseInt(params.currentwPage) : 1
          this.setState({currentwPage: current_page})        
        } 
        this.callAPIBasedONFunCall(nextProps)
 
      }

      routeToProjects = (workspaceId, workspaceName, type) => {
    	  const userOrgId = this.props.user.orgId || window.localStorage.getItem("orgId");
    	  this.props.pushState('/workspace/'+workspaceName+'/'+workspaceId+'/'+userOrgId+'/'+type);
      }
      goToProjects = (workspaceId, workspaceName, type, current_page, strquery) => {
        const userOrgId = this.props.user.orgId || window.localStorage.getItem("orgId");
        this.setState({currentwPage: current_page})
    	  this.props.pushState('/workspace/'+workspaceName+'/'+workspaceId+'/'+userOrgId+'/'+type+'/?'+strquery);
      }

      createNewWorkspace = (workspaceName) => {
    	const userOrgId = this.props.user.orgId || window.localStorage.getItem("orgId");
    	let workspace = {workspaceName : workspaceName, orgId: userOrgId }
        this.props.setloaderMenu(true);
        this.setState({type: 'insert',existingWorkspace:false})
        createNewWorkSpace(workspace, this.updateWorkSpacecallback);
      }

      callWorkspaceLists = (page, sorter, current_page) => {
        const { sizecount } = this.state;
        this.setState({ tableDataLoading: true, currentwPage: current_page });
        let funType = 'All'
        if (window.sessionStorage.getItem("uid") === null) {
          this.props.pushState('/projects/login');
        }
        if(getactiveName() === "archived") {
          funType = 'archived'
        }
        let field = (sorter && sorter.field) ? sorter.field : null;
        let order = (sorter && sorter.order) ? sorter.order : null;
        const userOrgId = this.props.user.orgId || window.localStorage.getItem("orgId");
        getAllWorkspace(userOrgId, funType , page - 1, sizecount, field, order, this.createWorkSpacecallback);
      }

      updateWorkSpacecallback = (err, response) => {
        const { pagecount, sizecount } = this.state;
        if(!err){
          let funType = 'All'
          if(getactiveName() === "archived") {
            funType = 'archived'
          }
          const userOrgId = this.props.user.orgId || window.localStorage.getItem("orgId");
          getAllWorkspace(userOrgId, funType, pagecount, sizecount, undefined, undefined, this.createWorkSpacecallback);
        }
        else{
          this.props.setloaderMenu(false);
          this.setState({ existingWorkspace:true, message : response.body.message, type: ''})
        }
      }

      createWorkSpacecallback = (err, response) => {
        const { workspaceActionCount, type } = this.state;
        if(!err){
          this.props.setloaderMenu(false);
          this.setState({workspaceList : response.body,
                         total : response.body.total, existingWorkspace: false, removeCheckBox: false})
          const modifyNotification = (workspaceActionCount === 1) ?
            'workspace is' : (workspaceActionCount > 1) ?
            'workspaces are' : 'workspace(s)';
          if(type === "deleteAll") {
            showAlert(`The selected ${modifyNotification} deleted successfully.`,'success')
          }
          if(type === "archiveAll" || type === "archive" ) {
            showAlert(`The selected ${modifyNotification} moved to Archive successfully.`,'success')
          }
          if(type === "update") {
            showAlert("The workspace name updated successfully.",'success')
          }
          if(type === "insert") {
            showAlert("The workspace created successfully.",'success')
          }
          if(type === "recoverAll") {
            showAlert("The selected workspace(s) recovered successfully.",'success')
          }
          this.setState({type : ''})
        }
        this.setState({ tableDataLoading: false, workspaceActionCount: 0 });
        if(response.body && response.body["projectWorkspaceList"])
          this.props.setProjectWorkspaceList(response.body["projectWorkspaceList"])
      }

      updateWorkspace = (workspace) => {
        this.props.setloaderMenu(true);
        this.setState({ type:'update', })
        updateWorkspace (workspace, this.updateWorkSpacecallback)
      }

      deleteWorkspace = (workspaceIDs,type) =>{
        this.setState({workspaceActionCount : workspaceIDs.length})
        let uiDeleteType="archiveAll";
        if(getactiveName() === 'archived'){
          this.setState({loadingMessages : "Loading the archived workspaces"})
          uiDeleteType="deleteAll";
          }
        else {
            this.setState({loadingMessages : "Loading the workspaces"})
        }
        this.props.setloaderMenu(true);
        this.setState({type: uiDeleteType,  existingWorkspace:false})
        deleteWorkspace(workspaceIDs,type,this.updateWorkSpacecallback)
      }

      // Function to recover the selected Workspaces
      RecoverSelectedWorkspace = (recoverList) => {
        this.props.setloaderMenu(true);
        this.setState({type : "recoverAll",  loadingMessages : "Recovering the archived workspaces"})
        undoDeleteWorkspace(recoverList, this.updateWorkSpacecallback)
      }

      cancelShowWorkspace = () => {
        this.setState({existingWorkspace: false})
      }

    render() {
      const { workspaceList, total, existingWorkspace, removeCheckBox, loadingMessages, tableDataLoading, message, currentwPage } = this.state;
        return (
            <div className="pr_workspace">
              <LocaleProvider locale={en_US}>
                <WorkSpaceListComponent
                routeToProjects = {this.routeToProjects}
                goToProjects = {this.goToProjects}
                createNewWorkspace = {this.createNewWorkspace}
                pageLoading = {this.props.loading}
                workspaceList = {workspaceList}
                updateWorkspace = {this.updateWorkspace}
                deleteWorkspace = {this.deleteWorkspace}
                total = {total}
                callWorkspaceLists = {this.callWorkspaceLists}
                existingWorkspace = {existingWorkspace}
                message = {message}
                RecoverSelectedWorkspace = {this.RecoverSelectedWorkspace}
                removeCheckBox = {removeCheckBox}
                loadingMessages = {loadingMessages}
                tableDataLoading = {tableDataLoading}
                cancelShowWorkspace = {this.cancelShowWorkspace}
                currentwPage = {currentwPage}
                >
                </WorkSpaceListComponent>
              </LocaleProvider>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.loginReducer.user, 
    };
  };


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushState: push,
      setProjectWorkspaceList,
      setloaderMenu
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps) (WorkSpaceComponent);