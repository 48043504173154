import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import {
    Button as AntButton,
    Icon as AntIcon,
    Upload as AntUpload,
  } from "antd";

  import {
    Checkbox,
    Button,
    Form,
    Accordion,
  } from "semantic-ui-react";

import {
    VIDEO_CLASSIFICATION,
    VIDEO_BOUNDING_BOX,
    IMAGE_CLASSIFICATION,
    DOCUMENT_ANNOTATION,
    TEXT_SUMMARIZATION,
    IMAGE_POLYGON_BOUNDING_BOX,
    IMAGE_POLYGON_BOUNDING_BOX_V2,
    POS_TAGGING,
    POS_TAGGING_GENERIC,
    TEXT_CLASSIFICATION,
    TEXT_MODERATION,
    IMAGE_BOUNDING_BOX,
    DICOM_CLASSIFICATION,
    DICOM_SEGMENTATION,
    textClassificationJsonSample,
    IMAGE_MASKING,
    IMAGE_SEGMENTATION,
    checkFileSize,
    imageBoundingSample,
    imagePolyBoundingSample,
    posSample
  } from "../../../helpers/Utils";
  import BACK from "../../../images/Arrow_Simple_left1.svg";
  import "../ImportProjects.css";
const bytes = require("bytes");
class TaggerImportFileUpload extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      count: 0
    };
  }

  render() {
    const {propsState,type,projectCreationComplete,projectCreated,uploadType,
        uploadprops,advPanels,loading,backUrl,handleSubmit,handleAntUploadFile,handleAntUploadFileCompare,
        advancedOptionChange,selectFilewithPreannoInputdata} = this.props
    return (
      <div>
        {!projectCreationComplete &&
              projectCreated &&
              uploadType === "Pre-Annotated" && (
                <div className="pr_selectfile m-t-30">
                  <div className="f-center">
                    <div
                      className="m-r-5"
                      onClick={() => this.props.pushState(backUrl)}
                    >
                      <span className="t_btn_back">
                        <img alt="back" src={BACK} />
                      </span>
                    </div>
                    <h3 className="sec_tilte" style={{ marginTop: "0" }}>
                      Import Data
                    </h3>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h3>Select file with Pre-Annotated data </h3>
                    <Button
                      className="text-left default_btn"
                      onClick={selectFilewithPreannoInputdata}
                    >
                      Change Import Type
                    </Button>
                  </div>
                  <div className="select_holder m-b-15">
                    <div className="b_l textClassify">
                      {(type === POS_TAGGING ||
                        type === POS_TAGGING_GENERIC ||
                        type === IMAGE_MASKING ||
                        type === DOCUMENT_ANNOTATION) && (
                        <div id="con">
                          <p>
                            Please upload a text file with each line in file
                            having input sentence in following json
                            format.Format is similar to the annotated and
                            downloaded json file from ProNotate.Max size 10MB
                          </p>
                          <div className="codeArea">
                            <code>
                              {JSON.stringify(posSample)}
                            </code>
                          </div>
                          <p>
                            <b>Content</b> contains input text,{" "}
                            <b>annotation</b> has the labeled content,{" "}
                            <b>extras</b> is for some extra columns that you
                            want to show with each row.
                          </p>
                        </div>
                      )}

                      {type === IMAGE_BOUNDING_BOX && (
                        <div className="w-100">
                          <p>
                            Please upload a text file with each line in file
                            having input sentence in following json
                            format.Format is similar to the annotated and
                            downloaded json file from ProNotate.Max size 10MB
                          </p>
                          <div className="codeArea">
                            <code>
                              {JSON.stringify(imageBoundingSample)}
                            </code>
                          </div>
                          <p>
                            <b>Content</b> contains image URL, <b>annotation</b>{" "}
                            has the labeled content [top-left and right-bottom
                            co-ordinate], <b>extras</b> is for some extra
                            columns that you want to show with each row.
                          </p>
                        </div>
                      )}

                      {type === IMAGE_POLYGON_BOUNDING_BOX && (
                        <div className="w-100">
                          <p>
                            Please upload a text file with each line in file
                            having input sentence in following json
                            format.Format is similar to the annotated and
                            downloaded json file from ProNotate.Max size 10MB
                          </p>
                          <div className="codeArea">
                            <code>
                              {JSON.stringify(imagePolyBoundingSample)}
                            </code>
                          </div>
                          <p>
                            <b>Content</b> contains image URL, <b>annotation</b>{" "}
                            has the co-ordinate of polygon [first and last value
                            of array is of same co-ordinate], <b>extras</b> is
                            for some extra columns that you want to show with
                            each row.
                          </p>
                        </div>
                      )}

                      {(type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
                        type === IMAGE_SEGMENTATION) && (
                        <div className="w-100">
                          <p>
                            Please upload a text file with each line in file
                            having input sentence in following json
                            format.Format is similar to the annotated and
                            downloaded json file from ProNotate.Max size 10MB
                          </p>
                          <div className="codeArea">
                            <code>
                              {JSON.stringify(imagePolyBoundingSample)}
                            </code>
                          </div>
                          <p>
                            <b>Content</b> contains image URL, <b>annotation</b>{" "}
                            has the co-ordinate of polygon [first and last value
                            of array is of same co-ordinate], <b>extras</b> is
                            for some extra columns that you want to show with
                            each row.
                          </p>
                        </div>
                      )}

                      {type === VIDEO_BOUNDING_BOX && (
                        <div>
                          <p>
                            Please upload a text file with each line in file
                            having input sentence in following json
                            format.Format is similar to the annotated and
                            downloaded json file from ProNotate.Max size 10MB
                          </p>
                          <div className="codeArea">
                            <code>
                              {JSON.stringify(imagePolyBoundingSample)}
                            </code>
                          </div>
                          <p>
                            <b>Content</b> contains video URL, <b>annotation</b>{" "}
                            has the co-ordinate of polygon [first and last value
                            of array is of same co-ordinate], <b>extras</b> is
                            for some extra columns that you want to show with
                            each row.
                          </p>
                        </div>
                      )}
                      {type === DICOM_SEGMENTATION && (
                        <div>
                          <p>
                            Please upload a text file with each line in file
                            having input sentence in following json
                            format.Format is similar to the annotated and
                            downloaded json file from ProNotate.Max size 10MB
                          </p>
                          <div className="codeArea">
                          <code style={{color:"black"}}>
                            {JSON.stringify(imagePolyBoundingSample)}
                          </code>
                          </div>
                          <p>
                            <b>Content</b> contains image URL, <b>annotation</b>{" "}
                            has the co-ordinate of polygon [first and last value
                            of array is of same co-ordinate], <b>extras</b> is
                            for some extra columns that you want to show with
                            each row.
                          </p>
                        </div>
                      )}
                      {(type === TEXT_SUMMARIZATION ||
                        type === TEXT_MODERATION) && (
                        <div>
                          <p>
                            Please upload a text file with each line in file
                            having input sentence in following tab seperated
                            format. Max size 10MB
                            <pre>
                              Text Line Result Text Extra KeyValue
                              Pair1(optional) Extra KeyValue Pair1(optional) AFP
                              - India's Tata Iron and Steel Company Ltd. Tata
                              Iron and Steel Company. id=1 content=games British
                              Foreign Minister UK Foreign Minister id=2
                              content=UK site=34 Japan carmaker Toyota Japanese
                              carmaker Toyota id=100
                            </pre>
                            <b>Content</b> contains input text,{" "}
                            <b>annotation</b> has the labeled content,{" "}
                            <b>extras</b> is for some extra columns that you
                            want to show with each row.
                          </p>
                        </div>
                      )}
                      {type === TEXT_CLASSIFICATION && (
                        <Form>
                          <Form.Field>
                            Selected value: <b>{propsState.value}</b>
                          </Form.Field>
                          <Form.Field>
                            <Checkbox
                              radio
                              label="TSV/CSV"
                              name="checkboxRadioGroup"
                              value="tsv"
                              checked={propsState.selectedFormat === "tsv"}
                              onChange={advancedOptionChange.bind(
                                this,
                                "selectedFormat"
                              )}
                            />
                          </Form.Field>
                          <Form.Field>
                            <Checkbox
                              radio
                              label="JSON"
                              name="checkboxRadioGroup"
                              value="json"
                              checked={propsState.selectedFormat === "json"}
                              onChange={advancedOptionChange.bind(
                                this,
                                "selectedFormat"
                              )}
                            />
                          </Form.Field>
                        </Form>
                      )}
                      {type === TEXT_CLASSIFICATION &&
                        propsState.selectedFormat === "json" && (
                          <div className="w-100">
                            <p>
                              Please upload a text file with each line in file
                              having input sentence in json format.This is same
                              as download format from ProNotate.Max size 10MB
                            </p>
                            <pre>{textClassificationJsonSample}</pre>
                          </div>
                        )}
                      {type === TEXT_CLASSIFICATION &&
                        propsState.selectedFormat === "tsv" && (
                          <div className="w-100">
                            <p>
                              Please upload a text file with each line in file
                              having input sentence in following tab seperated
                              format. Max size 10MB
                            </p>
                            <pre>
                              Text Line Comma Separated Labels Extra KeyValue
                              Pair1(optional) Extra KeyValue Pair1(optional)
                              <br />
                              <br />
                              <br />
                              <br />
                              AFP - India's Tata Iron and Steel Company Ltd.
                              Class1, Class2 id=1 content=games
                              <br />
                              British Foreign Minister Class4,Class5 id=2
                              content=UK site=34
                              <br />
                              Japan carmaker Toyota Class1 id=100
                            </pre>
                          </div>
                        )}
                      {type === IMAGE_CLASSIFICATION && (
                        <div className="w-100">
                          <p>
                            Please upload a text file with each line in file
                            having input sentence in following tab seperated
                            format. Max size 10MB
                          </p>
                          <pre>
                            Image_URL Comma Separated Labels Extra KeyValue
                            Pair1(optional) Extra KeyValue Pair1(optional)
                            <br />
                            <br />
                            <br />
                            <br />
                            http://amazonaws.com/grande.jpg Class1, Class2,
                            Class3 id=1 context=image1
                            <br />
                            http://amazonaws.com/Carraway.jpg Class1 id=2
                            context=image2 site=34
                            <br />
                            http://.amazonaws.com/slices.jpg Class1, Class2
                            id=32
                          </pre>
                        </div>
                      )}
                      {type === VIDEO_CLASSIFICATION && (
                        <div className="w-100">
                          <p>
                            Please upload a text file with each line in file
                            having input sentence in following tab seperated
                            format. Max size 10MB
                          </p>
                          <pre>
                            Video_URL Comma Separated Labels Extra KeyValue
                            Pair1(optional) Extra KeyValue Pair1(optional)
                            <br />
                            <br />
                            <br />
                            <br />
                            http://amazonaws.com/grande.mp4 Class1, Class2,
                            Class3 id=1 context=image1
                            <br />
                            http://amazonaws.com/Carraway.mp4 Class1 id=2
                            context=image2 site=34
                            <br />
                            http://.amazonaws.com/slices.mp4 Class1, Class2
                            id=32
                          </pre>
                        </div>
                      )}
                      {type === TEXT_SUMMARIZATION && (
                        <div className="w-100">
                          <p>
                            Please upload a text file with each line in file
                            having input sentence in following tab seperated
                            format. Max size 10MB
                            <pre>
                              Text Line Result Text Extra KeyValue
                              Pair1(optional) Extra KeyValue Pair1(optional) AFP
                              - India's Tata Iron and Steel Company Ltd. Tata
                              Iron and Steel Company. id=1 content=games British
                              Foreign Minister UK Foreign Minister id=2
                              content=UK site=34 Japan carmaker Toyota Japanese
                              carmaker Toyota id=100
                            </pre>
                            <b>Content</b> contains input text,{" "}
                            <b>annotation</b> has the labeled content,{" "}
                            <b>extras</b> is for some extra columns that you
                            want to show with each row.
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="b_r">
                      <form
                        encType="multipart/form-data"
                        action=""
                        key="importFile"
                        className="text-center"
                      >
                        <AntUpload
                          {...uploadprops}
                          disabled={loading}
                          onChange={handleAntUploadFile}
                          className="upload_filebtn"
                        >
                          <AntButton>
                            <AntIcon type="upload" /> Click to Upload
                          </AntButton>
                        </AntUpload>

                        <div className="upload_info">
                          <h4>{propsState.fileName}</h4>

                          {propsState.file && (
                            <p> File Size: {bytes(propsState.file.size)} </p>
                          )}
                          <p
                            className="error"
                            disabled={!propsState.errors.file}
                          >
                            {propsState.errors.file}
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="txt-center msg_txt">
                    <p className="error" disabled={!propsState.errors.submit}>
                      {propsState.errors.submit}
                    </p>
                    <Button
                      className="base_btnsubmit m-b-15"
                      type="submit"
                      disabled={
                        propsState.file === undefined ||
                        checkFileSize(
                          bytes(propsState.file.size).toString(),
                          propsState.uploadType
                        )
                          ? true
                          : false
                      }
                      onClick={handleSubmit}
                    >
                      Submit 4
                    </Button>
                  </div>
                </div>
              )}
            {projectCreated &&
              !propsState.projectCreationComplete &&
              propsState.uploadType === "Raw" && (
                <div className="pr_selectfile m-t-30">
                  <div className="f-center">
                    <div
                      className="m-r-5"
                      onClick={() => this.props.pushState(backUrl)}
                    >
                      <span className="t_btn_back">
                        <img alt="back" src={BACK} />
                      </span>
                    </div>
                    <h3 className="sec_tilte" style={{ marginTop: "0" }}>
                      Import Data
                    </h3>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h3>Select file with input data </h3>
                    <Button
                      className="text-left default_btn"
                      onClick={selectFilewithPreannoInputdata}
                    >
                      Change Import Type
                    </Button>
                  </div>
                  <div className="select_holder m-b-15">
                    <div className="b_l">
                      {type === POS_TAGGING && (
                        <p>Please upload a text/doc/pdf file </p>
                      )}
                      {type === POS_TAGGING_GENERIC && (
                        <div>
                          <p>
                            Upload a Text/CSV file where each line has one
                            data-item to be tagged.
                          </p>
                          <Accordion
                            as={Form.Field}
                            panels={advPanels}
                            style={{ fontSize: "14px" }}
                          />
                        </div>
                      )}
                      {type === TEXT_SUMMARIZATION && (
                        <div className="w-100">
                          <p>
                            Please upload a text file with each line in file
                            having sentence to be summarized.
                          </p>
                          <strong> OR </strong>
                          <p>
                            A zip file of all the text documents to be
                            summarized. Max file size is 100 MB for free plans
                          </p>
                        </div>
                      )}
                      {type === TEXT_MODERATION && (
                        <div className="w-100">
                          <p>
                            Please upload a text file with each line in file
                            having sentence to be moderated.
                          </p>
                          <strong> OR </strong>
                          <p>
                            A zip file of all the text documents to be
                            moderated. Max file size is 100 MB for free plans
                          </p>
                        </div>
                      )}
                      {type === TEXT_CLASSIFICATION && (
                        <div className="w-100">
                          <p>
                            Please upload a text file with each line in file
                            having sentence to be classified.
                          </p>
                          <strong> OR </strong>
                          <p>
                            A zip file of all the text documents to be
                            classified. Max file size is 100 MB for free plans
                          </p>
                        </div>
                      )}
                      {type === IMAGE_CLASSIFICATION && (
                        <div className="w-100">
                          <p>Upload a text file containing URLs of images. </p>
                          <strong> OR </strong>
                          <p>
                            A zip file of all the images. Max file size is 100
                            MB for free plans
                          </p>
                        </div>
                      )}
                      {type === VIDEO_CLASSIFICATION && (
                        <p>Upload a text file containing URLs of videos.</p>
                      )}
                      {(type === IMAGE_BOUNDING_BOX ||
                        type === IMAGE_POLYGON_BOUNDING_BOX ||
                        type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
                        type === IMAGE_SEGMENTATION ||
                        type === IMAGE_MASKING) && (
                        <div className="w-100">
                          <p>Upload a text file containing URLs of images. </p>
                          <strong> OR </strong>
                          <p>
                            A zip file of all the images. Max file size is 100
                            MB for free plans
                          </p>
                          {(type === IMAGE_MASKING ||
                            type === IMAGE_SEGMENTATION) && (
                            <div className="b_r">
                              <form
                                encType="multipart/form-data"
                                action=""
                                key="importFile"
                                className="text-center"
                              >
                                <AntUpload
                                  {...uploadprops}
                                  disabled={loading}
                                  onChange={handleAntUploadFile}
                                  className={
                                    type === IMAGE_MASKING ||
                                    type === IMAGE_SEGMENTATION
                                      ? "upload_filebtn"
                                      : "upload_filebtn1"
                                  }
                                >
                                  <AntButton>
                                    <AntIcon type="upload" /> Click to Upload
                                  </AntButton>
                                </AntUpload>

                                <div
                                  className={
                                    type === IMAGE_MASKING ||
                                    type === IMAGE_SEGMENTATION
                                      ? "upload_info"
                                      : "upload_info1"
                                  }
                                >
                                  <h4>{propsState.fileName}</h4>
                                  {propsState.file && (
                                    <p>
                                      {" "}
                                      File Size: {bytes(
                                        propsState.file.size
                                      )}{" "}
                                    </p>
                                  )}
                                  <p
                                    className="error"
                                    disabled={!propsState.errors.file}
                                  >
                                    {propsState.errors.file}
                                  </p>
                                </div>
                              </form>
                            </div>
                          )}
                        </div>
                      )}
                      {type === VIDEO_BOUNDING_BOX && (
                        <p>Upload a text file containing URLs of videos.</p>
                      )}
                      {type === DOCUMENT_ANNOTATION && (
                        <div className="w-100">
                          <p>Please upload a valid text/doc/pdf file.</p>
                          <strong> OR </strong>
                          <p>
                            A zip file of all the text/pdf/doc documents. Max
                            file size is 100 MB for free plans
                          </p>
                          <Accordion
                            as={Form.Field}
                            panels={advPanels}
                            style={{ fontSize: "14px" }}
                          />
                        </div>
                      )}
                      {type === DICOM_CLASSIFICATION && (
                        <div className="w-100">
                          <p>Upload a text file containing URLs of images. </p>
                          <strong> OR </strong>
                          <p>
                            A zip file of all the images. Max file size is 100
                            MB for free plans
                          </p>
                        </div>
                      )}
                      {type === DICOM_SEGMENTATION && (
                        <div className="w-100">
                          <p>Upload a text file containing URLs of images. </p>
                          <strong> OR </strong>
                          <p>
                            A zip file of all the images. Max file size is 100
                            MB for free plans
                          </p>
                        </div>
                      )}
                    </div>
                    {(type === IMAGE_MASKING ||
                      type === IMAGE_SEGMENTATION) && (
                      <div className="b_r">
                        <h3 className="h3tag">
                          Upload dataset to compare with reference images while
                          annotating
                        </h3>
                        <form
                          encType="multipart/form-data"
                          action=""
                          key="importFile"
                          className="text-center"
                        >
                          <AntUpload
                            {...uploadprops}
                            disabled={loading}
                            onChange={handleAntUploadFileCompare}
                            className="upload_filebtn"
                          >
                            <AntButton>
                              <AntIcon type="upload" /> Click to Upload
                            </AntButton>
                          </AntUpload>
     
                          <div className="upload_info">
                            <h4>{propsState.fileNameCompare}</h4>
                            {propsState.fileCompare && (
                              <p>
                                {" "}
                                File Size: {bytes(
                                  propsState.fileCompare.size
                                )}{" "}
                              </p>
                            )}
                            <p
                              className="error"
                              disabled={!propsState.errors.file}
                            >
                              {propsState.errors.file}
                            </p>
                          </div>
                        </form>
                      </div>
                    )}
                    {type !== IMAGE_MASKING && type !== IMAGE_SEGMENTATION && (
                      <div className="b_r">
                        <form
                          encType="multipart/form-data"
                          action=""
                          key="importFile"
                          className="text-center"
                        >
                          <AntUpload
                            {...uploadprops}
                            disabled={loading}
                            onChange={handleAntUploadFile}
                            className="upload_filebtn"
                          >
                            <AntButton>
                              <AntIcon type="upload" /> Click to Upload
                            </AntButton>
                          </AntUpload>
                          <div className="upload_info">
                            <h4>{propsState.fileName}</h4>
                            {propsState.file && (
                              <p> File Size: {bytes(propsState.file.size)} </p>
                            )}
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                  <div className="txt-center msg_txt">
                    <p className="error" disabled={!propsState.errors.submit}>
                      {propsState.errors.submit}
                    </p>
                    <Button
                      className="base_btnsubmit m-b-15"
                      type="submit"
                      disabled={
                        propsState.file === undefined ||
                        checkFileSize(
                          bytes(propsState.file.size).toString(),
                          propsState.uploadType
                        )
                          ? true
                          : false
                      }
                      onClick={handleSubmit}
                    >
                      Submit 5
                    </Button>
                  </div>
                </div>
              )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        pushState: push, 
      },
      dispatch
    );

export default connect(
    null,
     mapDispatchToProps
    )(TaggerImportFileUpload);
