import { getUidToken } from '../../helpers/commonFunctions';
import { BASE_URL } from '../../helpers/Constants';
import superagent from 'superagent';

const GET_SEARCH_DATA = 'GET_SEARCH_DATA';
const QUERY_BASED_SEARCH = 'QUERY_BASED_SEARCH';
const QUERY_BASED_SEARCH_SUCCESS = 'QUERY_BASED_SEARCH_SUCCESS';
const QUERY_BASED_SEARCH_FAILURE = 'QUERY_BASED_SEARCH_FAILURE';
const QUERY_BASED_ARCHIVE = 'QUERY_BASED_ARCHIVE';
const QUERY_BASED_ARCHIVE_SUCCESS = 'QUERY_BASED_ARCHIVE_SUCCESS';
const QUERY_BASED_ARCHIVE_FAILURE = 'QUERY_BASED_ARCHIVE_FAILURE';
const ARCHIVE_FALSE = 'ARCHIVE_FALSE';
const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';
const SET_SEARCH_CRITERIA = 'SET_SEARCH_CRITERIA';
const RESET = 'RESET';

const initialState = {
  projectWorkspaceList: null,
  searchResult: null,
  loading: false,
  archivedSuccess: false,
  searchText: '',
  searchCriteria: 'text',
  searchType: '',
};


export default function searchReducer(state = initialState, action = {}) {
    console.log('search reduce called ', state, action);
    switch (action.type) {
        case GET_SEARCH_DATA:
            return {
                ...state,
                projectWorkspaceList: action.payload
            };
        case QUERY_BASED_SEARCH:
            return {
                ...state,
                loading: true
            }
        case QUERY_BASED_SEARCH_SUCCESS:
            console.log("XXXXXXXXXXXX",action)
            return {
                ...state,
                loading: false,
                searchResult: action.payload.body
            }
        case QUERY_BASED_SEARCH_FAILURE:
            return {
                ...state,
                loading: false,
                searchResult: 'error'
            }
        case QUERY_BASED_ARCHIVE:
            return {
                ...state,
                loading: true
            }
        case QUERY_BASED_ARCHIVE_SUCCESS:
            return {
                ...state,
                loading: false,
                archivedSuccess: true,
                searchResult: action.payload.body
            }
        case QUERY_BASED_ARCHIVE_FAILURE:
            return {
                ...state,
                loading: false,
                searchResult: 'error'
            }
        case ARCHIVE_FALSE:
            return {
                ...state,
                archivedSuccess: false
            }
        case SET_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.payload
            }
        case SET_SEARCH_CRITERIA:
            return {
                ...state,
                searchCriteria: action.payload
            }
        case SET_SEARCH_TYPE:
            return {
                ...state,
                searchType: action.payload
            }
        case RESET:
            return {
                ...state,
            }
        default:
            return state
        // END
    }
}

export function setProjectWorkspaceList(payload) {
    console.log("Payload for worspacelist",payload)
  return {
    type: GET_SEARCH_DATA,
    payload
  };
}

export function getSearchProjectWorkspace(orgId, page, size, searchText, sortField, sortOrder, searchType) {
    return (dispatch) => {
        const { uid, token } = getUidToken();
        let reqBody = {};
        if (sortField && sortOrder) {
            reqBody = { sortBy: sortField, sortOrder: sortOrder === "ascend" ? "ASC" : "DESC" };
        }

        dispatch({ type: QUERY_BASED_SEARCH }); // Dispatch the initial action

        superagent
            .post(BASE_URL + orgId + "/getSearchResult?page=" + page + "&size=" + size + "&searchText=" + searchText + "&searchType=" + searchType)
            .send(reqBody)
            .set("uid", uid)
            .set("token", token)
            .end((error, payload) => {
                if (error) {
                    console.error("Error in API request:", error);
                    dispatch({ type: QUERY_BASED_SEARCH_FAILURE, payload: error });
                } else {
                    dispatch({ type: QUERY_BASED_SEARCH_SUCCESS, payload: payload });
                }
            });
    };
}

export function archiveProjectWorkspaceProcess(projectWorkspaceList, orgId, page, size, searchText, searchType,isArchive) {
    return (dispatch) => {
        const { uid, token } = getUidToken();
        let reqBody = {};

        dispatch({ type: QUERY_BASED_ARCHIVE }); // Dispatch the initial action

        superagent
            .post(BASE_URL + orgId + "/archiveProjectWorkspace?page=" + page + "&size=" + size + "&searchText=" + searchText + "&searchType=" + searchType + "&isArchive=" + isArchive)
            .send(projectWorkspaceList)
            .set("uid", uid)
            .set("token", token)
            .end((error, payload) => {
                if (error) {
                    console.error("Error in API request:", error);
                    dispatch({ type: QUERY_BASED_ARCHIVE_FAILURE, payload: error });
                } else {
                    dispatch({ type: QUERY_BASED_ARCHIVE_SUCCESS, payload: payload });
                }
            });
    };
}

export function setArchiveStatus() {
    return {
      type: ARCHIVE_FALSE
    };
}
export function setSearchText(payload) {
    return {
        type: SET_SEARCH_TEXT,
        payload
    }
}
export function setSearchCriteria(payload) {
    console.log("setSearchCriteria payload",payload)
    return {
        type: SET_SEARCH_CRITERIA,
        payload
    }
}
export function setSearchType(payload) {
    return {
        type: SET_SEARCH_TYPE,
        payload
    }
}
export function reset() {
    return {
        type: RESET
    };
  }

export function queryBasedSearch() {
    return {
        type: QUERY_BASED_SEARCH
    };
}

export function queryBasedSearchSuccess(data) {
    console.log("Query search data",data.projectWorkspaceList)
    return {
        type: QUERY_BASED_SEARCH_SUCCESS,
        payload: data.projectWorkspaceList
    };
}

export function queryBasedSearchFailure(error) {
    return {
        type: QUERY_BASED_SEARCH_FAILURE,
        payload: error
    };
}