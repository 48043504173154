import React from "react";
import "antd/dist/antd.css";
import { Modal, Table, Input, LocaleProvider, Icon, Button } from 'antd';
import en_US from 'antd/lib/locale-provider/en_US';
import { showAlert } from "../../helpers/commonFunctions";

class NERTagsModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: []
        }

        this.columns = [
            {
              title: "Defined Name",
              dataIndex: "desc",
              width: "50%"
            },
            {
              title: "Client Prefered Name",
              dataIndex: "slabel",
              width: "50%",
              render: (text, record, index) =>  
                        <span style={{display:'flex'}}>
                          <Input value = {text} onChange = {(e)=>this.renderValue(e, record)} />
                          <Icon type="cross" title = "Delete" onClick={() => this.removeTag(record)}/>
                        </span>,
            }
          ];
      }

    componentWillMount() {
        console.log('this.props values==============>',this.props)
        console.log(this.props.NERTags)
        let dataSource = []
        let tags = JSON.parse(this.props.NERTags)
        tags.map((tag, index)=> {
            let data = {}
            data.key = index.toString()
            data.desc = tag.desc
            data.slabel = tag.slabel
            dataSource.push(data)
        })
        console.log('taga===============>',dataSource)
        this.setState({dataSource : dataSource})
        console.log('state-=============>',this.state.dataSource)
    }

    renderValue = (data, record) => {
      console.log('value======>',data)
      console.log('record======>',record)
    
      const { dataSource } = this.state;
      let dataSrc = dataSource
      const index = dataSource.findIndex(item => record.key === item.key);
      dataSrc[index].slabel = data.target.value
      console.log('new dtasetgjhihk==========>',dataSrc)
      this.setState({dataSource : dataSrc})
    }
    
    removeTag = (removeRec) => {
      console.log('removeREc----------->',removeRec)
      const { dataSource } = this.state;
      const index = dataSource.findIndex(item => removeRec.key === item.key);
      console.log('index===========>',index)
      let dataSrc = dataSource
      dataSrc.splice( index, 1 )
      this.setState({dataSource : dataSrc })
    }
      
    saveChanges = () => {
        console.log('save the changes')
        const { dataSource } = this.state;
        // Validation
        let validateFields = false
        dataSource.map((data)=>{
          if(data.slabel.trim() === ""){
            validateFields = true
          }
        })
        validateFields ? showAlert("Label cannot be Empty.",'warning') : this.props.updateValue(dataSource)
    }

    cancelChanges = () => {
        console.log('cancel the changes')
        this.props.closeModel()
    }

      render () {

        console.log('props=============>',this.props)
        const { nermodelVisible } = this.props
        const { dataSource } = this.state;
        console.log('datasoucr=============>',this.state.dataSource)
        const columns = this.columns

        return (
            <div>
                <LocaleProvider locale={en_US}>
                    <Modal
                        title="Pre Populated Values"
                        visible={nermodelVisible}
                        onOk={this.saveChanges}
                        onCancel={this.cancelChanges}
                        footer={[
                          <Button key="submit" type="primary" onClick={this.saveChanges}>
                            Save
                          </Button>,
                        ]}
                        >
                            <Table
                                bordered
                                dataSource={dataSource}
                                columns={columns}
                                pagination = {{ pageSize: 5 }}
                                />
                    </Modal>
                </LocaleProvider>
            </div>
        )
    }
}

export default NERTagsModal
