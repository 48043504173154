import React from "react";
import { Form } from "semantic-ui-react";
import { Button, Tooltip } from "antd";
import "antd/dist/antd.css";
class ImageAttributeForm extends React.Component {

  render() {
    let { value, index, addAttribute, total,handleAttributeChange,removeAttribute,role } = this.props;
    return (
      <Form className="div-classify">
        {!role && <Form.Input
          className="w-33 p-r-5"
          onChange={(e)=>handleAttributeChange("displayName",e,index)}
          id="displayName"
          value={value.displayName}
          placeholder="Attribute Name"
        /> }
        <Form.Input
          className="w-33"
          onChange={(e)=>handleAttributeChange("classes",e,index)}
          id="classes"
          compact
          value={value.classes}
          placeholder="List of comma separated image attribute"
        />
        {total - 1 === index && (
          <Tooltip title="Click here to add an image attribute">
            <Button
              className="m-l-10"
              onClick={addAttribute}
              type="link"
              icon="plus"
              style={{ color: "#0e77ca", border: "thin" }}
            />
          </Tooltip>
        )}
        {total - 1 !== index && (
          <Tooltip title="Click here to remove an image attribute">
            <Button
              className="m-l-10"
              type="link"
              icon="close"
              style={{ color: "rgb(240, 65, 52)", border: "thin" }}
              onClick={()=>removeAttribute(index)}
            />
          </Tooltip>
        )}
      </Form>
    );
  }
}

export default ImageAttributeForm

