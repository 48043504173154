import React from "react";
import ColorPickerForLabel from '../../../Components/ColorPicker/ColorPickerForLabel';
import { Form } from 'semantic-ui-react';
import { Button, Tooltip } from 'antd';
import 'antd/dist/antd.css';

class ColorLabelForm extends React.Component {

handleChange = (field, element) => {
    this.props.handleColorLabelChange(field, element, this.props.index);
};

handleRemove = () => {
    this.props.removeColorTag(this.props.index);
}

changeExistingColor = () => {
    this.props.changeExistingColor( this.props.index);
}

render() {
    let { coloredTag, handleColorChange, index, addColorTag } = this.props;

    return (
        <div className="base_root w-100">
            <div className="m-b-8 w-100 p-r-10 p-l-10">
                <Form>
                    <div>
                        <Form.Input className="w-100"
                            id="coloredTag"
                            size="small"
                            placeholder="Entity Name"
                            compact
                            control="input"
                            type="text"
                            onChange={this.handleChange.bind(this, "coloredTag")}

                            value={coloredTag.label}
                        />
                    </div>
                </Form>
            </div>
            <div className={"m-b-8 w-50 p-r-10 p-l-10 flex-center clr_pick " + (index === 0 ? 'm-t-0' : '')}>
                <ColorPickerForLabel color={coloredTag.color} handleColorChange={handleColorChange} index={index} className="ts" />
                {index === 0 &&
                    <Tooltip title="Click here to add an entity">
                    <Button className="m-l-10"
                  type="link"
                  icon="plus"
              onClick={addColorTag}
                style={{ color: '#0e77ca', border: 'thin' }}
                />
                </Tooltip>
                    }
                     
            {index > 0 &&
                    <Tooltip title="Click here to remove an entity">
                    <Button className="m-l-10"
                        type="link"
                        icon="close"
                        onClick={this.handleRemove}
                        style={{ color: 'rgb(240, 65, 52)', border: 'thin' }}
                    />
                    </Tooltip>
                }
            </div>
        </div>
    )
}
}

export default ColorLabelForm;
