import React, {Component, PropTypes} from 'react';
import { setSearchText, setSearchCriteria, setSearchType } from '../../redux/reducer/searchReducer';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import {bindActionCreators} from 'redux';
import { Card, Button, Menu, Dropdown, Progress, Segment, Label, Message } from 'semantic-ui-react';
import { Icon as Anticon, Button as Antbutton } from 'antd';
import { push } from 'react-router-redux';
import { getHomeDataWithPagination,archiveProjectList,undoArchiveProjects,deleteArchiveProjects } from '../../Services/WorkspaceApis';
import { getUidToken,refreshUidToken,logEvent,saveOrgId,getTaskType,getactiveName,
    setSharedProjects,getSharedProjects,showAlert,saveactiveTab } from '../../helpers/commonFunctions';
import { timeConverter, DUMMY_UID, POS_TAGGING, breadcrumbSeperate, POS_TAGGING_GENERIC, TEXT_SUMMARIZATION, IMAGE_CLASSIFICATION, TEXT_MODERATION, TEXT_CLASSIFICATION, DOCUMENT_ANNOTATION, IMAGE_POLYGON_BOUNDING_BOX, IMAGE_POLYGON_BOUNDING_BOX_V2,IMAGE_SEGMENTATION, IMAGE_BOUNDING_BOX ,DICOM_SEGMENTATION, DICOM_CLASSIFICATION, ROLES_TYPES, displaySettingsByRole} from '../../helpers/Utils';
import { updateHomeData, selectProject, getOrgDetails, updateProjectDetails } from '../../redux/reducer/projectReducer';
import { signIn } from '../../redux/reducer/loginReducer';
import { Pagination, LocaleProvider, Checkbox, Modal } from 'antd';
import en_US from 'antd/lib/locale-provider/en_US';
import BACK from '../../images/Arrow_Simple_left1.svg';
import { setloaderMenu } from '../../redux/reducer/projectReducer';
import 'antd/dist/antd.css';
class ProjectsView extends Component {
  static propTypes = {
    user: PropTypes.object,
    login: PropTypes.func,
    logout: PropTypes.func,
    pushState: PropTypes.func,
    updateHomeData: PropTypes.func,
    projects: PropTypes.array,
    selectProject: PropTypes.func,
    params: PropTypes.object,
    orgName: PropTypes.string,
    getOrgDetails: PropTypes.func,
    updateProjectDetails: PropTypes.func,
    signIn: PropTypes.func
  }

  constructor(props) {
    console.log('props are ', props);
    super(props);
    this.openCreate = this.openCreate.bind(this);
    this.loadProjectDetails = this.loadProjectDetails.bind(this);
    this.projectDetailsFetched = this.projectDetailsFetched.bind(this);
    this.openStats = this.openStats.bind(this);
    this.selectProjectToArchive = this.selectProjectToArchive.bind(this);
    this.selectArchivedProjects = this.selectArchivedProjects.bind(this);
    this.showSingleConfirmationPopup = this.showSingleConfirmationPopup.bind(this);
    this.showMultipleConfirmationPopup = this.showMultipleConfirmationPopup.bind(this);
    let search = new URLSearchParams(this.props.location.search)
    let searchtype = (search !== "") ? search.get("projectType") : ""
    let currentpPageNo = (this.props.location.search !== '' && search !== "") ? parseInt(search.get("currentpPage")) : 1
    let currentwPageNo = (this.props.location.search !== '' && search !== "") ? parseInt(search.get("currentwPage")) : 1
    let isFilter = (this.props.location.query.isFilter) ? this.props.location.query.isFilter : 0
    this.state = {
      loading: false,
      homeDetails: null,
      currentpPage: currentpPageNo,
      currentwPage: currentwPageNo,
      projectPageSize: 5,
      projectPageOptions: [5, 10],
      totalProjectCount: 0,
      pageLoading: false,
      visible: false,
      callProjects: false,
      projectArchivedList: [],
      type: '',
      projectSelectedList: [],
      searchType: searchtype,
      isFilter: isFilter
    };
  }
  selectedCheckbox = false

  componentWillMount() {
    window.localStorage.removeItem('isFilter');
    window.localStorage.removeItem('projectType');
    window.localStorage.removeItem('currentProjectPage');
  }

  componentDidMount() {
    setSharedProjects('')
    this.loadProjectDetails();
    logEvent('buttons', 'Home page opened');
  }

  componentDidUpdate(prevProps, prevState) {
    if (getactiveName() === "shared"  && this.state.callProjects === true) {
      this.setState({ callProjects: false})
      this.loadProjectDetails();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.projects && nextProps.projects) {
      this.setState({ homeDetails: nextProps.projects, loading: false });
    }

    if (getactiveName() === "shared" &&  getSharedProjects() === 'shared') {
      this.setState({callProjects: true})
      setSharedProjects('')
    }
  }

  componentWillUnmount() {
    console.log('unmounting Component');
    this.setState({ homeDetails: undefined });
  }

  getProjects = (projects) => {
    const arrs = [];
    for (let index = 0; index < projects.length; index ++) {
      console.log(' project detail ', projects[index].projectDetails.visibility_type, projects[index].projectDetails);
      let tagButtonHidden = true;
      let overviewClass = '';
      if ( projects[index].projectDetails.totalHitsDone === projects[index].projectDetails.totalHits ||
        projects[index].projectDetails.totalHits === 0) {
        tagButtonHidden = true;
        overviewClass = '';
      }
      console.log(this.state.projectSelectedList);
      const projectArchivedList = this.state.projectArchivedList
      const projectSelectedList = this.state.projectSelectedList
      let checkedProject = false
      let checkedArchiveProject = false
      projectArchivedList.map((id)=>{
        if (id === projects[index].projectDetails.id) {
          checkedProject = true
        }
      })
      projectSelectedList.map((id)=>{
        if (id === projects[index].projectDetails.id) {
          checkedArchiveProject = true
        }
      })
      const nonEditable =  projects[index].projectDetails.status === "DELETED" || projects[index].projectDetails.isWorkspacdDeleted === true;
      arrs.push(
        <Card raised blue key={index} className="project_card" style={{ width: '31%', marginLeft: '1%', marginRight: '1%', marginBottom: '1%' }} id="project_card">
                          <Card.Content extra 
                          style={!nonEditable ? { cursor: 'pointer'} : {}} 
                          onClick={!nonEditable ? this.openStats.bind(this, 'stats', projects[index].projectDetails.orgName, projects[index].projectDetails.name, projects[index].projectDetails.id, projects[index].projectDetails.task_type, this.state.currentwPage, this.state.currentpPage, this.state.isFilter) : ''}>

                          <Label icon size="mini" attached="top right" style={{ width: '100%', background: 'white'}}>
                          <div className="card_header">
                          <div style={{display: 'flex', alignItems: 'center', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', paddingRight: '10px', width: '200px'}}>
                          {nonEditable && (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR)  &&
                          <Checkbox className="m-r-10"
                            checked={checkedArchiveProject}
                            onClick={this.restrictArchiveCardClick}
                            onChange={this.selectArchivedProjects.bind(this, projects[index].projectDetails)}
                            style={{marginTop: '10px'}}
                          />}
                          { (!nonEditable  && (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR) && getactiveName() === "home" && (this.props.routeParams.workspaceId !== "2")) &&
                          <Checkbox className="m-r-5"
                                    checked = {checkedProject}
                                    onClick={this.restrictCardClick}
                                    onChange={this.selectProjectToArchive.bind(this,projects[index].projectDetails)}
                                    style={{marginTop: '0', marginBottom: '0'}}
                        />
                          }
                          <h3 title={projects[index].projectDetails.name}>{projects[index].projectDetails.name}</h3>
                          </div>
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            {projects[index].role === 'OWNER' &&
                              <Label className="green" size="mini" inverted  style={{ width: 'auto', background: '#D8D8D8', color: 'white', padding: '5px 10px', borderRadius: '30px', marginRight: '5px', textTransform: 'uppercase'}}> {projects[index].role === 'OWNER'? projects[index].role : ""} </Label>
                            }
                            {projects[index].projectDetails.visibility_type &&
                              <Label size="mini" inverted  style={{ width: 'auto', background: '#D8D8D8', color: 'white', padding: '5px 10px', borderRadius: '30px', marginRight: '5px', textTransform: 'uppercase'}}> { projects[index].projectDetails.visibility_type } </Label>
                            }
                            { projects[index].role === 'OWNER' &&
                            <Menu secondary className="card_menu"  style={{display: 'flex', alignItems: 'center', margin: '0'}} disabled = {nonEditable} >
                              <Dropdown icon="ellipsis horizontal">
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={this.copyProject.bind(this, index,this.state.currentwPage, this.state.currentpPage)}>Copy</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </Menu>
                          }
                          </div>
                          </div>
                          </Label>
                          <Card.Description className="progress_dec">

                          <h6 className="label_right clr_gray">{getTaskType(projects[index].projectDetails)}</h6>
                                <Progress className="custom_progress" style={{ marginBottom: '1em', fontSize: '0.75rem'}} precision={0} color="olive" indicating progress percent={projects[index].projectDetails.totalHits === 0 ? 0 : (projects[index].projectDetails.totalHitsDone * 100) / projects[index].projectDetails.totalHits} />
                                <div style ={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                  <h6 className="label_right">{(getactiveName() === "shared") ? projects[index].adminName : ''}</h6>
                                  <h6 className="label_right">{projects[index].projectDetails.totalHitsDone} / {projects[index].projectDetails.totalHits}</h6>
                                </div>
                            </Card.Description>
                          </Card.Content>
                          <Card.Content extra className="card_extra">
                              {!nonEditable &&
                                <Button className={`btn_cardview ${overviewClass}`} name="posT" size="mini" onClick={this.openStats.bind(this, 'stats', projects[index].projectDetails.orgName, projects[index].projectDetails.name, projects[index].projectDetails.id, projects[index].projectDetails.task_type, this.state.currentwPage, this.state.currentpPage, this.state.isFilter)}>
                                Overview
                                </Button>
                              }
                              {nonEditable &&
                                <div>
                                <Button className={`btn_cardview ${overviewClass}`} name="posT" size="mini" onClick={this.showSingleConfirmationPopup.bind(this, projects[index].projectDetails.id, "recoverAll")}>
                                  Recover </Button>
                                <Button className={`btn_cardview ${overviewClass}`} name="posT" size="mini" onClick={this.showSingleConfirmationPopup.bind(this, projects[index].projectDetails.id, "deleteAll")}>
                                  Delete </Button>
                                </div>
                              }
                            { !tagButtonHidden &&
                              <Button color="teal" className="pull-right" name="posT1" size="mini" onClick={this.openStats.bind(this, 'tag', projects[index].projectDetails.orgName, projects[index].projectDetails.name, projects[index].projectDetails.id, projects[index].projectDetails.task_type, this.state.currentwPage, this.state.currentpPage, this.state.isFilter)}>
                              { (projects[index].projectDetails.task_type === POS_TAGGING || projects[index].projectDetails.task_type === DOCUMENT_ANNOTATION ||
                                projects[index].projectDetails.task_type === POS_TAGGING_GENERIC)
                                && 'Start Tagging'

                              }
                              {projects[index].projectDetails.task_type === TEXT_SUMMARIZATION && 'Write Summary'
                              }
                              {projects[index].projectDetails.task_type === TEXT_MODERATION && 'Moderate Text'
                              }
                              {projects[index].projectDetails.task_type === TEXT_CLASSIFICATION && 'Classify Text'
                              }
                              { (projects[index].projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX || projects[index].projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 || projects[index].projectDetails.task_type === IMAGE_SEGMENTATION ) && 'Bound Images'
                              }
                              {projects[index].projectDetails.task_type === IMAGE_BOUNDING_BOX && 'Bound Images'
                              }
                              {projects[index].projectDetails.task_type === IMAGE_CLASSIFICATION && 'Classify Image'
                              }
                              </Button>
                            }
                          </Card.Content>
          </Card>
        );
    }
    return (
          <Card.Group stackable centered itemsPerRow="2" className="flx_rowset">
              {arrs}
          </Card.Group>
             );
  }

  /*
    function to handle pageSize change in pagination component
  */
  onShowSizeChange = (current, pageSize) =>{
    this.props.setloaderMenu(true);
    this.setState({projectPageSize: pageSize, pageLoading: true });
    let queryParam = {pageSize: pageSize, pageCurrent: 0};
      queryParam.workspaceId = this.props.params.workspaceId;
      queryParam.orgId = this.props.params.orgId;
      queryParam.type = this.props.params.type;
      if (this.props.params.roleId === "3" || this.props.routeParams.roleId === "3" || this.props.params.roleId === "5" || this.props.routeParams.roleId === "5" || this.props.params.roleId === "6" || this.props.routeParams.roleId === "6") {
        queryParam.type = "shared"
      }
    getHomeDataWithPagination(this.projectDetailsFetched, null, queryParam );
  }

  /*
    function to handle page change in pagination component
  */
  onPageChange = (current) =>{
    this.props.setloaderMenu(true);
    this.setState({currentpPage: current, pageLoading: true });
    let queryParam = {pageSize: this.state.projectPageSize, pageCurrent: current ? current - 1 : 0, pageStart: current ? current - 1 : 0};
    queryParam.workspaceId = this.props.params.workspaceId;
    queryParam.orgId = this.props.params.orgId;
    queryParam.type = this.props.params.type;
    queryParam.searchType = this.state.searchType;
    if (this.props.params.roleId === "3" || this.props.routeParams.roleId === "3" || this.props.params.roleId === "5" || this.props.routeParams.roleId === "5" || this.props.params.roleId === "6" || this.props.routeParams.roleId === "6") {
      queryParam.type = "shared"
    }
    getHomeDataWithPagination(this.projectDetailsFetched, null, queryParam);
  }

  copyProject = (index, event,currentwPage, currentpPage) => {

    this.props.updateProjectDetails(this.state.homeDetails[index].projectDetails);
    let queryParam = "";
    if ( currentwPage ) {
      queryParam = queryParam + "&currentwPage=" + this.state.currentwPage;
    }
    if ( currentpPage ) {
      queryParam = queryParam + "&currentpPage=" + this.state.currentpPage;
    }
    this.props.pushState('/projects/import?type=' + this.state.homeDetails[index].projectDetails.task_type + queryParam);
  }

  openStats(field1, field2, field3, field4, projectType, currentwPage, currentpPage, isFilter) {
    if (this.selectedCheckbox === false) {
      let workspaceType = this.props.params.type;
      if (this.props.params.roleId === "3" || this.props.routeParams.roleId === "3" || this.props.params.roleId === "5" || this.props.routeParams.roleId === "5" || this.props.params.roleId === "6" || this.props.routeParams.roleId === "6") {
        workspaceType = "shared"
      }
      let queryParam = "?";
      if ( this.props.params.workspaceName ) {
        queryParam = queryParam + "wName=" + this.props.params.workspaceName;
      }
      if ( this.props.params.workspaceId ) {
        queryParam = queryParam + "&wId=" + this.props.params.workspaceId;
      }
      if ( this.props.params.orgId ) {
        queryParam = queryParam + "&orgId=" + this.props.params.orgId;
      }
      if ( workspaceType ) {
        queryParam = queryParam + (queryParam.includes('&') ? "&" : "" ) + "wType=" + workspaceType;
      }
      
      if( projectType ) {
        if(isFilter === 1) {
          queryParam = queryParam + "&projectType=" + projectType;
        }
      }
      if ( currentwPage ) {
        queryParam = queryParam + "&currentwPage=" + currentwPage;
      }
      if ( currentpPage ) {
        queryParam = queryParam + "&currentpPage=" + currentpPage;
      }
      if( isFilter !== undefined ) {
        queryParam = queryParam + "&isFilter=" + isFilter;
      }

      if (field1 === 'tag') {
        this.props.pushState('/projects/' + field2 + '/' + field3 + '/' + field4 + '/space' + queryParam);
      } else {
        if ( (projectType === DICOM_SEGMENTATION) || (projectType === DICOM_CLASSIFICATION)) {
          console.log('dicom dicom')
          this.props.pushState('/dicomprojects/' + field2 + '/' + field3 + '/' + field4 + queryParam);
        } else {
          this.props.pushState('/projects/' + field2 + '/' + field3 + '/' + field4 + queryParam);
        }
      }
    } else {
       this.selectedCheckbox = false
    }
  }

  projectDetailsFetched( error, response ) {
    if (!error) {
      saveOrgId(response.body.orgId);
      this.props.signIn({firstName: response.body.userDetails.firstName, lastName: response.body.userDetails.secondName,  fullName: response.body.userDetails.firstName + " " + response.body.userDetails.secondName, uid: response.body.userDetails.uid, email: response.body.userDetails.email, profileImage: response.body.userDetails.profileImage, orgId: response.body.orgId, roleId: response.body.roleId });
      this.props.updateHomeData(response.body.userDetails, response.body.projects, response.body.planName, response.body.labelsAllowed, response.body.labelsDone, response.body.subscriptionExpiryTimestamp, response.body.hasSubscriptionExpired);
      this.setState({totalProjectCount: response.body.totalProjects, homeDetails: response.body.projects, pageLoading: false, loading: false, plan: response.body.planName, labelsAllowed: response.body.labelsAllowed, labelsDone: response.body.labelsDone, subscriptionExpiryTimestamp: response.body.subscriptionExpiryTimestamp, hasSubscriptionExpired: response.body.hasSubscriptionExpired });
      this.props.setloaderMenu(false);
      this.setState({pageLoading: false});
    } else if (response && response.body) {
      this.props.setloaderMenu(false);
      this.setState({pageLoading: false});
      if (response.body.code === 401) {
        refreshUidToken(this.loadProjectDetails);
      } else {
        this.setState({ projectDetailsError: response.body.message, pageLoading: false});
        if (window.sessionStorage.getItem("uid") === null) {
          this.props.pushState('/projects/login');
        }
      }
    } else {
      this.setState({ projectDetailsError: 'Not able to connect'});
      this.props.setloaderMenu(false);
      this.setState({pageLoading: false});
    }
  }

  loadProjectDetails(cache) {
    console.log('loadProjectDetails ++++++++++++++++++++++==', this.props)
    const { uid, token } = getUidToken();
    if (!uid) {
      this.props.pushState('/projects/login');
    } else
    if (this.props.params.orgName) {
      console.log('loadProjectDetails Entered ')
      this.props.getOrgDetails(this.props.params.orgName, { uid, token });
    } else if (uid && uid !== DUMMY_UID) {
      this.props.setloaderMenu(true);
      this.setState({pageLoading: true, callProjects: false});
      console.log('this.props====>', this.props)
      let queryParam = {pageSize: this.state.projectPageSize, pageCurrent: this.state.currentpPage ? this.state.currentpPage - 1 : 0, pageStart: this.state.currentpPage ? this.state.currentpPage - 1 : 0};
      queryParam.workspaceId = this.props.params.workspaceId;
      queryParam.orgId = this.props.params.orgId;
      queryParam.type = this.props.params.type;
      if (this.props.params.roleId === "3" || this.props.routeParams.roleId === "3" || this.props.params.roleId === "5" || this.props.routeParams.roleId === "5" || this.props.params.roleId === "6" || this.props.routeParams.roleId === "6") {
        queryParam.type = "shared"
      }
        queryParam.searchType = this.state.searchType
      // Added below condition for the scenario - if localstorage has uid and the url provided is domain/projects (in new tab) then
      // empty screen with no projects found was displayed along with side menus
      if (uid && this.props.location.pathname === "/projects") {
        saveactiveTab("home");
        this.props.pushState('/workspace');
      }
      getHomeDataWithPagination(this.projectDetailsFetched, cache, queryParam );
    }
  }

  openCreate(val) {
    let workspaceId = 0
    const { routeParams, params } = this.props;
    if (routeParams.workspaceId) {
      workspaceId = routeParams.workspaceId
    }

    this.props.pushState({
      pathname: '/projects/' + val,
      query: {
        workspaceId: workspaceId,
        wName: params.workspaceName,
        orgId: params.orgId,
        currentwPage: this.state.currentwPage,
        currentpPage: this.state.currentpPage
      }
    });
  }

  handleSubmit = (response) => {
    const { login } = this.props;
    const { profileObj } = response
    if (response && response.profileObj) {
      login(profileObj.name, profileObj.imageUrl);
    }
  }

  archiveProjectsSelected = () => {
    console.log('select deleted')
    if (this.state.projectArchivedList.length === 0) {
      showAlert("Please select at least one project.", 'warning')
    } else {
          const that = this
          const confirm = Modal.confirm;
          confirm({
              title: 'Confirmation',
              content: 'Are you sure you want to archive the selected ' + this.state.projectArchivedList.length + ' project(s)?',
              okText: 'OK',
              cancelText: 'CANCEL',
              onOk() {that.archiveSelectedProjects()},
              onCancel() {that.cancelarchivepProjectsList()},
            });
    }
  }

  cancelarchivepProjectsList = () =>{
    this.setState({projectArchivedList: []})
  }

  archiveSelectedProjects = () => {
    this.props.setloaderMenu(true);
    this.setState({pageLoading: true})
    archiveProjectList(this.state.projectArchivedList, this.archivedProjectsCallback.bind(this))
  }

  archivedProjectsCallback = (err) => {
    this.props.setloaderMenu(false);
    if (!err) {
      this.loadProjectDetails();
      this.setState({pageLoading: false, homeDetails: null, projectArchivedList: []})
      showAlert("The selected project(s) archived successfully", 'success')
    } else {
      this.setState({projectArchivedList: []})
      this.loadProjectDetails();
      this.setState({pageLoading: false})
      showAlert("The few of selected project(s) could not be deleted successfully.", 'success')
    }
  }

  selectProjectToArchive(projectValue, event) {
    let projectArchivedList = this.state.projectArchivedList
    if (event.target.checked) {
      projectArchivedList.push(projectValue.id)
    } else {
      let spliceIndex = 0
      projectArchivedList.map((id, index)=>{
        if (id === projectValue.id) {
          spliceIndex = index
        }
      })
      projectArchivedList.splice(spliceIndex, 1)
    }
    this.setState({projectArchivedList})
  }

  // When checkbox is checked or unchecke, page will not get routes.
  restrictCardClick = () => {
    this.selectedCheckbox = true;
  }
  restrictArchiveCardClick = () => {
    this.selectedCheckbox = true;
  }
  showSingleConfirmationPopup = (projectID, type) => {
    const that = this;
    let projectList = []
    projectList.push(projectID)
    let processType = (type === "recoverAll") ? 'recover' : 'delete';
    const contentDescription = 'Are you sure you want to ' + processType + ' the selected '+(processType==='delete' ? 'project(s) permanently?' : 'project(s)?');
    const confirm = Modal.confirm;
    confirm({
      title: 'Confirmation',
      content: contentDescription,
      okText: 'OK',
      cancelText: 'CANCEL',
      onOk() {(type === "recoverAll") ? that.recoverSelectedProjects(projectList) : that.deleteSelectedProjects(projectList) },
      onCancel() {},
    });
  }
  showMultipleConfirmationPopup = (projectIDs, type) => {
    const {projectSelectedList} = this.state
    const that = this;
    let processType = (type === "recoverAll") ? 'recover' : 'delete';
    const contentDescription = 'Are you sure you want to ' + processType + ' the selected ' + projectSelectedList.length + ' project(s)?';

    if (projectSelectedList.length === 0) {
      showAlert("Please select at least one project.",'warning')
    } else {
      const confirm = Modal.confirm;
      confirm({
        title: 'Confirmation',
        content: contentDescription,
        okText: 'OK',
        cancelText: 'CANCEL',
        onOk() {(type === "recoverAll") ? that.recoverSelectedProjects(that.state.projectSelectedList) :
        that.deleteSelectedProjects(that.state.projectSelectedList) },
        onCancel() {that.cancelSelection();},
      });
    }
  }
  cancelSelection() {
    this.setState({projectSelectedList: []})
  }
  updateWorkSpacecallback = (err, response) => {
    const {type} = this.state
    this.props.setloaderMenu(false);
    if (!err) {
      this.loadProjectDetails();
      this.setState({pageLoading: false, homeDetails: null})
      if (type === "deleteAll") {
        showAlert("The selected project(s) deleted successfully.", 'success')
      }
      if (type === "recoverAll") {
        showAlert("The selected project(s) recovered successfully.", 'success')
      }
      this.setState({type: ''})
    } else {
      this.setState({
        pageLoading: false,
        message: response.body.message,
        type: ''
      });
    }
  }
  selectArchivedProjects(projectValue, event) {
    console.log("selectArchivedProjects : ", event);
    let projectSelectedList = this.state.projectSelectedList
    if (event.target.checked) {
      projectSelectedList.push(projectValue.id)
    } else {
      let spliceIndex = 0
      projectSelectedList.map((id, index)=>{
        if (id === projectValue.id) {
          spliceIndex = index
        }
      })
      projectSelectedList.splice(spliceIndex, 1)
    }
    this.setState({projectSelectedList})
  }
  // Function to recover the selected project
  recoverSelectedProjects = (recoverList, event) => {
    this.setState({
      type: "recoverAll",
      pageLoading: "true",
      loadingMessages: "Recovering the archived Projects"
    })
    undoArchiveProjects(recoverList, this.updateWorkSpacecallback)
  }
  // Function to delete the selected project
  deleteSelectedProjects = (deleteList, event) => {
    this.setState({
      type: "deleteAll",
      pageLoading: "true",
      loadingMessages: "Deleting the archived Projects"
    })
    deleteArchiveProjects(deleteList, this.updateWorkSpacecallback)
  }

  render() {
    let lroleId = getUidToken().roleId;
    let workspaceId = false
    let backUrl =  ''
    let currrent_page = ''
    const {homeDetails,projectSelectedList,labelsDone,labelsAllowed,hasSubscriptionExpired,subscriptionExpiryTimestamp,pageLoading,totalProjectCount,projectPageSize,currentpPage} = this.state
    if(this.props.location !== undefined){
      let queryparams = new URLSearchParams(this.props.location.search)
      currrent_page = (queryparams !== undefined && queryparams.get('currentwPage') !== '') ? parseInt(queryparams.get('currentwPage')) : undefined;
      console.log(currrent_page);
          }
    if((parseInt(lroleId)  === 2 && getactiveName() === "home" ) || (getactiveName() === "archived")){
      backUrl =  (currrent_page !== '' && !isNaN(currrent_page)) ? '/workspace?currentwPage='+currrent_page : '/workspace'
          }
    if(this.props.routeParams.workspaceId !== "2") {
      workspaceId = true
    }
    console.log("getactiveName",getactiveName())
    return (
      <div className="taggerPages">
        <Helmet title="My Projects" />
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <div className="f-column">
            {((parseInt(lroleId)  === 2 && getactiveName() === "home" ) || (getactiveName() === "archived")) &&
              <div className="f-center">
                <div className="m-r-5" onClick={() => {this.props.pushState(backUrl);
                this.props.setSearchText('');
                this.props.setSearchType('Search by Text');
                this.props.setSearchCriteria('text');
                }}>
                    <span className="t_btn_back">
                      <img alt='back' src={BACK} />
                    </span>
                </div>
              </div>
            }
            <div className="breadcrum-sec">
              {((parseInt(lroleId)  === 2 && getactiveName() === "home" ) || (getactiveName() === "archived")) &&
                <div className="sec_holder">
                  <span
                    className={
                      "tilte_display fw-400 " +
                      (this.props.params.workspaceName &&
                      this.props.params.workspaceName.length > 15 ?
                        'pro_width' : ''
                      )}
                    title={this.props.params.workspaceName}>{this.props.params.workspaceName}
                  </span>
                  {breadcrumbSeperate()}
                  <span className="tilte_display fw-400"> Projects </span>
                </div>
              }
              {(parseInt(lroleId)  !== 2 || getactiveName() === "shared" || (parseInt(lroleId)  === parseInt(ROLES_TYPES.GUEST))) &&
                <div className="sec_holder">
                  <h2 className="tilte_display fw-600 color-b">SHARED WITH ME</h2>
                  {breadcrumbSeperate()}
                  <span className="tilte_display fw-400" style = {{display: displaySettingsByRole(getUidToken().roleId)}}>Projects</span>
                </div>
              }
            </div>
          </div>
          {(getactiveName() === "home" && workspaceId) &&
            <div className="w_btn">
            <Antbutton
              className="btn_base btn_success border-n btn_create"
              onClick={this.openCreate.bind(this, 'create')}
            >
              <Anticon type="plus" />
              Project
            </Antbutton>
            <Antbutton
              className="btn_base btn_danger  border-n btn_archive  m-l-5"
              type="danger"
              onClick={this.archiveProjectsSelected}
              disabled={(homeDetails && homeDetails.length === 0) ? true : false}
            >
              <Anticon type="inbox"/>
              Archive
            </Antbutton>
          </div>
          }
            {(getactiveName() === "archived") &&
            <div className="w_btn">
              <Antbutton className="btn_create m-l-5" title="Recover" onClick={this.showMultipleConfirmationPopup.bind(this, projectSelectedList, "recoverAll")}>
              <Anticon type="rollback"/>Recover </Antbutton>
                <Antbutton className="btn_archive m-l-5"  type="danger"  title="Delete" onClick={this.showMultipleConfirmationPopup.bind(this, projectSelectedList, "deleteAll")}>
                <Anticon type="delete"/>Delete </Antbutton>
            </div>
            }
        </div>
        <div className="text-center">
          {
            (labelsDone > labelsAllowed || hasSubscriptionExpired) &&
              <div className="text-center" style={{ marginBottom: '40px' }}>
                <Message negative style={{ marginTop: '-60px' }} size="mini">
                  <Message.Header>
                    Your account needs renewal, please contact
                    <u> contact@pronotate.com </u>
                  </Message.Header>
                  <div className="text-center">
                    <p> Labels Used </p>
                    <Label> {labelsDone} </Label>
                    <p> Total Labels in Package</p>
                    <Label> {labelsAllowed} </Label>
                    { subscriptionExpiryTimestamp &&
                      <div>
                        <p> Subsription Expiry </p>
                        <Label> {timeConverter(subscriptionExpiryTimestamp / 1000)}</Label>
                      </div>
                    }
                    <div style={{ height: '20px' }} />
                    <Button color="blue" size="mini">
                      <h6 style={{ color: 'white' }}>Renew</h6>
                    </Button>
                  </div>
                </Message>
              </div>
            }
            <Segment basic >
                {!pageLoading && homeDetails && homeDetails.length > 0 && this.getProjects(homeDetails)}
                {!pageLoading && totalProjectCount > 0 &&
                  <LocaleProvider locale={en_US}>
                    <Pagination style={{marginTop: '30px'}}
                      showQuickJumper={totalProjectCount>projectPageSize}
                      onShowSizeChange={this.onShowSizeChange}
                      pageSizeOptions={projectPageSize}
                      defaultPageSize={5}
                      defaultCurrent={currentpPage}
                      total={totalProjectCount}
                      onChange={this.onPageChange}
                    />
                  </LocaleProvider>
                }
            </Segment>
        </div>
        {homeDetails && homeDetails.length === 0 &&
          <div className="txt-center">
            <h2 className="fw-300 m-t-10 m-b-10 color-lite">No Project Found</h2>
          </div>
        }
      </div>
    );
  }
}


const mapStateToProps = (state) => {
    return {
    user: state.loginReducer.user, 
    projects: state.projectReducer.projects 
  };
  };

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        signIn,
        setloaderMenu,
        pushState: push,
        updateHomeData,
        setSearchText,
        setSearchCriteria,
        setSearchType,
        updateProjectDetails,
        selectProject,
        getOrgDetails,
      },
      dispatch
    );
  
  export default connect(mapStateToProps, mapDispatchToProps)(ProjectsView);