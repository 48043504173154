import React from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import { push, replace } from "react-router-redux";

class Loader extends React.Component {
    
  render() {
    const { isLoading } = this.props;
    return (
      isLoading &&
      <div className="overlay_dom" >
        <div className="cssload-wraper">
          <div className="cssload-dots"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      isLoading: state.projectReducer.isLoading
    };
  };
  
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        pushState: push,
        replaceState: replace,
      },
      dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(Loader);
  