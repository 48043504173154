import React, {Component} from 'react';
import { Button , Select, LocaleProvider, Icon , Upload , Slider} from 'antd';
import helper from '../TaggerPage/helper';
import en_US from 'antd/lib/locale-provider/en_US';
import { uploadModel } from '../../Services/UploadFileApis';
import { logEvent } from '../../helpers/commonFunctions';
import { Form } from 'semantic-ui-react';
import '../ImportProjects/ImportProjects.css'
const bytes = require('bytes');
export default class UploadModel extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        predictionValue : 0.0,
        modelfile : undefined,
        modelfileName : undefined,
        errors : {},
        selectedModelType : undefined,
        isRunModel : true
    }
  }

  onPredictionFilter = (value) => {
	this.setState({predictionValue: value})
  }

  handleAntUploadFile(type, info) {
	const file = info.file.originFileObj;
    this.setState({ modelfile : file });
	this.setState({ modelfileName : file.name });
  }

  handleModelTypeChange = value => {
	this.setState({ selectedModelType: value });
  }

  handleSubmitModel = () => {
    const { modelfile, selectedModelType, predictionValue, isRunModel} = this.state
    const { currentProject } = this.props;
    this.props.modelLoading(true)
    uploadModel(modelfile, currentProject, this.fileUploaded, this.fileUploadProgressCallback, selectedModelType, predictionValue, isRunModel);
}

fileUploadProgressCallback = () => {

}

fileUploaded = (error, response) => {

    this.props.modelLoading(false)
    if (response.statusCode && response.statusCode === 200) {
        logEvent('buttons', 'file upload sucessful'); 
        this.props.showCompletedModel()        
    } else {
        const errors = this.state.errors;
        logEvent('buttons', 'file upload failed');
        errors.submit = response.body ? response.body.message : response.statusText;
        this.setState({ fileUploaded: false, errors});
    }
}


skipToBoundPages = () => {
    this.props.goBackToOverview()
}

advancedOptionChange = (e1, e2, e3) => {
    this.setState({isRunModel : e3.checked})
}

  render () {
    const {isRunModel,modelfile,modelfileName,selectedModelType,predictionValue,errors} = this.state
    const uploadprops = {
        name: 'file',
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        headers: {
          authorization: 'authorization-text',
        },
        showUploadList: false
      };

	let modelOption  = helper.modelOptions()
    const modeloptions = modelOption.map(model => <option key={model.key}>{model.value}</option>);
    const  disablesubmitModel = ((this.props.projectType === "POS_TAGGING_GENERIC" && modelfile === undefined) || (this.props.projectType === "IMAGE_CLASSIFICATION" && (modelfile === undefined || selectedModelType === undefined))) ? true : false
    return (
        <div className="pr_uploadsec">
        { this.props.projectType === "IMAGE_CLASSIFICATION" &&
        <div>
            <h2 className="m-b-20 m-t-5">Upload Model</h2>
            <div className="pr_selectfile m-t-30">


            <div className="select_holder m-b-15">
                <div className="b_l">
                    <div className="w-100">
                        <p>Upload a model file with extension .h5 </p>
                                                <strong> OR </strong>
                        <p>A zip file with the model. Max file size is 200 MB </p>

                        <div>
                            <b>Model Prediction Accuracy Filter</b>
                            <Slider step={0.1} min={0} max={1} value = {predictionValue} onChange={this.onPredictionFilter}/>
                        </div>
                    </div>
                </div>

                <div className="b_r">
                    <form encType="multipart/form-data" action="" key="importFile" className="text-center">
                        <Upload {...uploadprops} onChange={this.handleAntUploadFile.bind(this,'model')} className="upload_filebtn">
                            <Button>
                                <Icon type="upload"/> Select Model
                            </Button>
                        </Upload>
                        <div className="upload_info">
                            <h4>{modelfileName}</h4>
                                {modelfile && <p> File Size: {bytes(modelfile.size)} </p>}
                            <p className="error" disabled={!errors.file}>
                                {errors.file}
                            </p>
                        </div>
                    </form>
                    <div className="m-t-20 w-100" style={{display: 'flex', alignItems: 'center'}}>

                        <div className="w-50 m-r-5">
							<b>Model Upload Type</b>
                            <LocaleProvider locale={en_US}>
                                <Select className ="select_width"
                                    placeholder="Select a model type"
                                    onChange={this.handleModelTypeChange}
                                    value = {selectedModelType}
                                >
                                    {modeloptions}
                                </Select>
                            </LocaleProvider>
                        </div>
                        <div className="w-50 m-l-5 m-t-20">
                            <Form>
                                <Form.Checkbox label="Run Model" name="runmodel" checked={isRunModel} value="Run Model" onChange={this.advancedOptionChange.bind(this, 'runModel')} />
                            </Form>
                        </div>
                    </div>
                </div>
                </div>
                <div className="txt-center msg_txt">
                <p className="error" disabled={!errors.submit}>
                {errors.submit}
            </p>
                <Button className="base_btnsubmit m-b-15" type="submit" disabled={disablesubmitModel ? true : false} onClick={this.handleSubmitModel}>Submit</Button>
                <Button style = {{ width: '9%', height: '40px' }} className="btn_base btn_danger m-l-5 base_btnsubmit ant-btn-danger" type="danger" onClick={this.skipToBoundPages}>Skip</Button>
            </div>
            </div>
        </div>
        }
        { this.props.projectType === "POS_TAGGING_GENERIC" &&
        <div>
            <h2 className="m-b-20 m-t-5">Upload Model</h2>
            <div className="pr_selectfile m-t-30">


            <div className="select_holder m-b-15">
                <div className="b_l">
                    <div className="w-100">
                        <p>Upload a compressed Spacy model file with extension .zip </p>
                    </div>
                </div>

                <div className="b_r">
                    <form encType="multipart/form-data" action="" key="importFile" className="text-center">
                        <Upload {...uploadprops} accept = ".zip" onChange={this.handleAntUploadFile.bind(this,'model')} className="upload_filebtn">
                            <Button>
                                <Icon type="upload"/> Select Model
                            </Button>
                        </Upload>
                        <div className="upload_info">
                            <h4>{modelfileName}</h4>
                                {modelfile && <p> File Size: {bytes(modelfile.size)} </p>}
                            <p className="error" disabled={!errors.file}>
                                {errors.file}
                            </p>
                        </div>
                    </form>
                    <div className="m-t-20 w-100" style={{display: 'flex', alignItems: 'center'}}>

                        <div className="w-50 m-l-5 m-t-20">
                            <Form>
                                <Form.Checkbox label="Run Model" name="runmodel" checked={isRunModel} value="Run Model" onChange={this.advancedOptionChange.bind(this, 'runModel')} />
                            </Form>
                        </div>
                    </div>
                </div>
                </div>
                <div className="txt-center msg_txt">
                <p className="error" disabled={!errors.submit}>
                {errors.submit}
            </p>
                <Button className="base_btnsubmit m-b-15" type="submit" disabled={disablesubmitModel ? true : false} onClick={this.handleSubmitModel}>Submit</Button>
                <Button style = {{ width: '9%', height: '40px' }} className="btn_base btn_danger m-l-5 base_btnsubmit ant-btn-danger" type="danger" onClick={this.skipToBoundPages}>Skip</Button>
            </div>
            </div>
        </div>
        }
        </div>
    )
  }
}