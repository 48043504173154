import React from "react";
import "antd/dist/antd.css";
import { Row, Col, Input, Button,Icon,Tooltip } from "antd";
import style from './style-sheet'

class AdvanceEntityForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isLabel: false,serNum:0} ;
  }

  componentWillMount() {
   	let isLabel = true;
   	this.setState({ isLabel});
   }	

   componentDidMount() {
   		let isLabel = false;
   		this.setState({ isLabel});
   }
   
    renderList(item, level) {
      const {isLabel} = this.state
    
    let itemNode;
    if(isLabel) {
    	itemNode =  <Input placeholder="Entity Name" defaultValue={item.text || item.label || item.name || item.labelName} suffix={<Tooltip> <Icon type="close" onClick={() => this.props.removeChild(item.id)} style={{ color:'#f04134',cursor: 'pointer' }} /></Tooltip>} onChange={e => this.props.updateInput(e, item.id, 'entity')}></Input>
    } else {
    	itemNode =  <Input placeholder="Entity Name" defaultValue={item.text || item.label || item.name || item.labelName} suffix={<Tooltip> <Icon type="close" onClick={() => this.props.removeChild(item.id)} style={{ color:'#f04134',cursor: 'pointer' }} /></Tooltip>} onChange={e => this.props.updateInput(e, item.id, 'entity')}></Input>
    }
    
  	let width = level * 2
  	
    return (
      <div>
        <Row
          gutter={15}
          key={item.id}
          style={{
            width: `100%`,
            float: "rigt",
            padding: 10,
            display: this.props.list.length > 0 ? 'block' : 'none'
          }}
        >
        
        <Col span={width}/>
          <Col span={23 - width}>
            {itemNode} 
          </Col>
          
          <Col span={1}>
            <Button
              onClick={() => this.props.addChild(item.id)}
              type="link"
              icon="plus"
              style={{color:'#0e77ca',border:'thin'}}
            />
            
          </Col>
          
        </Row>
        {item.entities.length > 0 &&
          item.entities.map(it => {
            return this.renderList(it, it.level);
          })}
      </div>
    );
  }

  render() {
    const { list, label, formId ,serNum} = this.props;
    return (
      <div id="form_balance" style={style.advanceForm.container}>
        <Button
          style={style.advanceForm.close_fill}
          onClick={() => this.props.removeLabel()}
          type="danger"
          shape="circle"
          icon="close"
        />
        <Row style={{color: 'rgba(0,0,0,.45)',width:'0px'}}>
          <span style={{display: 'inline-block',padding: '0',border: '1px #c5aaaa solid',borderRadius: '50%',width: '30px',height: '30px',margin: '0 0 10px 0',lineHeight: '30px',textAlign: 'center'}}>{serNum}</span>
        </Row>
        <Row>
          <Input placeholder="Label Name" value={label} onChange={e => this.props.updateInput(e, formId, 'label')} />
        </Row>
        <Row>
          <Button style={style.advanceForm.btn_noborderfill} onClick={() => this.props.addParent(formId)} type="default">
            Add Parent
          </Button>
        </Row>
        {
          list.map(it => {
          	return this.renderList(it, it.level);
          })
        }
      </div>
    )
  }
}

export default AdvanceEntityForm
