import React, { Component, PropTypes } from "react";
import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import './style.css';

export default class SingleDatePicker extends Component {

  render() {
    const today = new Date();
    const {setSelectedDate ,clearDisable} = this.props
    let placeholder = 'Filter by Date'
    let isDisabled = false
    let todayDate = null;
    const onChange = (jsDate, dateString) => {
      this.date = jsDate;
       todayDate = convertDate(jsDate)
      setSelectedDate( (todayDate !== 'Invalid date')?todayDate:undefined, jsDate);
    }

    const onClear = () => {
    	  setSelectedDate(undefined, null);
    }

    // From Date and To date for Insights Page added by monica
    function convertDate(date) {
      var yyyy = date.getFullYear().toString();
      var mm = (date.getMonth() + 1).toString();
      var dd = date.getDate().toString();

      var mmChars = mm.split('');
      var ddChars = dd.split('');

      return yyyy + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + (ddChars[1] ? dd : "0" + ddChars[0]);
    }

    if (this.props.placeholder) {
      placeholder = this.props.placeholder //Setting Placeholder for from and to date
      isDisabled = this.props.isDisabled // Enable to date field only if from date selected
    }

    return (
    		
      <div className='ui input'>
        <DatePickerInput
          displayFormat='YYYY-MM-DD'
          returnFormat='YYYY-MM-DD'
          showOnInputClick
          placeholder={placeholder}
          iconClassName='calendar icon'
          onChange={onChange}
          value={this.props.selectedDate ? this.props.selectedDate : ''}
          maxDate={today}
          disabled={isDisabled}
          onClear={onClear}
          iconClearClassName={!clearDisable ? 'icon-rc-datepicker icon-rc-datepicker_clear' : ''}
  
        />
      </div>
    );
  }
}
