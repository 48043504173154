import React from "react";
import domtoimage from 'dom-to-image';
import moment from 'moment';
import { SYMBOLS, SESSION_VARIABLES, BOOLEAN_TYPES, ACTIONS, MESSAGES, CONFIRMATION_BOX } from "./Constants";
import { Modal as AntModal } from 'antd';
import {getUidToken} from '../helpers/commonFunctions'
import { extendTimeout } from "../Services/commonApis";


const uuidv1 = require('uuid/v1')
const FileSaver = require('file-saver');
// import { uploadAnnotatedImagesToS3, extendTimeout, getUidToken, dtLogout } from "./dthelper";


export const ENTITY_COLORS = [
  "orange",
  "olive",
  "green",
  "teal",
  "red",
  "blue",
  "violet",
  "purple",
  "pink",
  "black"
];

export const STATIC_HIT_LABEL_COLOR  = "#000000";

export const DOC_ENTITY_COLORS = [
  "#FF0000", "#320AF2", "#FFA500", "#8B572A", "#87F50E", "#BD10FF", "#06FCC5",
  "#3E836F", "#F5F5B9", "#71E476", "#FA3777", "#FFFF00", "#BADB99", "#D58C8C",
  "#AAFFD1", "#400772", "#A999F8", "#2E5304", "#C54B8C", "#AFEEEE", "#9ACD32",
  "#C19A6B", "#F5D452", "#57959E", "#000000", "#4FC3F7", "#8C4C45", "#FC06CD",
  "#9B9B9B", "#577039", "#ff00ff", "#800000", "#000080", "#808000", "#ffc0cb",
  "#00ffff", "#f0ffff", "#1b4f72", "#00008b", "#008b8b", "#FFF8DC", "#D2691E",
  "#b881ab", "#006400", "#bdb76b", "#8b008b", "#556b2f", "#ff8c00", "#9932cc",
  "#8b0000", "#e9967a", "#9400d3", "#ffd700", "#008000", "#4b0082", "#f0e68c",
  "#006699", "#FF6699", "#66CCFF", "#530000", "#535c00", "#535c57", "#ab5c57",
  "#800080", "#c0c0c0", "#6666FF", "#00ffa9", "#cdb7b5", "#6f9560", "#368565",
  "#63b598", "#ce7d78", "#ea9e70", "#a48a9e", "#c6e1e8", "#648177", "#0d5ac1",
  "#f205e6", "#1c0365", "#14a9ad", "#4ca2f9", "#a4e43f", "#d298e2", "#6119d0",
  "#d2737d", "#c0a43c", "#f2510e", "#651be6", "#79806e", "#61da5e", "#cd2f00",
  "#9348af", "#01ac53", "#c5a4fb", "#996635", "#b11573", "#4bb473", "#75d89e",
  "#2f3f94", "#2f7b99", "#da967d", "#34891f", "#b0d87b", "#ca4751", "#7e50a8",
  "#c4d647", "#e0eeb8", "#11dec1", "#289812", "#566ca0", "#ffdbe1", "#2f1179",
  "#935b6d", "#916988", "#513d98", "#aead3a", "#9e6d71", "#4b5bdc", "#0cd36d",
  "#250662", "#cb5bea", "#228916", "#ac3e1b", "#df514a", "#539397", "#880977",
  "#f697c1", "#ba96ce", "#679c9d", "#c6c42c", "#5d2c52", "#48b41b", "#e1cf3b",
  "#5be4f0", "#57c4d8", "#a4d17a", "#225b8", "#be608b", "#96b00c", "#088baf",
  "#f158bf", "#e145ba", "#ee91e3", "#05d371", "#5426e0", "#4834d0", "#802234",
  "#6749e8", "#0971f0", "#8fb413", "#b2b4f0", "#c3c89d", "#c9a941", "#41d158",
  "#fb21a3", "#51aed9", "#5bb32d", "#807fb", "#21538e", "#89d534", "#d36647",
  "#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
  "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
  "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#21538e", "#89d534", "#d36647",
  "#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
  "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
  "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#9cb64a", "#996c48", "#9ab9b7",
  "#06e052", "#e3a481", "#0eb621", "#fc458e", "#b2db15", "#aa226d", "#792ed8",
  "#73872a", "#520d3a", "#cefcb8", "#a5b3d9", "#7d1d85", "#c4fd57", "#f1ae16",
  "#8fe22a", "#ef6e3c", "#243eeb", "#1dc18", "#dd93fd", "#3f8473", "#e7dbce",
  "#421f79", "#7a3d93", "#635f6d", "#93f2d7", "#9b5c2a", "#15b9ee", "#0f5997",
  "#409188", "#911e20", "#1350ce", "#10e5b1", "#fff4d7", "#cb2582", "#ce00be",
  "#32d5d6", "#17232", "#608572", "#c79bc2", "#00f87c", "#77772a", "#6995ba",
  "#fc6b57", "#f07815", "#8fd883", "#060e27", "#96e591", "#21d52e", "#d00043",
  "#b47162", "#1ec227", "#4f0f6f", "#1d1d58", "#947002", "#bde052", "#e08c56",
  "#28fcfd", "#bb09b", "#36486a", "#d02e29", "#1ae6db", "#3e464c", "#a84a8f",
  "#911e7e", "#3f16d9", "#0f525f", "#ac7c0a", "#b4c086", "#c9d730", "#30cc49",
  "#3d6751", "#fb4c03", "#640fc1", "#62c03e", "#d3493a", "#88aa0b", "#406df9",
  "#615af0", "#4be47", "#2a3434", "#4a543f", "#79bca0", "#a8b8d4", "#00efd4",
  "#7ad236", "#7260d8", "#1deaa7", "#06f43a", "#823c59", "#e3d94c", "#dc1c06",
  "#f53b2a", "#b46238", "#2dfff6", "#a82b89", "#1a8011", "#436a9f", "#1a806a",
  "#4cf09d", "#c188a2", "#67eb4b", "#b308d3", "#fc7e41", "#af3101", "#ff065",
  "#71b1f4", "#a2f8a5", "#e23dd0", "#d3486d", "#00f7f9", "#474893", "#3cec35",
  "#1c65cb", "#5d1d0c", "#2d7d2a", "#ff3420", "#5cdd87", "#a259a4", "#e4ac44",
  "#1bede6", "#8798a4", "#d7790f", "#b2c24f", "#de73c2", "#d70a9c", "#25b67",
  "#88e9b8", "#c2b0e2", "#86e98f", "#ae90e2", "#1a806b", "#436a9e", "#0ec0ff",
  "#f812b3", "#b17fc9", "#8d6c2f", "#d3277a", "#2ca1ae", "#9685eb", "#8a96c6",
  "#dba2e6", "#76fc1b", "#608fa4", "#20f6ba", "#07d7f6", "#dce77a", "#77ecca",
  "#FF6633", "#FFB399", "#FF33FF", "#FFFF99", "#00B3E6", "#E6B333", "#3366E6",
  "#999966", "#99FF99", "#B34D4D", "#80B300", "#809900", "#E6B3B3", "#6680B3",
  "#66991A", "#FF99E6", "#CCFF1A", "#FF1A66", "#E6331A", "#33FFCC", "#66994D",
  "#B366CC", "#4D8000", "#B33300", "#CC80CC", "#66664D", "#991AFF", "#E666FF",
  "#4DB3FF", "#1AB399", "#E666B3", "#33991A", "#CC9999", "#B3B31A", "#00E680",
  "#4D8066", "#809980", "#E6FF80", "#1AFF33", "#999933", "#FF3380", "#CCCC00",
  "#66E64D", "#4D80CC", "#9900B3", "#E64D66", "#4DB380", "#FF4D4D", "#99E6E6",
  "#b64545", "#6e2c00", "#a52a2a", "#641e16", "#4a235a", "#154360", "#0e6251",
  "#250633", "#cb45ea", "#228956", "#9b19d7", "#d71958", "#d76e19", "#aa7346",
  "#4ce6d6", "#e64cc7", "#e6d94c", "#80b394", "#5d2c52", "#969c98", "#787aed",
  "#6e7b8b", "#bcd2ee", "#cd8c95", "#7a8b8b", "#b4cdcd", "#f08080", "#9ac0cd",
  "#add8e6", "#cd5555", "#8b3a3a", "#9bcd9b", "#698b69", "#8fbc8f", "#a2cd5a",
  "#cdc8b1", "#eee8cd", "#53868b", "#cd3333", "#919bc3", "#7480ae", "#447265",
  "#a9a9a9", "#b46666", "#97b39c", "#9295ac", "#e0e5da", "#d3b3d0", "#b3c6b0",
  "#65848c", "#98d0ae", "#dbb9c8", "#75ab72", "#a5848c", "#bd7d82", "#468499",
  "#4d0033", "#336600", "#009999", "#550080", "#b300b3", "#990000", "#008055",
  "#000066", "#262626", "#008040", "#ff9999", "#e6ff99", "#adebcc", "#ffcc99",
  "#add8e6", "#e0ffff", "#90ee90", "#d3d3d3", "#ffb6c1", "#ffffe0", "#00ff00",
  "#aba357", "#aba389", "#0b6789", "#f76789", "#f7e189", "#f7e1b7", "#f7e16f",
  "#2e0b0a", "#0b0b0a", "#4b4a39", "#4b1239", "#4d1f60", "#9c1f60", "#55115d",
  "#191970", "#32CD32", "#4682B4", "#7FFF00", "#B8860B", "#CD5C5C", "#FF4500",
  "#FFE4B5", "#f5f5dc", "#0000ff"];

export const DOCUMENT_ANNOTATION_TYPES = {
  BASIC: 'Basic',
  ADVANCE: 'Advance'
}
export const DEFAULT_PROJECT = 'DEFAULT_PROJECT'
export const ADVANCE_PROJECT = 'ADVANCE_PROJECT'
export const HIT_ID = 'HIT_ID';

// for listing modal views
export const TAGGER_CATEGORY_INPUT_BOX = 'taggerCategoryInputBox'

export const DUMMY_UID = "123";
export const DUMMY_TOKEN = "11111";
export const POS_TAGGING = "POS_TAGGING";
export const TEXT_SUMMARIZATION = "TEXT_SUMMARIZATION";
export const TEXT_CLASSIFICATION = "TEXT_CLASSIFICATION";
export const VIDEO_CLASSIFICATION = "VIDEO_CLASSIFICATION";
export const TEXT_MODERATION = "TEXT_MODERATION";
export const IMAGE_SEGMENTATION = "IMAGE_SEGMENTATION";
export const IMAGE_BOUNDING_BOX = "IMAGE_BOUNDING_BOX";
export const IMAGE_MASKING = "IMAGE_MASKING";
export const IMAGE_CLASSIFICATION = "IMAGE_CLASSIFICATION";
export const DOCUMENT_ANNOTATION = "DOCUMENT_ANNOTATION";
export const IMAGE_POLYGON_BOUNDING_BOX = "IMAGE_POLYGON_BOUNDING_BOX";
export const IMAGE_POLYGON_BOUNDING_BOX_V2 = "IMAGE_POLYGON_BOUNDING_BOX_V2";
export const IMAGE_BOUNDING_BOX_V2 = "IMAGE_POLYGON_BOUNDING_BOX_V2";
export const POS_TAGGING_GENERIC = "POS_TAGGING_GENERIC";
export const VIDEO_BOUNDING_BOX = "VIDEO_BOUNDING_BOX";

export const HIT_STATE_SKIPPED = "skipped";
export const HIT_STATE_DONE = "done";
export const HIT_STATE_NOT_DONE = "notDone";
export const HIT_STATE_DELETED = "deleted";
export const HIT_STATE_PRE_TAGGED = "preTagged";
export const HIT_STATE_REQUEUED = "reQueued";
export const HIT_STATE_ALL = "all";

export const HIT_EVALUATION_CORRECT = "correct";
export const HIT_EVALUATION_INCORRECT = "incorrect";
export const HIT_EVALUATION_NE = "none";
export const HIT_EVALUATION_ALL = "all";
export const HIT_COMMENTED = "COMMENTED";
export const HIT_UN_COMMENTED = "UN_COMMENTED";
export const HIT_COMMENTS_All = "all";
export const DICOM_CLASSIFICATION = "DICOM_CLASSIFICATION";
export const DICOM_SEGMENTATION = "DICOM_SEGMENTATION";
export const HIT_ENTITIES_ALL = "all";
export const HIT_CONTRIBUTORS_ALL = "all";
export const HIT_EVALUATION_TYPE_ALL = "All";
export const HIT_ENTITY_ALL = "All";


export const NEW_LABEL_UPDATE = "update";
export const NEW_LABEL_DELETE = "delete";
export const DOC_ANNO_CHILDNODEALERT= "You have exceeded the child node limit!";
export const NO_RECORDS_AVAILABLE= "There are no records for the selected item";
export const TOO_LARGE_ENTITY= "The file size exceeds the limit for the 'Move all to done' function. Please upload fewer than 1000 images";
export const DOC_ANNO_ALERT_TITLE = "Alert";
export const DOC_ANNO_OK_TEXT = "OK";
export const SELECT_BY_HITS = "By HITs";
export const SELECT_BY_LABELTYPE = "By Label Type";
export const DONE_SCREEN = "doneScreen";
export const PENDING_SCREEN = "pendingScreen";
export const TOTAL_SCREEN = "pendingScreen";

export const RECTANGLE = "rectangle";
export const CHILD_NODE_LIMIT = 8;

export const hitStateNameMap = {
  deleted: "Deleted",
  skipped: "Skipped",
  done: "Completed",
  reQueued: "Re-Queued",
  notDone: "Not Done",
  preTagged: "Pre Tagged",
  pending: "Pending"
};

export const ROLES_TYPES = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  ANNOTATOR: 3,
  SYSTEM_ADMIN: 4,
  GUEST: 5,
  QA: 6
}

export const nameTypeMap = {
  POS_TAGGING: "POS TAGGING FOR SMALL SENTENCES",
  TEXT_SUMMARIZATION: "TEXT SUMMARIZATION",
  TEXT_CLASSIFICATION: "TEXT CLASSIFICATION",
  TEXT_MODERATION: "TEXT MODERATION",
  IMAGE_BOUNDING_BOX: "IMAGE BOUNDING BOX",
  IMAGE_CLASSIFICATION: "IMAGE CLASSIFICATION",
  VIDEO_CLASSIFICATION: "VIDEO CLASSIFICATION",
  DOCUMENT_ANNOTATION: "DOCUMENT ANNOTATION",
  IMAGE_MASKING: 'IMAGE MASKING',
  IMAGE_POLYGON_BOUNDING_BOX: "IMAGE BOUNDING BOX",
  IMAGE_POLYGON_BOUNDING_BOX_V2: "IMAGE SEGMENTATION",
  IMAGE_SEGMENTATION: "IMAGE SEGMENTATION",
  IMAGE_BOUNDING_BOX_V2: "IMAGE BOUNDING BOX",
  POS_TAGGING_GENERIC: "NER TAGGING",
  VIDEO_BOUNDING_BOX: "VIDEO ANNOTATION",
  DICOM_CLASSIFICATION: "MEDICAL IMAGE CLASSIFICATION",
  DICOM_SEGMENTATION: "MEDICAL IMAGE SEGMENTATION"
};
export const keyMap = {
  8: "backspace",
  9: "tab",
  13: "enter",
  20: "capslock",
  27: "esc",
  32: "space",
  33: "pageup",
  34: "pagedown",
  35: "end",
  36: "home",
  37: "left",
  38: "up",
  39: "right",
  40: "down",
  45: "ins",
  46: "del"
};

export const taskTypeMap = {
  VIDEO_CLASSIFICATION: "Video Classification",
  VIDEO_BOUNDING_BOX: "Video Annotation",
  POS_TAGGING_GENERIC: "NER Tagging",
  IMAGE_BOUNDING_BOX_V2: "Image Bounding Box",
  IMAGE_POLYGON_BOUNDING_BOX: "Image Polygon Bounding",
  IMAGE_POLYGON_BOUNDING_BOX_V2: "Image Bounding Box",
  DOCUMENT_ANNOTATION: "Document Annotation",
  IMAGE_CLASSIFICATION: "Image Classification",
  IMAGE_BOUNDING_BOX: "Image OCR Bounding Boxes",
  IMAGE_SEGMENTATION: "Image Segmentation",
  POS_TAGGING: "Part of Speech Tagging: Small Sentences",
  TEXT_SUMMARIZATION: "Text Summarization",
  TEXT_CLASSIFICATION: "Text Classification",
  TEXT_MODERATION: "Text Moderation",
  DICOM_CLASSIFICATION: "DICOM_CLASSIFICATION",
  DICOM_SEGMENTATION: "Medical Image Segmentation",
  IMAGE_MASKING: "Image Masking"
};

export const publicEmails = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "aol.com",
  "hotmail.co.uk",
  "hotmail.fr",
  "msn.com",
  "yahoo.fr",
  "wanadoo.fr",
  "orange.fr",
  "comcast.net",
  "yahoo.co.uk",
  "yahoo.com.br",
  "yahoo.co.in",
  "live.com",
  "rediffmail.com",
  "free.fr",
  "gmx.de",
  "web.de",
  "yandex.ru",
  "ymail.com",
  "libero.it",
  "outlook.com",
  "uol.com.br",
  "bol.com.br",
  "mail.ru",
  "cox.net",
  "hotmail.it",
  "sbcglobal.net",
  "sfr.fr",
  "live.fr",
  "verizon.net",
  "live.co.uk",
  "googlemail.com",
  "yahoo.es",
  "ig.com.br",
  "live.nl",
  "bigpond.com",
  "terra.com.br",
  "yahoo.it",
  "neuf.fr",
  "yahoo.de",
  "alice.it",
  "rocketmail.com",
  "att.net",
  "laposte.net",
  "facebook.com",
  "bellsouth.net",
  "yahoo.in",
  "hotmail.es",
  "charter.net",
  "yahoo.ca",
  "yahoo.com.au",
  "rambler.ru",
  "hotmail.de",
  "tiscali.it",
  "shaw.ca",
  "yahoo.co.jp",
  "sky.com",
  "earthlink.net",
  "optonline.net",
  "freenet.de",
  "t-online.de",
  "aliceadsl.fr",
  "virgilio.it",
  "home.nl",
  "qq.com",
  "telenet.be",
  "me.com",
  "yahoo.com.ar",
  "tiscali.co.uk",
  "yahoo.com.mx",
  "voila.fr",
  "gmx.net",
  "mail.com",
  "planet.nl",
  "tin.it",
  "live.it",
  "ntlworld.com",
  "arcor.de",
  "yahoo.co.id",
  "frontiernet.net",
  "hetnet.nl",
  "live.com.au",
  "yahoo.com.sg",
  "zonnet.nl",
  "club-internet.fr",
  "juno.com",
  "optusnet.com.au",
  "blueyonder.co.uk",
  "bluewin.ch",
  "skynet.be",
  "sympatico.ca",
  "windstream.net",
  "mac.com",
  "centurytel.net",
  "chello.nl",
  "live.ca",
  "aim.com",
  "bigpond.net.au"
];

export const isNumber = event =>{
  const keyCode = event.keyCode;
  if (keyCode > 48 && keyCode < 57 ) {
    return true;
  }
    return false;
}

export const createEntities = ruleLine => {
  const rules = JSON.parse(ruleLine);
  if (!rules.tags) {
    return [];
  }
  return rules.tags
    .split(",")
    .map(Function.prototype.call, String.prototype.trim);
};


//adds to process advance document annotation tags
const processToJSON = (object, key) => {
  if (key in object) {
    const isArray = Array.isArray(object[key])
    if (isArray && object[key].length > 0) {
      object[key].map(tag => {
        processToJSON(tag, 'entities')
      })
    }
    if (isArray) {
      return object
    } else {
      const obj = object[key]
        .split(",")
        .map(Function.prototype.call, String.prototype.trim)
        .map(it => {
          return {
            id: uuidv1(),
            name: it
          }
        })
      object[key] = obj
      return object
    }
  } else {
    return object
  }
}

export const createEntitiesJson = ruleLine => {
  const rules = JSON.parse(ruleLine);
  if (!rules.tags) {
    if (Array.isArray(rules)) {
      const newRules = []
      rules.forEach(tag => {
        newRules.push(processToJSON(tag, 'entities'))
      })
      return {
        entities: newRules,
        entityJson: newRules
      }
    } else {
      return [];
    }
  }
  if (typeof rules.tags !== 'string') {
    const entities = [];
    const entityJson = {};
    for (let index = 0; index < rules.tags.length; index++) {
      rules.tags[index].label ? entities.push(rules.tags[index].label) : entities.push(rules.tags[index])
      entityJson[rules.tags[index].label] = rules.tags[index].imageUrl
    }
    return { entities, entityJson };
  } else if (typeof rules.tags === 'string') {
    return {
      entities: rules.tags
        .split(",")
        .map(Function.prototype.call, String.prototype.trim), entityJson: {}
    };
  }
};

export const checkFileSize = (fileSize, uploadType) => {

  if (uploadType != 'Pre-Annotated') {
    if (fileSize.includes('MB')) {
      let fileValue = fileSize.substr(0, fileSize.indexOf('MB'));
      if (Math.floor(fileValue) > 100) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      if (fileSize.includes('GB')) {
        return true;
      }
      else {
        return false;
      }
    }
  }
  else {
    if (fileSize.includes('MB')) {
      let fileValue = fileSize.substr(0, fileSize.indexOf('MB'));
      if (Math.floor(fileValue) > 10) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      if (fileSize.includes('GB')) {
        return true;
      }
      else {
        return false;
      }
    }
  }
}

export const getDetaultShortcuts = (type, entities) => {
  const commonImageKeys = {
    DragImageUp: { qualifier: "", key: "w" },
    DragImageDown: { qualifier: "", key: "s" },
    DragImageLeft: { qualifier: "", key: "a" },
    DragImageRight: { qualifier: "", key: "d" },
  };
  const commonKeys = {
    Next: { qualifier: "", key: "right" },
    Previous: { qualifier: "", key: "left" },
    Skip: { qualifier: "ctrl", key: "q" },
    MoveToDone: { qualifier: "ctrl", key: "enter" }
  };
  const posKeys = {
    left: { qualifier: "", key: "p" },
    right: { qualifier: "", key: "n" }
  };
  const docKeys = {
    Close: { qualifier: "", key: "esc" },
    Save: { qualifier: "", key: "enter" }
  };
  const polyKeys = {
    tool: { qualifier: "", key: "space" },
    delete: { qualifier: "", key: "backspace" },
    clearAll: { qualifier: "ctrl", key: "x" },
    undo: { qualifier: "ctrl", key: "z" }
  };
  const videoKeys = {
    forward: { qualifier: "", key: "]" },
    backward: { qualifier: "", key: "[" },
    fast_forward: { qualifier: "", key: "}" },
    fast_backward: { qualifier: "", key: "{" },
    delete: { qualifier: "", key: "backspace" },
    clearAll: { qualifier: "ctrl", key: "x" },
    undo: { qualifier: "ctrl", key: "z" }
  };
  const boundKeys = { tool: { qualifier: "", key: "space" } };
  const entityKeys = {};
  if (entities) {
    for (let index = 0; index < entities.length && index < 10; index++) {
      if (index === 9) {
        entityKeys[entities[index]] = { qualifier: "ctrl", key: 0 };
      } else {
        entityKeys[entities[index]] = {
          qualifier: "ctrl",
          key: `${index + 1}`
        };
      }
    }
  }
  if (type === POS_TAGGING ) {
    return { ...commonKeys, ...posKeys, ...entityKeys };
  } else if (type === DOCUMENT_ANNOTATION || type === POS_TAGGING_GENERIC) {
    return { ...commonKeys, ...docKeys };
  } else if (
    type === IMAGE_POLYGON_BOUNDING_BOX ||
    type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
    type === IMAGE_SEGMENTATION ||
    type === IMAGE_MASKING
  ) {
    return { ...commonImageKeys,...commonKeys, ...polyKeys, ...entityKeys };
  } else if (type === IMAGE_BOUNDING_BOX) {
    return { ...commonImageKeys,...commonKeys, ...boundKeys };
  } else if (type === VIDEO_BOUNDING_BOX) {
    return { ...commonKeys, ...videoKeys };
  }
  else if ( type === IMAGE_CLASSIFICATION) {
    return {  ...commonKeys, ...entityKeys };
  }
  return { ...commonKeys, ...entityKeys };
};

export const getClassificationResult = classificationResponse => {
  let response = [];
  for (const key of Object.keys(classificationResponse)) {
    response.push({ name: key, classes: classificationResponse[key] });
  }
  console.log("classificationResponse result is", response);
  return response;
};

export const getClassificationResponse = (classificationResult, classification, projectType) => {
  let response = [];
  let name = '';
  let classes = [];
    for (let index = 0; index < classificationResult.length; index++) {
      if (projectType === DOCUMENT_ANNOTATION) {
        name = classification[0].name;
      } else {
        name = classificationResult[index].name;
      }
       classes = classificationResult[index].classes;
       response[name] = classes;
    }
  console.log("classificationResponse result is", response);
  return response;
};

export const checkImageURL = url => {
  return url.match(/\.(jpeg|jpg|gif|png|tif|bmp|webp|)$/) !== null;
};

export const checkVideoURL = url => {
  return url.match(/\.(webm|mp4|ogg|flac|mov)$/) !== null;
};

export const convertKeyToString = shortcut => {
  const qualifier = shortcut.qualifier;
  const key = shortcut.key;
  if (qualifier.length > 0) {
    return qualifier + "+" + key;
  } else if (qualifier.length === 0) {
    return key;
  }
};

export const createEntityColorMap = entities => {
  const colorMap = {};
  for (let index = 0; index < entities.length; index++) {
    colorMap[entities[index]] =
      ENTITY_COLORS[
      index > ENTITY_COLORS.length ? index % ENTITY_COLORS.length : index
      ];
  }
  return colorMap;
};

export const createDocEntityColorMap = (entities, color, multiCategory) => {
  const colorMap = {};
  if (entities !== undefined) {
    const isArray = Array.isArray(multiCategory)
    if (isArray) {
      let categoryCount = 0
      multiCategory.forEach(element => {
        const splitElement = element.split(",").map(Function.prototype.call, String.prototype.trim)
        splitElement.forEach(value => {
          let colorVal = [];
          let color = DOC_ENTITY_COLORS[
            categoryCount > DOC_ENTITY_COLORS.length
              ? categoryCount % DOC_ENTITY_COLORS.length
              : categoryCount
          ];
          colorVal.push(color)
          if (colorMap[value] !== undefined) {
            let colorVal = colorMap[value]
            colorVal.push(color)
            colorMap[value] = colorVal
          }
          else {
            colorMap[value] = colorVal
          }
        })
        categoryCount = categoryCount + 1
      })
    } else {
      for (let index = 0; index < entities.length; index++) {
        colorMap[entities[index]] =
          DOC_ENTITY_COLORS[
          index >= DOC_ENTITY_COLORS.length
            ? index % DOC_ENTITY_COLORS.length
            : index
          ];
      }
    }
  }
  return colorMap;
};

export const timeConverter = unixTimestamp => {
  const aaa = new Date(unixTimestamp * 1000);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const year = aaa.getFullYear();
  const month = months[aaa.getMonth()];
  const date = aaa.getDate();
  // const hour = aaa.getHours();
  // const min = aaa.getMinutes();
  // const sec = aaa.getSeconds();
  const time = date + " " + month + " " + year;
  return time;
};

export const dateTimeConverter = unixTimestamp => {
  let date = moment(new Date(unixTimestamp)).format('DD MMM YYYY HH:mm:ss')
  return date;
};

export const getTaskTypeByShape = (task_type, defaultShape) => {
  let displayTaskType = taskTypeMap[task_type];
   if ((taskTypeMap[task_type] === taskTypeMap[IMAGE_POLYGON_BOUNDING_BOX_V2])  && defaultShape === RECTANGLE) {
     displayTaskType = taskTypeMap.IMAGE_BOUNDING_BOX_V2;
   }
   return displayTaskType;
 };

export const captureException = (message) => {
  if (window.Raven) {
    //alert(message);
    window.Raven.captureException(
      "Exception-Capture" +
      JSON.stringify(message)
    );
  }
}

export const posSample = {
  content: "cd players and tuners",
  annotation: [
    { label: ["Category"], points: [{ start: 0, end: 1, text: "cd" }] },
    { label: ["Category"], points: [{ start: 3, end: 9, text: "players" }] },
    { label: ["Category"], points: [{ start: 15, end: 20, text: "tuners" }] }
  ],
  extras: { Name: "columnName", Class: "ColumnValue" }
};

export const imageBoundingSample = {
  content:
    "https://s3.amazonaws.com/com.pronotate.uploads/airplanes__image_0582.jpg",
  annotation: [
    {
      label: "Airplane",
      points: [
        { x: 0.10173697270471464, y: 0.147239263803681 },
        { x: 0.9057071960297767, y: 0.7055214723926381 }
      ],
      imageWidth: 403,
      imageHeight: 135
    }
  ],
  extras: { Name: "columnName", Class: "ColumnValue" }
};

export const textClassificationJsonSample = '{ "content": "when his eye chanced to fall upon alice, as she stood watching","annotation":{"labels":["fiction"],"note":"Alice Speaking"},"extras":null,"metadata":{"first_done_at":1539871791000,"last_updated_at":1539871791000,"sec_taken":0,"last_updated_by":"eMRjkQfSKOVqTlBUJqAKuAj6Tnv1","status":"done","evaluation":"NONE"}}';


export const imagePolyBoundingSample = {
  content:
    "https://s3.amazonaws.com/com.pronotate.uploads/airplanes__image_0001.jpg",
  annotation: [
    {
      label: "Airplane",
      points: [
        [0.2500753738173288, 0.20270832379659018],
        [0.34806532356607256, 0.4579166571299235],
        [0.6143969818575299, 0.40583332379659015],
        [0.7400251225610475, 0.42145832379659015],
        [0.7927889416565248, 0.5620833237965902],
        [0.8681658260786353, 0.6245833237965902],
        [0.8983165798474796, 0.7131249904632568],
        [0.8204271326112986, 0.7443749904632568],
        [0.7274623084906957, 0.7704166571299235],
        [0.6093718562293892, 0.7235416571299235],
        [0.551582911505771, 0.7443749904632568],
        [0.4762060270836605, 0.8016666571299235],
        [0.43097989643039414, 0.7443749904632568],
        [0.34304019793793183, 0.7027083237965902],
        [0.23248743411883638, 0.7131249904632568],
        [0.1420351728123037, 0.6766666571299235],
        [0.09178391653089667, 0.6089583237965902],
        [0.11188441904345948, 0.44749999046325683],
        [0.16967336376707756, 0.4579166571299235],
        [0.14957286125451477, 0.3172916571299235],
        [0.1420351728123037, 0.2079166571299235],
        [0.25510049944546953, 0.19749999046325683],
        [0.2500753738173288, 0.20270832379659018]
      ],
      imageWidth: 398,
      imageHeight: 164
    }
  ],
  extras: null
};


export const fromDateTimestamp = (Date) => {
  Date.setHours(0);
  Date.setMinutes(0)
  Date.setSeconds(0)
  return Date.getTime();
}

export const toDateTimestamp = (Date) => {
  Date.setHours(23);
  Date.setMinutes(59)
  Date.setSeconds(59)
  return Date.getTime();
}

export const validMobileNumbers = ( mobilenumber ) => {
  console.log("mobilenumber length-------->", mobilenumber)
  if ( mobilenumber <= 10 ) {
  if ( mobilenumber ===0 || mobilenumber === 10 ) {
  console.log("mobilenumber length-------->", mobilenumber)
  return true;
  }
  else
  {
    return false
  }
  }
}
export const validEmail = (Email) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(Email)
}

export const validEnitity = (tags) => {
  return /^.*[^,]$/.test(tags)
}
export const checkPassword = (inputtxt) => {
  var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
  if (inputtxt.match(decimal)) {
    return true;
  }else {
    return false;
  }
}

export const workspaceType = {
  All: "All",
  Archived : "archived"
}

export const hitStatustitle =(type) =>{
  let statusTitle="";
  switch(type){
    case HIT_STATE_DONE:
        statusTitle = "Done";
        break;
    case HIT_STATE_DELETED:
        statusTitle = "Deleted";
        break;
    case HIT_STATE_SKIPPED:
      statusTitle = "Skipped";
      break;
    case HIT_STATE_PRE_TAGGED:
      statusTitle = "Pre-Tagged";
      break;
    case HIT_STATE_REQUEUED:
      statusTitle = "Re-Tagging";
      break;
    case HIT_STATE_NOT_DONE:
        statusTitle = "Pending";
        break;
    case HIT_STATE_ALL:
        statusTitle = "Total";
        break;
    case HIT_EVALUATION_CORRECT:
      statusTitle = "Correct";
        break;
    case HIT_EVALUATION_INCORRECT:
      statusTitle = "Incorrect";
        break;
    default:
      statusTitle = "List";
  }
  return statusTitle;
}

export const hitsBreadCrumbTitle = (type) => {
  return (<span className="tilte_display fw-400">HITs&nbsp;{hitStatustitle(type)} </span>);
}


export const projectTypePath = (projectDetails) => {
    let projectTypePath = '/projects/'
    if (projectDetails && projectDetails.task_type === DICOM_CLASSIFICATION ||
      projectDetails.task_type === DICOM_SEGMENTATION) {
        projectTypePath = '/dicomprojects/'
      }
    return projectTypePath
}


export const trimSpaces = (text) =>{
  return  text.replace(/^\s+|\s+$/g, "").replace(/\s+/g, " ");
}

export const breadcrumbSeperate = () => {
  return (<span className="m-l-10 m-r-10 d_icon"><svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.56209 11.8759C1.47933 11.9586 1.37588 12 1.26209 12C1.1483 12 1.04485 11.9586 0.96209 11.8759C0.796572 11.7104 0.796572 11.4414 0.96209 11.2759L6.23796 6.00001L0.96209 0.724139C0.796572 0.558621 0.796572 0.289655 0.96209 0.124138C1.12761 -0.0413793 1.39657 -0.0413793 1.56209 0.124138L7.13796 5.7C7.30348 5.86552 7.30348 6.13449 7.13796 6.30001L1.56209 11.8759V11.8759Z" fill="#5B5B5B"/>
  </svg></span>);
}

export const domToimageURL = (pId, imageId, hitId, uploadAnnotatedImagesToS3CallBack) => {

    const node = document.getElementById(imageId);
    node.getElementsByTagName("img")[0].style.transform = "translate3d(0px, 0px, 0px) scale(1)";
    node.getElementsByTagName("svg")[0].style.transform = "translate3d(0px, 0px, 0px) scale(1)";
    console.log('node node=====>',node)
    let imageURL = null;
    domtoimage.toBlob(node, { cacheBust: true })
    .then( function (blob) {
      imageURL = blob2file(blob, hitId+".png");
      FileSaver.saveAs(imageURL, 'my-node.png');
      console.log('blob value===============>',imageURL)
      // uploadAnnotatedImagesToS3(pId, hitId, imageURL, uploadAnnotatedImagesToS3CallBack.bind(this),0)
    });
}

export const domToimageURLDownload = (imageId) => {
// Function to download Image

let downloadNode = document.getElementById(imageId)
// let downloadNodeCopy  = cloneDeep(downloadNode)
downloadNode.getElementsByTagName("img")[0].style.transform = "translate3d(0px, 0px, 0px) scale(1)";
downloadNode.getElementsByTagName("svg")[0].style.transform = "translate3d(0px, 0px, 0px) scale(1)";

  domtoimage.toJpeg(downloadNode, { quality: 0.95, cacheBust: true })
      .then(function (dataUrl) {
          var link = document.createElement('a');
          link.download = 'myImage.jpeg';
          link.href = dataUrl;
          link.click();
      });
  }

export const blob2file = (theBlob, fileName) => {
/*	  const fd = new FormData();
	  fd.set('file', theBlob);*/
	  var file = new File([theBlob], fileName, {type: 'image/png', lastModified: Date.now()});
	  return file;
	}

export const blobToFile = (theBlob, fileName ) => {
/*    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;*/
    }

export const saveButtonShow = (changesInSession, hits, currentIndex) => {
  let saveButtonShow = false
  if (hits !== undefined && currentIndex !== undefined) {
    let currentHit = hits[currentIndex]
    if (changesInSession > 0 || (currentHit && currentHit.hitResults !== null && currentHit.hitResults.length > 0)) {
      if (changesInSession === 0) {
        let hitResults = currentHit.hitResults
        hitResults.map((result)=>{
          if (result.result !== "[]" && result.result !== "" && result.result !== null){
            saveButtonShow = true
          }
        })
      }
      else {
        saveButtonShow = true
      }
    }
  }
  return saveButtonShow
}
export const getImageMaskEntityColorMap = (coloredTags) => {
  let coloredEntities = [];
  let coloredEntityColorMap = {};
  coloredTags.map((coloredTag, index) => {
    coloredEntities.push(coloredTag.label);
    coloredEntityColorMap[coloredEntities[index]] = coloredTag.color;
  })
  return coloredEntityColorMap;
}

export const getPageSizeOptions = () => {
  const pageSize = [5, 8, 10, 20, 50, 100];
  return pageSize;
}

export const createHitJson = (hits, entityColorMap) => {
  const hit = hits ? JSON.parse(hits) : [];
  let hitMap = {
    hitEntit : {},
    hits : hit
  };
  //if ( hit[0].color !== null) {
    for (var i = 0; i < hit.length; i++) {
      hitMap['hitEntit'][i] = hit[i].color ? hit[i].color : entityColorMap[hit[i].label];
      if (hit[i].color === undefined) {
        hit[i].color = entityColorMap[hit[i].label];
      }
    //}
  }
   return hitMap
}
export const getByValue = (map, searchValue) => {
  return Object.keys(map).filter(key => map[key] === searchValue);
}

export const getGroupingLabels = (labelGroupMap) => {
  let arrayItems = {};
  let keys = [];
  if (labelGroupMap) {
    keys = Object.keys(labelGroupMap);
  }
  keys.map((element) => {
    if (labelGroupMap[element]) {
      if (Object.values(arrayItems) && Object.values(arrayItems).length === 0) {
        arrayItems[element] = labelGroupMap[element];
      } else {
        if (labelGroupMap[element].startsWith(SYMBOLS.HASH)) {
          if (!Object.values(arrayItems).includes(labelGroupMap[element])) {
            arrayItems[element] = labelGroupMap[element];
          }
        } else {
          arrayItems[element] = labelGroupMap[element];
        }
      }
    }
  });
  return arrayItems;
}

export const getAnnotatedLabelsCount = (labelGroupMap) => {
  let groupArray = getGroupingLabels(labelGroupMap)
  return Object.keys(groupArray).length
}

export const getTimeDifference = (dateFrom, dateTo) => {
  let difference = dateFrom.getTime() - dateTo.getTime();
  let daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24
  let hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60
  let minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60
  let secondsDifference = Math.floor(difference / 1000);
  let jsonObj = {};
  jsonObj.daysDifference = daysDifference;
  jsonObj.hoursDifference = hoursDifference;
  jsonObj.minutesDifference = minutesDifference;
  jsonObj.secondsDifference = secondsDifference;
  return jsonObj;
}

export const redirectLogin = () => {
  // dtLogout();
  window.sessionStorage.clear();
  setTimeout(() => {
    let url = window.location.protocol + "//" + window.location.host + "/projects/login";
    window.location.replace(url);
    window.sessionStorage.setItem(SESSION_VARIABLES.TOKEN_EXPIRY, BOOLEAN_TYPES.TRUE);
  }, 100);
}

var countdown = 0;

export const startTimer = () => {
  var seconds = 100; // 120 seconds
  countdown = setInterval(() => {
    seconds--;
    if (seconds <= 15) {
      clearInterval(countdown);
      redirectLogin();
    }
  }, 1000);
}

export const isJSON = (str) => {
  try {
  JSON.parse(str);
  } catch (e) {
  return false;
  }
  return true;
  }

export const stopTimer = () => {
  clearInterval(countdown);
}

export const CLICK_TIMEOUT = 1;  // 5 min
export const IDLE_TIMEOUT = 13; // 13 min

export const extendTimeoutInvoke = (type) => {
  if (getUidToken().token && window.sessionStorage.getItem(SESSION_VARIABLES.LAST_ACCESS_TIME_API)) {
    const difference = getTimeDifference(new Date(Date.now()), new Date(parseInt(window.sessionStorage.getItem(SESSION_VARIABLES.LAST_ACCESS_TIME_API))));
    if ((type === ACTIONS.CLICK && difference.minutesDifference >= CLICK_TIMEOUT) || (type === ACTIONS.IDLE && difference.minutesDifference >= IDLE_TIMEOUT)) {
      if (type === ACTIONS.IDLE) {
        window.sessionStorage.setItem(SESSION_VARIABLES.TIME_EXPIRY_POPUP, BOOLEAN_TYPES.TRUE);
        startTimer();
        const confirm = AntModal.confirm;
        confirm({
          title: CONFIRMATION_BOX.CONFIRMATION_TITLE,
          content: MESSAGES.EXPIRY_ALERT_MESSAGE,
          okText: CONFIRMATION_BOX.CONFIRMATION_MODAL_OK,
          cancelText: CONFIRMATION_BOX.CONFIRMATION_MODAL_CANCEL,
          onOk() { stopTimer(); extendTimeout(); },
          onCancel() { window.sessionStorage.setItem(SESSION_VARIABLES.TIME_EXPIRY_POPUP, BOOLEAN_TYPES.FALSE); redirectLogin(); },
        });
      } else {
        stopTimer();
        // extendTimeout();
      }
    }
  }
}


export function displaySettingsByRole(roleId){
  if ( parseInt(roleId) === parseInt(ROLES_TYPES.GUEST) || parseInt(roleId) === parseInt(ROLES_TYPES.QA)){
    return 'none';
  }
  return 'block';
}

export function pointerSettingsByRole(roleId) {
  if (parseInt(roleId) === parseInt(ROLES_TYPES.GUEST) || parseInt(roleId) === parseInt(ROLES_TYPES.QA)){
    return 'none';
  }
  return 'default';
}

export const RoleOptions = [
  { key: '0', text: 'Select Role', value: '0' },
  { key: '2', text: 'Admin', value: '2' },
  { key: '3', text: 'Annotator', value: '3'},
  { key: '5', text: 'Guest', value: '5' },
  { key: '6', text: 'QA', value: '6' }
]
