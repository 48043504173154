import React, { Component, PropTypes } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { bindActionCreators } from "redux";
import { Button, Form, Message } from "semantic-ui-react";
import { updateUserPassword } from "../../Services/LoginApis";
import { showAlert } from "../../helpers/commonFunctions";
import { logout } from "../../redux/reducer/loginReducer";
import { push, replace } from "react-router-redux";
import { checkPassword } from "../../helpers/Utils";
import "./TaggerUsers.css";

class UpdatePassword extends Component {
  submitDisabled = false;
  static propTypes = {
    user: PropTypes.object,
    login: PropTypes.func,
    logout: PropTypes.func.isRequired,
    pushState: PropTypes.func,
    replaceState: PropTypes.func,
  };

  static userPropTypes = {
    firstName: PropTypes.string.isRequired,
    secondName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };
  constructor(props) {
    console.log("props are ", props);
    super(props);
    this.updatePassword = this.updatePassword.bind(this);
    this.handleUserFieldChange = this.handleUserFieldChange.bind(this);
  }

  componentDidMount() {
    console.log("update password component did mount");
    if (window.sessionStorage.getItem("uid") === null) {
      this.props.pushState("/projects/login");
    }
  }
  handleLogout = (event) => {
    event.preventDefault();
    this.props.logout();
  };
  state = {
    activeMenu: "projects/updatePassword",
    passwordChanged: false,
    oldPassword: "",
    newPassword: "",
    reEnterPassword: "",
    error: "",
    success: "",
  };

  handleUserFieldChange = (event) => {
    if (event.target.name === "oldPassword") {
      this.setState({ oldPassword: event.target.value });
    } else if (event.target.name === "newPassword") {
      this.setState({ newPassword: event.target.value });
    } else if (event.target.name === "reEnterPassword") {
      this.setState({ reEnterPassword: event.target.value });
    }
  };

  updatePassword(event) {
    const {oldPassword,newPassword,reEnterPassword} = this.state
    if (oldPassword.length === 0) {
      showAlert("Please enter the Old Password.", "error");
    } else if (newPassword.length === 0) {
      showAlert("Please enter the New Password.", "error");
    } else if (
      newPassword &&
      !checkPassword(newPassword)
    ) {
      showAlert(
        "Password between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.",
        "error"
      );
    } else if (reEnterPassword.length === 0) {
      showAlert("Please enter the Confirm New Password.", "error");
    } else if (newPassword !== reEnterPassword) {
      showAlert(
        "New Password and Confirm New Password does not match.",
        "error"
      );
    } else {
      this.setState({ error: "", success: "" });
      updateUserPassword(
        oldPassword,
        newPassword,
        this.dtUpdateUserPasswordCallback.bind(this)
      );
    }
  }
  validateInput() {
    const {oldPassword,newPassword,reEnterPassword} = this.state
    if (
      oldPassword.length === 0 ||
      newPassword.length === 0 ||
      reEnterPassword.length === 0
    ) {
      return true;
    }

    return false;
  }
  dtUpdateUserPasswordCallback(error, response) {
    if (!error) {
      showAlert("Password changed successfully");
      this.props.logout();
    } else {
      let errMessage =
        response && response.body.message
          ? response.body.message
          : "an internal server issue. Please retry after some time.";
      showAlert("Password change is failed due to " + errMessage, "error");
    }
  }

  render() {
    const {oldPassword,newPassword,reEnterPassword,error,success} = this.props
    this.submitDisabled = this.validateInput();
    return (
      <div className="pr_changepassword">
        <Helmet title="Change Password" />
        <div className="title_section f-center">
          <h2 className="tilte_display fw-600">Change Password</h2>
        </div>
        <div className="change_password">
          <Form>
            <div className="hold-sec">
              <Form.Group className="password_input" widths="equal">
                <Form.Input
                  fluid
                  label="Old Password"
                  type="password"
                  name="oldPassword"
                  value={oldPassword}
                  onChange={this.handleUserFieldChange.bind(this)}
                  placeholder="Old Password"
                />
              </Form.Group>

              <Form.Group className="password_input" widths="equal">
                <Form.Input
                  fluid
                  label="New Password"
                  type="password"
                  name="newPassword"
                  value={newPassword}
                  onChange={this.handleUserFieldChange.bind(this)}
                  placeholder="New Password"
                />
              </Form.Group>

              <Form.Group className="password_input" widths="equal">
                <Form.Input
                  fluid
                  label="Confirm New Password"
                  type="password"
                  name="reEnterPassword"
                  value={reEnterPassword}
                  onChange={this.handleUserFieldChange.bind(this)}
                  placeholder="Confirm New Password"
                />
              </Form.Group>
            </div>
            <div className="text-center">
              <Message negative hidden={!error}>
                <p>{error}</p>
              </Message>
              <Message positive hidden={!success}>
                <p>{success}</p>
              </Message>
              <Button
                className="btn_base password_submit"
                onClick={this.updatePassword.bind(this)}
                disabled={this.submitDisabled}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.loginReducer.user,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushState: push,
      logout,
      replaceState: replace,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
