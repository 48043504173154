import { createStore, applyMiddleware, combineReducers } from 'redux';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { browserHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { logout } from '../reducer/loginReducer';
import { reducer as reduxAsyncConnect } from 'redux-async-connect';
import superagent from 'superagent'; // Import superagent here
import loginReducer from '../reducer/loginReducer';
import searchReducer from '../reducer/searchReducer';
import projectReducer from '../reducer/projectReducer'; // Import action type constants
import dicomViewerReducer from '../reducer/dicomReducer';

const middleware = [thunk, routerMiddleware(browserHistory)];
const dispatch = useDispatch

const rootReducer = combineReducers({
  routing: routerReducer,
  reduxAsyncConnect,
  loginReducer,
  searchReducer,
  projectReducer,
  dicomViewerReducer
  // Add your other reducers here
});

// Define clientMiddleware function
const clientMiddleware = store => next => action => {
  if (typeof action === 'function') {
    return action(store.dispatch, store.getState);
  }

  const { promise, types, ...rest } = action;

  if (!promise || !types) {
    return next(action);
  }

  const [REQUEST, SUCCESS, FAILURE] = types;
  next({ ...rest, type: REQUEST });

  const actionPromise = promise(superagent); // Pass superagent to the promise function

  actionPromise.then(
    (result) => next({...rest, result, type: SUCCESS}),
    (error) => {
      console.log('1 middleware error ', error, error.code, error.message);
      if (error.message === 'Unauthorized') {
        dispatch(logout());
      } else {
        next({...rest, error, type: FAILURE});
      }
    }
  ).catch((error)=> {
    console.log('2 MIDDLEWARE ERROR:', error, error.message);
    next({...rest, error, type: FAILURE});
  });

  return actionPromise;
};

const store = createStore(
  rootReducer,
  applyMiddleware(...middleware, clientMiddleware) // Add clientMiddleware to applyMiddleware
);

export { store, browserHistory as history };