import { LOGIN, LOGOUT, SIGN_IN } from './loginReducer';
import initialState from '../store/dturks-store';
import actionTypes from '../actions/action-types';
import { BASE_URL } from '../../helpers/Constants';
import superagent from 'superagent';

const LOAD = 'redux-example/auth/LOAD';
const LOAD_SUCCESS = 'redux-example/auth/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-example/auth/LOAD_FAIL';
const RESET = 'redux-example/store/RESET';

const UPLOAD_DATA_FORM_REQUEST = 'redux-example/dataturks/UPLOAD_DATA_FORM_REQUET';
const UPLOAD_DATA_FORM_SUCCESS = 'redux-example/dataturks/UPLOAD_DATA_FORM_SUCCESS';
const UPLOAD_DATA_FORM_FAILURE = 'redux-example/dataturks/UPLOAD_DATA_FORM_FAIL';

const UPLOAD_FILE_STATS = 'redux-example/dataturks/UPLOAD_FILE_STATS';
const TAG_DATA_ROW = 'redux-example/dataturks/TAG_DATA_ROW';
const SELECT_PROJECT = 'redux-example/dataturks/SELECT_PROJECT';
const UPDATE_CURRENT_PROJECT_DETAILS = 'redux-example/dataturks/UPDATE_CURRENT_PROJECT_DETAILS';
const UPDATE_HOME_DATA = 'redux-example/dataturks/UPDATE_HOME_DATA';
const UPDATE_CURRENT_HIT = 'redux-example/dataturks/UPDATE_CURRENT_HIT';

const GET_HOME_DATA = 'redux-example/dataturks/GET_HOME_DATA_REQUST';
const GET_HOME_DATA_SUCCESS = 'redux-example/dataturks/GET_HOME_DATA_SUCCESS';
const GET_HOME_DATA_FAILURE = 'redux-example/dataturks/GET_HOME_DATA_FAIL';

const GET_PROJECT_ID = 'redux-example/dataturks/GET_PROJECT_ID';
const GET_PROJECT_ID_SUCCESS = 'redux-example/dataturks/GET_PROJECT_ID_SUCCESS';
const GET_PROJECT_ID_FAILURE = 'redux-example/dataturks/GET_PROJECT_ID_FAILURE';

const GET_ORG_DETAILS = 'redux-example/dataturks/GET_ORG_DETAILS_REQUEST';
const GET_ORG_DETAILS_SUCCESS = 'redux-example/dataturks/GET_ORG_DETAILS_SUCCESS';
const GET_ORG_DETAILS_FAILURE = 'redux-example/dataturks/GET_ORG_DETAILS_FAIL';

const GET_PROJECT_DETAIL = 'redux-example/dataturks/GET_PROJECT_DETAIL_REQUEST';
const GET_PROJECT_DETAIL_SUCCESS = 'redux-example/dataturks/GET_PROJECT_DETAIL_SUCCESS';
const GET_PROJECT_DETAIL_FAILURE = 'redux-example/dataturks/GET_PROJECT_DETAIL_FAIL';

const GET_USER_DATA = 'redux-example/dataturks/GET_USER_DATA_REQUST';
const GET_USER_DATA_SUCCESS = 'redux-example/dataturks/GET_USER_DATA_SUCCESS';
const GET_USER_DATA_FAILURE = 'redux-example/dataturks/GET_USER_DATA_FAIL';

const SET_ERROR = 'redux-example/dataturks/SET_ERROR';

const TOGGLE_MENU = 'redux-example/dataturks/TOGGLE_MENU';
const USER_DEFINED_COLOR_STATS = 'redux-example/dataturks/USER_DEFINED_COLOR_STATS';

  const projectReducer = (state = initialState, action = {})=>{
  console.log('project reduce called ', state, action);
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        ...state,
        menuHidden: action.value
      };
    case SET_ERROR:
      return {
        ...state,
        globalError: action.value
      };
    case GET_PROJECT_DETAIL:
      return {
        ...state,
        loading: true
      };
    case GET_PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        projectDetails: action.result.body
      };
    case GET_PROJECT_DETAIL_FAILURE:
      return {
        ...state,
        projectDetails: undefined
      };
    case GET_ORG_DETAILS:
      return {
        ...state,
        loading: true
      };
    case GET_ORG_DETAILS_SUCCESS:
      return {
        ...state,
        orgData: action.result.body
      };
    case GET_ORG_DETAILS_FAILURE:
      let error = 'Internal Server Error';
      if (action.error.response && action.error.response.body && action.error.response.body.message) {
        error = action.error.response.body.message;
      }
      return {
        ...state,
        orgDataFailure: error
      };
    case GET_PROJECT_ID:
      return {
        ...state,
        loading: true,
        currentProject: undefined
      };
    case GET_PROJECT_ID_FAILURE:
      return {
        ...state,
        loading: false,
        currentProject: undefined
      };
    case GET_PROJECT_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        currentProject: action.result.body.response
      };
    case GET_HOME_DATA_SUCCESS:
      let orgName = action.result.body.orgName || undefined;
      if (action.result.body.projects && action.result.body.projects.length > 0) {
        for (let index = 0; index < action.result.body.projects.length; index++) {
          console.log(' project is ', action.result.body.projects[index]);
          if (action.result.body.projects[index].role === 'OWNER') {
            orgName = action.result.body.projects[index].projectDetails.orgName;
          }
        }
      }
      console.log('orgName is ', orgName);
      return {
        ...state,
        user: action.result.body.userDetails,
        projects: action.result.body.projects,
        orgName
      };
      case GET_USER_DATA:
      return {
        ...state,
        loading: true
      };
    case GET_USER_DATA_SUCCESS:
      console.log("GET_USER_DATA_SUCCESS",action.result.body)
      return {
        ...state,
        allUserData: action.result.body
      };
    case GET_USER_DATA_FAILURE:
      let userError = 'Internal Server Error';
      if (action.error.response && action.error.response.body && action.error.response.body.message) {
        userError = action.error.response.body.message;
      }
      return {
        ...state,
        allUserDataFailure: userError
      };

    case LOGIN:
    case LOGOUT:
    case RESET:
    case SIGN_IN:
      console.log('resetting state');
      return initialState;
    case UPDATE_CURRENT_HIT:
      return {
        ...state,
        currentHit: action.data
      };
    case UPDATE_HOME_DATA:
      return {
        ...state,
        projects: action.projects,
        plan: action.plan,
        subscriptionExpiryTimestamp: action.subscriptionExpiryTimestamp,
        labelsAllowed: action.labelsAllowed,
        labelsDone: action.labelsDone,
        hasSubscriptionExpired: action.hasSubscriptionExpired
      };
    case UPDATE_CURRENT_PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: action.data,
        currentProject: action.data.id,
        totalHits: action.data.totalHits
      };

    case UPLOAD_FILE_STATS:
      return {
        ...state,
        uploadedFileStats: action.stats
      };
    case SELECT_PROJECT:
      return {
        ...state,
        projectCreated: false,
        currentProject: action.id
      };
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        user: action.result
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case UPLOAD_DATA_FORM_REQUEST:
      console.log("STATE",state)
      return {
        ...state,
        loading: true,
        projectCreateError: undefined
      };
    case UPLOAD_DATA_FORM_SUCCESS:
      console.log("Upload Data success",action)
      return {
        ...state,
        projectCreated: true,
        currentProject: action.result.body.response
      };
    case UPLOAD_DATA_FORM_FAILURE:
      let projectError = 'Error in project creation';
      if (action.error.response && action.error.response.body && action.error.response.body.message) {
        projectError = action.error.response.body.message;
      }
      return {
        ...state,
        loading: false,
        loaded: false,
        projectCreateError: projectError
      };
    case TAG_DATA_ROW:
      return {
        ...state,
        loading: true
      };
    case 'persist/PERSIST':
      return {
        state
      };
    case '@@router/LOCATION_CHANGE':
      console.log('dataturks location is changing', action);
      const pathSplits = action.payload.pathname.split('/');
      let menuHidden = state.menuHidden;
      console.log('locationchange', pathSplits);
      if (pathSplits.length === 5 && ( pathSplits[4] === 'space' || pathSplits[4] === 'view')) {
        menuHidden = true;
      }
      if (action.payload.pathname === '/projects/create') {
        console.log('project import create=>',state)
        return { ...state, menuHidden, currentPathProject: pathSplits[3], currentPathOrg: pathSplits[2], locationPath: action.payload.pathname, currentProject: null, projectCreated: false, projectDetails: null };
      } else if (action.payload.pathname === '/projects/import') {
        console.log('state=import==>',state)
        return { ...state, menuHidden, currentPathProject: pathSplits[3], currentPathOrg: pathSplits[2], locationPath: action.payload.pathname, currentProject: null, projectCreated: false };
      }
      console.log('state==workspace=>',state)
      return { ...state, menuHidden, currentPathProject: pathSplits[3], currentPathOrg: pathSplits[2], locationPath: action.payload.pathname };
      case actionTypes.updateOrgName:
      return {
        ...state,
        orgName: action.payload
      }
    case actionTypes.updateActiveMenu:
      return {
        ...state,
        activeMenu: action.payload
      }
    default:
      return state;

      case actionTypes.UPDATE_MODAL_VIEW:
      const modal = state.modals[action.payload]
      return {
        ...state,
        modal: !modal
      }
    case actionTypes.UPDATE_CURRENT_PAGE_STATUS:
      return {
        ...state,
        currentPageStatus: action.payload
      }
    case actionTypes.UPDATE_CURRENT_HITS_URL:
      return {
        ...state,
        currentHitsData: action.payload
      }
    case actionTypes.UPDATE_HITS_DETAILS:
      return {
        ...state,
        selectedHitsDetails: action.payload
      }
    case actionTypes.UPDATE_PREV_PAGE_DETAILS:
      return {
        ...state,
        prevPageDetails: action.payload
      }
    case actionTypes.SET_ACTIVE_MENU:
      console.log('dispactvhjvhgvjvv=====>',action.payload)
        return {
          ...state,
          getactiveMenu: action.payload
        }
    case actionTypes.LOADER_VIEW:
      return {
        ...state,
        isLoading: action.payload
      }
     case USER_DEFINED_COLOR_STATS:
      return {
        ...state,
        showUserDefinedColor: action.payload
      }
    //END
}
}

const HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'UID': '2c9fafb06185d9b3016185dbb66a0000',
  'TOKEN': '12345'
};

export function resetState() {
  return {
    type: RESET
  };
}

export function setError(value) {
  return {
    type: SET_ERROR,
    value: value
  };
}

export function toggleMenu(value) {
  return {
    type: TOGGLE_MENU,
    value: value
  };
}

export function setactiveMenu(name) {
  console.log('setActive====>',name)
  return {
      type: "SET_ACTIVE_MENU",
      payload: name
  };
}



export function uploadDataForm(input, uidToken) {
  console.log('upload form data ', input, uidToken);
  const { uid, token } = uidToken;
  console.log("upload data D=Form", uid, token);
  
  // Assuming HEADERS is defined somewhere in your code
  HEADERS.TOKEN = token;
  HEADERS.UID = uid;

  return {
    types: [UPLOAD_DATA_FORM_REQUEST, UPLOAD_DATA_FORM_SUCCESS, UPLOAD_DATA_FORM_FAILURE],
    promise: () => new Promise((resolve, reject) => {
      console.log("in the api call");
      superagent.post(BASE_URL + 'createProject')
        .send(input)
        .set('uid', uid)
        .set('token', token)
        .end((err, res) => { // Change 'error' to 'err' to avoid conflict
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
    })
  };
}

export function getUserHomeData(uidToken) {
  const { uid, token } = uidToken;
  console.log('get home data ', uid, token);
  HEADERS.TOKEN = token;
  HEADERS.UID = uid;
  return {
    types: [GET_HOME_DATA, GET_HOME_DATA_SUCCESS, GET_HOME_DATA_FAILURE],
    promise: () => new Promise((resolve, reject) => {
      superagent.post(BASE_URL + 'getUserHome').set('uid', uid).set('token', token)
        .end((error, res) => {
          if (error) reject(error); else resolve(res);
        });
    })
  };
}

export function getUserHomeDataWithFetchOption(uidToken) {
  const { uid, token } = uidToken;
  console.log('get home data getUserHomeDataWithFetchOption', uid, token);
  HEADERS.TOKEN = token;
  HEADERS.UID = uid;
  return {
    types: [GET_HOME_DATA, GET_HOME_DATA_SUCCESS, GET_HOME_DATA_FAILURE],
    promise: () => new Promise((resolve, reject) => {
      superagent.post(BASE_URL + 'getUserHomeCompact').set('uid', uid).set('token', token)
        .end((error, res) => {
          if (error) reject(error); else resolve(res);
        });
    })
  };
}

export function getProjectDetails(pid, uidToken) {
  const { uid, token } = uidToken;
  console.log('get projects data ', uid, token);
  HEADERS.TOKEN = token;
  HEADERS.UID = uid;
  return {
    types: [GET_PROJECT_DETAIL, GET_PROJECT_DETAIL_SUCCESS, GET_PROJECT_DETAIL_FAILURE],
    promise: () => new Promise((resolve, reject) => {
      superagent.post(BASE_URL + pid + '/getProjectDetails').set('uid', uid).set('token', token)
        .end((error, res) => {
          if (error) reject(error); else resolve(res);
        });
    })
  };
}

export function getHitsDetails(pid,uidToken,
  start,
  count,
  type,
  label,
  userId) {
  const { uid, token } = uidToken;
  console.log('get home data ', uid, token);
  HEADERS.TOKEN = token;
  HEADERS.UID = uid;
  let status = "done";
  if (type && type === "skipped") {
    status = "skipped";
  }
  if (type && type === "all") {
    status = null;
  }
  let url =
    BASE_URL +
    pid +
    "/getHits?start=" +
    start +
    "&count=" +
    count;
  if (status !== null)
    url = url + "&status=" + status;
  if (label) {
    url = url + "&label=" + label.replace(" ", "+");
  }
  if (userId) {
    url = url + "&userId=" + userId;
  }
      return superagent
      .post(url)
      .set("uid", uid)
      .set("token", token)
        .end((error, res) => {
          console.log("newres", error, res)
          return res;
        });
}


export function setCurrentProject(input, uidToken) {
  const { uid, token } = uidToken;
  HEADERS.TOKEN = token;
  HEADERS.UID = uid;
  return {
    types: [GET_PROJECT_ID, GET_PROJECT_ID_SUCCESS, GET_PROJECT_ID_FAILURE],
    promise: () => new Promise((resolve, reject) => {
      superagent.post(BASE_URL + 'getProjectId').send(input).set('uid', uid).set('token', token)
        .end((error, res) => {
          if (error) reject(error); else resolve(res);
        });
    })
  };
}

export function getOrgDetails(orgName, uidToken, cache) {
  console.log("getOrgDetails Data" ,orgName,uidToken,cache)
  const { uid, token } = uidToken;
  HEADERS.TOKEN = token;
  HEADERS.UID = uid;
  let url = 'getOrgProjects?orgName=';
  if (cache) {
    url = 'getOrgProjects?cache=false&orgName=';
  }
  return {
    types: [GET_ORG_DETAILS, GET_ORG_DETAILS_SUCCESS, GET_ORG_DETAILS_FAILURE],
    promise: () => new Promise((resolve, reject) => {
      superagent.post(BASE_URL + url + orgName).set('uid', uid).set('token', token)
        .end((error, res) => {
          if (error) reject(error); else resolve(res);
        });
    })
  };
}

export function getAllUsers(uidToken, searchObject) {
  console.log("getAllUsers called",uidToken,searchObject)
  const { uid, token } = uidToken;
  if(searchObject.orderBy === null || searchObject.orderBy === undefined)
    searchObject.orderBy = "firstName";
  HEADERS.TOKEN = token;
  HEADERS.UID = uid;
  return {
    types: [ GET_USER_DATA, GET_USER_DATA_SUCCESS, GET_USER_DATA_FAILURE ],
    promise: () => new Promise((resolve, reject) => {
      superagent.get(BASE_URL + 'getAllUsersInfo?page='+searchObject.page+'&size='+searchObject.size+'&orderBy='+searchObject.orderBy+'&sortOrder='+searchObject.sortOrder+'&searchType='+searchObject.searchType+'&searchKeyword='+searchObject.searchKeyword).set('uid', uid).set('token', token)
      .end((error, res) => {
        if (error) reject(error); else resolve(res);
      });
    })
  };
}


export function updateFileUploadStats(input) {
  return {
    type: UPLOAD_FILE_STATS,
    stats: input
  };
}

export function updateHomeData(userData, projects, plan, labelsAllowed, labelsDone, subscriptionExpiryTimestamp, hasSubscriptionExpired) {
  return {
    type: UPDATE_HOME_DATA,
    user: userData,
    projects,
    plan,
    labelsDone,
    labelsAllowed,
    subscriptionExpiryTimestamp,
    hasSubscriptionExpired
  };
}

export function selectProject(input) {
  return {
    type: SELECT_PROJECT,
    id: input
  };
}

export function updateProjectDetails(input) {
  return {
    type: UPDATE_CURRENT_PROJECT_DETAILS,
    data: input
  };
}

export function setCurrentHit(input) {
  return {
    type: UPDATE_CURRENT_HIT,
    data: input
  };
}

export function isLoaded(globalState) {
  return globalState.auth && globalState.auth.loaded;
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.get('/loadAuth')
  };
}

export function updateModalView(modal) {
  return {
    type: actionTypes.UPDATE_MODAL_VIEW,
    payload: modal
  }
}

export function updateCurrentPageStatus(status) {
  return {
    type: actionTypes.UPDATE_CURRENT_PAGE_STATUS,
    payload: status
  }
}

export function updateCurrentHitsData(key) {
  return {
    type: actionTypes.UPDATE_CURRENT_HITS_URL,
    payload: key
  }
}

export function updateHitsDeatailsData(key) {
  return {
    type: actionTypes.UPDATE_HITS_DETAILS,
    payload: key
  }
}

export function updatePrevPageDeatails(key) {
  console.log("updatePrevPageDeatails",key)
  return {
    type: actionTypes.UPDATE_PREV_PAGE_DETAILS,
    payload: key
  }
}


export function createNewWorkSpace(pid, uidToken) {
  const { uid, token } = uidToken;
  console.log('create work space reducer ', uid, token);
  HEADERS.TOKEN = token;
  HEADERS.UID = uid;
  return {
    types: [GET_PROJECT_DETAIL, GET_PROJECT_DETAIL_SUCCESS, GET_PROJECT_DETAIL_FAILURE],
    promise: () => new Promise((resolve, reject) => {
      superagent.post(BASE_URL + pid + '/createWorkspace').set('uid', uid).set('token', token)
        .end((error, res) => {
          if (error) reject(error); else resolve(res);
        });
    })
  };
}

export function setloaderMenu(type) {
  console.log('setloaderMenu====>', type)
  return {
    type: actionTypes.LOADER_VIEW,
    payload: type
  };
}

export function updateUserDefinedColorStats(input) {
  return {
    type: USER_DEFINED_COLOR_STATS,
    payload: input

  };
}

export const updateOrgName = (name) => ({
  type: actionTypes.updateOrgName,
  payload: name
})

export const updateActiveMenu = (menu) => ({
  type: actionTypes.updateActiveMenu,
  payload: menu
})



export default projectReducer