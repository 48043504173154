import React, { Component, PropTypes } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createOrganization,getOrganizationById,updateOrganization } from "../../Services/usersApis";
import { isUrlValid } from "../../Services/commonApis";
import { showAlert } from "../../helpers/commonFunctions";
import { Button, Form, Message } from "semantic-ui-react";
import { push, replace } from "react-router-redux";
import { validEmail } from "../../helpers/Utils";
import actionDispatcher from "../../redux/app-actions";
import BACK from "../../images/Arrow_Simple_left1.svg";

class AddOrModifyOrganization extends Component {
  static propTypes = {
    user: PropTypes.object,
    login: PropTypes.func,
    logout: PropTypes.func,
    pushState: PropTypes.func,
    replaceState: PropTypes.func,
  };
  static userPropTypes = {
    firstName: PropTypes.string.isRequired,
    secondName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };
  constructor(props) {
    console.log("props are ", props);
    super(props);
    this.handleCreateChange = this.handleCreateChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.createOrganizationAccount = this.createOrganizationAccount.bind(this);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let currentPage = params.get("currentpage");
    this.state = {
      currentpage: currentPage ? currentPage : 1,
    };
  }
  state = {
    id: undefined,
    activeMenu: "organizations",
    loading: false,
    orgId: this.props.params.orgId,
    error: null,
    orgName: null,
    webSiteUrl: "",
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    city: "",
    country: "",
    logoUrl: "",
    notes: "",
    success: null,
    submitDisabled: false,
  };
  handleChange = (e, { value }) => this.setState({ value });
  componentWillMount() {
    if (this.props.user.roleId === 4) {
      if (this.props.params.orgId !== undefined) {
        getOrganizationById(
          this.props.params.orgId,
          this.dtgetOrganizationCallback.bind(this)
        );
      }
    } else {
      try {
        this.props.logout();
      } catch (error) {
        this.props.replaceState("/projects/login");
      }
    }
  }
  dtgetOrganizationCallback(error, response) {
    if (!error) {
      console.log("else part of org");
      this.setState({
        id: response.body.id,
        orgName: response.body.name,
        webSiteUrl: response.body.website,
        contactName: response.body.contactName,
        contactEmail: response.body.contactEmail,
        contactPhone: response.body.contactPhone,
        city: response.body.city,
        country: response.body.country,
        logoUrl: response.body.logoPic,
        notes: response.body.notes,
      });
    } else {
      console.log("else part of org");
    }
  }
  handleCreateChange(event) {
    if (event.target.name === "orgName") {
      this.props.updateOrgName(event.target.value);
      this.setState({ orgName: event.target.value });
    } else if (event.target.name === "webSiteUrl") {
      this.setState({ webSiteUrl: event.target.value });
    } else if (event.target.name === "contactName") {
      this.setState({ contactName: event.target.value });
    } else if (event.target.name === "contactEmail") {
      this.setState({ contactEmail: event.target.value });
    } else if (
      event.target.name === "contactPhone" &&
      ((!isNaN(parseFloat(event.target.value)) &&
        isFinite(event.target.value)) ||
        event.target.value.length === 0)
    ) {
      this.setState({ contactPhone: event.target.value });
    } else if (event.target.name === "city") {
      this.setState({ city: event.target.value });
    } else if (event.target.name === "country") {
      this.setState({ country: event.target.value });
    } else if (event.target.name === "logoUrl") {
      this.setState({ logoUrl: event.target.value });
    } else if (event.target.name === "notes") {
      this.setState({ notes: event.target.value });
    }
  }
  handleSubmit(event) {
    alert("A name was submitted: " + this.state.value);
    event.preventDefault();
  }
  dtCreateOrganizationCallback(error, response) {
    let responseCode = response.body ? response.body.code : null;
    this.setState({ submitDisabled: false });
    if (!responseCode || (responseCode && parseInt(responseCode) === 200)) {
      this.setState({
        error: null,
        success: "Organization created/Updated successfully",
      });
      showAlert("Organization created/Updated successfully.");
      this.props.replaceState("/projects/organizations");
    } else {
      let errorMessage = response.body ? response.body.message : null;
      showAlert(errorMessage, "error");
    }
  }
  dtUpdateOrganizationCallback(error, response) {
    this.setState({ submitDisabled: false });
    let responseCode = response.body ? response.body.code : null;
    if (!responseCode || (responseCode && parseInt(responseCode) === 200)) {
      showAlert("Organization updated successfully.");
      this.setState({
        error: null,
        success: "Organization updated successfully.",
      });
      this.props.replaceState("/projects/organizations");
    } else {
      let errorMessage = response.body ? response.body.message : null;
      showAlert(errorMessage, "error");
    }
  }
  createOrganizationAccount(event) {
    const {orgName,webSiteUrl,contactName,contactEmail,contactPhone,city,country,logoUrl,notes} = this.state
    if (this.validOrganizationFields()) {
      this.setState({ submitDisabled: true });
      createOrganization(
        orgName,
        webSiteUrl,
        contactName,
        contactEmail,
        contactPhone,
        city,
        country,
        logoUrl,
        notes,
        this.dtCreateOrganizationCallback.bind(this)
      );
    }
  }

  backPage(event) {
    this.props.replaceState(
      "/projects/organizations" + "?currentpage=" + this.state.currentpage
    );
  }

  validOrganizationFields = () => {
    const { orgName,webSiteUrl,contactName,contactEmail,contactPhone } = this.state
    let isValid = false;
    if (!orgName) {
      showAlert("Please enter the Organization Name.", "error");
    } else if (!webSiteUrl) {
      showAlert("Please enter the Website URL.", "error");
    } else if (webSiteUrl && !isUrlValid(webSiteUrl)) {
      showAlert("Please enter the valid Website URL.", "error");
    } else if (!contactName) {
      showAlert("Please enter the Contact Name.", "error");
    } else if (!contactEmail) {
      showAlert("Please enter the Email.", "error");
    } else if (
      contactEmail &&
      !validEmail(contactEmail)
    ) {
      showAlert("Please enter a valid Email.", "error");
    } else if (!contactPhone) {
      showAlert("Please enter the Contact Phone.", "error");
    } else {
      isValid = true;
    }
    return isValid;
  };
  updateOrganizationAccount(event) {
    const {id,orgName,webSiteUrl,contactName,contactEmail,contactPhone,city,country,logoUrl,notes} = this.state
    if (this.validOrganizationFields()) {
      this.setState({ submitDisabled: true });
      updateOrganization(
        id,
        orgName,
        webSiteUrl,
        contactName,
        contactEmail,
        contactPhone,
        city,
        country,
        logoUrl,
        notes,
        this.dtUpdateOrganizationCallback.bind(this)
      );
    }
  }
  cancelOrganizationAccount(event) {
    console.log("?currentpage=------------>", this.state.currentpage);
    this.props.replaceState(
      "/projects/organizations" + "?currentpage=" + this.state.currentpage
    );
  }
  render() {
    const { id,orgName,webSiteUrl,contactName,contactEmail,contactPhone,
      success,error,submitDisabled,notes,logoUrl,country,city
    } = this.state
    return (
      <div style={{ paddingLeft: "3%", paddingRight: "3%" }}>
        <div className="pr_labelset w-100">
          <div className="project_sec m-t-20">
            <div className="project_title f-column singletxt">
              <div className="f-center">
                <a>
                  <span
                    className="btn_back m-r-5"
                    onClick={this.backPage.bind(this)}
                  >
                    <img alt="back" src={BACK} />
                  </span>
                </a>
              </div>
              <div className="breadcrum-sec">
                <div className="sec_holder">
                  <a className="tilte_display fw-400">
                    {id === undefined ? (
                      <span>Add Organization</span>
                    ) : (
                      <span>Edit Organization</span>
                    )}
                  </a>
                </div>
              </div>
            </div>
            <div className="project_set"></div>
          </div>
          <div className="project_view project_new advance">
            <div className="w-100 box_shodow m-h-content box_area">
              <div className="main_box flex-colum m-t-40">
                <div className="w-100">
                  <Form>
                    <Form.Group className="field_flex_Warp">
                      <div className="m-b-8 w-50 p-r-10 p-l-10">
                        <label style={{ color: "black" }}>
                          Organization Name
                        </label>{" "}
                        <label style={{ color: "red" }}>*</label>
                        <Form.Input
                          fluid
                          ref={(orgName) => {
                            this.orgName = orgName;
                          }}
                          type="text"
                          onChange={this.handleCreateChange.bind(this)}
                          value={orgName}
                          name="orgName"
                          placeholder="Organization Name"
                        />
                      </div>
                      <div className="m-b-8 w-50 p-r-10 p-l-10">
                        <label style={{ color: "black" }}>Website Url</label>{" "}
                        <label style={{ color: "red" }}>*</label>
                        <Form.Input
                          fluid
                          ref={(webSiteUrl) => {
                            this.webSiteUrl = webSiteUrl;
                          }}
                          type="text"
                          onChange={this.handleCreateChange.bind(this)}
                          value={webSiteUrl}
                          name="webSiteUrl"
                          placeholder="Website Url"
                        />
                      </div>
                      <div className="m-b-8 w-50 p-r-10 p-l-10">
                        <label style={{ color: "black" }}>Contact Name</label>{" "}
                        <label style={{ color: "red" }}>*</label>
                        <Form.Input
                          fluid
                          ref={(contactName) => {
                            this.contactName = contactName;
                          }}
                          type="text"
                          onChange={this.handleCreateChange.bind(this)}
                          value={contactName}
                          name="contactName"
                          placeholder="Contact Name"
                        />
                      </div>
                      <div className="m-b-8 w-50 p-r-10 p-l-10">
                        <label style={{ color: "black" }}>Contact Email</label>{" "}
                        <label style={{ color: "red" }}>*</label>
                        <Form.Input
                          fluid
                          ref={(contactEmail) => {
                            this.contactEmail = contactEmail;
                          }}
                          type="text"
                          onChange={this.handleCreateChange.bind(this)}
                          value={contactEmail}
                          name="contactEmail"
                          placeholder="Contact Email"
                        />
                      </div>
                      <div className="m-b-8 w-50 p-r-10 p-l-10">
                        <label style={{ color: "black" }}>Contact Phone</label>{" "}
                        <label style={{ color: "red" }}>*</label>
                        <Form.Input
                          fluid
                          ref={(contactPhone) => {
                            this.contactPhone = contactPhone;
                          }}
                          type="text"
                          onChange={this.handleCreateChange.bind(this)}
                          value={contactPhone}
                          name="contactPhone"
                          placeholder="Contact Phone"
                          maxLength="10"
                        />
                      </div>
                      <div className="m-b-8 w-50 p-r-10 p-l-10">
                        <label style={{ color: "black" }}>City</label>
                        <Form.Input
                          fluid
                          ref={(city) => {
                            this.city = city;
                          }}
                          type="text"
                          onChange={this.handleCreateChange.bind(this)}
                          value={city}
                          name="city"
                          placeholder="City"
                        />
                      </div>
                      <div className="m-b-8 w-50 p-r-10 p-l-10">
                        <label style={{ color: "black" }}>Country</label>
                        <Form.Input
                          fluid
                          ref={(country) => {
                            this.country = country;
                          }}
                          type="text"
                          onChange={this.handleCreateChange.bind(this)}
                          value={country}
                          name="country"
                          placeholder="Country"
                        />
                      </div>
                      <div className="m-b-8 w-50 p-r-10 p-l-10">
                        <label style={{ color: "black" }}>Logo Url</label>
                        <Form.Input
                          fluid
                          ref={(logoUrl) => {
                            this.logoUrl = logoUrl;
                          }}
                          type="text"
                          onChange={this.handleCreateChange.bind(this)}
                          value={logoUrl}
                          name="logoUrl"
                          placeholder="Logo Url"
                        />
                      </div>
                    </Form.Group>
                    <label style={{ color: "black" }}>Notes</label>
                    <Form.TextArea
                      className="label_txt"
                      ref={(notes) => {
                        this.notes = notes;
                      }}
                      type="text"
                      onChange={this.handleCreateChange.bind(this)}
                      value={notes}
                      name="notes"
                      placeholder="Tell us more about you..."
                    />
                    <div className="text-center model_submit m-t-20">
                      {id === undefined ? (
                        <Button
                          className="ant-btn btn_apply h-35"
                          disabled={submitDisabled}
                          onClick={this.createOrganizationAccount.bind(this)}
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                          className="ant-btn btn_apply h-35"
                          disabled={submitDisabled}
                          onClick={this.updateOrganizationAccount.bind(this)}
                        >
                          Submit
                        </Button>
                      )}
                      <Message
                        negative
                        className="msg-fix"
                        hidden={!error}
                      >
                        <p>{error}</p>
                      </Message>
                      <Message
                        positive
                        className="msg-fix"
                        hidden={!success}
                      >
                        <p>{success}</p>
                      </Message>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.loginReducer.user,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushState: push,
      updateOrgName: actionDispatcher.updateOrgName,
      replaceState: replace,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddOrModifyOrganization);