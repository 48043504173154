import React from "react";
import "antd/dist/antd.css";
import { Row, Col, Input, Button } from "antd";
import style from "./style-sheet";
class BasicEntityForm extends React.Component {
  renderList(item, length) {
    return (
      <div>
        <Row key={item.id} style={style.basicForm.row}>
          <Col span={7} style={style.basicForm.col}>
            <Input
              style={
                item.isLabelDuplicate
                  ? style.basicForm.duplicate_entity
                  : style.basicForm.remove_duplicate_entity
              }
              placeholder="Label Name"
              defaultValue={
                item.text || item.label || item.name || item.labelName
              }
              onChange={(e) => this.props.updateInput(e, item.id, "label")}
            />
          </Col>
          <Col span={13} style={style.basicForm.col}>
            <Input
              style={
                item.isEntityDuplicate
                  ? style.basicForm.duplicate_entity
                  : style.basicForm.remove_duplicate_entity
              }
              placeholder="List of comma separated entities"
              defaultValue={item.text || item.entities}
              onChange={(e) => this.props.updateInput(e, item.id, "entities")}
            />
          </Col>
          <Col span={2}>
            <Button
              onClick={() => this.props.removeLabel(item.id, length)}
              style={{ display: length > 1 ? "block" : "none" }}
              type="danger"
              shape="circle"
              icon="close"
            />
          </Col>
          <Col span={2} />
        </Row>
      </div>
    );
  }
  render() {
    const { list } = this.props;
    const buttonStyle = style.basicForm.close_fill;
    const container = style.basicForm.constainer;
    return (
      <div style={container} id="form_balance">
        <Button
          style={buttonStyle}
          onClick={() => this.props.removeLabel(list.id)}
          type="danger"
          shape="circle"
          icon="close"
        />
        {this.renderList(list, 1)}
      </div>
    );
  }
}

export default BasicEntityForm;
