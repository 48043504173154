const style = {
    icon: {
        color: '#898989',
        background: '#2E2E2E',
        cursor: 'pointer'
    },
    iconHover: {
        color: '#ffffff',
        background: '#2E2E2E',
        cursor: 'pointer'
    },
    menu: {
        background: '#2E2E2E'
    },
    menuHover: {
        background: '#2E2E2E'
    }
}

export default style