import React, { Component } from "react";
import "./styles.css";
import 'semantic-ui-css/semantic.min.css';
import "semantic-ui-css/components/reset.min.css";
import "semantic-ui-css/components/site.min.css";
import "semantic-ui-css/components/container.min.css";
import "semantic-ui-css/components/icon.min.css";
import "semantic-ui-css/components/message.min.css";
import "semantic-ui-css/components/header.min.css";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import { Icon, Input, Checkbox } from "antd";
import Helmet from "react-helmet";
import swot from 'swot-simple';
import { connect } from "react-redux";
import {firebase} from 'firebase';
import { replace } from 'react-router-redux';
import {bindActionCreators} from 'redux';
import { publicEmails, validEmail, checkPassword, validMobileNumbers } from "../../helpers/Utils";
import { dtLogin,getAdminsByOrgIdForRegister,forgotPasswordCall,createUserWithPassword } from "../../Services/LoginApis";
import {saveOrgId,showAlert,logEvent} from '../../helpers/commonFunctions'
import { COPYRIGHT } from "../../helpers/Constants";
import { login, signIn, logout, resetFlags } from '../../redux/reducer/loginReducer';
import config from "../../helpers/config";
import logo from "../../images/logo1.svg";
import EYEHIDE from "../../images/eye_hide.svg";
import EYEOPEN from "../../images/eye_show.svg";


import Register from "../Register/Register";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      emailSignup: false,
      email: "",
      fname: "",
      lname: "",
      password: "",
      error: undefined,
      orgName: "",
      mobileNumber: "",
      success: undefined,
      hidden: true,
      role: "0",
      orgOptions: [],
      organization: "",
      adminUserOptions: [],
      admin: "",
      orgBySelect: false,
      adminBySelect: false,
      fnameError: "",
      lnameError: "",
      mobileError: "",
      passwordError: "",
      roleError: "",
      organizationError: "",
      reportingError: "",
      mailError: "",
      loginPasswordError: "",
      loginMailError: "",
    };
  }

  componentWillMount() {
    console.log("will mount props",this.props.user,window.sessionStorage.getItem("uid"))
    if (this.props.user && window.sessionStorage.getItem("uid") !== null) {
      if (this.props.user.roleId !== 1) {
        this.props.replaceState('/projects');
      } else {
        this.props.replaceState('/projects/organizations');
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.userCreated && nextProps.userCreated) {
      this.props.signIn(this.state.userInfo);
    }
  }

  componentWillUnmount() {
    this.props.resetFlags();
  }

  getOrganizationUsersCallback(error, response) {
    console.log('getOrganizationUsersCallback')
    if (!error) {
      this.state.adminUserOptions.push({
        key: "0",
        text: "Select Reporting To",
        value: "0"
      });

      let data = response.body.userInfos;
      for (let index = 0; index < data.length; index++) {
        this.state.adminUserOptions.push({
          key: data[index].id,
          text: data[index].firstName + ' ' + data[index].secondName,
          value: data[index].id

        });
      }
    }

  }

  handleRoleChange = (value) => {
    this.setState({ role: value });
    console.log("role value------>",value)
      if(value>"0")
      {
        let roleError=''
        this.setState({roleError})
      }
  };

   handleOrganizationChange = (value) => {
      let organizationError=''
      if(this.state.organization.length>0)
      {
        this.setState({organizationError})
      }
    };


  setOrganisationValueOnChange = (value, option) => {
    this.setState({
      organization: value.toString(),
      orgBySelect: true
    }, () => {
      if ( this.state.role === "3" || this.state.role === "5" || this.state.role === "6") {
        getAdminsByOrgIdForRegister(this.state.organization, this.getOrganizationUsersCallback.bind(this));
      }
    });
  };

  setOrganisationValueOnSearch = (data) => {
    this.setState({
      organization: data.toString(),
      orgBySelect: false
    });
  };

  dtCreateUserCallback(error, response) {
    if (!error) {
      this.setState({ loading: false });
      window.sessionStorage.setItem("uid", response.body.id);
      window.sessionStorage.setItem("token", response.body.token);
      window.sessionStorage.setItem("tokenExpiry", "false");
      window.sessionStorage.setItem(
        "passwordChanged",
        response.body.passwordChanged
      );
      window.sessionStorage.setItem("profileImage", response.body.profileImage);
      window.sessionStorage.setItem("activeName", "home");
      window.localStorage.setItem("roleId", response.body.roleId);
      window.localStorage.setItem("searchClear", "1");
      window.sessionStorage.setItem("timeExpiryPopup", "false");
      if (response.body.approved) {
        saveOrgId(response.body.orgId);
        this.props.
        signIn({
          authType: "password",
          firstName: response.body.firstName,
          lastName: response.body.secondName,
          fullName: response.body.firstName + " " + response.body.secondName,
          uid: response.body.id,
          email: this.state.email,
          roleId: response.body.roleId,
          orgId: response.body.orgId,
          profileImage: response.body.profileImage,
        });
        this.setState({
          userInfo: {
            authType: "emailSignup",
            firstName: this.state.fname,
            lastName: this.state.lname,
            fullName: this.state.fname + " " + this.state.lname,
            email: this.state.email,
            uid: response.body.id,
            roleId: response.body.roleId,
            orgId: response.body.orgId,
            profileImage: response.body.profileImage,
          },
        });
        var user = response.body;
        console.log("Response user",user)
        if (user.roleId === 4) {
          this.props.replaceState("/projects/organizations");
        } else if (user.roleId === 1) {
          this.props.replaceState("/projects/users");
        } else {
          if (!user.passwordChanged && user.roleId > 1 && user.roleId !== 4) {
            this.props.replaceState("/projects/updatePassword");
          } else {
            if (user.roleId === 3 || user.roleId === 5 || user.roleId === 6) {
              // If user is Annotator
              this.props.replaceState("/projects/" + user.roleId + "/list");
            } else {
              // If user is Admin
              // commented below line as it would affect scenario - while contributor clicks project url from email
              // and if session not available user would login and then has to redirect to mentioned url
              this.props.replaceState('/workspace');
            }
          }
        }
      } else {
        showAlert("Your account is still under processing. Please wait for admin's approval.");
      }
    } else {
      window.sessionStorage.setItem("tokenExpiry", "false");
      let errMessage =
        response && response.body.message
          ? response.body.message
          : "Internal Server Error.";
      showAlert(errMessage, 'error')
      this.setState({ loading: false });
    }
  }

  handleInputChange = (event) => {
    console.log("handleInputChange",event.target.value)
    const { name, value } = event.target;
    if(name === "mobileNumber")
      {
        if (/^\d*$/.test(value)) {
          this.setState({ [name]: value });
        }
      }
      else{
        this.setState({ [name]: value });
      }
  };

  handlePasswordChange=(event)=>{
    this.setState({hidden: !this.state.hidden});
  }


  clearErrorFields = (val) => 
  {
    console.log("Value ",val)  
    this.setState({
      emailSignup: val,
      fnameError:'',
      lnameError:'',
      roleError:'',
      organizationError:'',
      emailError:'',
      passwordError:'',
      mobileError:'',
      reportingError:'',
      loginMailError:'',
      loginPasswordError:''
    })
  }
  // Forgot password

  forgotPassword = () => {
    if (!validEmail(this.state.email)) {
      showAlert( 'Please enter a valid Email Address.', 'error' );
    } else {
      forgotPasswordCall(this.state.email, this.dtForgotPasswordCallback);
    }
  }


dtForgotPasswordCallback (error, response) {
    if (!error) {
      if (response.body === true) {
        console.log("password sent to email")
        showAlert("We have sent the password reset credentials to your mail id. Please check and reset password.");
      } else {
        showAlert('Email ID doesn’t exist. Please enter a valid email', 'error');
      }
  } else {
    let responseMessage = response && response.body ? response.body.message : "";
    showAlert(responseMessage, 'error');
    this.setState({ loading: false });
  }
}

dtCreateUserWithPasswordCallback(error, response) {
  console.log('dtCreateUserCallback', error, response);
  if (!error) {
    console.log('dtCreateUserCallback', error, response);
    if (this.clearFields())  {
      showAlert("Registered successfully. Please wait for admin's approval.", "success");
      this.setState({emailSignup: false});
    }
  } else {
    let responseMessage = response && response.body ? response.body.message : "";
    showAlert(responseMessage, 'error');
    this.setState({ loading: false });
  }
}

clearFields = () => {
  let isClear = false
  this.setState({
    email: '',
    fname: '',
    lname: '',
    password: '',
    role:'0',
    organization: '',
    adminUserOptions: [],
    admin: '',
    orgBySelect: false,
    adminBySelect: false,
    hidden: true,
    mobileNumber: ''}, () => {
    isClear = true
  })
  return isClear
}

 validateFname=()=>{

    let fnameError="First Name should be at least 2 letters"

    if(this.state.fname.length === 0)
    {
      fnameError="First Name is required"
      this.setState({fnameError})
      return false;
    }
    else if (this.state.fname.length < 2) {
      this.setState({fnameError})
      return false;    
    }
    else{
      fnameError='';
      this.setState({fnameError})
    }
 
  }

  validateLname()
  {
    let lnameError="Last Name should be at least 2 letters"
    if (this.state.lname.length === 0){
      lnameError="Last Name is required"
      this.setState({lnameError})    
     }
    else if (this.state.lname.length < 2)
    {
      this.setState({lnameError})
    }
     else{
      let lnameError='';
      this.setState({lnameError})
         }
  }

  validateRole()
  {
    let roleError="Role is required";
    if (this.state.role ==  "0"){
      console.log("role value=========================================>",this.state.role)
      this.setState({roleError})   
    } else{
       let roleError='';
       this.setState({roleError})
    }
  }

  validateOrganization()
  {
    console.log("outside organization------------->",this.state.organization.length)

    let organizationError="Organization Name is required"
    if (this.state.organization.length < 1 || (this.state.role === "3" && this.state.admin.length > 0 && this.state.organization.length === 0)) {
      this.setState({organizationError})

    } 
    else if(this.state.organization.length > 1)
    {
      console.log("inside organization------------->",this.state.organization.length)
      let organizationError='';
      this.setState({organizationError})
    }
  }
  validateReport()
  {
    let reportingError="Reporting To is required"
    if (this.state.role >= "3" && this.state.admin.length === 0) {
      this.setState({reportingError})
    }else {
       reportingError='';
       this.setState({reportingError})
    }
  }

  validateMobile()
  {
    let mobileError="Mobile Number should be at least  10 numbers";
 console.log("mobile number length",this.state.mobileNumber.length)
    if(this.state.mobileNumber.length < 10 && this.state.mobileNumber > 0)
    {
      console.log("inside mobile number length",this.state.mobileNumber.length)
      this.setState({mobileError})
    }
    else if(this.state.mobileNumber.length < 1 || this.state.mobileNumber.length===10){
      console.log("inside 0 mobile number length",this.state.mobileNumber.length)
      mobileError="";
      this.setState({mobileError})
    }
    else{
      mobileError="";
      this.setState({mobileError})
    }
    
  }

  validatePassword()
  {    
    let passwordError ="Password between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character"
      if(this.state.password.length < 1)
    {
      console.log("password value===>",this.state.password)
      passwordError='Password is required'
      this.setState({passwordError})
    }
    else if (!checkPassword(this.state.password)) {
      let passwordError="";
      this.setState({passwordError})
      showAlert('Password between 8 and 15 characters; must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character', 'error');
    } 
    else
    {
      let passwordError="";
      this.setState({passwordError})
    }
  
  }

  validateEmail()
  {
    let emailError="Please enter a valid Email address"
    if(this.state.email.length < 1)
    {
   emailError="Email Address is required"
   this.setState({emailError})
    }
    else if (!validEmail(this.state.email))
    {
      this.setState({emailError})
    }
    else{
      emailError=''
      this.setState({emailError})
    }
   
  }
  



  createAccount=()=> {
    this.validateFname()
    this.validateLname();
    this.validateOrganization();
    this.validateReport();
    this.validateRole();
    this.validatePassword();
    this.validateEmail();
    this.validateMobile();
 
    if (validEmail(this.state.email) && (checkPassword(this.state.password)) && this.state.fname.length>1 && this.state.lname.length>1 &&
    this.state.organization.length > 0 && this.state.role !== "0" && ( this.state.admin.length > 1 || this.state.role === "2")&&(validMobileNumbers(this.state.mobileNumber.length))){
      this.setState({ loading: true});
      if (config.servingEnv === 'online') {
        firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).
          then(this.firebaseCreateUserCallback).catch(this.errorCallback);
      } 
      else {
        this.setState({ error: null});
        const {organization, admin, adminBySelect, orgBySelect} = this.state;
        let org = '';
        let adm = '';
        if (orgBySelect)
          org = organization + "#select";
        else
          org = organization + "#search";
 
        if (adminBySelect)
          adm = admin + "#select";
        else
          adm = admin + "#search";
 
        if (this.state.role === "3" || this.state.role === "5" || this.state.role === "6") {
          createUserWithPassword(this.state.fname, this.state.lname, this.state.email, this.state.password, org, this.state.mobileNumber, this.state.role, adm, this.dtCreateUserWithPasswordCallback.bind(this));
        } else {
          createUserWithPassword(this.state.fname, this.state.lname, this.state.email, this.state.password, org, this.state.mobileNumber, this.state.role, null, this.dtCreateUserWithPasswordCallback.bind(this));
        }
      }
    } 
  
   }

getEmailCategory(email) {
  try {
    const splits = email.split('@');
    if (swot.isAcademic(email)) {
      return 'Academic';
    } else if (publicEmails.includes(splits[1])) {
      return 'Public';
    }
    return 'Business';
  } catch (event) {
    return 'Category identiy error';
  }
}



firebaseIdTokenCallBack(result, idToken) {
  console.log('idtoken is ', idToken, result);
  window.sessionStorage.setItem('token', idToken);
  window.sessionStorage.setItem('uid', result.user.uid);
  if (result.additionalUserInfo.isNewUser) {
    logEvent('buttons', 'New user firebase success');
    logEvent('user', this.getEmailCategory(result.user.email));
console.log('result.user======1>', result.user)
    this.props.login({authType: result.credential.providerId,
                        firstName: result.additionalUserInfo.profile.given_name,
                        secondName: result.additionalUserInfo.profile.family_name,
                        fullName: result.user.displayName,
                        email: result.user.email,
                        phone: result.user.phoneNumber,
                        profilePic: result.user.photoURL,
                        roleId: result.user.roleId
                        }, result.user.uid, idToken);
    this.setState({ userInfo: {authType: result.credential.providerId,
                        firstName: result.additionalUserInfo.profile.given_name,
                        lastName: result.additionalUserInfo.profile.family_name,
                        fullName: result.user.displayName,
                        email: result.user.email,
                        uid: result.user.uid,
                        phone: result.user.phoneNumber,
                        profilePic: result.user.photoURL,
                        roleId: result.user.roleId
                        } });
  } else {
    logEvent('buttons', 'Old User firebase success');
    logEvent('user', this.getEmailCategory(result.user.email));
    console.log('ser======1>', result.user)
    this.props.signIn({authType: result.credential.providerId,
                        firstName: result.additionalUserInfo.profile.given_name,
                        lastName: result.additionalUserInfo.profile.family_name,
                        fullName: result.user.displayName,
                        uid: result.user.uid,
                        email: result.user.email,
                        phone: result.user.phoneNumber,
                        profilePic: result.user.photoURL,
                        roleId: result.user.roleId
                        });
  }
}

firebaseCreateUserCallback(result) {
  logEvent('buttons', 'Login Firebase CallBack');
  firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(this.firebaseIdTokenCreateUserCallBack.bind(this, result))
  .catch(function(error) {
    console.log('error generating token ', error);
  });
}

firebaseCallback(result) {
  logEvent('buttons', 'Login Firebase CallBack');
  firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(this.firebaseIdTokenCallBack.bind(this, result))
  .catch(function(error) {
    console.log('error generating token ', error);
  });
}


firebaseIdTokenCreateUserCallBack(result, idToken) {
  console.log('idtoken is ', idToken, result);
  window.sessionStorage.setItem('token', idToken);
  window.sessionStorage.setItem('uid', result.uid);
  const category = this.getEmailCategory(result.email);
  logEvent('user', category);
  if (this.state.emailSignup) {
    logEvent('buttons', 'New user firebase success');
    logEvent('buttons', 'emailSignUp');
    this.props.login({authType: 'emailSignup',
                        firstName: this.state.fname,
                        secondName: this.state.lname,
                        fullName: this.state.fname + this.state.lname,
                        email: result.email,
                        emailVerified: result.emailVerified,
                        phone: result.phoneNumber,
                        profilePic: result.photoURL,
                        roleId: result.roleId
                        }, result.uid, idToken);
        this.setState({ userInfo: {authType: 'emailSignup',
                        firstName: this.state.fname,
                        lastName: this.state.lname,
                        fullName: this.state.fname + this.state.lname,
                        email: result.email,
                        emailVerified: result.emailVerified,
                        uid: result.uid,
                        phone: result.phoneNumber,
                        profilePic: result.photoURL,
                        roleId: result.roleId
                        } });
    firebase.auth().currentUser.sendEmailVerification().then(function() {
     // Email sent.
      console.log('Verification email sent');
    }, function(error) {
      // An error happened.
      console.log('couldnt sent verification emailSignu', error);
    });
  } else if (this.state.emailSignIn) {
    logEvent('buttons', 'Old User firebase success');
    logEvent('buttons', 'emailSignIn');
    logEvent('user', this.getEmailCategory(result.email));

console.log('result.user====pppp==1>', result)
    this.props.signIn({authType: 'password',
                        firstName: result.displayName,
                        lastName: result.displayName,
                        fullName: result.displayName,
                        uid: result.uid,
                        emailVerified: result.emailVerified,
                        email: result.email,
                        phone: result.phoneNumber,
                        profilePic: result.photoURL,
                        roleId: result.roleId
                        });
  }
}


errorCallback(error) {
  console.log('error is', error);
  logEvent('buttons', 'Login Error', error.message);
  this.setState({ loading: false, error: error.message});
}

handleSubmit = (response) => {
  console.log('response', response, this.props.login);
  this.setState({loading: true});
  logEvent('buttons', 'Google Login');
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  firebase.auth().signInWithPopup(googleProvider).then(this.firebaseCallback).catch(this.errorCallback);
}


  handleEmailSignIn = (response) => {
    const { email, password } = this.state;
    console.log("login", email, password);
    console.log("response", response, this.props.login);
    this.setState({ loading: true, emailSignup: false, emailSignIn: true });
    console.log(this.state.email);
    console.log(!this.state.email);
    let loginPasswordError = "";
    let loginMailError = "";
    let isMailError = false;
    if (this.state.email.length < 1) {
      loginMailError = "Email address is required";
      isMailError = true;
      this.setState({ loginMailError });
    } else if (!validEmail(this.state.email)) {
      isMailError = true;
      loginMailError = "Please enter a valid email address";
       showAlert('Please enter a valid Email Address.', 'error');
      this.setState({ loginMailError });
    } else {
      this.setState({ loginMailError });
    }

    if (this.state.password === "") {
      loginPasswordError = "Password is required";
      this.setState({ loginPasswordError });
      showAlert('Please enter the Login Password.', 'error');
    } else if (isMailError === false) {
      if (config.servingEnv === 'online') {
        console.log("Please enter the Login Password")
        firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then(this.firebaseCreateUserCallback).catch(this.errorCallback);
      } else {
      dtLogin(
        this.state.email,
        this.state.password,
        this.dtCreateUserCallback.bind(this)
      );
      }
    } else {
      loginPasswordError = "";
      this.setState({ loginPasswordError });
    }
  };
  
  setAdminValueOnSearch = (data) => {
    this.setState({
      admin: data.toString(),
      adminBySelect: false
    });
  }

  setAdminValueOnSelect = (value, option) => {
    this.setState({
      admin: value.toString(),
      adminBySelect: true
    });
  }

  render() {
    const { user } = this.props;
    console.log("User from redux",this.props)
    return (
      <div>
        <Helmet title="Login" />
        {!user && (
          <div className="pr_login_section">
            <div className="sec_left w-50">
              <div className="logo_main">
                <img alt="logo" src={logo} loading="lazy" />
                {!this.state.emailSignup && (
                  <div className="login_box w-100">
                    <div className="login_base w-55">
                      <h6 className="fw-600">Welcome to pronotate</h6>
                      <h4 className="fw-600">Login your account</h4>
                      <div className="form_field">
                        <label>E-mail</label>
                        <div className="input_box">
                          <Input
                            autoComplete="off"
                            prefix={<Icon type="mail" 
                            style={{ color: 'rgba(0,0,0,.25)' }}
                             />}
                            ref={(email) => {
                              this.email = email;
                            }}
                            type="email"
                            onChange={this.handleInputChange}
                            value={this.state.email}
                            name="email"
                            placeholder="Your E-mail"
                          />
                        </div>
                        <div className="errormsg">
                          {this.state.loginMailError}
                        </div>
                      </div>
                      <div className="form_field">
                        <label>Password</label>
                        <div className="input_box">
                          <Input
                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            suffix={ <span 
                              onClick={this.handlePasswordChange}
                            className={ this.state.hidden ? 'img_hide' : 'img_show' }>
                            <img alt="eyeHide" className="eye_hide" title="Show password" loading="lazy" src={EYEHIDE} />
                            <img alt="eyeShow" className="eye_show" title="Hide password" loading="lazy" src={EYEOPEN} /></span> }
                            ref={(password) => {
                              this.password = password;
                            }}
                            value={this.state.password}
                            onChange={this.handleInputChange}
                            name="password"
                            type={this.state.hidden ? "password" : "text"}
                            placeholder="Your Password"
                          />
                        </div>
                        <div className="errormsg">
                          {this.state.loginPasswordError}
                        </div>
                      </div>
                      <div className="action_btn">
                        <button
                          className="btn_base btn_borderd"
                          onClick={()=>{
                            this.clearErrorFields()
                            this.setState({emailSignup: true})
                          }}
                        >
                          Register{" "}
                        </button>
                        <button
                          className="btn_base btn_primary"
                          onClick={this.handleEmailSignIn}
                        >
                          Login
                        </button>
                      </div>
                      <div className="hint_txt">
                        <div>
                          <Checkbox className="remember_txt">
                            Remember Me
                          </Checkbox>
                        </div>
                        <a
                          as="a"
                          onClick={this.forgotPassword}
                          className="forget_txt"
                        >
                          Forgot Password?
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.emailSignup && (
                  <Register
                  propsState={this.state}
                  handleInputChange={this.handleInputChange}
                  createAccount={this.createAccount}
                  clearErrorFields={this.clearErrorFields}
                  setAdminValueOnSearch={this.setAdminValueOnSearch}
                  setAdminValueOnSelect={this.setAdminValueOnSelect}
                  handleRoleChange={this.handleRoleChange}
                  handleOrganizationChange={this.handleOrganizationChange}
                  />
                )}                
                <div className="login_footer">
                  <h6 className="txt_lowercase">{COPYRIGHT.YEAR}</h6>
                  <h6 className="txt-upper">
                    Privacy policy<span className="div_bar"> | </span>Terms and
                    Conditions
                  </h6>
                </div>
              </div>
            </div>
            <div className="sec_right w-50">
              <div className="screen_overlay">
                <h1>Super Easy Data Annotations</h1>
                <h5>
                  Invite your team and generate high quality labeled data in
                  minutes
                </h5>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  user: state.loginReducer.user, 
  userCreated: state.loginReducer.userCreated
};
};

const mapDispatchToProps = dispatch => bindActionCreators({
  login,
  signIn,
  logout,
  replaceState: replace,
  resetFlags,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);