const UPDATE_MODAL_VIEW = 'UPDATE_MODAL_VIEW'
const UPDATE_CURRENT_PAGE_STATUS = 'UPDATE_CURRENT_PAGE_STATUS'
const UPDATE_CURRENT_HITS_URL = 'UPDATE_CURRENT_HITS_URL'
const UPDATE_HITS_DETAILS = 'UPDATE_HITS_DETAILS'
const UPDATE_PREV_PAGE_DETAILS = 'UPDATE_PREV_PAGE_DETAILS'
const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU'
const LOADER_VIEW = 'LOADER_VIEW'
export default {
    UPDATE_MODAL_VIEW,
    UPDATE_CURRENT_PAGE_STATUS,
    UPDATE_CURRENT_HITS_URL,
    UPDATE_HITS_DETAILS,
    UPDATE_PREV_PAGE_DETAILS,
    SET_ACTIVE_MENU,
    LOADER_VIEW
}