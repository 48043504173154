import React from "react";
import { Sidebar, Menu, Icon, Segment, Popup, Dropdown } from 'semantic-ui-react';
import { displaySettingsByRole } from "../../helpers/Utils";
import { getUidToken } from "../../helpers/commonFunctions";

import style from './style-sheet'
class SidebarTools extends React.Component {
    state = {
        anchorEl: null,
        showColorPlate : false
    };
    constructor(props) {
        super(props);
        this.state = {
            isEraseSelected: props.isEraseSelected,
            isContourSelected: props.isContourSelected,
            isBubbleSelected: props.isBubbleSelected,
            isInvertSelected: props.isInvertSelected,
            isClearSelected: props.isClearSelected,
            isClearAllSelected: props.isClearAllSelected,
            isUnidirectionSelected: props.isUnidirectionSelected,
            isBidirectionSelected: props.isBidirectionSelected,
            hoveredIcon: null,
        }
        this.style = {

        }

    };


    componentWillReceiveProps(nextProps) {
        const { isEraseSelected, isContourSelected, isBubbleSelected, isClearSelected, isClearAllSelected, isInvertSelected } = this.state;
        // You don't have to do this check first, but it can help prevent an unneeded render
        if(nextProps.isContourSelected){
       	 this.setState({ hoveredIcon: 'contour' })
       }

        if (nextProps.isEraseSelected !== isEraseSelected) {
            this.setState({ isEraseSelected: nextProps.isEraseSelected });
        }
        if (nextProps.isContourSelected !== isContourSelected) {
            this.setState({ isContourSelected: nextProps.isContourSelected });
        }
        if (nextProps.isBubbleSelected !== isBubbleSelected) {
            this.setState({ isBubbleSelected: nextProps.isBubbleSelected });
        }
        if (nextProps.isInvertSelected !== isInvertSelected) {
            this.setState({ isInvertSelected: nextProps.isInvertSelected });
        }
        if (nextProps.isClearSelected !== isClearSelected) {
            this.setState({ isClearSelected: nextProps.isClearSelected });
        }
        if (nextProps.isClearAllSelected !== isClearAllSelected) {
            this.setState({ isClearAllSelected: nextProps.isClearAllSelected });
        }
    }

    handleClose = (value) => {
        this.props.layoutSelect(value * value);
        this.setState({ anchorEl: null });
    };

    layoutSelect = event => {
        this.setState({ anchorEl: event.currentTarget });
    }

    goFullScreen = () => {
        this.props.goFullScreen();
    }

    callUndo = () => {
        this.props.callUndo();
    }

    callRedo = () => {
        this.props.callRedo();
    }

    imageRotate = (side) => {
        this.props.imageRotate(side);
    }

    copy = (value) => {
        this.props.copy(value);
    }

    paste = (value) => {
        this.props.paste(value);
    }

    erase = (value) => {
        this.props.erase(value);
    }

    invert = (value) => {
        this.props.invert(value);
    }

    Resize = (value) => {
        this.props.Resize(value);
    }

    flip = (value) => {
        this.props.flip(value);
    }

    interpolation = (value) => {
        this.props.interpolation(value);
    }

    enableTool = (value, index) => {
        this.props.enableTool(value, index);
    }
    disableAllTools = () => {
        this.props.disableAllTools();
    }

    clearAnnotations = () => {
        this.props.clearAnnotations();
    }

    clearAllAnnotations = () => {
        this.props.clearAllAnnotations();
    }

    chooseAnnoColor = (color) => {
        this.props.chooseAnnoColor(color);
    };

    showColorTool = () => {
        const { showColorPlate } = this.state;
        this.setState({ showColorPlate : !showColorPlate })
    }

    render() {
        const { hoveredIcon } = this.state;
        return (
            <div  className="customTool"
            style = {{display:'flex', width: '100%'}}>
                <div style = {{width:'100px'}}>
                    {
                    <Segment color="" vertical style={{ minWidth: '', height: '576px', padding:'0', border: 'none'}} className="mainTool" >
                        <Sidebar.Pushable>
                            <Sidebar
                                as={Menu}
                                animation="overlay"
                                width=""
                                visible icon="labeled"
                                vertical className="customTool medical_tool"
                                style={{ border: 'none', width:'100%', background: 'transparent', display: displaySettingsByRole(getUidToken().roleId)}}>
                                <Menu.Item name="undo" style={hoveredIcon === 'undo' ? style.menuHover: style.menu} >
                                    <Icon
                                        style={hoveredIcon === 'undo' ? style.iconHover: style.icon}
                                        title="Undo"
                                        name='reply all'
                                        id="funundo"
                                        onClick={() => {
                                            this.callUndo();
                                            this.setState({ hoveredIcon: 'undo' })
                                        }}/>
                                </Menu.Item>
                                <Menu.Item name="redo" style={hoveredIcon === 'redo' ? style.menuHover: style.menu} >
                                    <Icon
                                        style={hoveredIcon === 'redo' ? style.iconHover: style.icon}
                                        title="Redo"
                                        name='share'
                                        id="funredo"
                                        onClick={() => {
                                            this.callRedo();
                                            this.setState({ hoveredIcon: 'redo' })
                                        }} />
                                </Menu.Item>
                                <Menu.Item name="leftrotate" style={hoveredIcon === 'leftRotate' ? style.menuHover: style.menu} >
                                <Icon
                                    style={hoveredIcon === 'leftRotate' ? style.iconHover: style.icon}
                                    title="Left Rotate"
                                    name='undo'
                                    onClick={() => {
                                            this.imageRotate('left');
                                            this.setState({ hoveredIcon: 'leftRotate' })
                                        }} />
                                </Menu.Item>
                                <Menu.Item name="rightrotate" style={hoveredIcon === 'rightRotate' ? style.menuHover: style.menu} >
                                <Icon
                                    style={hoveredIcon === 'rightRotate' ? style.iconHover: style.icon}
                                    title="Right Rotate"
                                    name='repeat'
                                    onClick={() => {
                                            this.imageRotate('right');
                                            this.setState({ hoveredIcon: 'rightRotate' })
                                        }}/>
                                </Menu.Item>
                                <Menu.Item name="copy" style={hoveredIcon === 'copy' ? style.menuHover: style.menu} >
                                    <Icon
                                        style={hoveredIcon === 'copy' ? style.iconHover: style.icon}
                                        title="Copy"
                                        name='copy'
                                        onClick={() => {
                                            this.copy("freehand");
                                            this.setState({ hoveredIcon: 'copy' })
                                        }}
                                    />
                                </Menu.Item>
                                <Menu.Item name="paste" style={hoveredIcon === 'paste' ? style.menuHover: style.menu} >
                                    <Icon
                                        style={hoveredIcon === 'paste' ? style.iconHover: style.icon}
                                        title="Paste"
                                        name="paste"
                                        onClick={() => {
                                                this.paste("freehand");
                                                this.setState({ hoveredIcon: 'paste' })
                                            }}
                                    />
                                </Menu.Item>
                                <Menu.Item name="invert" style={hoveredIcon === 'invert' ? style.menuHover: style.menu} >
                                    <Icon
                                        style={hoveredIcon === 'invert' ? style.iconHover: style.icon}
                                        title="Contrast"
                                        name='adjust'
                                        onClick={() => {
                                            this.invert();
                                            this.setState({ hoveredIcon: 'invert' })
                                        }}/>

                                </Menu.Item>
                                <Menu.Item name="resizebubble" style={hoveredIcon === 'resize' ? style.menuHover: style.menu} >
                                    <Icon
                                        style={hoveredIcon === 'resize' ? style.iconHover: style.icon}
                                        title="Resizing Bubble"
                                        name="circle"
                                        onClick={() => {
                                            this.Resize("Resize");
                                            this.setState({ hoveredIcon: 'resize' })
                                        }}/>
                                </Menu.Item>
                                <Menu.Item name="flip" style={hoveredIcon === 'flip' ? style.menuHover: style.menu} >
                                    <Icon
                                        style={hoveredIcon === 'flip' ? style.iconHover: style.icon}
                                        title="Flip"
                                        name="paste"
                                        onClick={() => {
                                            this.flip()
                                            this.setState({ hoveredIcon: 'flip' })
                                        }}
                                    />
                                </Menu.Item>
                                <Menu.Item name="interpolation" style={hoveredIcon === 'interpolate' ? style.menuHover: style.menu} >
                                    <Icon
                                        style={hoveredIcon === 'interpolate' ? style.iconHover: style.icon}
                                        title="Interpolation"
                                        name="toggle off"
                                        onClick={() => {
                                            this.interpolation("FreehandRoi")
                                            this.setState({ hoveredIcon: 'interpolate' })
                                        }}
                                    />
                                </Menu.Item>
                                <Menu.Item name="contour" style={hoveredIcon === 'contour' ? style.menuHover: style.menu} >
                                        <Icon
                                            style={hoveredIcon === 'contour' ? style.iconHover: style.icon}
                                            title="Contour"
                                            name="pencil alternate"
                                            onClick={() => {
                                                this.enableTool("freehand", 1);
                                                this.setState({ hoveredIcon: 'contour' })
                                            }}/>
                                </Menu.Item>
                                <Menu.Item name="clear" style={hoveredIcon === 'clear' ? style.menuHover: style.menu} >
                                    <Icon id="tool_close"
                                        style={hoveredIcon === 'clear' ? style.iconHover: style.icon}
                                        title="Clear"
                                        name="close"
                                        onClick={() => {
                                            this.clearAnnotations();
                                            this.setState({ hoveredIcon: 'clear' })
                                        }}
                                    />
                                </Menu.Item>
                                <Menu.Item name="clearall" className="clear_icon" style={hoveredIcon === 'clearAll' ? style.menuHover: style.menu} >
                                    <a className="clear_holder" style={hoveredIcon === 'clearAll' ? style.iconHover: style.icon}
                                                title="Clear All"
                                                name="quidditch icon"
                                                onClick={() => {
                                                    this.clearAllAnnotations();
                                                    this.setState({ hoveredIcon: 'clearAll' })
                                                }} >
                                        <svg width="36" height="38" viewBox="0 0 36 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.6349 33.4581C11.38 33.4318 8.10103 33.4581 4.84615 33.4581C3.3031 33.4581 2.50746 32.5382 2.48335 30.7509C2.48335 24.0751 2.48335 17.373 2.48335 10.6971C2.48335 8.96245 3.25488 7.98998 4.7256 7.98998C5.37658 7.98998 6.02755 7.98998 6.65442 7.98998V5.02002C5.35247 5.02002 4.07463 5.02002 2.77268 5.02002C2.48335 5.02002 2.16992 5.02002 1.90471 5.12515C0.650976 5.51939 0 6.62327 0 8.35794C0 16.5582 0 24.7584 0 32.9324C0 33.1427 9.16139e-08 33.3792 0.0241103 33.5895C0.144661 35.219 1.1814 36.3755 2.55568 36.4017C6.12399 36.428 9.6682 36.4017 13.2365 36.4017C13.4053 36.4017 13.6464 36.3492 13.7428 36.2178C14.3456 35.4556 14.9242 34.6934 15.6475 33.7209C15.2135 33.6158 14.9242 33.4581 14.6349 33.4581Z" fill="#898989"/>
                                            <path d="M30.3065 3.39049C30.3065 1.20901 29.1492 0 27.124 0C22.2537 0 17.3835 0 12.5132 0C11.1148 0 9.6923 0 8.2939 0C7.98047 0 7.64293 -1.01828e-07 7.32949 0.105131C5.95521 0.499374 5.2319 1.62954 5.2319 3.39049C5.2319 11.7222 5.2319 20.0538 5.2319 28.3855C5.2319 28.622 5.2319 28.8323 5.25601 29.0688C5.40067 30.7247 6.53386 31.9074 8.02869 31.9074C10.512 31.9074 12.9954 31.9074 15.4787 31.9074C16.9013 31.9074 18.3238 31.9074 19.7463 31.9074C19.9391 31.9074 20.1802 31.8548 20.3008 31.7234C20.9518 30.9612 21.5786 30.1727 22.3984 29.174C21.9162 29.0426 21.6027 28.8849 21.2652 28.8849C18.806 28.8849 16.3467 28.8849 13.8875 28.8849C12.7784 28.8849 11.6452 28.8849 10.5362 28.8849C8.84844 28.8849 7.95636 27.965 7.95636 26.1252C7.95636 22.5244 7.95636 18.9237 7.95636 15.3492C7.95636 13.9036 7.95636 12.4581 7.95636 11.0125C7.95636 9.25156 7.95636 7.49061 7.95636 5.75595C7.95636 4.54693 8.36623 3.6796 9.13776 3.28536C9.42708 3.12766 9.74052 3.04881 10.1263 3.02253C10.2227 3.02253 10.2951 3.02253 10.3915 3.02253C15.2859 2.99624 20.1802 2.99624 25.0746 3.02253C26.7141 3.02253 27.558 4.02128 27.558 5.80851C27.558 10.2503 27.558 14.6658 27.558 19.1076C27.558 19.3967 27.5821 19.7384 27.7267 19.9487C28.0643 20.4481 28.4742 20.8949 28.884 21.4205C29.2698 20.9474 29.6797 20.5006 29.9931 20.0275C30.1378 19.8173 30.2583 19.5019 30.2583 19.2128C30.3307 13.9036 30.3307 8.64706 30.3065 3.39049Z" fill="#898989"/>
                                            <path d="M35.6349 22.0776C35.1527 20.0275 32.9104 19.3442 31.5362 20.8949C30.403 22.1564 29.3421 23.4969 28.2331 24.8373C27.1481 23.5232 26.0872 22.2353 25.0023 20.9737C24.0379 19.8698 22.5671 19.791 21.6027 20.7372C20.5901 21.7096 20.5419 23.3392 21.5063 24.5219C21.9644 25.1001 22.4466 25.6521 22.9047 26.204C23.6039 27.0451 24.3031 27.9124 25.0023 28.7797C23.7968 30.2516 22.6154 31.6183 21.4822 33.0638C20.5178 34.2728 20.6142 35.8761 21.651 36.8486C22.7118 37.8736 24.1102 37.7159 25.1469 36.4806C25.7015 35.8235 26.2319 35.1665 26.7623 34.5094C27.2445 33.9312 27.7508 33.3529 28.2813 32.7222C29.3421 34.01 30.3306 35.219 31.3433 36.428C31.9942 37.2165 32.7899 37.7159 33.8025 37.4268C35.7072 36.9011 36.3582 34.7196 35.0562 33.0638C33.9231 31.6183 32.7176 30.2253 31.5362 28.7534C32.6693 27.3867 33.7784 26.02 34.9116 24.6796C35.4902 23.9437 35.876 23.1026 35.6349 22.0776Z" fill="#898989"/>
                                            <path d="M25.5327 8.25284C25.4122 7.49063 24.8335 7.04382 23.9173 7.04382C22.0126 7.04382 20.132 7.04382 18.2273 7.04382C16.2021 7.04382 14.2009 7.04382 12.1757 7.04382C12.0792 7.04382 11.9587 7.04382 11.8622 7.04382C10.946 7.04382 10.3674 7.5432 10.271 8.35797C10.1986 9.04132 10.4879 9.59326 11.0184 9.85609C11.2354 9.96122 11.5006 10.0401 11.814 10.0401C13.8152 10.0664 15.8404 10.0401 17.8416 10.0401C19.8909 10.0401 21.9403 10.0401 24.0138 10.0401C25.0505 10.0138 25.6774 9.2253 25.5327 8.25284Z" fill="#898989"/>
                                            <path d="M23.9656 12.1164C21.9644 12.1164 19.9633 12.1164 17.938 12.1164C15.9128 12.1164 13.8875 12.1164 11.8381 12.1164C10.8255 12.1164 10.2469 12.6683 10.2469 13.562C10.2469 14.4293 10.8255 14.9812 11.7899 15.0075C15.8404 15.0075 19.9151 15.0075 23.9656 15.0075C24.9541 15.0075 25.5327 14.4293 25.5086 13.5094C25.5086 12.6421 24.93 12.1164 23.9656 12.1164Z" fill="#898989"/>
                                            <path d="M24.0379 17.1101C19.9633 17.1101 15.8886 17.1101 11.7899 17.1101C10.8496 17.1101 10.271 17.6621 10.2469 18.5294C10.2227 19.423 10.8255 20.0275 11.7899 20.0275C14.3456 20.0275 16.9013 20.0275 19.457 20.0275C19.6739 20.0275 19.9392 19.975 20.0838 19.8173C21.5545 18.1089 23.3387 17.5569 25.4604 18.1877C25.2193 17.3992 24.7371 17.1101 24.0379 17.1101Z" fill="#898989"/>
                                            <path d="M11.5247 21.9987C11.2113 21.9987 10.946 22.1301 10.7291 22.3141C10.4397 22.577 10.271 22.9975 10.2469 23.418C10.2227 24.3379 10.8255 24.9424 11.7899 24.9424C13.7669 24.9424 15.7681 24.9424 17.7451 24.9424C18.2032 24.9424 18.6372 24.9424 19.023 24.9424C19.023 23.8911 19.023 22.9449 19.023 21.9462C16.4914 21.9724 14.008 21.9462 11.5247 21.9987Z" fill="#898989"/>
                                        </svg>
                                    </a>
                                </Menu.Item>
                                <Menu.Item name="unidirection" style={hoveredIcon === 'unidirection' ? style.menuHover: style.menu} >
                                    <Icon
                                        style={hoveredIcon === 'unidirection' ? style.iconHover: style.icon}
                                        title='Unidirectional Ruler'
                                        name='arrows alternate horizontal'
                                        onClick={() => {
                                                    this.props.ruler('unidirection');
                                                    this.setState({ hoveredIcon: 'unidirection' })
                                                    }}
                                    />
                                </Menu.Item>
                                <Menu.Item name="bidirection" style={hoveredIcon === 'bidirection' ? style.menuHover: style.menu} >
                                    <Icon
                                        style={hoveredIcon === 'bidirection' ? style.iconHover: style.icon}
                                        title="Bidirectional Ruler"
                                        name="exchange"
                                        onClick = {() => {
                                                    this.props.ruler('bidirection');
                                                    this.setState({ hoveredIcon: 'bidirection' })
                                                    }}
                                    />
                                </Menu.Item>
                            </Sidebar>
                        </Sidebar.Pushable>
                    </Segment>
                    }
                </div>
            </div>
            
        );
    }
}

export default SidebarTools;