import firebase from "firebase";
import ReactGA from "react-ga";
import { DUMMY_UID, DUMMY_TOKEN,taskTypeMap, RECTANGLE, IMAGE_POLYGON_BOUNDING_BOX_V2} from "./Utils";
// import config from '../config'
import { notification } from 'antd';

export const getUidToken = (dummyRequired) => {
    let token = window.sessionStorage.getItem("token");
    let uid = window.sessionStorage.getItem("uid");
    let roleId = window.localStorage.getItem("roleId");
    let orgId = window.localStorage.getItem("orgId");
    let pwdChanged = window.sessionStorage.getItem("passwordChanged");
    if (dummyRequired) {
      uid = DUMMY_UID;
      token = DUMMY_TOKEN;
    }
    return { uid, token, roleId, pwdChanged, orgId };
  };


  export const logEvent = (category, action, label) => {
    try {
      if (label) {
        ReactGA.event({
          category,
          action,
          label
        });
      } else {
        ReactGA.event({
          category,
          action
        });
      }
    } catch (exception) {
      console.log(exception);
    }
  };


  export const refreshToken = user => {
    if (user) {
      user
        .getIdToken(/* forceRefresh */ true)
        .then(function(idToken) {
          console.log("refresh token", idToken);
          window.sessionStorage.setItem("token", idToken);
          setTimeout(function() {
            refreshToken();
          }, 60000 * 15);
        })
        .catch(function(error) {
          console.log("error generating token ", error);
        });
    } else {
        console.log("Force refresh ");
    //   const cu = firebase.auth().currentUser;
    //   if (cu) {
    //     cu.getIdToken(/* forceRefresh */ true)
    //       .then(function(idToken) {
    //         console.log("refresh token", idToken);
    //         window.sessionStorage.setItem("token", idToken);
    //         setTimeout(function() {
    //           refreshToken();
    //         }, 60000 * 15);
    //       })
    //       .catch(function(error) {
    //         console.log("error generating token ", error);
    //       });
    //   }
    }
  };

  export const saveactiveTab = active =>{
    window.sessionStorage.setItem("activeName", active);
  };


  export const setSharedProjects = project =>{
    window.sessionStorage.setItem("sharedProject", project);
  };

  export const getSharedProjects = () =>{
    const sharedProject = window.sessionStorage.getItem("sharedProject");
    return sharedProject;
  };

  export const showAlert = ( message, type = null, title = '' ) => {
    notification.destroy()
    if ( message && message.length > 1 ) {
      const notifiyType = type ? type : 'info'
      notification.open({
        type: notifiyType,
        message: title,
        description: message,
        className: notifiyType
      });
    }
  }

  export const getOrgId = () =>{
    const orgId = window.localStorage.getItem("orgId");
    console.log("Org id",orgId)
    return orgId;
  };

  export const saveOrgId = orgId =>{
    window.localStorage.setItem("orgId", orgId);
  };

  export const saveData = (orgName, projectName) => {
    window.sessionStorage.setItem("orgName", orgName);
    window.sessionStorage.setItem("projectName", projectName);
  };

  export const getactiveName = () =>{
    const activeName = window.sessionStorage.getItem("activeName");
    return activeName;
  };

  export const getTaskType = (projectDetails) => {
    console.log("gettask type",projectDetails);
    let displayTaskType = taskTypeMap[projectDetails.task_type];
    if ((taskTypeMap[projectDetails.task_type] === taskTypeMap[IMAGE_POLYGON_BOUNDING_BOX_V2])  && (JSON.parse(projectDetails.taskRules).defaultShape === RECTANGLE)) {
       displayTaskType = taskTypeMap.IMAGE_BOUNDING_BOX_V2;
     }
     console.log("Display task type",displayTaskType)
     return displayTaskType;
   };


  export const refreshUidToken = callbackFn => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        console.log("refreshuid user is", user);
        window.sessionStorage.setItem("uid", user.uid);
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(function(idToken) {
            console.log("refresh token", idToken);
            window.sessionStorage.setItem("token", idToken);
            if (callbackFn) {
              callbackFn();
            }
            setTimeout(function() {
              refreshToken();
            }, 60000 * 5);
          })
          .catch(function(error) {
            console.log("error generating token ", error);
          }); // User is signed in.
      } else {
        return -1;
      }
    });
  };

export const validateBasicEntityDOC = (basicEntities) => {
	  let emptyCheck = false;
	  for (let i = 0; i < basicEntities.length; i++) {
		  if ('entities' in basicEntities[i]) {
	    	  for (let j = 0; j <  basicEntities[i].entities.length; j++) {
	    		  if (basicEntities[i].entities[j].name === "" || basicEntities[i].entities[j].name.trim().length === 0) {
	    			  emptyCheck = true;
	    			  break;
	    		  }
	    	  }
	      }
	  }
	  return emptyCheck
  }
