import React from 'react';
import 'antd/dist/antd.css';
import { DOCUMENT_ANNOTATION_TYPES } from '../../../helpers/Utils';
import ADD from '../../../images/Add-2.svg'

class EntityOptions extends React.Component {
  
    render() {
      const { value, onInputChange } = this.props;
      const { BASIC, ADVANCE } = DOCUMENT_ANNOTATION_TYPES;
        return (
            <div className="project_dec">
            <div className="action_btn m-r-10" onClick={() => onInputChange(BASIC)}
                    type={value[BASIC] ? 'primary' : 'default'}>
              <img alt='add' src={ADD} />
              <span>BASIC LABEL</span>
            </div>
            <div className="action_btn" onClick={() => onInputChange(ADVANCE)}
                    type={value[ADVANCE] ? 'primary' : 'default'}>
              <img alt='add' src={ADD} />
              <span>ADVANCED LABEL</span>
            </div>
            </div>
        )
  }
}
export default EntityOptions

