import React from 'react';
import "./NotFound.css"

export default function NotFound() {
  return (
    <div className="container">
        <div 
        className="loading text-center"
        >
          <span className="glyphicon glyphicon-repeat glyphicon-refresh-animate gi-3x">
          </span>
        </div>
    </div>
  );
}
