import { getUidToken } from "../helpers/commonFunctions";
import { middleCallback } from "./commonApis";
import { BASE_URL } from "../helpers/Constants";
const superagent = require("superagent");

export const fetchHits = (
  pid,
  start,
  count,
  callbackfn,
  type,
  label,
  userId,
  evaluationType,
  commentFilter,
  fromDate,
  pageSortBy,
  toDate,
  searchHitUrl,
  classificationLabel,
  niriFilteredHits
) => {
  console.log(
    "fetching project details ",
    pid,
    start,
    count,
    type,
    label,
    userId,
    fromDate,
    toDate,
    pageSortBy,
    classificationLabel
  );
  if (niriFilteredHits === undefined) {
    niriFilteredHits = 0;
  }
  const { uid, token } = getUidToken();
  let status = "notDone";
  if (type) {
    status = type;
  }
  let url =
    BASE_URL +
    pid +
    "/getHits?start=" +
    start +
    "&count=" +
    count +
    "&status=" +
    status +
    "&niriFilteredHits=" +
    niriFilteredHits;
  if (label) {
    url = url + "&label=" + label.replace(" ", "+");
  }
  if (userId) {
    url = url + "&userId=" + userId;
  }
  if (evaluationType) {
    url = url + "&evaluation=" + evaluationType;
  }
  if (commentFilter) {
    url = url + "&commentFilter=" + commentFilter;
  }
  if (fromDate) {
    url = url + "&from=" + fromDate;
  }
  if (toDate) {
    url = url + "&to=" + toDate;
  }
  if (pageSortBy) url = url + "&order=" + pageSortBy;
  if (classificationLabel)
    url = url + "&classificationLabel=" + escape(classificationLabel);
  superagent
    .post(url)
    .send(searchHitUrl)
    .set("uid", uid)
    .set("token", token)
    .end((err, res) => {
      middleCallback(err, res, callbackfn);
      // callbackfn(err, res);
    });
};


export const fetchNotDoneHits = ( pid, callbackfn, type, userId, pageSortBy ) => {
    const { uid, token } = getUidToken();
      let status = "notDone";
      if (type) {
        status = type;
      }
    let url =
        BASE_URL +
        pid +
        "/getNotDoneHits?status=" +
        status;
    if (userId) {
        url = url + "&userId=" + userId;
      }
    if (pageSortBy)
        url = url + "&order=" + pageSortBy;
    superagent
        .post(url)
        .set("uid", uid)
        .set("token", token)
        .end((err, res) => {
          middleCallback(err, res, callbackfn)
          // callbackfn(err, res);
        });
    };


    export const addHits = (hitId, result, pid, callback) => {
        console.log("adding hit ", hitId, pid);
        const { uid, token } = getUidToken();
      
        superagent
          .post(BASE_URL + pid + "/addHitResult?hitId=" + hitId)
          .send(result)
          .set("uid", uid)
          .set("token", token)
          .end((err, res) => {
            middleCallback(err, res, callback)
          });
      };

export const skipHits = (hitId, pid, callback) => {
  console.log("adding hit ", hitId, pid);
  const { uid, token } = getUidToken();

  superagent
    .post(BASE_URL + pid + "/addHitResult?hitId=" + hitId)
    .send({ skipped: true })
    .set("uid", uid)
    .set("token", token)
    .end((err, res) => {
      middleCallback(err, res, callback)
    });
};


export const editProject = (pid, data, callback) => {
    console.log("update proejct ", pid,data);
    const { uid, token } = getUidToken();
  
    superagent
      .post(BASE_URL + pid + "/updateProject")
      .send(data)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
        // callback(err, res);
      });
  };

  export const updateHitStatus = (hitId, pid, status, result, callback) => {
    console.log("updating hit status", hitId, pid, status, result);
    const { uid, token } = getUidToken();
  
    superagent
      .post(BASE_URL + pid + "/addHitResult?hitId=" + hitId)
      .send({ status, result })
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };


  export const addHitEvaluation = (hitId, pid, evaluation, callback) => {
    console.log("update hit status", hitId, pid, evaluation);
    const { uid, token } = getUidToken();
  
    superagent
      .post(BASE_URL + pid + "/evaluationResult?hitId=" + hitId)
      .send({ evaluation })
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };

  export const updateNewLabelName = (hitId, pid, labelName, operation, callback) => {
    console.log("update hit status", hitId, pid, labelName, operation);
    const { uid, token } = getUidToken();
  
    superagent
      .post(BASE_URL + pid + "/addNewLabel?operation=" + operation)
      .send(labelName)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };

  export const uploadAnnotatedImagesToS3 = (pid, hitId, DomToImage, callback,val,height,width) => {
    if(height !== undefined && width !== undefined){
      const { uid, token } = getUidToken();
    const data = new FormData();
    data.append("file", DomToImage);
    data.append("filename", hitId+".png");
    superagent
      .post(BASE_URL +"image/"+pid+"/uploadAnnotatedImagesToS3/"+hitId+"/"+val+"/"+height+"/"+width)
      .set("uid", uid)
      .set("token", token)
      .attach("file", DomToImage)
      .end((err, res) => {

      });
    }
    callback();
  };


  export const uploadAnnotatedImagesToS3Mask = (pid, hitId, DomToImage,callback, val,height,width) => {
    if(height !== undefined && width !== undefined){
      const { uid, token } = getUidToken();
    const data = new FormData();
    data.append("file", DomToImage);
    data.append("filename", hitId+".png");
    superagent
      .post(BASE_URL +"image/"+pid+"/uploadAnnotatedImagesToS3Mask/"+hitId+"/"+val+"/"+height+"/"+width)
      .set("uid", uid)
      .set("token", token)
      .attach("file", DomToImage)
      .end((err, res) => {

      });
    }
    callback();
    return true;
  };


  export const deleteAnnotatedImagesFromS3 = (pid, hitId, callback) => {
    // callback();
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL +"image/"+pid+"/deleteAnnotatedImagesFromS3/"+hitId)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        // callback(err, res);
      });
  };


  export const imageMaskCreation = (pid,tasktype,hitId,callback) =>{
    const {uid , token} = getUidToken();
    superagent
    .post(BASE_URL + pid + "/imageMaskCreation/"+tasktype+"/"+hitId)
    .set("uid", uid)
    .set("token", token)
    .end((err, res) => {
    });
    callback()
  }

  export const addHitLabel = (hitId, pid, labelName, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + pid + "/addHitLabel/" + hitId)
      .send(labelName)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };


  export const uploadGrayScaleImage = (pid, hitId) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL +"image/"+pid+"/uploadGrayScaleImage/"+hitId)
      .set("uid",uid)
      .set("token",token)
      .end((err, res) => {
        //callback(err, res);
      });
  };

  export const moveAllToDone = (pid,callback)=>{
    let url = BASE_URL + pid + "/moveAllToDone";
    const {uid,token} = getUidToken();
    superagent
    .post(url)
    .set("uid",uid)
    .set("token",token)
    .end((err,res)=>{ 
      middleCallback(err, res, callback)
    });
  };

  export const filterHits = (org_name, project_id, hitUrl, hitStatus, pageSize, pageStart, hitCompletedStartDate, hitCompletedEndDate, hitCreatedStartDate, hitCreatedEndDate, hitAnalystId, pageCurrent, pageSortBy, hitEvaluationType, label, callback) => {

    const { uid, token } = getUidToken();
    let url = BASE_URL + project_id + "/hits/filter";
    superagent
      .post(url)
      .send({ hitUrl: hitUrl, hitStatus: hitStatus, pageSize: pageSize, pageStart: pageStart, hitCompletedStartDate: hitCompletedStartDate, hitCompletedEndDate: hitCompletedEndDate, hitCreatedStartDate: hitCreatedStartDate, hitCreatedEndDate: hitCreatedEndDate, hitAnalystId: hitAnalystId, pageCurrent: pageCurrent, pageSortBy: pageSortBy, hitEvaluationType: hitEvaluationType, label: label })
      .set("uid", uid)
      .set("token", token)
      .set("Content-Type", 'application/json')
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  }

  export const changeExistingColorAPI = (pid, data, callback) => {
    console.log("changeExistingColor ", pid);
    const { uid, token } = getUidToken();
  
    superagent
      .post(BASE_URL + pid + "/changeExistingColor")
      .send(data)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
     });
  };

  export const updateDicomListHitStatus = (pid, saveList, callback) => {
    console.log("update hit status",  pid, );
    const { uid, token } = getUidToken();
  
    superagent
      .post(BASE_URL + pid + "/addDicomHitResultList")
      .send( saveList )
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };