import React, { Component } from 'react';
import {Switch} from 'antd';
export default class ToggleSwitch extends Component {

 constructor(props) {
    super(props);
    this.props = props;
 }

  render() {
    return (
        <Switch checked={this.props.createUserDefinedColor} onChange={this.props.isUserDefinedColorSwitchChange} />
    );
  }
}
