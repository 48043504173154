import React from "react";
import "antd/dist/antd.css";
import { Row, Col, Input, Button,Icon,Tooltip } from "antd";
import style from './style.js'

class AdvanceEntityForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isLabel: false,serNum:0} ;
  }
  advanWidth  = 500
  componentWillMount() {
   	let isLabel = true;
   	this.setState({ isLabel});
   }	

   componentDidMount() {
   		let isLabel = false;
   		this.setState({ isLabel});
   }
    renderList(item, level) {
    const { isLabel } = this.state;
    if(level == 0 || level == 1) {
      this.advanWidth  = 500
    }
    else{
      this.advanWidth  = 500 - 50
    }
    
    let itemNode;
    let s_no;
    if(item.serialNum != undefined) {
    	s_no = <span style={{ color: 'rgba(0,0,0,.45)',fontSize:'10px'}}>{item.serialNum}</span>
    }
    if(isLabel) {
        if(!item.newLabel) {
            itemNode = <div style = {{ width:  '100%', display: 'flex', padding: '5px'}}  className={item.setActive ? "dynamicLabeActive" : "dynamicLabel"} > 
                                <span id={"span-" + item.id} style ={{ display:(item.entities.length>0) ? "block":"none" }}  onClick={() => this.expand(item)}
                                 className={item.expand === true || item.showexpand === true || item.searchexpand === true ? "expand_sec":"collapse_sec" }>
                                   <Icon type="caret-right" style={{fontSize: '8px', marginRight:'5px'}} />
                                   <Icon type="caret-down" style={{fontSize: '8px', marginRight:'5px'}} /></span>
                                <span style={{width: '100%',fontWeight : item.showBold ? 'bold' : 'normal' }} onClick={() =>this.props.handleDynamicClick(item.text || item.label || item.name || item.labelName, item.id)}>
                                    {item.text || item.label || item.name || item.labelName} 
                                </span>
                       </div>
                               
            if(item.showDelete){
                itemNode = <div style = {{display: 'flex', alignItems: 'center'}}>{itemNode} 
                                    
                                    <Icon type="close" style={{fontSize: '12px', marginLeft:'5px', color: 'red'}} onClick={() => this.props.removeChild(item.id)} />
                                  </div>
            }      
                       
                        
        }
        else {
            itemNode =  <Input placeholder="Entity Name" onPressEnter = {e => this.props.bindAdvanceSelectBox(item.id)} defaultValue={item.text || item.label || item.name || item.labelName} 
                            suffix={<Tooltip> 
                                    <Icon type="check" onClick={e =>  this.props.bindAdvanceSelectBox(item.id)} style={{ color:'green',cursor: 'pointer' }} />
                                    <Icon type="close" onClick={() => this.props.removeChild(item.id)} style={{ color:'#f04134',cursor: 'pointer' }} />
                                </Tooltip>} onChange={e => this.props.updateInput(e, item.id, 'entity')}></Input>
        }
        
    } else {
        if(!item.newLabel) {
            itemNode = <div style = {{ width:  '100%', display: 'flex', padding: '5px'}}  className={item.setActive ? "dynamicLabeActive" : "dynamicLabel"}>
                        <span id={"span-" + item.id} style ={{ display:(item.entities.length>0) ? "block":"none" }}  
                            onClick={() => this.expand(item)} className={item.expand === true || item.showexpand === true || item.searchexpand== true ? "expand_sec":"collapse_sec" } >
                          <Icon type="caret-right" style={{fontSize: '8px', marginRight:'5px'}} />
                          <Icon type="caret-down" style={{fontSize: '8px', marginRight:'5px'}} />
                        </span>
                               

                                <span style={{width: '100%', fontWeight : item.showBold ? 'bold' : 'normal'}} onClick={() =>this.props.handleDynamicClick(item.text || item.label || item.name || item.labelName, item.id)}>{item.text || item.label || item.name || item.labelName} </span></div>
                if(item.showDelete){
                itemNode = <div style = {{display: 'flex', alignItems: 'center'}}>{itemNode}
                                    <Icon type="close" style={{fontSize: '12px', marginLeft:'5px', color: 'red'}} onClick={() => this.props.removeChild(item.id)} /> 
                                        </div>
                    } 
                   
        }
        else{
    	    itemNode =  <Input placeholder="Entity Name" onPressEnter = {e => this.props.bindAdvanceSelectBox(item.id)} defaultValue={item.text || item.label || item.name || item.labelName} suffix={
                            <Tooltip> 
                                <Icon type="check" onClick={e =>  this.props.bindAdvanceSelectBox(item.id)} style={{ color:'green',cursor: 'pointer' }} />
                                <Icon type="close" onClick={() => this.props.removeChild(item.id)} style={{ color:'#f04134',cursor: 'pointer' }} />
                            </Tooltip>
                            } onChange={e => this.props.updateInput(e, item.id, 'entity')}></Input>
        }
    }
  	let width = level * 2
  	
    return (
      <div>
        <Row
          gutter={15}
          key={item.id}
          style={{
            width: `100%`,
            float: "rigt",
            padding: 10,
            display: this.props.list.length > 0 ? 'block' : 'none'
          }}
        >
        
        <Col span={width}/>
          <Col span={23 - width}>
            {itemNode} 
          </Col>
          { this.props.showAdvanceIcons &&
          <Col span={1}>
            <Button
              onClick={() => this.childexpand(item)} title="Add Child"
              type="link"
              icon="plus"
              style={{color:'#0e77ca',border:'thin'}}
            />
          </Col>
          }
        </Row>
        <div className="column-reverse" id={item.id} 
          style={item.expand == true || item.showexpand === true || item.searchexpand === true ? {display:'flex'} :  {display:'none'}}>
        {item.entities.length > 0 &&
          item.entities.map(it => {
            return this.renderList(it, it.level);
          })}
        </div>
      </div>
    );
  }
  
  childexpand (item){
    console.log("div Id",item);
    this.props.addChild(item.id)
    if(item.expand){
      var parent=document.getElementById(item.id);
      var master=document.getElementById('span-'+ item.id);
      if(master !==null && master !== undefined){
        master.className="expand_sec";
      }
      if(parent !==null && parent !== undefined){  
        parent.style.display = "flex";
      }
    }
  }

  expand (item){
    console.log("div Id",item);
    if('expand' in item){
        item.expand = !item.expand;
    }else {
        item.expand = true;
    }
    var parent=document.getElementById(item.id);
    var master=document.getElementById('span-'+ item.id);
    if(master !==null && master !== undefined){
        if(master.className === "expand_sec")
            master.className="collapse_sec";
        else {
            master.className="expand_sec";
        }
      }

    if(parent !==null && parent !== undefined){
        if(parent.style.display === "flex"){
            parent.style.display = "none";
        }else {
            parent.style.display = "flex";
        }
    }
}
setClass (formID, showExpandwhenAdd) {
  this.props.addParent(formID)
  let parent=document.getElementById(formID); 
  let master=document.getElementById('span-'+ formID);
  if(showExpandwhenAdd) 
  {
    if(master !==null && master !== undefined){
      master.className="expand_sec";
    } 
    if(parent !==null && parent !== undefined){
      parent.style.display = "flex";
    }
  }
}

labelAdvanced (label, formId ){
  let parent=document.getElementById(formId); 
  let master=document.getElementById('span-'+ formId);
    if(master !==null && master !== undefined){
        if(master.className === "expand_sec")
            master.className="collapse_sec";
        else {
            master.className="expand_sec";
        }
      }   

    if(parent !==null && parent !== undefined){
        if(parent.style.display === "flex"){
            parent.style.display = "none";
        }else {
            parent.style.display = "flex";
        }
    }
}

  render() {
    const { list, label, formId ,serNum, newLabel, setActive, showexpand, searchexpand} = this.props;
    console.log(label);
    return (
    <div>
        <Row className="d_title">
        <div  className={setActive ? "dynamicLabeActive" : "dynamicLabel"}  style={{padding:'5px', display: 'flex', alignItems: 'center', justifyContent:'space-between', fontWeight:'normal', color: 'rgb(92, 193, 187)'}}>
        <div style = {{display: 'flex'}}>
        <span id={"span-" + formId} onClick={() => this.labelAdvanced(label, formId )}
         className={ showexpand === true || searchexpand === true ? "expand_sec" : "collapse_sec"}>
        <Icon type="caret-right" style={{fontSize: '8px', marginRight:'5px'}} /> 
        <Icon type="caret-down" style={{fontSize: '8px', marginRight:'5px'}} /></span>
        <span  style={{width:'100%'}} onClick={() =>this.props.handleDynamicClick(label,formId)}>
        {label}</span></div>
        {this.props.showAdvanceIcons && 
        <Button style={style.advanceForm.btn_noborderfill}
          onClick = {() => this.setClass(formId, true)} type="default">
         Add Parent
        </Button>
        }
        
        </div>
        </Row>
        
        <div className="column-reverse" id={formId} 
        style={showexpand || searchexpand === true ? {display:'flex'}:{display:'none'}}>
        {
          list.map(it => {
            console.log("renderList ===> ",list)
          	return this.renderList(it, it.level);
          })
        }
        </div>
      </div>
    )
  }
}

export default AdvanceEntityForm
