const style = {
    basicForm: {
        constainer: {
            marginLeft: '5%',
            marginRight: '5%',
            border: '1px solid #bfbfbf',
            borderRadius: 4,
            marginTop: 10,
            padding: 10
        },
        close: {},
        close_fill: {
            position: 'relative',
            top: -25,
            right: -25,
            float: 'right',
            background: 'red',
            color: 'white'
        },
        row: {
            width: `100%`,
            float: "rigt",
            marginTop: 10
        },
        col: {
            paddingRight: 5
        },
        duplicate_entity: {
            borderColor: 'red'
        },
        remove_duplicate_entity: {
           // borderColor: '#62afe6'
        }
    },
    advanceForm: {
        container: {
            marginLeft: '5%',
            marginRight: '5%',
            padding: 10,
            border: '1px solid #bfbfbf',
            borderRadius: 4,
            marginTop: 10,
        },
        row: {
            width: `100%`,
            float: "rigt",
            marginTop: 10
        },
        col: {
            paddingRight: 5
        },
        btn_noborderfill: {
            border: 'none',
            float: 'right',
            color: '#5cc1bb',
            fontWeight: 'normal'
        },
        close: {},
        close_fill: {
            position: 'relative',
            top: -25,
            right: -25,
            float: 'right',
            background: 'red',
            color: 'white'
        }
    },
    
}
export default style