import React from "react";
import "antd/dist/antd.css";
import { TreeSelect } from "antd";
const { TreeNode } = TreeSelect;

class FilterEntityTree extends React.Component {
  renderTree(label) {
    const uniqueValue = label.id;
    if (label.entities !== undefined && label.entities.length !== 0) {
      return (
        <TreeNode
          value={uniqueValue}
          title={label.text || label.name || label.label || label}
          key={uniqueValue}
        >
          {label.entities &&
            label.entities.map((child) => {
              return this.renderTree(child);
            })}
        </TreeNode>
      );
    } else {
      return (
        <TreeNode
          value={uniqueValue}
          title={label.text || label.name || label.label || label}
          key={uniqueValue}
        ></TreeNode>
      );
    }
  }

  render() {
    const { entities, onSelect } = this.props;
    return (
      <div className="input_entity">
        <label> ENTITY</label>
        <TreeSelect
          className="f_tree"
          placeholder="Filter by Entity"
          onChange={onSelect}
          value={this.props.label}
          dropdownStyle={{ maxHeight: 350, overflow: "auto" }}
        >
          {entities.map((label) => {
            return this.renderTree(label);
          })}
          <TreeNode value="all" title="All" key="all"></TreeNode>
        </TreeSelect>
      </div>
    );
  }
}

export default FilterEntityTree;
