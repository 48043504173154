import React, { Component, PropTypes } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { setloaderMenu } from "../../redux/reducer/projectReducer.js";
import { bindActionCreators } from "redux";
import queryString from 'query-string'
import {
    uploadDataForm,
    updateProjectDetails,
    setCurrentProject,
    getUserHomeData,
    updatePrevPageDeatails
  } from "../../redux/reducer/projectReducer.js";

import {
  Segment,
  Table as STable,
  Button,
  Icon,
  Statistic,
  Progress,
  Dropdown,
  Label
} from "semantic-ui-react";
import {  Icon as AntIcon, Modal as AntModal,Tooltip } from 'antd';
import { getUidToken,logEvent,getTaskType,getactiveName,showAlert,saveactiveTab } from '../../helpers/commonFunctions';
import {  fetchProjectStats, addContributor, fetchHitsDetails,deleteProjectDt,runModelPrediction,removeContributor,
    } from '../../Services/OverviewApis.js'
import { createIndex ,downloadImageUrl,clearMachinePrediction} from "../../Services/commonApis.js";
import {
  timeConverter,
  checkVideoURL,
  VIDEO_BOUNDING_BOX,
  POS_TAGGING_GENERIC,
  VIDEO_CLASSIFICATION,
  IMAGE_CLASSIFICATION,
  DICOM_SEGMENTATION,
  DOCUMENT_ANNOTATION,
  IMAGE_POLYGON_BOUNDING_BOX,
  IMAGE_POLYGON_BOUNDING_BOX_V2,
  IMAGE_SEGMENTATION,
  IMAGE_MASKING,
  IMAGE_BOUNDING_BOX,
  TEXT_CLASSIFICATION,
  POS_TAGGING,
  createEntitiesJson,
  TEXT_MODERATION,
  createDocEntityColorMap,
  TEXT_SUMMARIZATION,
  DICOM_CLASSIFICATION,
  breadcrumbSeperate,
  getImageMaskEntityColorMap,
  createHitJson,
  ROLES_TYPES,
  displaySettingsByRole
} from "../../helpers/Utils";
import { push } from "react-router-redux";
import Popover from "react-bootstrap/lib/Popover";
import Table from "react-bootstrap/lib/Table";
import Modal from "react-bootstrap/lib/Modal";
import {
  Player,
  ControlBar,
  ForwardControl,
  PlaybackRateMenuButton
} from "video-react";
import BACK from '../../images/Arrow_Simple_left1.svg';
import OVERVIEW from '../../images/View-2.svg';
import CHART from '../../images/Chart-7.svg';
import TAG from '../../images/Badge.svg';
import PLUS from '../../images/Add-2.svg';
import WCHART from '../../images/Chart-7-1.svg';
import unshare from '../../images/unshare.svg';
import { CONTRIBUTE_MODE, ROLES, SHARE, UNSHARE, SUCCESS, ERROR, MESSAGES } from "../../helpers/Constants";
import ShareProjects from "../../Components/ShareProjects/ShareProjects.js";
import ImageAnnotator from "../../Components/PolygonAnnotator/ImageAnnotator.js";
import SampleData from "../../Components/SampleData/SampleDataRefactor.js";
import VideoAnnotator from "../VideoAnnotation/VideoAnnotation.js";
import DocumentAnnotator from "../../Components/DocumentAnnotator2/DocumentAnnotator.js";

const statsLabel = { textTransform: "initial", fontWeight: "300" };
class TaggerOrgProject extends Component {
  static propTypes = {
    user: PropTypes.object,
    uploadDataForm: PropTypes.func,
    pushState: PropTypes.func,
    updateProjectDetails: PropTypes.func,
    params: PropTypes.object,
    orgName: PropTypes.string,
    projectName: PropTypes.string,
    setCurrentProject: PropTypes.func,
    projectId: PropTypes.string,
    projectDetails: PropTypes.object,
    getUserHomeData: PropTypes.func,
    currentPathProject: PropTypes.string,
    currentPathOrg: PropTypes.string
  };

  constructor(props) {
    super(props);
    console.log("tagger stats props are", props);
    let selectedLabel = '';
    this.loadProjectDetails = this.loadProjectDetails.bind(this);
    this.projectDetailsFetched = this.projectDetailsFetched.bind(this);
    this.openInviteModal = this.openInviteModal.bind(this);
    this.inviteByEmail = this.inviteByEmail.bind(this);
    this.showSummaries = this.showSummaries.bind(this);
    this.inviteSent = this.inviteSent.bind(this);
    this.openScreen = this.openScreen.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.hitsFetched = this.hitsFetched.bind(this);
    this.projectDeleted = this.projectDeleted.bind(this);
    this.showTags = this.showTags.bind(this);
    this.deleteProject = this.deleteProject.bind(this);
    this.predictionCallback = this.predictionCallback.bind(this)
    this.nextElement = this.nextElement.bind(this);
    this.previousElement = this.previousElement.bind(this)

    this.state = {
      fields: {},
      errors: {},
      start: 0,
      count: 20,
      hitsDetails: undefined,
      projectDetailsError: undefined,
      projectDetails: this.props.projectDetails,
      inviteModal: false,
      loading: false,
      successModal: false,
      selectedLabel,
      projectType: undefined,
      inviteInProcess: false,
      confirmModalVisible: false,
      fromSample: true,
      contributorEmailAdded: [],
      currentProjectState:this.props.params.projectId
    };
  }

  state = {
    fields: {},
    errors: {},
    start: 0,
    projectDetails: null,
    inviteModal: false,
    loading: false,
    successModal: false,
    projectDetailsError: undefined,
    hitsDetails: undefined
  };

  componentWillMount() {
    console.log("TaggerStats componentWillMount", this.props);
    if (
      (this.props.params.orgName &&
        this.props.params.projectName &&
        (!this.props.projectDetails ||
          (this.props.projectDetails.name !== this.props.params.projectName ||
            this.props.projectDetails.orgName !==
            this.props.params.orgName))) ||
      !this.props.currentProject
    ) {
      this.setState({ projectDetails: undefined });
    }
    this.setState({ hitsDetails: undefined, isMounted: true });

    let isFilter = window.localStorage.getItem('isFilter')
    if (isFilter == undefined) {
      window.localStorage.setItem("isFilter", this.props.location.query.isFilter);
      window.localStorage.setItem("projectType", this.props.location.query.projectType);
      window.localStorage.setItem("currentProjectPage", this.props.location.query.currentpPage);
    }
  }

  componentDidMount() {
    const {currentProjectState} = this.state
    if (
      (this.props.params.orgName &&
        this.props.params.projectName &&
        (!this.props.projectDetails ||
          (this.props.projectDetails.name !== this.props.params.projectName ||
            this.props.projectDetails.orgName !== this.props.params.orgName ||
            this.props.projectDetails.id !== this.props.params.projectId
          ))) ||
      !this.props.currentProject
    ) {
      this.setState({ currentProjectState: this.props.params.projectId });
    } else {
      fetchHitsDetails(
        this.props.currentProject,
        0,
        20,
        this.hitsFetched,
        "done"
      );
    }
    if (currentProjectState) {
      this.loadProjectDetails(currentProjectState);
      fetchHitsDetails(currentProjectState, 0, 10, this.hitsFetched);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentProject) {
      if (this.props.currentProject !== nextProps.currentProject) {
        this.loadProjectDetails(nextProps.currentProject);
        fetchHitsDetails(
          nextProps.currentProject,
          0,
          20,
          this.hitsFetched,
          "done"
        );
      }
      if (
        this.props.currentProject === nextProps.currentProject &&
        !this.state.hitsDetails
      ) {
        fetchHitsDetails(
          nextProps.currentProject,
          0,
          20,
          this.hitsFetched,
          "done"
        );
      }
    }
  }

  componentWillUnmount() {
    console.log("unmounting taggerog");
    this.setState({ isMounted: false });
  }

  deleteContributor = (projectDetails, userEmail, isShare) => {
    removeContributor(this.props.currentProject, userEmail, isShare, this.loadProjectDetails);
  }

  isShowActions = projectDetails => {
    const data = projectDetails.contributorDetails
    for (let index = 0; index < data.length; index++) {
      if (data[index].role === "CONTRIBUTOR") {
        return true;
      }
    }
    return false;
  }

  removeUnshare = (index, data, isShare) => {
    this.deleteContributor(
      this.props.currentProject,
      data[index].userDetails.email,
      isShare
    );
  }

  getContributorsData = projectDetails => {
    const data = projectDetails.contributorDetails
    const labelcount = projectDetails.hitsContributorDetails;
    let totalLabelCounts = []
    let totalProcessedCount = []
    const totalHitsDone = projectDetails.totalHitsDone;
    let totalProcessedCountNiri = [totalHitsDone];
    // Since Label count list and hits done list are different, compared using uid, changes added by monica
    for (var i = 0; i < data.length; i++) {
      for (var j = 0; j < labelcount.length; j++) {
        if (labelcount[j].userDetails.uid === data[i].userDetails.uid) {
          totalLabelCounts.push(labelcount[j].hitsStats.done.labels)
          totalProcessedCount.push(labelcount[j].hitsStats.totalHits)
        }
      }
    }
    if(totalProcessedCount[0] !== totalProcessedCountNiri[0] && projectDetails.niriFilteredHits > 0) {
      totalProcessedCount = totalProcessedCountNiri
      if(projectDetails.niriFilteredHits > 0)
      {
        if(totalLabelCounts[0] % 2 === 0) {
          totalLabelCounts = [totalLabelCounts / 2]
        } else {
          totalLabelCounts = [(totalLabelCounts[0] + 1) / 2]
        }
      }
    }
    const arrs = [];
    for (let index = 0; index < data.length; index++) {
      if (data[index].hitsDone >= 0) {
        let onClickFn = (isShare) => {
          const that = this
          const confirm = AntModal.confirm;
          console.log("this.props : ", this.props)
          confirm({
            title: 'Confirmation',
            content: (isShare ? SHARE : UNSHARE) + data[index].userDetails.firstName + ' ' + data[index].userDetails.secondName + '\'?',
            okText: 'OK',
            cancelText: 'CANCEL',
            onOk() { that.removeUnshare(index, data, isShare) },
            onCancel() { that.cancelDelete(); },
          });
        };

        arrs.push(
          <tr className="spaceUnder" key={index}>
            <td>{data[index].userDetails.firstName}</td>
            <td>{(data[index].role === null) ? CONTRIBUTE_MODE.IN_ACTIVE : CONTRIBUTE_MODE.ACTIVE}</td>
            <td>{totalProcessedCount[index] ? totalProcessedCount[index] : 0}</td>
            {(this.state.projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2
              || this.state.projectDetails.task_type === IMAGE_MASKING
              || this.state.projectDetails.task_type === IMAGE_SEGMENTATION
              || this.state.projectDetails.task_type === IMAGE_CLASSIFICATION
              || this.state.projectDetails.task_type === DOCUMENT_ANNOTATION ||
              this.state.projectDetails.task_type === DICOM_SEGMENTATION ||
              this.state.projectDetails.task_type === DICOM_CLASSIFICATION ||
              this.state.projectDetails.task_type === TEXT_CLASSIFICATION) ?
              <td>{totalLabelCounts[index] ? totalLabelCounts[index] : 0}</td> : ''}
            <td>{data[index] && data[index].avrTimeTakenInSec ? data[index].avrTimeTakenInSec : 0}</td>
            {!projectDetails.projectContributor && data[index].role === ROLES.CONTRIBUTOR &&
              (data[index].userDetails.is_shared ?
                <td><Label id="unshare_btn"
                  as="a"
                  size="large"
                  style={{ marginTop: '5px', marginRight: '5px', padding: "5px", color: "white", backgroundColor: "#e84747" }}
                  name={CONTRIBUTE_MODE.UNSHARE}
                  onClick={() => onClickFn(false)}
                >
                  <img alt="unshare" src={unshare} />
                  {CONTRIBUTE_MODE.UNSHARE}
                </Label></td> :
                <td><Label id="share_btn"
                  as="a"
                  size="large"
                  style={{ marginTop: '5px', marginRight: '5px', padding: "5px", color: "white", backgroundColor: "#e84747" }}
                  name={CONTRIBUTE_MODE.SHARE}
                  onClick={() => onClickFn(true)}
                >
                  <img alt="unshare" src={unshare} />
                  {CONTRIBUTE_MODE.SHARE}
                </Label></td>)
            }
            {!projectDetails.projectContributor && data[index].role === ROLES.CONTRIBUTOR &&
              <td>{timeConverter(data[index].userDetails.created_timestamp / 1000)}</td>
            }
            {!projectDetails.projectContributor && data[index].role === ROLES.CONTRIBUTOR && !data[index].userDetails.is_shared &&
              <td>{timeConverter(data[index].userDetails.updated_timestamp / 1000)}</td>
            }
            <td></td>
          </tr>
        );
      }
    }
    return <tbody>{arrs}</tbody>;
  };

  hitsFetched(error, response) {
    console.log("hitsFetched ", error, response);
    if (!error) {
      let projectDetails = undefined;
      let hitDetails = undefined;
      if (response.body.projectDetails) {
        projectDetails = response.body.projectDetails;
      } else if (this.props.projectDetails) {
        projectDetails = this.props.projectDetails;
      }

      if (response.body.hits.length > 0) {
        hitDetails = response.body.hits[0].hitResults[0].result
      }
      let entities = [];
      let entityColorMap = {};
      let hitEntities = {};
      if (
        projectDetails.task_type === POS_TAGGING ||
        projectDetails.task_type === TEXT_CLASSIFICATION ||
        projectDetails.task_type === DOCUMENT_ANNOTATION ||
        projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX ||
        projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
        projectDetails.task_type === IMAGE_SEGMENTATION ||
        projectDetails.task_type === IMAGE_MASKING ||
        projectDetails.task_type === IMAGE_BOUNDING_BOX ||
        projectDetails.task_type === IMAGE_CLASSIFICATION ||
        projectDetails.task_type === VIDEO_CLASSIFICATION ||
        projectDetails.task_type === POS_TAGGING_GENERIC
      ) {

        const rules = JSON.parse(projectDetails.taskRules);
        const newTags = rules.tags
        const entitiesObject = projectDetails.task_type === DOCUMENT_ANNOTATION ? createEntitiesJson(JSON.stringify(newTags)) : createEntitiesJson(projectDetails.taskRules);;
        entities = entitiesObject.entities;
        if (projectDetails.task_type === DOCUMENT_ANNOTATION) {
          entities = this.getEntities(entities);
        }

        entityColorMap = createDocEntityColorMap(entities);
        hitEntities = createHitJson(hitDetails, entityColorMap);

        if (projectDetails.task_type === IMAGE_MASKING && projectDetails.createUserDefinedColor) {
          entityColorMap = getImageMaskEntityColorMap(rules.coloredTags);
        }
        console.log("image task1", projectDetails.task_type);
        if (projectDetails.task_type.indexOf("IMAGE") >= 0) {
          console.log("image task");
        }
      }
      this.props.setloaderMenu(false);
      this.setState({
        loading: false,
        entities,
        entityColorMap,
        hitEntities,
        hitsDetails: response.body.hits,
        projectType: response.body.projectDetails.task_type
      });
    }
  }

  loadImages(currentHits) {
    if (this.state.isMounted) {
      for (let index = 1; index < currentHits.length; index++) {
        let data = currentHits[index].data;
        const image1 = new Image(); // eslint-disable-line no-undef
        if (data.includes('hits/images') && !data.includes('http') && !data.includes('https')) {
          downloadImageUrl((url, uid, token) => {
            if (data.includes('hits/images') && !data.includes('http') && !data.includes('https')) {
              image1.src = `${url}${data}?token=${token}&uid=${uid}`;
            }
          });
        } else {
          image1.src = currentHits[index].data;
        }
      }
    }
  }

  open = () => this.setState({ successModal: true });
  close = () => {
    this.setState({ successModal: false, closeModal: true });
    if (this.state.redirectToHome) {
      let pathName = '/workspace';
      if (getactiveName() !== 'shared' && (parseInt(getUidToken().roleId) === 2 && this.state.projectDetails.workspaceName)) {
        pathName = "/workspace/" + this.state.projectDetails.workspaceName + "/" + this.state.projectDetails.workspaceId + "/" + this.state.projectDetails.orgId + "/" + (getactiveName() === 'home' ? "All" : "Archived");
      }
      this.props.pushState({
        pathname: pathName
      });
    }
  };

  openExport = () => {
    this.props.pushState("/projects/export");
  };
  optionChange = (evt) => {
    this.openScreen("overview", evt.target.value);
    evt.preventDefault();
  }

  openScreen = (screen, type) => {
    console.log("opening screen ", screen);
    console.log("opening screen type ", type);
    logEvent("buttons", "Project " + screen);

    if (screen === "edit") {
      this.props.pushState({
        pathname:
          "/projects/" +
          this.props.params.orgName +
          "/" +
          this.props.params.projectName +
          "/" +
          this.props.params.projectId +
          "/" +
          "edit",
        query: { type }
      });
    } else if (screen === "overview") {
      let screenName = 'space';
      let documentScreen = 'space'
      if (this.state.projectDetails.task_type === POS_TAGGING) {
        screenName = 'space';
      } else if (this.state.projectDetails.task_type === DICOM_SEGMENTATION) {
        documentScreen = 'view';
      }
      // changes to redirect screen to image listing by Michale Simson
      let contributorDetails = JSON.stringify(this.state.projectDetails.contributorDetails);
      if (
        this.state.projectType === IMAGE_POLYGON_BOUNDING_BOX ||
        this.state.projectType === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
        this.state.projectType === IMAGE_SEGMENTATION ||
        this.state.projectType === IMAGE_MASKING ||
        this.state.projectType === IMAGE_BOUNDING_BOX ||
        this.state.projectType === IMAGE_CLASSIFICATION) {
        this.props.pushState({
          pathname:
            "/projects/hitslist/" +
            this.props.params.orgName +
            "/" +
            this.props.params.projectName +
            "/" +
            this.props.params.projectId +
            "/" + type
        });
      } else if (this.state.projectType === DOCUMENT_ANNOTATION || this.state.projectType === DICOM_SEGMENTATION
        || this.state.projectType === POS_TAGGING_GENERIC || this.state.projectType === TEXT_SUMMARIZATION
        || this.state.projectType === VIDEO_CLASSIFICATION || this.state.projectType === VIDEO_BOUNDING_BOX
        || this.state.projectType === TEXT_MODERATION || this.state.projectType === TEXT_CLASSIFICATION) {
        this.props.pushState({
          pathname:
            "/projects/" +
            this.props.params.orgName +
            "/" +
            this.props.params.projectName +
            "/" +
            this.props.params.projectId +
            "/" +
            documentScreen,
          query: { type }
        });
      }
      else if (this.state.projectDetails.task_type === POS_TAGGING) {
        this.props.pushState({
          pathname: "/projects/" + this.props.params.orgName + "/" + this.props.params.projectName + "/" + this.props.params.projectId + "/" + screenName,
          query: { type }
        });
      }
    }
    else if (screen === "space") {
      this.props.pushState({
        pathname:
          "/projects/" +
          this.props.params.orgName +
          "/" +
          this.props.params.projectName +
          "/" +
          this.props.params.projectId +
          "/" +
          screen
      });
      this.props.updatePrevPageDeatails(type)
    } else if (screen === "view") {
      this.props.pushState({
        pathname:
          "/projects/" +
          this.props.params.orgName +
          "/" +
          this.props.params.projectName +
          "/" +
          this.props.params.projectId +
          "/" +
          screen
      });
      this.props.updatePrevPageDeatails(type)
    } else {
      this.props.pushState({
        pathname:
          "/projects/" +
          this.props.params.orgName +
          "/" +
          this.props.params.projectName +
          "/" +
          this.props.params.projectId +
          "/" +
          screen
      });
    }
  };

  // Navigate to Hits Done Image Annotation Page
  editScreen = (screen, type) => {
    console.log("opening screen ", screen);
    logEvent("buttons", "Project " + screen);

    if (screen === "edit") {
      this.props.pushState({
        pathname:
          "/projects/" +
          this.props.params.orgName +
          "/" +
          this.props.params.projectName +
          "/" +
          this.props.params.projectId +
          "/" +
          "edit",
        query: { type }
      });
    } else if (screen === "overview") {
      let screenName = 'space';
      if (this.state.projectDetails.task_type === POS_TAGGING) {
        screenName = 'space';
      }

      this.props.pushState({
        pathname:
          "/projects/" +
          this.props.params.orgName +
          "/" +
          this.props.params.projectName +
          "/" +
          this.props.params.projectId +
          "/" +
          screenName,
        query: { type }
      });
    } else {
      this.props.pushState({
        pathname:
          "/projects/" +
          this.props.params.orgName +
          "/" +
          this.props.params.projectName +
          "/" +
          this.props.params.projectId +
          "/" +
          screen
      });
    }
  };

  projectDetailsFetched(error, response) {
    console.log(" project details fetched fg", error, response);
    if (!error) {
      if (response.body.projectContributor === true) {
        saveactiveTab("shared");
      } else {
        saveactiveTab("home");
      }
      this.props.updateProjectDetails(response.body);
      this.props.setloaderMenu(false);
      this.setState({
        projectDetails: response.body,
        loading: false,
        projectDetailsError: undefined
      });
    } else {
      const userId = window.sessionStorage.getItem("uid")
      if (this.props.user) {
        showAlert(`invalid url - ${response.body.message}.`, 'error');
      }
      if (response && response.body && response.body.message) {
        this.props.setloaderMenu(false);
        this.setState({ loading: false, projectDetailsError: response.body.message });
      } else {
        this.props.setloaderMenu(false);
        this.setState({ loading: false, projectDetailsError: "Error in fetching data" });
      }
      if (this.props.user) {
        this.props.pushState('/workspace');
        saveactiveTab("home");
      } else if (!userId) {
        this.props.pushState('/projects/login');
      }
    }
  }

  openInviteModal(event, data) {
    console.log("open invite modal", event, data);
    logEvent("buttons", "Open invite modal");
    this.setState({ inviteModal: true });
    document.body.classList.add('c-modal');
    event.preventDefault();
  }

  loadProjectDetails(pid) {
    console.log("Entered in loadProjectDetails",pid)
    this.props.setloaderMenu(true);
    this.setState({
      loading: true,
      projectDetails: undefined,
      hitsDetails: undefined
    });
    if (!getUidToken().uid) {
      this.props.pushState('/projects/login');
    }
    if (pid) {
      fetchProjectStats(pid, this.projectDetailsFetched);
    } else {
      fetchProjectStats(this.props.currentProject, this.projectDetailsFetched);
    }
  }

  loadCreateIndex(pid) {
    console.log("loadCreateIndex called", pid)
    createIndex(
      pid ? pid : this.props.currentProject
    )
  }

  inviteSent(error, response) {
    console.log("invite sent ", error, response);
    this.setState({ inviteInProcess: false });
    if (!error) {
      logEvent("buttons", "Invite sent success");
      this.loadProjectDetails(this.props.currentProject);
      let alreadyAdded = this.props.projectDetails.contributorDetails.some(contributor => {
        if (this.state.contributorEmailAdded.includes(contributor.userDetails.email)) {
          return contributor.userDetails.is_shared
        }
        return false;
      });
      if (alreadyAdded) {
        showAlert(MESSAGES.CONTRIBUTOR_ALREADY_ADDED, ERROR);
      } else {
        showAlert(MESSAGES.CONTRIBUTOR_ADDED_SUCCESS, SUCCESS);
      }
    } else {
      logEvent("buttons", "Invite sent fail");
      this.setState({ inviteModal: false, error: true });
    }
  }

  projectDeleted(error, response) {
    console.log("project deleted ", error, response);
    if (!error) {
      logEvent("buttons", "project delete success");
      this.setState({
        successModal: false,
        showDeleteConfirmation: false,
        redirectToHome: true,
        successMessage: "The project is archived successfully."
      });
      showAlert("The project is archived successfully.", 'success');
      this.close();
    } else {
      logEvent("buttons", "Delete Project fail");
      showAlert(error.message, 'error');
      this.setState({
        error: true,
        showDeleteConfirmation: false,
        redirectToHome: true
      });
    }
  }

  inviteByEmail(email, isAdmin) {
    logEvent("buttons", "Sending invite");
    this.setState({ contributorEmailAdded: email });
    if (email.length > 0) {
      this.setState({ inviteInProcess: true });
      addContributor(this.props.currentProject, email, isAdmin, this.inviteSent)
    }
  }

  deleteProject() {
    this.props.setloaderMenu(true);
    this.setState({ loading: true });
    deleteProjectDt(this.props.currentProject, this.projectDeleted);
    // Unwanted loading symbol displays after delete also.
    this.props.setloaderMenu(false);
    this.setState({ loading: false });
  }

  openModal() {
    document.body.classList.add('c-modal');
    this.setState({ inviteModal: true });
  }

  closeModal() {
    document.body.classList.remove('c-modal');
    this.setState({ inviteModal: false });
  }

  showTags = entiti => {
    const renderArrs = [];
    for (let index = 0; index < entiti.length; index++) {
      const entity = entiti[index];
      const color = this.state.entityColorMap[entity];
      let onClickFn = undefined;
      if (this.state.label !== entity) {
        onClickFn = () => {
          this.state.start = 0;
          this.state.label = entity;
          fetchHitsDetails(
            this.props.currentProject,
            0,
            20,
            this.hitsFetched,
            "done",
            entity
          );
        };
      } else {
        onClickFn = () => {
          this.state.start = 0;
          this.setState({ label: undefined });
          fetchHitsDetails(
            this.props.currentProject,
            0,
            20,
            this.hitsFetched,
            "done"
          );
        };
      }
      renderArrs.push(
        <Label
          as="a"
          size="large"
          style={{ marginTop: '5px', 
                  display:"flex",justifyContent:"center",alignItems:"center",
                  color: "white", backgroundColor: color }}
          onClick={() => onClickFn()}
          name={entity}
          key={index}
        >
          {entity}
          {this.state.label === entity && <Icon name="delete" />}
        </Label>
      );
    }

    return <div style={{ maxHeight: '10em', display: 'flex', flexWrap: 'wrap', overflow: 'auto' }}>{renderArrs}</div>;
  };

  previousElement(event) {
    console.log("event", event);
    console.log("previousElement",this.state.start)
    if (this.state.start > 0) {
      this.setState({ start: this.state.start - 1 });
    }
  }

  nextElement(event) {
    console.log("event", event);
    console.log("State in overview",this)
    if (
      this.state.start < this.state.count &&
      this.state.start < this.state.hitsDetails.length
    ) {
      this.setState({ start: this.state.start + 1 });
    } else {
      const newCount = this.state.currentCount + this.state.count;
      this.loadProjectDetails(0, newCount);
    }
  }

  showCurrentTags(tags) {
    console.log(" show current tags ", this.state);
    const { entityColorMap } = this.state;
    const renderArrs = [];
    for (const ent of tags) {
      console.log("show current tag", ent, entityColorMap[ent]);
      renderArrs.push(
        <div style={{width:"20%"}}>
          <Label
          key={ent}
          style={{
            padding: "5px",
            color: "white",
            backgroundColor: entityColorMap[ent]
          }}
        >
          {" "}
          {ent}
        </Label>
        </div>
       
      );
    }
    return <div style={{display:"flex",gap:"5px",flexWrap:'wrap'}}> {renderArrs} </div>;
  }

  showClassifications = hitsDetails => {
    if (hitsDetails && hitsDetails.length === 0) {
      return <h2>No Sample HITs</h2>;
    }
    console.log("show classifications ", this.state);
    const currentHit = hitsDetails[this.state.start];
    const data = currentHit.data;
    const result = currentHit.hitResults[0].result;
    let currentTags = [];
    let currentNote = "";
    try {
      const resultJson = JSON.parse(result);
      if (resultJson.labels) {
        currentTags = new Set(resultJson.labels);
      } else {
        currentTags = result;
      }
      if (resultJson.note) {
        currentNote = resultJson.note;
      }
    } catch (exception) {
      // statements
      console.log("exception", exception);
      currentTags = new Set(result.split("____"));
    }
    console.log("results", result, currentTags);
    const popoverTop = (
      <Popover id="popover-positioned-top" title="Notes">
        A note could be added with each labeled item.
      </Popover>
    );

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <div className={"dataArea"}>
          <p>{data}</p>
          <br />
          <div style={{display:"flex",flexDirection:"column"}}>
          {this.showCurrentTags(currentTags)}
          </div>
        </div>
        <br />
        {currentNote &&
          currentNote.length > 0 && (
            <div>
              <p
                size="tiny"
                style={{ whiteSpace: "pre-wrap", marginLeft: "10%" }}
              >
                <Tooltip title={popoverTop}>
                  <Icon name="sticky note" color="teal" size="large" />
                </Tooltip>
                {currentNote}
              </p>
            </div>
          )}
        <br />
        <br />
        <br />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
          <Button
            size="mini"
            color="grey"
            icon
            labelPosition="left"
            onClick={this.previousElement.bind(this, "pervious")}
            disabled={this.state.start === 0}
          >
            <Icon name="left arrow" />
            Previous
          </Button>
          <Button
            size="mini"
            color="blue"
            icon
            labelPosition="right"
            onClick={this.nextElement.bind(this, "right")}
            disabled={
              this.state.start < 0 ||
              this.state.start >= this.state.hitsDetails.length - 1
            }
          >
            Next
            <Icon name="right arrow" />
          </Button>
        </div>
      </div>
    );
  };

  showClassificationImages = hitsDetails => {
    if (hitsDetails && hitsDetails.length === 0) {
      return <h2>No Sample HITs</h2>;
    }
    const currentHit = hitsDetails[this.state.start];
    let data = currentHit.data;
    downloadImageUrl((url, uid, token) => {
      if (data.includes('hits/images') && !data.includes('http') && !data.includes('https')) {
        data = `${url}${data}?token=${token}&uid=${uid}`;
      }
    });
    const result = currentHit.hitResults[0].result;
    let currentTags = [];
    let currentNote = "";
    console.log("result is", result);
    try {
      const resultJson = JSON.parse(result);
      if (resultJson && resultJson.labels) {
        currentTags = new Set(resultJson.labels);
      } else {
        currentTags = result;
      }
      if (resultJson && resultJson.note) {
        currentNote = resultJson.note;
      }
    } catch (exception) {
      // statements
      console.log("exception", exception);
      currentTags = new Set(currentHit.hitResults[0].result.split("____"));
    }
    const popoverTop = (
      <Popover id="popover-positioned-top" title="Notes">
        A note could be added with each labeled item.
      </Popover>
    );

    return (
      <div className="mimg_sec">
        <div>
          {!checkVideoURL(data) && <img alt="data" className="img-respons entity_img" src={data} />}
          {checkVideoURL(data) && (
            <Player
              preload="auto"
              poster="/assets/poster.png"
              src={data}
            >
              <ControlBar className={"controlBar"}>
                <ForwardControl seconds={5} order={3.1} />
                <ForwardControl seconds={10} order={3.2} />
                <ForwardControl seconds={30} order={3.3} />
                <PlaybackRateMenuButton
                  rates={[5, 3, 1.5, 1, 0.5, 0.1]}
                  order={7.1}
                />
              </ControlBar>
            </Player>
          )}
          <br />
          {this.showCurrentTags(currentTags)}
          <br />
        </div>
        <br />
        {currentNote &&
          currentNote.length > 0 && (
            <div>
              <p
                size="tiny"
                style={{ whiteSpace: "pre-wrap", marginLeft: "10%" }}
              >
                <Tooltip title={popoverTop}>
                  <Icon name="sticky note" color="teal" size="large" />
                </Tooltip>
                {currentNote}
              </p>
            </div>
          )}
      </div>
    );
  };

  showPosTags = hitsDetails => {
    if (hitsDetails && hitsDetails.length === 0) {
      return <h2>No Sample HITs</h2>;
    }
    const currentHit = hitsDetails[this.state.start];
    const result = currentHit.hitResults[0].result;
    return (
      <div className="wcolum">
        <div className="tag_style">{this.showTagLine(result, currentHit.data)}</div>
        <br />
        <br />
        <div>
          <div className="col-md-12">
            <div className="text-center crtlBtns">
              <Button
                size="mini"
                color="grey"
                icon
                labelPosition="left"
                onClick={this.previousElement.bind(this, "pervious")}
                disabled={this.state.start === 0}
              >
                <Icon name="left arrow" />
                Previous
              </Button>
              <Button
                size="mini"
                color="blue"
                icon
                labelPosition="right"
                onClick={this.nextElement.bind(this, "right")}
                disabled={
                  this.state.start < 0 ||
                  this.state.start >= this.state.hitsDetails.length - 1
                }
              >
                Next
                <Icon name="right arrow" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  showBoundedImages = hitsDetails => {
    if (hitsDetails && hitsDetails.length === 0) {
      return <h2>No Sample HITs</h2>;
    }
    const currentHit = hitsDetails[this.state.start];
    const result = currentHit.hitResults[0].result;

    console.log("bounded images are showBoundedImages", currentHit);
    let data = currentHit.data;
    downloadImageUrl((url, uid, token) => {
      if (data.includes('hits/images') && !data.includes('http') && !data.includes('https')) {
        data = `${url}${data}?token=${token}&uid=${uid}`;
      }
    });
    console.log("show text", this.state);
    const rects = [];
    const rectCatMap = {};
    const noteMap = {};
    const bbmap = JSON.parse(result);
    let notes = "new";
    if (currentHit.hitResults[0] && currentHit.hitResults[0].notes) {
      notes = currentHit.hitResults[0].notes;
    }
    if (bbmap) {
      for (let index = 0; index < bbmap.length; index++) {
        const points = {};
        points.x1 = bbmap[index].points[0].x;
        points.x2 = bbmap[index].points[1].x;

        points.y1 = bbmap[index].points[0].y;
        points.y2 = bbmap[index].points[1].y;

        rects.push(points);
        rectCatMap[index] = bbmap[index].label;
        noteMap[index] = bbmap[index].notes;
      }
    }
    return (
      <div>
        <br />
        <br />
        <br />
        <div className="d-flex justify-content justify-content-between">
          <div className="col-md-6 col-xs-4">
            <Button
              size="mini"
              color="grey"
              icon
              labelPosition="left"
              onClick={this.previousElement.bind(this, "pervious")}
              disabled={this.state.start === 0}
            >
              <Icon name="left arrow" />
              Previous
            </Button>
          </div>
          <div className="col-xs-4" />
          <div className="col-md-6 col-xs-4">
            <Button
              size="mini"
              color="blue"
              icon
              labelPosition="right"
              onClick={this.nextElement.bind(this, "right")}
              disabled={
                this.state.start < 0 ||
                this.state.start >= this.state.hitsDetails.length - 1
              }
            >
              Next
              <Icon name="right arrow" />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  showClassificationTags = classificationResult => {
    const renderArrs = [];
    for (let index = 0; index < classificationResult.length; index++) {
      const classification = classificationResult[index];
      const classes = classification.classes;
      const classArrs = [];
      for (let jindex = 0; jindex < classes.length; jindex++) {
        classArrs.push(<Label size="mini">{classes[jindex]} </Label>);
      }
      renderArrs.push(
        <div className="well well-sm">
          <label>{classification.name}</label>
          <br />
          {classArrs}
        </div>
      );
    }
    return <div> {renderArrs} </div>;
  };

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  getAnnotatedLabels = (result) => {
    let labelList = [];
    if (result !== "") {
      let jsonObj = JSON.parse(result);
      jsonObj.annotationResult.forEach(element => {
        element.label.map(label => {
          labelList.push(label);
        });
      });

      if (labelList.length > 1) {
        labelList = labelList.filter(this.onlyUnique);
      }
    }
    return labelList;
  }

  sortEntities = (a, b) => {
    a = a.toLowerCase();
    b = b.toLowerCase();
    if (a > b) {
      return 1;
    } else if (a < b) {
      return -1;
    } else if (a === b) {
      return 0;
    }
  }


  getLabels = (entities, options) => {
    if (entities !== undefined) {
      for (var i = 0; i < entities.length; i++) {
        options.push(entities[i].name)
      }

      entities.forEach(element => {
        options = this.getLabels(element.entities, options);
      });
    }
    return options;
  }

  getEntities = (entities) => {
    let options = [];
    entities.map(element => {
      options.push(element.name ? element.name : element.label)
      options = this.getLabels(element.entities, options);
    })
    options = options.filter(this.onlyUnique);
    return options.sort(this.sortEntities);
  }

  getReplaceLetter(size) {
    let result = '';
    for (let i = 0; i < size; i++) {
      result = result + 'Ⓔ';
    }
    return result;
  }

  replaceRange(s, start, end, substitute) {
    var arr = s.split('');
    s = arr.join('');
    return s;
  }

  containsNonLatinCodepoints(s) {
    return /[^\u0000-\u00ff]/.test(s);
  }

  replaceString(text) {
    return text;
  }

  showDocs = hitsDetails => {
    if (hitsDetails && hitsDetails.length === 0) {
      return (
        <div>

          <h2>No Sample HITs</h2>
        </div>

      )
    }
    const currentHit = hitsDetails[this.state.start];
    const result = currentHit.hitResults[0].result;
    let classificationObj = undefined;
    if (this.state.projectDetails && this.state.projectDetails.taskRules) {
      let ruleJson = JSON.parse(this.state.projectDetails.taskRules);
      if ("classification" in ruleJson) {
        classificationObj = ruleJson.classification;
      }
    }

    console.log("bounded images are showDocs", currentHit);
    const data = currentHit.data;
    const annotations = [];
    let classificationResult = undefined;
    let bbmap = undefined;
    console.log("show showPolygonImages", result);
    if (result.length > 0) {
      const resultObject = JSON.parse(result);
      if (classificationObj) {
        if ("annotationResult" in resultObject) {
          bbmap = resultObject.annotationResult;
          classificationResult = resultObject.classificationResult;
        } else {
          bbmap = resultObject;
        }
      } else {
        if ("annotationResult" in resultObject) {
          bbmap = resultObject.annotationResult;
        } else {
          bbmap = resultObject;
        }
      }
      if (bbmap) {
        for (let index = 0; index < bbmap.length; index++) {
          const bb = bbmap[index];
          let colors = [];
          if (this.state.projectDetails.task_type === DOCUMENT_ANNOTATION) {
            colors.push('#A52A2A');
          } else {
            for (let jindex = 0; jindex < bb.label.length; jindex++) {
              colors.push(this.state.entityColorMap[bb.label[jindex]]);
            }
          }

          if (this.containsNonLatinCodepoints(bb.points[0].text)) {
            console.log("inside containsNonLatinCodepoints")
            let selectedText = this.replaceString(bb.points[0].text);
            let strArr = [...bb.points[0].text];
            if (strArr.length === selectedText.length) {
              annotations.push({
                category: bb.label,
                start: bb.points[0].start,
                end: (bb.points[0].start + selectedText.length - 1),
                text: bb.points[0].text,
                id: bb.points[0].start + "-" + (bb.points[0].start + selectedText.length - 1),
                color: colors
              });
            } else {
              annotations.push({
                category: bb.label,
                start: bb.points[0].start,
                end: bb.points[0].end,
                text: bb.points[0].text,
                id: bb.points[0].start + "-" + (bb.points[0].start + selectedText.length),
                color: colors
              });
            }
          }
           else {
            annotations.push({
              category: bb.label,
              start: bb.points[0].start,
              end: bb.points[0].end,
              text: bb.points[0].text,
              id: bb.points[0].start + "-" + bb.points[0].end,
              color: colors
            });
          }
        }
      }
    }
    return (
      <div>
        {classificationResult &&
          this.showClassificationTags(classificationResult)
        }
        {this.showTags(this.state.entities)}
        <br />
        <DocumentAnnotator
          ref={node => (this.docAnnotator = node)}
          annotations={annotations}
          documentText={data}
          taskType={this.state.projectDetails.task_type}
          entityColorMap={this.state.entityColorMap}
          taskRules={this.state.projectDetails.taskRules}
          entities={this.state.entities}
          selectedLabel={this.state.selectedLabel}
        />
        <br />
      </div>
    );
  };

  checkPointsGroupOrNot = (points, count) => {
    if (Array.isArray(points)) {
      count = count + 1;
      count = this.checkPointsGroupOrNot(points[0], count)
    }
    return count;
  }

  showPolygonV2Images = hitsDetails => {
    if (hitsDetails && hitsDetails.length === 0) {
      return <h2>No Sample HITs</h2>;
    }
    const currentHit = hitsDetails[this.state.start];
    const result = currentHit.hitResults[0].result;

    console.log("bounded images are showPolygonV2Images", currentHit);
    let data = currentHit.data;
    downloadImageUrl((url, uid, token) => {
      if (data.includes('hits/images') && !data.includes('http') && !data.includes('https')) {
        data = `${url}${data}?token=${token}&uid=${uid}`;
        console.log("Data for image",data)
      }
    });
    console.log("show text", this.state);
    const rects = [];
    const rectCatMap = {};
    const rectShapeMap = {};
    const notes = {};
    const setDataFlag = true;
    let labelGroupMap = {};
    const rectColorMap = {};


    console.log("show showPolygonImages", result);
    if (result.length > 0) {
      const boundingBoxMap = JSON.parse(result);

      if (boundingBoxMap) {
        let pointIndex = 0;
        for (let index = 0; index < boundingBoxMap.length; index++) {
          let countIndex = Object.keys(labelGroupMap).length;
          let points = boundingBoxMap[index].points;
          let countPoints = this.checkPointsGroupOrNot(points, 0)
          if (countPoints > 2) {
            points.map((element, keyIndex) => {
              rects[pointIndex] = element;
              let labels = [];
              if (typeof boundingBoxMap[index].label === 'string') {
                labels.push(boundingBoxMap[index].label);
              } else {
                labels = boundingBoxMap[index].label;
              }
              rectCatMap[pointIndex] = labels;
              labelGroupMap[pointIndex] = "#" + labels[0] + "_" + countIndex;
              if (boundingBoxMap[index].shape) {
                rectShapeMap[pointIndex] = boundingBoxMap[index].shape;
              }
              if (boundingBoxMap[index].notes) {
                notes[pointIndex] = boundingBoxMap[index].notes;
              }
              if (boundingBoxMap[index].color) {
                rectColorMap[pointIndex] = boundingBoxMap[index].color;
                this.state.hitEntities[pointIndex] = boundingBoxMap[index].color;
              }
              pointIndex = pointIndex + 1;
            })
          } else {
            rects[pointIndex] = boundingBoxMap[index].points;
            let labels = [];
            if (typeof boundingBoxMap[index].label === 'string') {
              labels.push(boundingBoxMap[index].label);
            } else {
              labels = boundingBoxMap[index].label;
            }
            rectCatMap[pointIndex] = labels;
            labelGroupMap[pointIndex] = labels[0];
            if (boundingBoxMap[index].shape) {
              rectShapeMap[pointIndex] = boundingBoxMap[index].shape;
            }
            if (boundingBoxMap[index].notes) {
              notes[pointIndex] = boundingBoxMap[index].notes;
            }
            if (boundingBoxMap[index].color) {
              rectColorMap[pointIndex] = boundingBoxMap[index].color;
              this.state.hitEntities[pointIndex] = boundingBoxMap[index].color;
            }
            pointIndex = pointIndex + 1;
          }
        }

        const { hitKey, selectedHitLabel, hitLabelAction } = this.state
        if (hitLabelAction === 'add' && hitKey !== '') {
          const currentData = rectCatMap[parseInt(hitKey)];
          if (currentData && selectedHitLabel !== '' && !currentData.includes(selectedHitLabel)) {
            currentData.push(selectedHitLabel);
            rectCatMap[hitKey] = currentData;
          }
        } else if (hitLabelAction === 'update') {
          var keys = [...this.state.selectedHitLabelIndex];
          keys.map(value => {
            let currentData = rectCatMap[value];
            if (currentData && selectedHitLabel !== '' && !currentData.includes(selectedHitLabel)) {
              currentData.push(selectedHitLabel);
              rectCatMap[value] = currentData;
            }
          });
        }
      }


    }
    return (
      <div style={{ display: 'flex' }}>
        {
          <ImageAnnotator
            rects={rects}
            notes={notes}
            rectCatMap={rectCatMap}
            rectShapeMap={rectShapeMap}
            noTagSelection={'hide'}
            isSampleData={setDataFlag}
            image={data}
            entityColorMap={this.state.entityColorMap}
            hitEntities={this.state.hitEntities}
            labelGroupMap={labelGroupMap}
          />
        }
      </div>
    );
  };

  showVideoAnnotation = hitsDetails => {
    if (hitsDetails && hitsDetails.length === 0) {
      return <h2>No Sample HITs</h2>;
    }
    const currentHit = hitsDetails[this.state.start];
    const result = currentHit.hitResults[0].result;

    console.log("bounded images are showVideoAnnotation", currentHit);
    const data = currentHit.data;
    console.log("show text", this.state);
    const rects = [];
    const rectCatMap = {};
    const rectShapeMap = {};
    const rectTimeMap = {};
    const endTimeMap = {};
    console.log("show showPolygonImages", result);
    if (result.length > 0) {
      const bbmap = JSON.parse(result);
      if (bbmap) {
        for (let index = 0; index < bbmap.length; index++) {
          rects[index] = [];
          let timeMap = [];
          for (let jindex = 0; jindex < bbmap[index].positions.length; jindex++) {
            rects[index].push(bbmap[index].positions[jindex].points);
            timeMap.push(bbmap[index].positions[jindex].time)
          }
          if (timeMap.length === 1) {
            timeMap.push(undefined);
          }
          rectCatMap[index] = bbmap[index].label;
          if (bbmap[index].shape) {
            rectShapeMap[index] = bbmap[index].shape;
          }
          rectTimeMap[index] = timeMap;
          endTimeMap[index] = bbmap[index].endTime;
        }
      }
    }
    return (
      <div>
        {
          <VideoAnnotator
            rects={rects}
            rectCatMap={rectCatMap}
            rectShapeMap={rectShapeMap}
            rectTimeMap={rectTimeMap}
            endTimeMap={endTimeMap}
            video={data}
            entityColorMap={this.state.entityColorMap}
            fromSample={this.state.fromSample}
          />
        }
        <br />
        <br />
        <br />
        <div className="d-flex justify-content justify-content-between">
          <div className="col-md-6 col-xs-4">
            <Button
              size="mini"
              color="grey"
              icon
              labelPosition="left"
              onClick={this.previousElement.bind(this, "pervious")}
              disabled={this.state.start === 0}
            >
              <Icon name="left arrow" />
              Previous
            </Button>
          </div>
          <div className="col-xs-4" />
          <div className="col-md-6 col-xs-4">
            <Button
              size="mini"
              color="blue"
              icon
              labelPosition="right"
              onClick={this.nextElement.bind(this, "right")}
              disabled={
                this.state.start < 0 ||
                this.state.start >= this.state.hitsDetails.length - 1
              }
            >
              Next
              <Icon name="right arrow" />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  showPolygonImages = hitsDetails => {
    if (hitsDetails && hitsDetails.length === 0) {
      return <h2>No Sample HITs</h2>;
    }
    const currentHit = hitsDetails[this.state.start];
    const result = currentHit.hitResults[0].result;

    console.log("bounded images are showPolygonImages", currentHit);
    let data = currentHit.data;
    downloadImageUrl((url, uid, token) => {
      if (data.includes('hits/images') && !data.includes('http') && !data.includes('https')) {
        data = `${url}${data}?token=${token}&uid=${uid}`;
      }
    });
    console.log("show text", this.state);
    const rects = [];
    let notes = "new";
    if (currentHit.hitResults[0] && currentHit.hitResults[0].notes) {
      notes = currentHit.hitResults[0].notes;
    }
    const rectCatMap = {};
    console.log("show showPolygonImages", result);
    if (result.length > 0) {
      const bbmap = JSON.parse(result);
      if (bbmap) {
        for (let index = 0; index < bbmap.length; index++) {
          rects[index] = bbmap[index].points;
          rectCatMap[index] = bbmap[index].label;
        }
      }
    }
    return (
      <div>
        <br />
        <br />
        <br />
        <div className="d-flex justify-content justify-content-between">
          <div className="col-md-6 col-xs-4">
            <Button
              size="mini"
              color="grey"
              icon
              labelPosition="left"
              onClick={this.previousElement.bind(this, "pervious")}
              disabled={this.state.start === 0}
            >
              <Icon name="left arrow" />
              Previous
            </Button>
          </div>
          <div className="col-xs-4" />
          <div className="col-md-6 col-xs-4">
            <Button
              size="mini"
              color="blue"
              icon
              labelPosition="right"
              onClick={this.nextElement.bind(this, "right")}
              disabled={
                this.state.start < 0 ||
                this.state.start >= this.state.hitsDetails.length - 1
              }
            >
              Next
              <Icon name="right arrow" />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  showTagLine(tagLine, data) {
    const splits = tagLine.split(" ");
    const renderArrs = [];
    try {
      const resultJson = JSON.parse(tagLine);
      const labelMap = {};
      let words = [];
      if (data) {
        words = data.split(" ");
      }
      for (let index = 0; index < resultJson.length; index++) {
        const points = resultJson[index].points;
        const text = points[0].text;
        const entity = resultJson[index].label[0];
        labelMap[text] = entity;
      }
      for (let index = 0; index < words.length; index++) {
        let word = words[index];
        if (word.length > 0) {
          let color = "grey";
          let entity = "";
          if (word in labelMap) {
            color = this.state.entityColorMap[labelMap[word]];
            entity = labelMap[words[index]];
          }
          renderArrs.push(
            <Label
              name={index}
              style={{ color: "white", backgroundColor: color }}
              key={index}
            >
              <span name={index} key={index}>
                {word}
              </span>
              {entity !== "" && (
                <p className={"entityTag"} style={{ display: "inline" }}>
                  &nbsp; {entity}
                </p>
              )}
            </Label>
          );
        }
      }
    } catch (exception) {
      for (let index = 0; index < splits.length; index++) {
        let word = splits[index].trim();
        if (word.length > 0) {
          const wordSplits = word.split("____");
          let color = "grey";
          let entity = "";
          if (wordSplits.length > 1) {
            color = this.state.entityColorMap[wordSplits[1]];
            entity = wordSplits[1];
            word = wordSplits[0];
          }
          renderArrs.push(
            <Label
              name={index}
              style={{ color: "white", backgroundColor: color }}
              key={index}
            >
              <span name={index} key={index}>
                {word}
              </span>
              {entity !== "" && (
                <p className={"entityTag"} style={{ display: "inline" }}>
                  &nbsp; {entity}
                </p>
              )}
            </Label>
          );
        }
      }
    }

    return <div className={"posResultArea"} id="pos_wrap">{renderArrs}</div>;
  }

  showExtra = extra => {
    console.log("extra data is", extra);
    const arrs = [];
    for (const k1 of Object.keys(extra)) {
      arrs.push(
        <STable.Row>
          <STable.Cell textAlign="left" className="h5 bold">
            {k1}
          </STable.Cell>
          <STable.Cell className="h6">{extra[k1]}</STable.Cell>
        </STable.Row>
      );
    }

    return (
      <STable
        celled
        color="blue"
        key="blue"
        size="small"
        compact="very"
        collapsing
      >
        <STable.Body>{arrs}</STable.Body>
      </STable>
    );
  };
  showSummaries = (hitsDetails, type) => {
    if (hitsDetails && hitsDetails.length === 0) {
      return <h2>No Sample HITs</h2>;
    }
    console.log("show hits details ", hitsDetails);
    const currentHit = hitsDetails[this.state.start];
    const data = currentHit.data;
    const result = currentHit.hitResults[0].result;
    let title = "Summaries";
    if (type === TEXT_MODERATION) {
      title = "Moderated Text";
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Segment basic className="p-0">
          <h5>Text</h5>
          <p className={"dataArea"}>{data}</p>
        </Segment>
        <br />
        <div style={{ paddingBottom: "5%" }}>
          <h5>{title}</h5>
          <p className={"resultArea"}>{result}</p>
        </div>
        <div className="d-flex justify-content justify-content-between col-md-12">
          <div className="col-md-6 col-xs-4">
            <Button
              size="mini"
              color="grey"
              icon
              labelPosition="left"
              onClick={this.previousElement.bind(this, "pervious")}
              disabled={this.state.start === 0}
            >
              <Icon name="left arrow" />
              Previous
            </Button>
          </div>
          <div className="col-md-6">
            <Button
              size="mini"
              color="blue"
              icon
              labelPosition="right"
              onClick={this.nextElement.bind(this, "right")}
              disabled={
                this.state.start < 0 ||
                this.state.start >= this.state.hitsDetails.length - 1
              }
            >
              Next
              <Icon name="right arrow" />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  archiveSelectedProject = () => {
    // permanent delete
    const that = this
    const confirm = AntModal.confirm;
    console.log("this.props : ", this.props)
    confirm({
      title: 'Confirmation',
      content: 'Are you sure you want to archive the project \'' + this.props.projectDetails.name + '\'?',
      okText: 'OK',
      cancelText: 'CANCEL',
      onOk() { that.deleteProject() },
      onCancel() { that.cancelDelete(); },
    });
  }

  clearMachinePredictionOpen = () => {
    const that = this
    const confirm = AntModal.confirm;
    confirm({
      title: 'Clear Machine Prediction',
      content: 'Do you want to clear machine prediction ?',
      okText: 'OK',
      cancelText: 'CANCEL',
      onOk() { that.runPredictionClearConfirm() },
      onCancel() { that.setState({ confirmModalVisible: false }); },
    });
  }

  runPredictionClearConfirm = () => {
    const type = "clearAll"
    this.setState({ confirmModalVisible: false });
    clearMachinePrediction(this.props.currentProject, null, type, this.clearMachinePredictionCallback.bind(this, () => { }))
  }

  clearMachinePredictionCallback = () => {
    showAlert("All Machine Prediction Cleared for this project.", "success");
  }

  runModelPredictionOpen = () => {
    const that = this
    const confirm = AntModal.confirm;
    confirm({
      title: 'Run Model Prediction',
      content: 'Do you want to run model prediction ?',
      okText: 'OK',
      cancelText: 'CANCEL',
      onOk() { that.runModelConfirm() },
      onCancel() { that.setState({ confirmModalVisible: false }); },
    });
  }

  runModelConfirm = () => {
    this.props.setloaderMenu(true);
    this.setState({ loading: true })
    runModelPrediction(this.props.currentProject, this.predictionCallback.bind(this))
  }

  predictionCallback() {
    this.props.setloaderMenu(false);
    this.setState({ loading: false, confirmModalVisible: false })
    showAlert("AI-assisted prediction is in-progress for the project. Please wait for atleast 5 minutes before start working with the project.", "success")
  }

  cancelDelete = () => {

  }

  render() {
    const { projectDetails, successModal, hitsDetails, entities } = this.state;
    const path =
      "/projects/" +
      this.props.params.orgName +
      "/" +
      this.props.params.projectName +
      "/" +
      this.props.params.projectId +
      "/";
    let permissions = {};
    let createdDate = "";
    let taggingProgress = 0;
    let disabledError = "Please login to start tagging";
    if (this.props.user) {
      disabledError = "You don't have required permission";
    }
    if (projectDetails && projectDetails.hasSubscriptionExpired) {
      disabledError = "Please renew subscription to continue";
    }
    let pageTitle = "Project stats";
    let pageDescription =
      "Just upload your data, invite your team members and start tagging. The best way to tag training/evaluation data for your machine learning projects.";
    let extra = "";
    if (
      hitsDetails &&
      hitsDetails.length > 0 &&
      this.state.start >= 0 &&
      hitsDetails[this.state.start].extras
    ) {
      console.log("extra data", hitsDetails, hitsDetails.len);
      extra = JSON.parse(hitsDetails[this.state.start].extras);
    }
    if (projectDetails && projectDetails.permissions) {
      taggingProgress = Number(
        (projectDetails.totalHitsDone * 100) /
        projectDetails.totalHits
      ).toFixed(0);
      if (projectDetails.totalHits === 0) taggingProgress = 0;
      permissions = projectDetails.permissions;
      if (projectDetails.created_timestamp) {
        createdDate = timeConverter(projectDetails.created_timestamp / 1000);
      }
      pageTitle = projectDetails.name;
      if (projectDetails.shortDescription) {
        pageDescription = projectDetails.shortDescription;
        pageTitle = pageTitle + " - " + projectDetails.shortDescription;
      }
      if (projectDetails.subtitle) {
        pageTitle = pageTitle + " - " + projectDetails.subtitle;
      }
    }

    const scriptInnerHTML = `
                  {
                    "@context": "http://schema.org",
                  }
                `;
    const schema = {
      type: "application/ld+json",
      innerHTML: scriptInnerHTML
    };
    let currentpPage = 1
    let currentwPage = 1
    let isFilter = localStorage.getItem('isFilter');
    let projectType;
    if (isFilter == 1) {
      projectType = localStorage.getItem('projectType');
    }
    currentpPage = localStorage.getItem('currentProjectPage');
    if (this.props.location !== undefined && this.props.location.search != '') {
      let querystr = queryString.parse(this.props.location.search)
      currentwPage = (querystr.currentwPage !== undefined && querystr.currentwPage !== '') ? parseInt(querystr.currentwPage) : 1
    }
    return (
      <div
        className="taggerPages pr_project_detail"
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "3%",
          paddingRight: "3%",
        }}
      >
        <Helmet script={[schema]} title={pageTitle}>
          <meta property="og:title" content={pageTitle} />
          <meta name="description" content={pageDescription} />
          <meta property="og:description" content={pageDescription} />
        </Helmet>
        {(this.state.projectDetailsError ||
          this.state.currentProject === "-1") && (
          <div className="text-center">
            <h3>{this.state.projectDetailsError}</h3>
          </div>
        )}
        {!projectDetails && (
          <div className="overlay_dom">
            <div className="cssload-wraper">
              <div className="cssload-dots"></div>
            </div>
          </div>
        )}
        {projectDetails && (
          <div>
            <Segment
              basic
              vertical
              size="large"
              className={"title"}
              loading={this.state.loading}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="f-column">
                  <div className="f-center">
                    <div
                      className="m-r-5"
                      onClick={() => {
                        let backUrl = "/workspace";
                        if (
                          getactiveName() !== "shared" &&
                          parseInt(getUidToken().roleId) === 2
                        ) {
                          backUrl =
                            "/workspace/" +
                            projectDetails.workspaceName +
                            "/" +
                            projectDetails.workspaceId +
                            "/" +
                            projectDetails.orgId +
                            "/" +
                            (getactiveName() === "home" ? "All" : "Archived") +
                            "?currentwPage=" +
                            currentwPage +
                            "&currentpPage=" +
                            currentpPage;
                        } else {
                          backUrl =
                            "/projects/" +
                            3 +
                            "/list?currentwPage=" +
                            currentwPage +
                            "&currentpPage=" +
                            currentpPage;
                        }
                        this.props.pushState(backUrl);
                      }}
                    >
                      <span className="t_btn_back">
                        <img alt="back" src={BACK} />
                      </span>
                    </div>
                  </div>

                  <div className="breadcrum-sec">
                    <div className="sec_holder">
                      <div
                        className={
                          "tilte_display fw-400 " +
                          (projectDetails.orgName.length > 15
                            ? "pro_width"
                            : "")
                        }
                        title={projectDetails.orgName}
                      >
                        {projectDetails.orgName}
                      </div>
                      {(parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN ||
                        parseInt(getUidToken().roleId) ===
                          ROLES_TYPES.ANNOTATOR) &&
                        getactiveName() !== "shared" &&
                        breadcrumbSeperate()}
                      {(parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN ||
                        parseInt(getUidToken().roleId) ===
                          ROLES_TYPES.ANNOTATOR) &&
                        getactiveName() !== "shared" && (
                          <a
                            className={
                              "tilte_display fw-400 " +
                              (projectDetails.workspaceName.length > 15
                                ? "pro_width"
                                : "")
                            }
                            href={
                              "/workspace/" +
                              projectDetails.workspaceName +
                              "/" +
                              projectDetails.workspaceId +
                              "/" +
                              projectDetails.orgId +
                              "/All?currentwPage=" +
                              currentwPage +
                              "&currentpPage=" +
                              currentpPage
                            }
                            onClick={(event) => {
                              let queryParam = "";
                              console.log("Abdul", this.props);
                              if (isFilter == 0) {
                                queryParam =
                                  "currentwPage=" +
                                  currentwPage +
                                  "&currentpPage=" +
                                  currentpPage +
                                  "&isFilter=" +
                                  isFilter;
                              } else {
                                queryParam =
                                  "projectType=" +
                                  projectType +
                                  "&currentwPage=" +
                                  currentwPage +
                                  "&currentpPage=" +
                                  currentpPage +
                                  "&isFilter=" +
                                  isFilter;
                              }
                              this.props.pushState(
                                "/workspace/" +
                                  projectDetails.workspaceName +
                                  "/" +
                                  projectDetails.workspaceId +
                                  "/" +
                                  projectDetails.orgId +
                                  "/All?" +
                                  queryParam
                              );
                              event.preventDefault();
                            }}
                            title={projectDetails.workspaceName}
                          >
                            {" "}
                            {projectDetails.workspaceName}{" "}
                          </a>
                        )}

                      {breadcrumbSeperate()}

                      <span
                        className={
                          "tilte_display fw-400 active " +
                          (projectDetails.name.length > 15 ? "pro_width" : "")
                        }
                        title={projectDetails.name}
                      >
                        {projectDetails.name}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="project_set">
                  {projectDetails && projectDetails.contributorDetails && (
                    <div className="project_dec">
                      <h4 className="txt-captalize">
                        {projectDetails.visibility_type}{" "}
                      </h4>
                      <h4>{getTaskType(projectDetails)} </h4>
                      <h4>Created On {createdDate} </h4>
                      <h4>
                        {projectDetails.contributorDetails.length}{" "}
                        Contributor(s)
                      </h4>
                    </div>
                  )}

                  <Dropdown
                    icon="options"
                    button
                    className="icon mini ctrl_icon dropdown_mat "
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item
                        style={{
                          display: displaySettingsByRole(getUidToken().roleId),
                        }}
                        disabled={!permissions.canUploadData}
                        onClick={this.openScreen.bind(this, "edit", "file")}
                      >
                        {" "}
                        <Icon name="add circle" color="blue" /> Add Data
                      </Dropdown.Item>
                      {(this.state.projectDetails.task_type ===
                        "IMAGE_CLASSIFICATION" ||
                        this.state.projectDetails.task_type ===
                          "POS_TAGGING_GENERIC") && (
                        <Dropdown.Item
                          style={{
                            display: displaySettingsByRole(
                              getUidToken().roleId
                            ),
                          }}
                          disabled={!permissions.canUploadData}
                          onClick={this.openScreen.bind(this, "edit", "model")}
                        >
                          {" "}
                          <Icon name="add square" color="blue" /> Add Model
                        </Dropdown.Item>
                      )}

                      {((this.state.projectDetails.uploadModel &&
                        this.state.projectDetails.task_type ===
                          "IMAGE_CLASSIFICATION") ||
                        this.state.projectDetails.task_type === "POS_TAGGING" ||
                          this.state.projectDetails.task_type ===
                            "TEXT_CLASSIFICATION" ||
                          this.state.projectDetails.task_type ===
                            "POS_TAGGING_GENERIC") && (
                        <Dropdown.Item
                          style={{
                            display: displaySettingsByRole(
                              getUidToken().roleId
                            ),
                          }}
                          disabled={!permissions.canUploadData}
                          onClick={this.runModelPredictionOpen}
                        >
                          {" "}
                          <Icon name="play" color="blue" /> Run Model Prediction
                        </Dropdown.Item>
                      )}

                      {this.state.projectDetails.mPrediction &&
                        (this.state.projectDetails.task_type ===
                          "TEXT_CLASSIFICATION" ||
                          this.state.projectDetails.task_type ===
                            "IMAGE_CLASSIFICATION" ||
                          this.state.projectDetails.task_type ===
                            "POS_TAGGING_GENERIC" ||
                          this.state.projectDetails.task_type ===
                            "POS_TAGGING") && (
                          <Dropdown.Item
                            style={{
                              display: displaySettingsByRole(
                                getUidToken().roleId
                              ),
                            }}
                            disabled={!permissions.canUploadData}
                            onClick={this.clearMachinePredictionOpen}
                          >
                            {" "}
                            <Icon name="refresh" color="blue" /> Clear Machine
                            Prediction
                          </Dropdown.Item>
                        )}

                      <Dropdown.Item
                        style={{
                          display: displaySettingsByRole(getUidToken().roleId),
                        }}
                        disabled={!permissions.canEditProject}
                        onClick={this.openScreen.bind(this, "edit", "label")}
                      >
                        {" "}
                        <Icon name="edit" color="blue" />
                        Edit Project
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={!permissions.canSeeCompletedHITs}
                        onClick={this.openScreen.bind(this, "overview", "done")}
                      >
                        {" "}
                        <Icon name="database" color="blue" />
                        HITs Done
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={!permissions.canSeeCompletedHITs}
                        onClick={this.openScreen.bind(
                          this,
                          "overview",
                          "skipped"
                        )}
                      >
                        {" "}
                        <Icon name="mail forward" color="blue" />
                        HITs Skipped
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={!permissions.canDownloadData}
                        onClick={this.openScreen.bind(this, "export")}
                      >
                        <Icon name="download" color="blue" />
                        Download
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={{ display: displaySettingsByRole(getUidToken().roleId) }}
                        disabled={!permissions.canInviteCollaborators}
                        onClick={this.openScreen.bind(this, "contributors")}
                      >
                        {" "}
                        <Icon name="users" color="blue" /> Contributors
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={{
                          display: displaySettingsByRole(getUidToken().roleId),
                        }}
                        disabled={!permissions.canDeleteProject}
                        onClick={this.archiveSelectedProject}
                      >
                        <Icon name="file archive" color="blue" />
                        Archive
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="project_view">
                <div style={{display:"flex",flexDirection:"column"}}>
                <div className="p_view">
                  <div className="p_title">
                    <img alt="overview" src={OVERVIEW} />
                    <h3>Overview</h3>
                  </div>
                  <div className="det_box">
                    <div className="DBBody">
                      {projectDetails && projectDetails.contributorDetails && (
                        <div className="DBStatus">
                          <h5> HITs Progress </h5>
                          <div className="statusBar">
                            <Progress
                              color="green"
                              size="large"
                              indicating
                              percent={taggingProgress}
                              precision={2}
                              progress="percent"
                              style={{ fontSize: "0.75rem", height: "1em" }}
                            />
                          </div>
                          <span className="statusCount">
                            {taggingProgress}% Completed
                          </span>
                        </div>
                      )}
                    </div>
                    {projectDetails && projectDetails.contributorDetails && (
                      <div className="count_set">
                        <div>
                          <Statistic.Group
                            size="mini"
                            widths="eight"
                            className="count_holder"
                          >
                            <Statistic
                              color="green"
                              size="mini"
                              as="a"
                              href={
                                projectDetails.task_type === DICOM_SEGMENTATION
                                  ? path + "view?type=done"
                                  : path + "space?type=done"
                              }
                              onClick={(event) => {
                                this.openScreen("overview", "done");
                                event.preventDefault();
                              }}
                            >
                              <Statistic.Value>
                                {projectDetails.totalHitsDone}
                              </Statistic.Value>
                              <Statistic.Label style={statsLabel}>
                                HITs Done
                              </Statistic.Label>
                            </Statistic>
                            <Statistic
                              color="violet"
                              as="a"
                              size="mini"
                              href={
                                projectDetails.task_type === DICOM_SEGMENTATION
                                  ? path + "view?type=skipped"
                                  : path + "space?type=skipped"
                              }
                              onClick={(event) => {
                                this.openScreen("overview", "skipped");
                                event.preventDefault();
                              }}
                            >
                              <Statistic.Value>
                                {projectDetails.totalHitsSkipped}
                              </Statistic.Value>
                              <Statistic.Label style={statsLabel}>
                                HITs Skipped
                              </Statistic.Label>
                            </Statistic>
                            <Statistic
                              color="red"
                              size="mini"
                              as="a"
                              href={
                                projectDetails.task_type === DICOM_SEGMENTATION
                                  ? path + "view?type=deleted"
                                  : path + "space?type=deleted"
                              }
                              onClick={(event) => {
                                this.openScreen("overview", "deleted");
                                event.preventDefault();
                              }}
                            >
                              <Statistic.Value>
                                {projectDetails.totalHitsDeleted}
                              </Statistic.Value>
                              <Statistic.Label style={statsLabel}>
                                HITs Deleted
                              </Statistic.Label>
                            </Statistic>
                            <Statistic
                              size="mini"
                              as="a"
                              href={path + "space?type=all"}
                              onClick={(event) => {
                                this.openScreen("overview", "all");
                                event.preventDefault();
                              }}
                            >
                              <Statistic.Value color="blue">
                                {projectDetails.totalHits}
                              </Statistic.Value>
                              <Statistic.Label style={statsLabel}>
                                Total HITs
                              </Statistic.Label>
                            </Statistic>
                          </Statistic.Group>
                        </div>
                        {(projectDetails.totalEvaluationInCorrect > 0 ||
                          projectDetails.totalEvaluationCorrect > 0) && (
                          <div
                            id="eva_holder"
                            style={{ textAlign: "left", marginTop: "10px" }}
                          >
                            <h3 className="m-b-10">Evaluation Stats </h3>
                            <Statistic.Group size="mini" widths="four">
                              <Statistic
                                className="label_box"
                                color="green"
                                size="mini"
                                as="a"
                                href={path + "space?type=correct"}
                                onClick={(event) => {
                                  this.openScreen("overview", "correct");
                                  event.preventDefault();
                                }}
                              >
                                <Statistic.Value>
                                  {projectDetails.totalEvaluationCorrect}
                                </Statistic.Value>
                                <Statistic.Label style={statsLabel}>
                                  Correct
                                </Statistic.Label>
                              </Statistic>
                              <Statistic
                                className="label_box"
                                color="red"
                                size="mini"
                                as="a"
                                href={path + "space?type=incorrect"}
                                onClick={(event) => {
                                  this.openScreen("overview", "incorrect");
                                  event.preventDefault();
                                }}
                              >
                                <Statistic.Value>
                                  {projectDetails.totalEvaluationInCorrect}
                                </Statistic.Value>
                                <Statistic.Label style={statsLabel}>
                                  Incorrect
                                </Statistic.Label>
                              </Statistic>
                            </Statistic.Group>
                          </div>
                        )}
                      </div>
                    )}

                    {projectDetails && (
                      <div className="label_set p-2">
                        <a
                          href={path + "visualize"}
                          disabled={!permissions.canSeeInsights}
                          onClick={(event) => {
                            this.openScreen("visualize");
                            event.preventDefault();
                          }}
                        >
                          <img alt="chart" src={CHART} />
                          <span> Insights</span>
                        </a>
                        {(projectDetails.task_type === DICOM_SEGMENTATION ||
                          projectDetails.task_type ===
                            DICOM_CLASSIFICATION) && (
                          <a
                            style={{
                              display:
                                parseInt(getUidToken().roleId) ===
                                ROLES_TYPES.QA
                                  ? "none"
                                  : "block",
                            }}
                            href={path + "view"}
                            disabled={
                              (parseInt(getUidToken().roleId) ===
                                ROLES_TYPES.ADMIN ||
                                parseInt(getUidToken().roleId) ===
                                  ROLES_TYPES.ANNOTATOR) &&
                              !permissions.canCompleteHITs
                            }
                            onClick={(event) => {
                              this.openScreen("view", "leaderBoard");
                              event.preventDefault();
                            }}
                          >
                            <img alt="tag" src={TAG} />
                            <span> Start tagging</span>
                          </a>
                        )}
                        {projectDetails.task_type !== DICOM_SEGMENTATION &&
                          projectDetails.task_type !== DICOM_CLASSIFICATION && (
                            <a
                              style={{
                                display:
                                  parseInt(getUidToken().roleId) ===
                                  ROLES_TYPES.QA
                                    ? "none"
                                    : "block",
                              }}
                              href={path + "space"}
                              disabled={
                                (parseInt(getUidToken().roleId) ===
                                  ROLES_TYPES.ADMIN ||
                                  parseInt(getUidToken().roleId) ===
                                    ROLES_TYPES.ANNOTATOR) &&
                                !permissions.canCompleteHITs
                              }
                              onClick={(event) => {
                                this.openScreen("space", "leaderBoard");
                                event.preventDefault();
                              }}
                            >
                              <img alt="tag" src={TAG} />
                              <span> Start tagging</span>
                            </a>
                          )}
                          <a 
                          disabled={!permissions.canInviteCollaborators}
                          onClick={this.openInviteModal}
                          style={{
                            display: displaySettingsByRole(
                              getUidToken().roleId
                            ),
                          }}>
                          <div
                        >
                          <img alt="plus" src={PLUS} />
                          <span> Add Contributor</span>
                        </div>
                          </a>
                       
                      </div>
                    )}
                  </div>
                </div>

                <div className="p_view">
                  <div className="p_title">
                    <img alt="wchart" src={WCHART} />
                    <h3>Leaderboard</h3>
                  </div>
                  {projectDetails &&
                    projectDetails.contributorDetails &&
                    projectDetails.contributorDetails.length > 0 && (
                      <div className="table_view customLeaderBoardTable">
                        <Table striped condensed hover responsive>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Status</th>
                              <th>Total Tagged HITs</th>
                              {(this.state.projectDetails.task_type === 'IMAGE_POLYGON_BOUNDING_BOX_V2'
                                || this.state.projectDetails.task_type === 'IMAGE_SEGMENTATION'
                                || this.state.projectDetails.task_type === IMAGE_MASKING
                                || this.state.projectDetails.task_type === 'IMAGE_CLASSIFICATION'
                                || this.state.projectDetails.task_type === DOCUMENT_ANNOTATION
                                || this.state.projectDetails.task_type === DICOM_CLASSIFICATION
                                || this.state.projectDetails.task_type === DICOM_SEGMENTATION
                                || this.state.projectDetails.task_type === TEXT_CLASSIFICATION) ?
                                <th>Total Label Count</th> : ''}
                              <th>Time(s) / HIT</th>
                              {!projectDetails.projectContributor && this.isShowActions(projectDetails) &&
                                <th>Actions</th>}
                              {!projectDetails.projectContributor && this.isShowActions(projectDetails) &&
                                <th>Share Date</th>}
                              {!projectDetails.projectContributor && this.isShowActions(projectDetails) &&
                                <th>Unshare Date</th>}
                            </tr>
                          </thead>
                          {this.getContributorsData(projectDetails)}
                        </Table>
                      </div>
                    )}
                </div>
                </div>

                <div className="p_view">
                  <SampleData
                  propsState={this.state}
                  propsData={this.props}
                  projectDetails={projectDetails}
                  hitsDetails={hitsDetails}
                  extra={extra}
                  entities={entities}
                  showExtra={this.showExtra}
                  showTags={this.showTags}
                  showPolygonV2Images={this.showPolygonV2Images}
                  nextElement={this.nextElement}
                  previousElement={this.previousElement}
                  showSummaries={this.showSummaries}
                  showClassifications={this.showClassifications}
                  showPosTags={this.showPosTags}
                  showBoundedImages={this.showBoundedImages}
                  showPolygonImages={this.showPolygonImages}
                  showVideoAnnotation={this.showVideoAnnotation}
                  showClassificationImages={this.showClassificationImages}
                  showDocs={this.showDocs}
                  />

                </div>
              </div>
            </Segment>
          </div>
        )}
        {this.state.inviteModal && (
          <ShareProjects
            submitEmail={this.inviteByEmail}
            modalOpen={this.openModal}
            orgId={projectDetails ? projectDetails.orgId : null}
            modalClose={this.closeModal}
          />
        )}
        {this.state.showDeleteConfirmation && (
          <div>
            <Modal.Dialog>
              <Modal.Header>
                <Modal.Title>Delete Project</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to archive the project?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    this.setState({ showDeleteConfirmation: false });
                  }}
                >
                  No
                </Button>
                <Button
                  negative
                  onClick={() => {
                    this.deleteProject().bind(this);
                  }}
                >
                  Yes
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )}
        {successModal && (
          <div className="static-modal" style={{ marginTop: "50px" }}>
            <Modal.Dialog>
              <Modal.Header>
                <Modal.Title>Success</Modal.Title>
              </Modal.Header>
              <Modal.Body>{this.state.successMessage}</Modal.Body>

              <Modal.Footer>
                <Button bsStyle="success" onClick={this.close}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )}
      </div>
    );
  }
}


const mapStateToProps = (state) => {
    return {
      user: state.loginReducer.user, 
      currentPathOrg: state.projectReducer.currentPathOrg,
      currentPathProject: state.projectReducer.currentPathProject,
      currentProject: state.projectReducer.currentProject,
      projectDetails: state.projectReducer.projectDetails,
      sampleHits: state.projectReducer.sampleHits
  };
  };

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      uploadDataForm,
      setloaderMenu,
      getUserHomeData,
      setCurrentProject,
      updateProjectDetails,
      updatePrevPageDeatails,
      pushState: push
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(TaggerOrgProject);