import React, { Component } from 'react';
import Outline from '../../Components/Outline/Outline';
class Layout extends Component {
  render() {
    const { children, user, projects, ...rest } = this.props;
    return (
      <Outline user={user} projects={projects} {...rest}>
        {children}
      </Outline>
    );
  }
}

export default Layout;