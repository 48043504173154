import React, { Component } from 'react';
import Helmet from 'react-helmet';
import {  Table,  Pagination } from 'semantic-ui-react';
import { Icon as AntIcon } from 'antd';
import { pageSize } from '../../helpers/Constants';
import { getOrgId,showAlert } from '../../helpers/commonFunctions';
import { getDeleteHistory } from '../../Services/WorkspaceApis';
import moment from 'moment';
import { fromDateTimestamp, toDateTimestamp } from '../../helpers/Utils';
import SingleDatePicker from '../../Components/SingleDatePicker.js/SingleDatePicker';

class DeleteHistory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deleteHistorys: [],
      hasData:false,
      query: '',
      enableToDate: true,
      showDateError: false,
      offset: 0,
      activePage: 1,
      activePageForRequest: 0,
      boundaryRange: 1,
      siblingRange: 1,
      showEllipsis: true,
      showFirstNav: false,
      showPreviousNav: false,
      showLastNav: true,
      showNextNav: true,
      projectsTotal: 0,
      totalPages: 0,
      orderBy:'deletedTimestamp',
      sortOrder: 'desc',
      toggle: 0
    };
    this.getDeleteHistoryCallback = this.getDeleteHistoryCallback.bind(this);
    this.setFromSelectedDate = this.setFromSelectedDate.bind(this);
    this.setToSelectedDate = this.setToSelectedDate.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
  }

  handleOnDeletedProjects = (event) => {
    const query = event.target.value;
    this.setState({ query });
    if(event.target.value.length === 0){
      getDeleteHistory(getOrgId(), 0, pageSize, this.getDeleteHistoryCallback.bind(this), this.state.query?this.state.byAll:null,null,null,this.state.orderBy, this.state.sortOrder);
    }
  };

  searchDeletedProjects = (e) => {
    const {query,orderBy,sortOrder} = this.state
    e.preventDefault();
    getDeleteHistory(getOrgId(), 0, pageSize, this.getDeleteHistoryCallback.bind(this), query,null,null,orderBy, sortOrder);
  }

  componentDidMount() {
    const {query,activePageForRequest} = this.state
    getDeleteHistory(getOrgId(), activePageForRequest, pageSize, this.getDeleteHistoryCallback.bind(this), query,null,null, 'createdTimestamp', 'desc');
  }

  getDeleteHistoryCallback(error, response) {
    if (!error) {
      this.setState({
        deleteHistorys: response.deleteHistorys, totalPages: response.totalPages
      });
    } else {
      showAlert('error', 'error');
    }
  }

  setFromSelectedDate(selectedFromDate, cDate) {
    const {query,orderBy,sortOrder,activePageForRequest,tocDate} = this.state
    this.clearFromDisable = false;
    if (selectedFromDate === undefined) {
      this.clearFromDisable = true;
      this.setState({ fromcDate: undefined })
    }
    else {
      this.setState({ fromDate: selectedFromDate })
      this.changeToDateVal = true
      this.setState({ fromcDate: cDate })
    }
        
    let fromDate = selectedFromDate !== undefined ? fromDateTimestamp(new Date(cDate)) : undefined
    let toDate = tocDate !== undefined ? toDateTimestamp(new Date(tocDate)) : toDateTimestamp(new Date())
    if (fromDate > toDate) {
      showAlert('From Date should not be greater than To Date', 'error');
      return false;
    } else {
      getDeleteHistory(getOrgId(), activePageForRequest, pageSize, this.getDeleteHistoryCallback.bind(this), query, fromDate, toDate,orderBy, sortOrder);
    }
  }

  sortDeleteHistoryTable = (data) =>{
    const {query,orderBy,sortOrder,activePageForRequest} = this.state
        this.setState({ orderBy: data.orderBy, sortOrder: data.sortOrder }, () => {
          getDeleteHistory(getOrgId(), activePageForRequest, pageSize, this.getDeleteHistoryCallback.bind(this), query,null,null,orderBy, sortOrder);
        });
      }

  sortColumnTable = (data) =>{
    const {query,orderBy,sortOrder,activePageForRequest} = this.state
         if(sortOrder === "desc"){
        this.setState({ orderBy: data.orderBy, sortOrder: "asc"}, () => {
          getDeleteHistory(getOrgId(), activePageForRequest, pageSize, this.getDeleteHistoryCallback.bind(this), query,null,null,orderBy, sortOrder);
        });
      }
        else{
          this.setState({ orderBy: data.orderBy, sortOrder: "desc"}, () => {
            getDeleteHistory(getOrgId(), activePageForRequest, pageSize, this.getDeleteHistoryCallback.bind(this), query,null,null,orderBy, sortOrder);
          });
        }
      }
    
  
  getSortIcon(fieldName) {
    return (<div className="ant-table-column-sorter float-left pad-6-em"><span className="ant-table-column-sorter-up off" title="↑" onClick={()=>this.sortDeleteHistoryTable({orderBy: fieldName, sortOrder: "asc"})}><i className="anticon anticon-caret-up"></i></span><span className="ant-table-column-sorter-down off" title="↓" onClick={()=>this.sortDeleteHistoryTable({orderBy: fieldName, sortOrder: "desc"})}><i className="anticon anticon-caret-down"></i></span></div>);
  }


  setToSelectedDate(selectedToDate, cDate) {
    const {fromcDate,activePageForRequest,query,orderBy,sortOrder} = this.state
    this.clearToDisable = false;
    if (selectedToDate === undefined) {
      this.clearToDisable = true;
      this.setState({ tocDate: undefined })
    }
    else {
      this.changeToDateVal = false
      this.setState({ toDate: selectedToDate })
      this.setState({ tocDate: cDate })
    }

    let toDate = selectedToDate !== undefined ? toDateTimestamp(new Date(cDate)) : undefined
    let fromDate = fromcDate !== undefined ? toDateTimestamp(new Date(fromcDate)) : undefined
    if(fromDate===undefined && toDate!== undefined ){
      showAlert('From Date should not be Empty', 'error');
      return false;
    }
    if (fromDate > toDate) {
      showAlert('From Date should not be greater than To Date', 'error');
      return false;
    } else {
      getDeleteHistory(getOrgId(), activePageForRequest, pageSize, this.getDeleteHistoryCallback.bind(this), query, fromDate, toDate,orderBy, sortOrder);

    }
  }

  handlePaginationChange = (e, { activePage }) => {
    const {totalPages,query,orderBy,sortOrder} = this.state
    this.setState({
      activePage: activePage,
      activePageForRequest: activePage - 1
    })

    if (activePage === totalPages) {
      this.setState({ showLastNav: false, showNextNav: false })
    } else {
      this.setState({ showLastNav: true, showNextNav: true })
    }

    if (activePage === 1) {
      this.setState({ showFirstNav: false, showPreviousNav: false })
    } else {
      this.setState({ showFirstNav: true, showPreviousNav: true })
    }
    getDeleteHistory(getOrgId(), activePage - 1, pageSize, this.getDeleteHistoryCallback.bind(this), query,null,null, orderBy, sortOrder);
  }


  getTableData() {

    return this.getUsersData(this.state.deleteHistorys)

  }
  getUsersData = (data) => {
    const arrs = [];
    this.state.projectsTotal = data.total;
    // this.setState({projectsTotal:data.total})

    for (let index = 0; index < data.length; index++) {
      if (data[index] !== null) {
        this.hasData=true;
        arrs.push(
          <Table.Row key={index}>
            {(data[index].projectName) && <Table.Cell>
              Project
            </Table.Cell>}
            {(!data[index].projectName) && <Table.Cell>
              Workspace
            </Table.Cell>}
            <Table.Cell>
              {data[index].workspaceName}
            </Table.Cell>
            {data[index].projectName && <Table.Cell>
              {data[index].projectName}
            </Table.Cell>}
            {!data[index].projectName && <Table.Cell>
              -
            </Table.Cell>}
            {data[index].projectName && <Table.Cell style={{ textAlign: "center" }} >
              {data[index].hitsCount}
            </Table.Cell>}
            {!data[index].projectName && <Table.Cell style={{ textAlign: "center" }} >
              -
            </Table.Cell>}
            <Table.Cell>
              {data[index].createdBy}
            </Table.Cell>
            <Table.Cell>
              {data[index].deletedBy}
            </Table.Cell>
            <Table.Cell>
              {moment(new Date(data[index].createdTimestamp)).format('MM-DD-YYYY HH:mm:ss')}
            </Table.Cell>
            <Table.Cell>
              {moment(new Date(data[index].deletedTimestamp)).format('MM-DD-YYYY HH:mm:ss')}
            </Table.Cell>
          </Table.Row>
        );
      }
    }
    return (<Table.Body className="ant-table-tbody">{arrs}</Table.Body>);
  }

  render() {
    const { fromcDate, tocDate,query,showNextNav,showPreviousNav,showLastNav,showFirstNav,showEllipsis,
      totalPages,siblingRange,boundaryRange,activePage,deleteHistorys } = this.state;

    return (
      <div style={{ paddingLeft: '6%', paddingRight: '3%' }} className="m-t-20">
        <Helmet title="Delete History" />
        {
          <div className="title_section flex-between m-b-15 m-t-20 history_title">
            <h2 className="tilte_display fw-600" >Delete History </h2>
            <div className="m-r-15 deleteWrapper">
              <div className="historySection">
                <div className="site_date m-r-10">
                  <label>From Date</label>
                  <SingleDatePicker
                    setSelectedDate={this.setFromSelectedDate.bind(this)}
                    selectedDate={fromcDate}
                    placeholder="From Date"
                    convedDate={this.convedDate}
                    clearDisable={this.clearFromDisable}
                  />
                </div>
                <div className="site_date m-r-10">
                  <label>To Date</label>
                  <SingleDatePicker
                    setSelectedDate={this.setToSelectedDate.bind(this)}
                    selectedDate={tocDate}
                    placeholder="To Date"
                    changeToDateVal={this.changeToDateVal}
                    convedDate={this.convedDate}
                    clearDisable={this.clearToDisable}
                  />
                </div>
              </div>
              <div className="search_h">
                <div className="search_wrap">
                  <div className="search_sec">
                    <div className="search_select">
                      <form className="form_search">
                        <input type="text" className="Search Users searchTerm bdrUnset"
                          placeholder="Search"
                          onChange={this.handleOnDeletedProjects}
                          value={query}
                        />
                        <button type="submit" onClick={(e) => this.searchDeletedProjects(e)} className="searchButton">
                          <AntIcon type="search" />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        <div className="content_section ant-table-wrapper table_workspace wordBreakFix">
          <div style={{ width: '100%' }}>
            <Table compact="very" celled size="small" striped>
              <Table.Header className="ant-table-thead">
                <Table.Row>
                  <Table.HeaderCell><div className="width-100"><span className="float-left">Type</span></div></Table.HeaderCell>
                  <Table.HeaderCell><div className="width-100"><span onClick={()=>this.sortColumnTable({orderBy: "workspaceName"})}  className="float-left">Workspace Name</span>{this.getSortIcon("workspaceName")}</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="width-100"><span onClick={()=>this.sortColumnTable({orderBy: "projectName"})} className="float-left" >Project Name</span>{this.getSortIcon("projectName")}</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="width-100"><span onClick={()=>this.sortColumnTable({orderBy: "hitsCount"})} className="float-left">Total Hits</span>{this.getSortIcon("hitsCount")}</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="width-100"><span onClick={()=>this.sortColumnTable({orderBy: "createdBy"})} className="float-left">Created By</span>{this.getSortIcon("createdBy")}</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="width-100"><span onClick={()=>this.sortColumnTable({orderBy: "deletedBy"})} className="float-left">Deleted By</span>{this.getSortIcon("deletedBy")}</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="width-100"><span onClick={()=>this.sortColumnTable({orderBy: "createdTimestamp"})} className="float-left">Created Date</span>{this.getSortIcon("createdTimestamp")}</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="width-100"><span onClick={()=>this.sortColumnTable({orderBy: "deletedTimestamp"})} className="float-left">Deleted Date</span>{this.getSortIcon("deletedTimestamp")}</div></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {
                deleteHistorys && deleteHistorys.length > 0 &&
                this.getTableData()
              }
              {(deleteHistorys.length>0)?
                <Table.Footer fullWidth>
                  <Table.Row>
                    <Table.HeaderCell colSpan='8' className="noBg">
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ width: '90%', textAlign: 'center' }}>
                          {totalPages > 1 &&
                            <Pagination style={{ position: 'unset' }}
                              activePage={activePage}
                              boundaryRange={boundaryRange}
                              onPageChange={this.handlePaginationChange}
                              size='mini'
                              siblingRange={siblingRange}
                              totalPages={totalPages}
                              ellipsisItem={showEllipsis ? undefined : null}
                              firstItem={showFirstNav ? undefined : null}
                              lastItem={showLastNav ? undefined : null}
                              prevItem={showPreviousNav ? undefined : null}
                              nextItem={showNextNav ? undefined : null}
                            />
                          }
                        </div>
                      </div>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>:
                <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan='8' className="noBg">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <div style={{ width: '90%', textAlign: 'center' }}>
                        <h4>No matching records found</h4>
                      </div>
                    </div>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
              }
            </Table>
          </div>
        </div>
      </div>)
  }
}

export default DeleteHistory;
