// PredectionInsight.js
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Table, LocaleProvider } from 'antd';
import en_US from 'antd/lib/locale-provider/en_US';

import { predictionInsights } from '../../Services/OverviewApis';
const { Column } = Table;

export class PredictionInsights extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          data: null
        }
        this.predictionInsightsFetched = this.predictionInsightsFetched.bind(this)
      }
      componentDidMount() {
          predictionInsights(this.props.projectId, this.predictionInsightsFetched);
       }
      predictionInsightsFetched(error, response) {
        if (!error) {
          let data = [];
          let machinePredictMap = response.body.machinePredictMap;
          let machineUseMap = response.body.machineUseMap;
          if (machinePredictMap) {
           Object.entries(machinePredictMap).forEach((val) => {
             const useMapVal = Object.entries(machineUseMap).filter((useMap) =>  val[0] === useMap[0] )
            data.push({
              label: val[0],
              MachinePredicted: val[1],
              HumanPredicted: useMapVal[0][1]
            });
          })
          this.setState({data})
         }}
        }

  render() {
    const { data } = this.state;
    const paginationPosition =  data !== null && data.length > 5 ? "bottom" : "none";
    const isShowQuickJumper = data !== null && data.length > 5 ? true : false;
    const total = data ? data.length : 0;
    if (!data) {
      return (
        <div className="no_result" style={{paddingBottom : '50px'}}>No Data Available.</div>
      )
    }
    else {
      return (
        <div className="taggerPages">
          <div><h2 className="tilte_display fw-600 m-r-10">MACHINE VS HUMAN PREDICATION</h2></div>
          <div>
          <LocaleProvider locale={en_US}>
            <Table className="table_workspace" dataSource={data}
                   pagination={{
                     defaultPageSize: 5, total: total,
                     position: paginationPosition, showQuickJumper: isShowQuickJumper
                   }}>
              <Column
                title="PREDICTION STATISTICS"
                dataIndex="label"
                key="label"
              />
              <Column
                title="PREDICTION BY MACHINE"
                dataIndex="MachinePredicted"
                key="MachinePredicted"
              />
              <Column
                title="PREDICTION BY HUMAN"
                dataIndex="HumanPredicted"
                key="HumanPredicted"
              />
            </Table>
           </LocaleProvider>
          </div>
          <div>
            <BarChart
              width={1200}
              height={300}
              data={data}
              margin={{
                top: 25, right: 30, left: 20, bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis dataKey="label"/>
              <YAxis/>
              <Tooltip/>
              <Legend/>
              <Bar dataKey="MachinePredicted" fill="#fef568"/>
              <Bar dataKey="HumanPredicted" fill="#7bc623"/>
            </BarChart>
          </div>
        </div>
      );
    }
}
}
