import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import {
  IMAGE_POLYGON_BOUNDING_BOX_V2,
  DEFAULT_PROJECT,
  IMAGE_SEGMENTATION,
} from "../../helpers/Utils";
import PLUS from "../../images/Add-2.svg";
import BACK from "../../images/Arrow_Simple_left1.svg";
import { projectData } from "../../helpers/Constants";
import { logEvent, saveactiveTab } from "../../helpers/commonFunctions";

class CreateProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectState: DEFAULT_PROJECT,
    };
  }

  render() {
    const {location,pushState} = this.props
    const createProject = (type) => {
      logEvent("buttons", "Project Create", type);
      const queryParam = ["workspaceId", "wName", "orgId", "currentwPage", "currentpPage"].reduce((acc, query) => {
        if (location.query[query]) {
          acc[query] = location.query[query]
        }
        return acc
      }, {})
      if (type === "IMAGE_BOUNDING_BOX_V2") {
        queryParam.type = IMAGE_POLYGON_BOUNDING_BOX_V2;
        queryParam.shape = "rectangle";
      } else if (type === IMAGE_POLYGON_BOUNDING_BOX_V2) {
        queryParam.type = IMAGE_SEGMENTATION;
      } else {
        queryParam.type = type;
      }

      pushState({ pathname: "/projects/import", query: queryParam });
    };

    return (
      <div className="pr_project_create m-t-20">
        <div className="project_title f-column">
          <div className="f-center">
            {location.query.workspaceId && (
              <div
                onClick={() => {
                  let backUrl = "/workspace";
                  if (location.query.wName) {
                    backUrl =
                      "/workspace/" +
                      location.query.wName +
                      "/" +
                      location.query.workspaceId +
                      "/" +
                      location.query.orgId +
                      "/All?currentwPage=" +
                      location.query.currentwPage +
                      "&currentpPage=" +
                      location.query.currentpPage;
                  } else {
                    saveactiveTab("home");
                  }
                  pushState(backUrl);
                }}
              >
                <span className="t_btn_back m-r-5">
                  <img alt="back" src={BACK} />
                </span>
              </div>
            )}
            <h2 className="tilte_display fw-600">Create Project </h2>
          </div>
        </div>
        <div className="project_block">
          {projectData.map((value) => {
            return (
              <div key={value.prtitle}>
                <h3 className="sec_tilte">
                  <span>{value.prtitle}</span>
                </h3>
                <ul className="dashboardSet">
                  {value.data.map((projectdet) => {
                    return (
                      <li
                        key={projectdet.value}
                        onClick={
                          value.prtitle !== "CUSTOM ANNOTATIONS"
                            ? () => createProject(projectdet.value)
                            : null
                        }
                      >
                        <div
                          className="DBBox data_set"
                          onClick={
                            value.prtitle !== "CUSTOM ANNOTATIONS"
                              ? () => createProject(projectdet.value)
                              : null
                          }
                        >
                          <span>
                            <h3>{projectdet.title}</h3>
                            <span className="hint_txt">
                              {projectdet.shortdesc}
                            </span>
                            <p>{projectdet.describe}</p>
                          </span>
                          {value.prtitle !== "CUSTOM ANNOTATIONS" ? (
                            <span
                              style={{ cursor: "pointer" }}
                              className="action_btn m-b-0"
                              onClick={() => createProject(projectdet.value)}
                            >
                              <img alt="add" src={PLUS} />
                              <span>CREATE</span>
                            </span>
                          ) : (
                            <a
                              style={{ fontSize: "17px" }}
                              href="mailto:contact@pronotate.com"
                            >
                              contact@pronotate.com
                            </a>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushState: push,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(CreateProject);
