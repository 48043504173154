import React, { PropTypes } from "react";
import { fetchProjectStats,addContributor,deleteProjectDt,removeContributor,changeProjectStatus } from "../../Services/OverviewApis";
import { getUidToken,logEvent,getTaskType,showAlert,getactiveName } from "../../helpers/commonFunctions";
import {CONTRIBUTE_MODE, ROLES, SHARE, UNSHARE} from "../../helpers/Constants";
import ShareProjects from "../../Components/ShareProjects/ShareProjects.js";
import {
    Segment,
    Button,
    Icon,
    Statistic,
    Progress,
    Dropdown,
    Label
  } from "semantic-ui-react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { bindActionCreators } from "redux";
import { updateProjectDetails,setCurrentProject,updatePrevPageDeatails } from "../../redux/reducer/projectReducer";
  import { timeConverter, breadcrumbSeperate, displaySettingsByRole, ROLES_TYPES, DICOM_CLASSIFICATION, DICOM_SEGMENTATION } from "../../helpers/Utils";
  import Table from "react-bootstrap/lib/Table";
  import Modal from "react-bootstrap/lib/Modal";
  import { Icon as AntIcon, Modal as AntModal } from 'antd';
  import BACK from '../../images/Arrow_Simple_left1.svg';
  import OVERVIEW from '../../images/View-2.svg';
  import CHART from '../../images/Chart-7.svg';
  import TAG from '../../images/Badge.svg';
  import PLUS from '../../images/Add-2.svg';
  import WCHART from '../../images/Chart-7-1.svg';
  import STATUS from '../../images/sample_data.svg';
  import unshare from '../../images/unshare.svg';
  import { setloaderMenu } from "../../redux/reducer/projectReducer";
  import {SUCCESS, MESSAGES} from "../../helpers/Constants";
  import queryString from 'query-string'
import "./DicomOverviewContainer.css"
const statsLabel = { textTransform: "initial", fontWeight: "300" };
class DicomOverviewContainer extends React.Component {

    static propTypes = {
        pushState: PropTypes.func,
        currentProject: PropTypes.string,
        setCurrentProject: PropTypes.func
      };

    constructor (props) {
        super (props)
        this.loadProjectDetails = this.loadProjectDetails.bind(this);
        this.projectDetailsFetched = this.projectDetailsFetched.bind(this);
        this.deleteProject = this.deleteProject.bind(this);
        this.projectDeleted = this.projectDeleted.bind(this);
        this.openInviteModal = this.openInviteModal.bind(this);
        this.inviteByEmail = this.inviteByEmail.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.inviteSent = this.inviteSent.bind(this);
        this.projectStatusChangedCallback = this.projectStatusChangedCallback.bind(this);

        let search = new URLSearchParams(this.props.location.search)
        let searchtype = (search !== "") ? search.get("projectType") : ""
        let currentpPageNo = (this.props.location.search !== '' && search !== "") ? parseInt(search.get("currentpPage")) : 1
        let currentwPageNo = (this.props.location.search !== '' && search !== "") ? parseInt(search.get("currentwPage")) : 1
        let isFilter = (this.props.location.search !== '' && search !== "") ? search.get("isFilter") : 0

        this.state = {
            loading : false,
            projectDetails: this.props.projectDetails,
            inviteModal : false,
            inviteInProcess: false,
            statusActiveComplete: false,
            confirmModalVisible: false,
            searchType: searchtype,
            currentpPage: currentpPageNo,
            currentwPage: currentwPageNo,
            isFilter: isFilter,
            currentProject: this.props.params.projectId
        }

    }


    componentWillMount() {
    let isFilter = window.localStorage.getItem('isFilter')
        if( isFilter == undefined) {
            window.localStorage.setItem("isFilter", this.props.location.query.isFilter);
            window.localStorage.setItem("projectType", this.props.location.query.projectType);
            window.localStorage.setItem("currentProjectPage", this.props.location.query.currentpPage);
        }
    }

    componentDidMount () {
        console.log("params",this.props.params)
        this.loadProjectDetails();
    }

    deleteContributor = (projectDetails, userEmail, isShare) => {
        removeContributor(this.state.currentProject, userEmail,  isShare, this.loadProjectDetails);
      }

    isShowActions = projectDetails => {
    const data = projectDetails.contributorDetails
    for (let index = 0; index < data.length; index++) {
        if(data[index].role === "CONTRIBUTOR"){
        return true;
        }
    }
    return false;
    }

    loadProjectDetails (pid) {
      console.log("LoadPRoject detatils ",pid)
      this.props.setloaderMenu(true);
        this.setState({
          loading: true,
          projectDetails: undefined,
          hitsDetails: undefined
        });
        if (pid) {
          fetchProjectStats(pid, this.projectDetailsFetched);
        } else {
        fetchProjectStats(this.state.currentProject, this.projectDetailsFetched);
        }
    }

    projectDetailsFetched(error, response) {
        console.log(" project details fetched ", error, response);
        if (!error) {
          this.props.updateProjectDetails(response.body);
          if(response.body.status === 'COMPLETED'){
        	  this.setState({statusActiveComplete : true})
          }
          this.props.setloaderMenu(false);
          this.setState({
            projectDetails: response.body,
            loading: false,
            projectDetailsError: undefined
          });
        } else {
          if (response && response.body && response.body.message) {
            this.setState({ projectDetailsError: response.body.message });
          } else {
            this.setState({ projectDetailsError: "Error in fetching data" });
          }
        }
      }

    projectStatusClickEvent = (status) => {
        if(status === "reOpened"){
            this.setState({confirmModalVisible: true})
        }else{
            this.projectstatusUpdateCall(status);
        }
    }

    projectstatusUpdateCall = (status) => {
        changeProjectStatus(this.state.currentProject, status,  this.projectStatusChangedCallback);
    }

    showConfirmMessage = (status) => {
        const that = this
        const confirm = AntModal.confirm;
         confirm({
               title: 'Confirmation',
               content: 'Are you sure you want to reopen the project ' + "'"+this.props.params.projectName+"'" +"?" ,
               okText: 'OK',
               cancelText: 'CANCEL',
               onOk() {that.projectstatusUpdateCall(status);},
               onCancel() {that.setState({confirmModalVisible: false});},
             });
    }

    projectStatusChangedCallback(error, response) {
    	 if (!error) {
    	    	const dProjects = response.body;
    		 if(dProjects.status === 'COMPLETED'){
                    this.setState({statusActiveComplete : true})
                    showAlert("You have successfully completed the Project." , 'success');
    	    	}else{
                    this.setState({statusActiveComplete : false, confirmModalVisible:false})
                    showAlert("The project is reopened successfully." , 'success');
    	    	}
    	 }
    }

    openScreenDicom = (screen, type, screenType) =>{
      console.log("openscreen dicom",screen,type,screenType)
    	let documentScreen = 'view'
    	this.props.pushState({
             pathname:
               "/projects/" +
               this.props.params.orgName +
               "/" +
               this.props.params.projectName +
               "/" +
               this.props.params.projectId +
               "/" +
               documentScreen,
               query: { type,screenType }
           });
    }

    openScreen = (screen, type) => {
        console.log("opening screen ", screen);
        logEvent("buttons", "Project " + screen);

        // Edit screen , when user click on  edit data from options
        if (screen === "edit") {
          this.props.pushState({
            pathname:
              "/projects/" +
              this.props.params.orgName +
              "/" +
              this.props.params.projectName +
              "/" +
              this.props.params.projectId +
              "/" +
              "edit",
            query: { type }
          });
        } else if (screen === "overview") {
          let documentScreen = 'view'

              this.props.pushState({
                pathname:
                  "/projects/" +
                  this.props.params.orgName +
                  "/" +
                  this.props.params.projectName +
                  "/" +
                  this.props.params.projectId +
                  "/" +
                  documentScreen,
                  query: { type }
              });

        } else if (screen === "view") {
            console.log("SCREEN view",screen)
          this.props.pushState({
            pathname:
              "/projects/" +
              this.props.params.orgName +
              "/" +
              this.props.params.projectName +
              "/" +
              this.props.params.projectId +
              "/" +
              screen
          });
          this.props.updatePrevPageDeatails(type)
        } else {
          this.props.pushState({
            pathname:
              "/projects/" +
              this.props.params.orgName +
              "/" +
              this.props.params.projectName +
              "/" +
              this.props.params.projectId +
              "/" +
              screen
          });
        }
      };

    removeUnshare = (index, data, isShare) => {
        this.deleteContributor(
        this.state.currentProject,
          data[index].userDetails.email,
          isShare
        );
      }

    getContributorsData = projectDetails => {
        const data = projectDetails.contributorDetails
        const labelcount = projectDetails.hitsContributorDetails;
        let totalLabelCounts = []
        let totalProcessedCount = []
    
        // Since Label count list and hits done list are different, compared using uid, changes added by monica
        for (var i = 0; i < data.length; i++) {
          for (var j = 0; j < labelcount.length; j++) {
            if (labelcount[j].userDetails.uid === data[i].userDetails.uid) {
              totalLabelCounts.push(labelcount[j].hitsStats.totalLabels)
              totalProcessedCount.push(labelcount[j].hitsStats.totalHits)
            }
          }
        }
        const arrs = [];
        let showZero = true;
        if (data && data.length > 10) {
          showZero = false;
        }
        for (let index = 0; index < data.length; index++) {
          if (data[index].hitsDone > 0 || showZero) {
    
            let onClickFn = (isShare) => {
              const that = this
              const confirm = AntModal.confirm;
              console.log("this.props : ", this.props)
              confirm({
                title: 'Confirmation',
                content: (isShare ? SHARE : UNSHARE ) + data[index].userDetails.firstName + ' ' + data[index].userDetails.secondName + '\'?',
                okText: 'OK',
                cancelText: 'CANCEL',
                onOk() { that.removeUnshare(index, data, isShare) },
                onCancel() { that.cancelDelete(); },
              });
            };
    
            arrs.push(
              <tr className="spaceUnder" key={index}>
                <td>{data[index].userDetails.firstName}</td>
                <td>{data[index] && data[index].avrTimeTakenInSec ? data[index].avrTimeTakenInSec : 0}</td>
                <td>{totalProcessedCount[index] ? totalProcessedCount[index] : 0}</td>
                {(this.state.projectDetails.task_type === DICOM_SEGMENTATION ||
                  this.state.projectDetails.task_type === DICOM_CLASSIFICATION) ?
                  <td>{totalLabelCounts[index] ? totalLabelCounts[index] : 0}</td> : ''}
                <td>{(data[index].role === null) ? CONTRIBUTE_MODE.IN_ACTIVE : CONTRIBUTE_MODE.ACTIVE}</td>
                {!projectDetails.projectContributor && data[index].role === ROLES.CONTRIBUTOR &&
                  (data[index].userDetails.is_shared ?
                    <td><Label id="unshare_btn"
                    as="a"
                    size="large"
                    style={{ marginTop: '5px', marginRight: '5px', padding: "5px", color: "white", backgroundColor: "#e84747" }}
                    name={CONTRIBUTE_MODE.UNSHARE}
                    onClick={() => onClickFn(false)}
                  >
                    <img alt="unshare" src={unshare} />
                     {CONTRIBUTE_MODE.UNSHARE}
                  </Label></td>   :
                  <td><Label id="share_btn"
                    as="a"
                    size="large"
                    style={{ marginTop: '5px', marginRight: '5px', padding: "5px", color: "white", backgroundColor: "#e84747" }}
                    name={CONTRIBUTE_MODE.SHARE}
                    onClick={() => onClickFn(true)}
                  >
                    {/* <AntIcon type="share-alt" /> */}
                    <img alt="unshare" src={unshare} />
                     {CONTRIBUTE_MODE.SHARE}
                  </Label></td>)
                }
                {!projectDetails.projectContributor && data[index].role === ROLES.CONTRIBUTOR &&
                <td>{timeConverter(data[index].userDetails.created_timestamp / 1000) }</td>
               }
               {!projectDetails.projectContributor && data[index].role === ROLES.CONTRIBUTOR && !data[index].userDetails.is_shared &&
                <td>{timeConverter(data[index].userDetails.updated_timestamp / 1000) }</td>
               }
               <td></td>
              </tr>
            );
          }
        }
        return <tbody>{arrs}</tbody>;
      };

    archiveSelectedProject = () => {
          const that = this
          const confirm = AntModal.confirm;
           confirm({
               title: 'Confirmation',
               content: 'Are you sure you want to move the project to Archive?',
               okText: 'OK',
               cancelText: 'CANCEL',
               onOk() {that.deleteProject()},
               onCancel() {that.cancelDelete();},
             });
    }


    deleteProject() {
      this.props.setloaderMenu(true);
        this.setState({ loading: true });
        deleteProjectDt(this.state.currentProject, this.projectDeleted);
      this.props.setloaderMenu(false);
        this.setState({ loading: false });
    }

    cancelDelete = () => {

    }

    projectDeleted(error, response) {
        console.log("project deleted ", error, response);
        if (!error) {
          logEvent("buttons", "project delete success");
          this.setState({
            successModal: false,
            showDeleteConfirmation: false,
            redirectToHome: true,
            successMessage: "The project is archived successfully."
          });
          showAlert('The project is archived successfully.', 'success');
          this.close();
        } else {
          logEvent("buttons", "Delete Project fail");
          showAlert(error.message, 'error');
          this.setState({
            error: true,
            showDeleteConfirmation: false,
            redirectToHome: true
          });
        }
    }

    // Add contributor
    openInviteModal(event, data) {
        console.log("open invite modal", event, data);
        logEvent("buttons", "Open invite modal");
        this.setState({ inviteModal: true });
        document.body.classList.add('c-modal');
        event.preventDefault();
    }

    open = () => this.setState({ successModal: true });

    close = () => {
      const {projectDetails,redirectToHome} = this.state
        this.setState({ successModal: false, closeModal: true });
        if (redirectToHome) {
        let pathName='/workspace';
        if( getactiveName() !== 'shared' && (parseInt(getUidToken().roleId)  === 2 && projectDetails.workspaceName ) ){
            pathName = "/workspace/" + projectDetails.workspaceName + "/" + projectDetails.workspaceId + "/" + projectDetails.orgId + "/" + ( getactiveName() === 'home' ? "All" : "Archived");
        }
        this.props.pushState({
            pathname: pathName
        });
        }
    };

    inviteByEmail(email, isAdmin) {
        logEvent("buttons", "Sending invite");
        this.setState({inviteInProcess: true});
        addContributor(this.state.currentProject, email, isAdmin, this.inviteSent)
    }

    inviteSent(error, response) {
        console.log("invite sent ", error, response);
        this.setState({inviteInProcess: false});
        if (!error) {
          logEvent("buttons", "Invite sent success");
        this.loadProjectDetails(this.state.currentProject)
          showAlert(MESSAGES.CONTRIBUTOR_ADDED_SUCCESS, SUCCESS);
        } else {
          logEvent("buttons", "Invite sent fail");
          this.setState({ inviteModal: false, error: true });
        }
      }

    openModal() {
        document.body.classList.add('c-modal');
        this.setState({ inviteModal: true });
    }

      closeModal() {
        document.body.classList.remove('c-modal');
        this.setState({ inviteModal: false });
    }

    render() {

        const { projectDetails, successModal,successMessage,confirmModalVisible,statusActiveComplete,inviteModal,currentpPage,currentwPage } = this.state;
        const path = "/projects/" +
                    this.props.params.orgName +
                    "/" +
                    this.props.params.projectName +
                    "/" +
                    this.props.params.projectId +
                    "/";
        let createdDate = "";
        let permissions = {};
        let taggingProgress = 0;
        if (projectDetails  && projectDetails.permissions) {

            taggingProgress = Number(
                                (projectDetails.totalHitsDone * 100) /
                                 projectDetails.totalHits).toFixed(0);
            permissions = projectDetails.permissions;
            if (projectDetails.created_timestamp) {
                createdDate = timeConverter(projectDetails.created_timestamp / 1000);
            }
            if (projectDetails.totalHits === 0){
                taggingProgress = 0;
            }
        }

        return (
           <div className="taggerPages pr_project_detail divContainer" >
                <div>
                  {projectDetails && (
                    // Back button and navigation START
                    <Segment basic vertical size="large" className="title"  >
                        <div 
                        className="flexBetween"
                         >
                            <div className="f-column">
                                <div className="f-center">
                                    <a className="m-r-5" onClick={() => {
                                            let backUrl = "/workspace";
                                            let params = queryString.parse(this.props.location.search)
                                            let currentPage = localStorage.getItem('currentProjectPage');
                                            let currentpPage = (params.length > 0 && params.currentpPage !== '') ? params.currentpPage : (currentPage == undefined) ? 1 : currentPage
                                            let currentwPage = (params.length > 0 && params.currentwPage !== '') ? params.currentwPage : 1
                                            if( getactiveName() !== 'shared' && (parseInt(getUidToken().roleId)  === 2 ) ){
                                                backUrl = "/workspace/" + projectDetails.workspaceName + "/" + projectDetails.workspaceId + "/" + projectDetails.orgId + "/" + ( getactiveName() === 'home' ? "All" : "Archived") + '?currentpPage='+currentpPage+'&currentwPage='+currentwPage;
                                            } else {
                                                backUrl = "/projects/" + 3 + "/list?currentpPage="+currentpPage;
                                            }
                                                this.props.pushState(backUrl)
                                            }
                                    }>
                                    <span className="t_btn_back">
                                        <img alt="back" src={BACK} />
                                    </span>
                                    </a>
                                </div>

                                <div className="breadcrum-sec">
                                    <div className="sec_holder">
                                        <a                                        
                                        href={"/projects/" + projectDetails.orgName} onClick={event => {
                                            this.props.pushState(
                                            "/projects/" + projectDetails.orgName
                                            );
                                            event.preventDefault();
                                            }} title={projectDetails.orgName}>{projectDetails.orgName}</a>
                                        { (parseInt(getUidToken().roleId)  === 2 && getactiveName() !== "shared") &&
                                        breadcrumbSeperate()}
                                        { (parseInt(getUidToken().roleId)  === 2 && getactiveName() !== "shared") &&

                                        <a 
                                        href={"/workspace/" + projectDetails.workspaceName +'/' + projectDetails.workspaceId +
                                        '/' + projectDetails.orgId + '/All'} onClick={event => {
                                            let queryParam =  ''
                                            let isFilter = localStorage.getItem('isFilter');
                                            let projectType;
                                            if(isFilter == 1) {
                                                projectType = localStorage.getItem('projectType');
                                            }
                                            if(isFilter == 0) {
                                              queryParam = 'currentwPage=' + currentwPage + '&currentpPage=' + currentpPage + '&isFilter=' + isFilter
                                            } else {
                                              queryParam = 'projectType=' + projectType + '&currentwPage=' + currentwPage + '&currentpPage=' + currentpPage + '&isFilter=' + isFilter
                                            }
                                        this.props.pushState(
                                            "/workspace/" + projectDetails.workspaceName +'/' + projectDetails.workspaceId +
                                            '/' + projectDetails.orgId + '/All?' + queryParam
                                        );
                                        event.preventDefault();
                                        }} title={projectDetails.workspaceName}> {projectDetails.workspaceName} </a> }
                                        {breadcrumbSeperate()}
                                        <a className={"tilte_display fw-400 active " + (projectDetails.name.length > 15 ? 'pro_width' : '')} title={projectDetails.name}>{projectDetails.name}</a>
                                    </div>
                                </div>
                            </div>

                            {/* Right side Options */}
                            <div className="project_set" style={{ marginTop : "-50px"}}>

                            {projectDetails &&
                                projectDetails.contributorDetails && (
                                    <div className="project_dec">
                                        <h4 className="txt-captalize">{projectDetails.visibility_type} </h4>
                                        <h4>{getTaskType(projectDetails)} </h4>
                                        <h4>Created On {createdDate} </h4>
                                        <h4>{projectDetails.contributorDetails.length} Contributor(s)</h4>
                                    </div>
                                )}

                                <Dropdown icon="options"  button className="icon mini ctrl_icon dropdown_mat ">
                                    <Dropdown.Menu>
                                    <Dropdown.Item
                                        disabled={!permissions.canUploadData || statusActiveComplete}
                                        onClick={this.openScreen.bind(this, "edit", "file")}
                                    >
                                        {" "}
                                        <Icon name="add circle" color="blue" /> Add Data
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        disabled={!permissions.canEditProject || statusActiveComplete}
                                        onClick={this.openScreen.bind(this, "edit", "label")}
                                    >
                                    {" "}
                                        <Icon name="edit" color="blue" />
                                    Edit Project
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        disabled={!permissions.canSeeCompletedHITs}
                                        onClick={this.openScreen.bind(this, "overview", "done")}
                                    >
                                    {" "}
                                        <Icon name="database" color="blue" />
                                        HITs Done1
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        disabled={!permissions.canDownloadData}
                                        onClick={this.openScreen.bind(this, "export")}
                                    >
                                        <Icon name="download" color="blue" />
                                        Download
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        disabled={!permissions.canInviteCollaborators}
                                        onClick={this.openScreen.bind(this, "contributors")}
                                    >
                                        {" "}
                                        <Icon name="users" color="blue" /> Contributors
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        disabled={!permissions.canDeleteProject}
                                        onClick={this.archiveSelectedProject}
                                    >
                                        <Icon name="file archive" color="blue" />
                                        Archive
                                    </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        {/* Hits Progress Table  */}

                        <div className="project_view">

                            <div className="p_view">
                            <div className="p_title">
                                <img alt="overview" src={OVERVIEW} />
                                <h3>Overview</h3>
                            </div>
                            <div className="det_box">
                                <div className="DBBody">
                                    {projectDetails &&
                                        projectDetails.contributorDetails && (
                                    <div className="DBStatus">
                                        <h5> HITs Progress </h5>
                                        <div className="statusBar">
                                            <Progress
                                                    color="green"
                                                    size="large"
                                                    indicating
                                                    percent={taggingProgress}
                                                    precision={2}
                                                    progress="percent"
                                                    className="prgresSize"
                                                />
                                        </div>
                                            <span className="statusCount">{taggingProgress}% Completed</span>
                                    </div>
                                    )}
                                </div>
                                {projectDetails &&
                                    projectDetails.contributorDetails && (

                                <div className="count_set">
                                <div>
                                    <Statistic.Group size="mini" widths="eight" className="count_holder">
                                        <Statistic
                                        color="green"
                                        size="mini"
                                        as="a"
                                        onClick={event => {
                                            this.openScreenDicom("overview", "done", "doneScreen");
                                           event.preventDefault();
                                        }}
                                        >
                                            <Statistic.Value>
                                                {projectDetails.totalHitsDone}
                                            </Statistic.Value>
                                            <Statistic.Label style={statsLabel}>
                                                HITs Done
                                            </Statistic.Label>
                                        </Statistic>
                                        <Statistic
                                        color="violet"
                                        as="a"
                                        size="mini"
                                        	onClick={event => {
                                                this.openScreenDicom("overview", "pending", "pendingScreen");
                                               event.preventDefault();
                                            }}
                                            >
                                            <Statistic.Value>
                                                {projectDetails.totalHits - projectDetails.totalHitsDone}
                                            </Statistic.Value>
                                            <Statistic.Label style={statsLabel}>
                                                HITs Pending
                                            </Statistic.Label>
                                        </Statistic>
                                        <Statistic
                                        color="green"
                                        size="mini"
                                        as="a"
                                        onClick={event => {
                                            this.openScreenDicom("overview", "all", "totalScreen");
                                           event.preventDefault();
                                        }}
                                        >
                                            <Statistic.Value color="blue">
                                                {projectDetails.totalHits}
                                            </Statistic.Value>
                                            <Statistic.Label style={statsLabel}>
                                                Total HITs
                                            </Statistic.Label>
                                        </Statistic>
                                    </Statistic.Group>
                                </div>

                            {(projectDetails.totalEvaluationInCorrect > 0 ||
                            projectDetails.totalEvaluationCorrect > 0) && (
                                <div style={{ textAlign: 'left' }}>
                                <h5>Evaluation Stats </h5>
                                    <Statistic.Group size="mini" widths="four">
                                        <Statistic
                                        color="green"
                                        size="mini"
                                        >
                                        <Statistic.Value>
                                            {projectDetails.totalEvaluationCorrect}
                                        </Statistic.Value>
                                        <Statistic.Label style={statsLabel}>
                                            Correct
                                        </Statistic.Label>
                                        </Statistic>
                                        <Statistic
                                        color="red"
                                        size="mini"
                                        >
                                        <Statistic.Value>
                                            {projectDetails.totalEvaluationInCorrect}
                                        </Statistic.Value>
                                        <Statistic.Label style={statsLabel}>
                                            Incorrect
                                        </Statistic.Label>
                                        </Statistic>
                                    </Statistic.Group>
                                </div>
                                )}
                                </div>
                                )}

                                {projectDetails && (
                                <div className="label_set">
                                    <a href={path + "visualize"}
                                        disabled={!permissions.canSeeInsights} onClick={event => {
                                            this.openScreen("visualize");
                                            event.preventDefault();
                                        }}>
                                        <img alt="chart" src={CHART} />
                                        <span> Insights</span>
                                    </a>

                                    <a href={path + "view"}
                                        disabled={(parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR) && !permissions.canCompleteHITs || statusActiveComplete } onClick={event => {
                                        this.openScreen("view", "leaderBoard");
                                        event.preventDefault();
                                        }}
                                         style = {{display:parseInt(getUidToken().roleId) === ROLES_TYPES.QA ? "none" : "block" }}>
                                        <img alt="tag" src={TAG} />
                                        <span> Start tagging</span>
                                    </a>

                                    <a disabled={(!permissions.canInviteCollaborators || statusActiveComplete)} onClick={this.openInviteModal} style = {{display: displaySettingsByRole(getUidToken().roleId)}}>
                                        <img alt="plus" src={PLUS} />
                                        <span> Add Contributor</span>
                                    </a>
                                </div>
                                )}
                            </div>
                        </div>

                        <div style={{width:"50%"}} className="p_view">
                            <div className="p_title">
                                <img alt="wchart" src={WCHART} />
                                <h3>Leaderboard</h3>
                            </div>
                            {projectDetails &&
                            projectDetails.contributorDetails &&
                            projectDetails.contributorDetails.length > 0 && (
                                <div className="table_view">
                                    <Table striped condensed hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Time(s) / HIT</th>
                                                <th>Total Tagged HITs</th>
                                                <th>Total Label Count</th>
                                                <th>Status</th>                         
                                                {!projectDetails.projectContributor && this.isShowActions(projectDetails) &&
                                                <th>Actions</th>                         
                                                }
                                            </tr>
                                        </thead>
                                            {this.getContributorsData(projectDetails)}
                                    </Table>
                                </div>
                                )}
                            </div>
                        </div>
                        {inviteModal && (
                          <ShareProjects
                            submitEmail={this.inviteByEmail}
                            modalOpen={this.openModal}
                            orgId={projectDetails ? projectDetails.orgId : null}
                            modalClose={this.closeModal}
                          />
                        )}
                        <div className="p_sampleview m-t-40" style = {{display: displaySettingsByRole(getUidToken().roleId)}}>
                            <div className="p_title">
                                <img alt="status" src={STATUS} />
                                <h3>Project Status</h3>
                            </div>
                            <div className="s_det_box">
                            <div className="label_set">
                                    <a className="completed" disabled = {statusActiveComplete}
                                    	onClick = {() => this.projectStatusClickEvent("completed") }
                                        >
                                        <AntIcon className="icn_state" type="check" />
                                        <span className="m-l-10"> Completed</span>
                                    </a>

                                    <a className="reopen" disabled={!statusActiveComplete}
                                    	onClick = {() => this.projectStatusClickEvent('reOpened') }
                                        >
                                        <AntIcon className="icn_state" type="rollback" />
                                        <span className="m-l-10"> Reopen</span>
                                    </a>
                            </div>
                            </div>
                        </div>
                    </Segment>
                    )}
                </div>
                {confirmModalVisible &&
                    this.showConfirmMessage("reOpened")
                }

                {/* Add contributor Success modal */}
                {successModal && (
                <div className="static-modal m-t-50">
                    <Modal.Dialog>
                    <Modal.Header>
                        <Modal.Title>Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{successMessage}</Modal.Body>

                    <Modal.Footer>
                        <Button bsStyle="success" onClick={this.close}>
                        Close
                        </Button>
                    </Modal.Footer>
                    </Modal.Dialog>
                </div>
                )}
            </div>

        )
    }
}

const mapStateToProps = (state) => {
  return {
    currentProject: state.projectReducer.currentProject,
    projectDetails: state.projectReducer.projectDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        pushState: push,
        setCurrentProject,
        updateProjectDetails,
        updatePrevPageDeatails,
        setloaderMenu,
      },
      dispatch
    );
  
export default connect(
    mapStateToProps,
     mapDispatchToProps
    )(DicomOverviewContainer);
