import React from 'react';
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color';
import { Tooltip } from 'antd';
import 'antd/dist/antd.css';
import { TOOLTIP} from '../../helpers/Constants';

class ColorPickerForLabel extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
    }

    state = {
        displayColorPicker: false
    };

    handleClick = () => {
        const { displayColorPicker } = this.state;
        this.setState({ displayColorPicker: !displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {
        const { index } = this.props;
        this.props.handleColorChange(color, index);
    };

    render() {
        const { color } = this.props;
        const { displayColorPicker } = this.state;
        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: color,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <span>
                <div style={styles.swatch} onClick={this.handleClick}>
                    <Tooltip title={TOOLTIP.SELECT_COLOR}>
                        <div style={styles.color} />
                    </Tooltip>
                </div>
                {displayColorPicker ? <div style={styles.popover}>
                    <div style={styles.cover} onClick={this.handleClose} />
                    <SketchPicker color={color} onChange={this.handleChange} />
                </div> : null}
            </span>
        )
    }
}

export default ColorPickerForLabel;
