import { DUMMY_UID, DUMMY_TOKEN } from '../helpers/Utils';
import { BASE_URL } from '../helpers/Constants';
import { middleCallback } from "./commonApis";
const superagent = require("superagent");

export const getUidToken = (dummyRequired) => {
    let token = window.sessionStorage.getItem("token");
    let uid = window.sessionStorage.getItem("uid");
    let roleId = window.localStorage.getItem("roleId");
    let orgId = window.localStorage.getItem("orgId");
    let pwdChanged = window.sessionStorage.getItem("passwordChanged");
    if (dummyRequired) {
      uid = DUMMY_UID;
      token = DUMMY_TOKEN;
    }
    return { uid, token, roleId, pwdChanged, orgId };
  };


export const dtLogin = (email, password, callback) => {
    console.log("adding hit ", email, password);
    const { uid, token } = getUidToken(true);
    console.log("url",BASE_URL+"pronotate/"+"login")
  
    superagent
      .post(BASE_URL + "login")
      .set("uid", uid)
      .set("token", token)
      .send({
        "email": email,
        "password": password,
      })
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };


  export const getAdminsByOrgIdForRegister = (orgId, callback) => {
    const { uid, token } = getUidToken();
    let url = BASE_URL + "getAdminsAndUsersByOrgIdForRegister/" + orgId;
    superagent
      .post(url)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  }

  export const forgotPasswordCall = (email, callback) => {
    console.log("Forgot Password Call ",callback);
	  const { uid, token } = getUidToken(true);
	  let url = BASE_URL + "forgetPassword?email=" + email;
	  superagent
	    .post(url)
	    .set("uid", uid)
	    .set("token", token)
	    .end((err, res) => {
	      middleCallback(err, res, callback)
	    });
  }

  export const updateUserPassword = (oldPassword,newPassword,callback) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + "updatePassword")
      .send({ uid:uid,oldPassword: oldPassword, password: newPassword})
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };

// Create User
  export const createUserWithPassword = (fname, lname, email, password, orgName, mobile, role, admin, callback) => {
    console.log("createUserWithPassword ", BASE_URL, window.location);
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + "createUserWithPassword")
      .send({ firstName: fname, secondName: lname, email, authType: 'emailSignUp', orgName: orgName, phone: mobile, role: role, admin: admin })
      .set("uid", uid)
      .set("token", token)
      .set("password", password)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };

  // Logout
  export const dtLogout = () => {
    const {uid, token} = getUidToken();
    console.log("Entered in dtlogout",uid,token)

  superagent
    .post(BASE_URL + "logout")
    .set("uid", uid)
    .set("token", token)
    .end();
  };

  export const clearSessionStorage = () => {
    window.sessionStorage.removeItem("uid");
    window.sessionStorage.removeItem("token");
    window.localStorage.removeItem("orgId");
    window.sessionStorage.removeItem("lastTimeAccessAPI");
  };

  
  export const getUserInfoForResetPassword = (userId, callback) => {
    let url = BASE_URL + "getUserInfoForResetPassword/" + userId;
    superagent
      .get(url)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };

  export const resetPassword = (uid, newPassword, callback) => {
    superagent
      .post(BASE_URL + "resetPassword")
      .send({ uid: uid, password: newPassword })
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };